import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import styles from "./createSequencesPage.module.scss";
import { SequencesInfoStep } from "../../../components/adminComponents/createSequencesComponents/sequencesInfoStep/sequencesInfoStep";
import { ISequencesCreationDto } from "../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { AddSequencesStep } from "../../../components/adminComponents/createSequencesComponents/addSequencesStep/addSequencesStep";
import { useMutation, useQuery } from "@apollo/client";
import {
    AddSequencesTemplateMutation,
    TasksQuery,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useNavigate } from "react-router-dom";
import { areSequencesEqual } from "../../../components/helpers/areSequencesEqual/areSequencesEqual";
import { IncompleteSectionModal } from "../../../components/adminComponents/createSequencesComponents/IncompleteSectionModal/IncompleteSectionModal";

interface IStep {
    title: string;
    description: string;
}

export const CreateSequencesPage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isFormValid, setisFormValid] = useState<boolean>(false);
    const [isSequenceDelete, setIsSequenceDelete] = useState<boolean>(false);
    const [isStepDelete, setIsStepDelete] = useState<boolean>(false);
    const [canBeCreated, setCanBeCreated] = useState<boolean>(false);
    const [sequenceType, setSequenceType] = useState<string>("dti");
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [sequencesTemplateId, setSequencesTemplateId] = useState<string>("");
    const [sequencesDetails, setSequencesDetails] = useState<
        Partial<ISequencesCreationDto>
    >({
        name: "",
        stepSequencesTemplate: [],
    });
    const [sequencesTemplate, setSequencesTemplate] = useState<
        Partial<ISequencesCreationDto>
    >({
        name: "",
        stepSequencesTemplate: [
            { sequenceType: undefined, stepTemplates: [{}] },
        ],
    });
    const initialSteps = [
        {
            title: t("createSequences.steps.1.title"),
            description: t("createSequences.steps.1.description"),
        },
    ];
    const [steps, setSteps] = useState<IStep[]>(initialSteps);
    const { setHeaderContent } = useHeader();
    const [
        isIncompleteSectionModalVisible,
        setIsIncompleteSectionModalVisible,
    ] = useState<boolean>(false);

    const [AddSequencesTemplate] = useMutation<AddSequencesTemplateMutation>(
        fragments.CREATE_SEQUENCES,
        {
            variables: {
                input: {
                    name: sequencesDetails.name,
                    stepSequenceTemplates:
                        sequencesDetails.stepSequencesTemplate?.map((e) => ({
                            ...e,
                            stepTemplates: e.stepTemplates?.map(
                                (s, index: number) => ({
                                    ...s,
                                    id: null
                                })
                            ),
                        })),
                },
            },
        }
    );

    const [tasks, setTasks] = useState<TasksQuery>();

    const { data: TaskTemplateData } = useQuery<TasksQuery>(
        fragments.GET_TASKS_BY_SEQUENCE,
        {
            variables: {
                input: sequenceType,
            },
        }
    );

    useEffect(() => {
        if (!TaskTemplateData) return;
        setTasks(TaskTemplateData);
    }, [TaskTemplateData]);

    const checkIsFormValid = (
        sequencesDetails: Partial<ISequencesCreationDto>
    ): boolean => {
        let result = true;
        if (!sequencesDetails.name) result = false;
        sequencesDetails.stepSequencesTemplate?.forEach((sequence) => {
            if (sequence.sequenceType === undefined) {
                result = false;
            }
            sequence.stepTemplates?.forEach((step) => {
                if (!step.unversionedEcsTaskName || !step.version) {
                    result = false;
                }
            });
        });
        return result;
    };

    const checkIfCanDeleteSequence = (
        sequencesDetails: Partial<ISequencesCreationDto>
    ): boolean => {
        let result = true;
        if (sequencesDetails.stepSequencesTemplate?.length! <= 1) {
            result = false;
        }
        return result;
    };

    useEffect(() => {
        setisFormValid(checkIsFormValid(sequencesDetails));
        setIsSequenceDelete(checkIfCanDeleteSequence(sequencesDetails));
    }, [sequencesDetails]);

    useEffect(() => {
        setCanBeCreated(
            isFormValid &&
                areSequencesEqual(sequencesDetails, sequencesTemplate)
        );
    }, [sequencesTemplate, sequencesDetails]);

    const onFinish = () => {
        setIsResultModalVisible(true);

        AddSequencesTemplate({ variables: sequencesTemplate })
            .then((result) => {
                if (!result.data) {
                    setIsResultModalError(true);
                }
                setIsResultModalLoading(false);
                setSequencesTemplateId(result.data?.addSequencesTemplate.id!);
            })
            .catch((error) => {
                setIsResultModalLoading(false);
                setIsResultModalError(true);
            });
    };

    const onEditClick = (buttonIndex: number) => {
        if (!areSequencesEqual(sequencesDetails, sequencesTemplate)) {
            setIsIncompleteSectionModalVisible(true);
        } else {
            setCurrentStep(buttonIndex);
        }
    };

    const onModalTecnique = () => {
        if (!areSequencesEqual(sequencesDetails, sequencesTemplate)) {
            setIsIncompleteSectionModalVisible(true);
        } else {
            onAddTecnique();
        }
    };

    const onAddTecnique = () => {
        setIsIncompleteSectionModalVisible(false);
        if (!sequencesDetails || !sequencesDetails.stepSequencesTemplate)
            return;
        const newSequence = [...sequencesDetails?.stepSequencesTemplate];
        newSequence.push({ sequenceType: undefined, stepTemplates: [{}] });
        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: newSequence,
        }));
        setCurrentStep(steps.length);
    };

    useEffect(() => {
        setHeaderContent({
            title: t("createLicense.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/sequences",
            buttonElement: (
                <Button
                    key="createSequencesButton"
                    type="primary"
                    size="large"
                    disabled={!canBeCreated}
                    onClick={onFinish}
                >
                    {t("createSequences.save")}
                </Button>
            ),
        });
    }, [isFormValid, canBeCreated]);

    useEffect(() => {
        const step = {
            title: t("createSequences.steps.2.title"),
            description: t("createSequences.steps.2.description"),
        };
        const newSteps = initialSteps;
        sequencesDetails.stepSequencesTemplate?.forEach(() => {
            newSteps.push(step);
        });
        setSteps(newSteps);
    }, [sequencesDetails.stepSequencesTemplate]);

    const onShowSequences = () => {
        navigate("../sequences");
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isEditStep={false}
                        isIconVisible={true}
                        currentStep={currentStep}
                        title={t("createSequences.steps.title")}
                        steps={steps}
                        onItemClick={onEditClick}
                        icon={
                            <span
                                className={`kenko-icon-edit ${styles.Edit}`}
                            />
                        }
                    />

                    <Spacer size={"s"} />

                    {currentStep >= 1 && (
                        <div className={styles.ButtonDiv}>
                            <Button
                                className={styles.Button}
                                disabled={!isFormValid}
                                onClick={onModalTecnique}
                            >
                                {t("createSequences.addTecnique")}
                                <span
                                    className={`kenko-icon-plus ${styles.ButtonIcon}`}
                                />
                            </Button>
                        </div>
                    )}
                </Col>

                <Col span={24} md={16} xl={18}>
                    {(currentStep === 0 && (
                        <SequencesInfoStep
                            setSequencesDetails={setSequencesDetails}
                            sequencesDetails={sequencesDetails}
                            sequencesTemplate={sequencesTemplate}
                            setSequencesTemplate={setSequencesTemplate}
                            onAddTecnique={onAddTecnique}
                        />
                    )) ||
                        (currentStep >= 1 && (
                            <AddSequencesStep
                                setCurrentStep={setCurrentStep}
                                currentStep={currentStep}
                                setSequencesDetails={setSequencesDetails}
                                sequencesDetails={sequencesDetails}
                                sequenceIndex={0}
                                isFormValid={isFormValid}
                                isSequenceDelete={isSequenceDelete}
                                isStepDelete={isStepDelete}
                                setIsStepDelete={setIsStepDelete}
                                setSteps={setSteps}
                                steps={steps}
                                sequencesTemplate={sequencesTemplate}
                                setSequencesTemplate={setSequencesTemplate}
                                sequenceType={sequenceType}
                                setSequenceType={setSequenceType}
                                tasks={tasks}
                            />
                        ))}
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowSequences}
                localisedTexts={{
                    title: t("createSequences.successModal.title"),
                    subtitle: t("createSequences.successModal.subtitle"),
                    secondaryButton: t(
                        "createSequences.successModal.createAnother"
                    ),
                    primaryButton: t("createSequences.successModal.showCase"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
            <IncompleteSectionModal
                isModalVisible={isIncompleteSectionModalVisible}
                setIsModalVisible={setIsIncompleteSectionModalVisible}
            />
        </>
    );
};
