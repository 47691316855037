import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useHeader } from "../../hooks/headerHook";
import Error404 from "./error-404.svg";
import styles from "./errorPage.module.scss";
import { Typography } from "antd";
import { Spacer } from "../../components/appCore/spacer/spacer";
import { Link } from "react-router-dom";

const ErrorPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const { Text, Title } = Typography;

    useEffect(() => {
        setHeaderContent({
            title: "",
            breadCrumb: [],
            goBackLinkOverride: undefined,
            isHeaderHidden: true,
        });
    }, [setHeaderContent, t]);

    return (
        <div className={styles.Content}>
            <img src={Error404} alt="404" />
            <div className={styles.ErrorTextBox}>
                <Text>{t("errorPage.error404")}</Text>
                <Title>{t("errorPage.title")}</Title>
                <Text className={styles.Subtext}>
                    {t("errorPage.subtitle")}
                </Text>
                <Spacer size="m" />
                <Link className={styles.Link} to="/">
                    <div>
                        <span className="kenko-icon-clip" />
                        <u>{t("errorPage.backToCases")}</u>
                    </div>
                </Link>
                <Spacer size="xs" />
                <Link className={styles.Link} to="/myProfile">
                    <div>
                        <span className="kenko-icon-clip" />
                        <u>{t("errorPage.myProfile")}</u>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ErrorPage;
