import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { StepsComponent } from "../../../components/appCore/stepsComponent/stepsComponent";
import { useHeader } from "../../../hooks/headerHook";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import styles from "./assignLicenseFeature.module.scss"
import { useMutation } from "@apollo/client";
import {
    AssignLicenseFeatureToOrgMutation,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import Table, { ColumnsType } from "antd/lib/table";
import { ILicenseFeatureSimpleData } from "../../../features/licenseFeature/domain/entities/licenseFeature";
import Card from "antd/lib/card/Card";
import { useUnassignedLicenseFeaturea } from "../../../features/licenseFeature/domain/hooks/useUnassignedLicenseFeatures";
import { Spacer } from "../../../components/appCore/spacer/spacer";


const { Title, Text } = Typography;

export const AssignLicenceFeaturesPage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [currentStep] = useState<number>(0);
    const { setHeaderContent } = useHeader();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    let { organisationId } = useParams<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const {data: licenseFeatures, getUnassignedLicenseFeatures} =  useUnassignedLicenseFeaturea();
    const [AssignLicenceFeatures] = useMutation<AssignLicenseFeatureToOrgMutation>(
        fragments.ASSIGN_LICENSE_FEATURES_TO_ORG
    );

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        setHeaderContent({
            title: t("editOrganisation.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/organisation/" + organisationId,
        });
        
        if(!organisationId) return;
        getUnassignedLicenseFeatures(organisationId)
    }, []);

    const onShowOrganisation = () => {
        navigate("../organisation/" + organisationId);
    };

    const onFinish = () => {
        setIsResultModalVisible(true);
        AssignLicenceFeatures({
            variables: {
                orgId: organisationId,
                licenseFeatureIds: selectedRowKeys
            },
        })
            .then((resOrg) => {
                if (!resOrg.data) {
                    setIsResultModalError(true);
                }
                setIsResultModalLoading(false);
            })
            .catch(() => {
                setIsResultModalLoading(false);
                setIsResultModalError(true);
            });
        
    };

    const columns: ColumnsType<ILicenseFeatureSimpleData> = [

        {
            title: t("licenseFeature.feature.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("licenseFeature.feature.description"),
            dataIndex: "description",
            key: "description"
        },
        {
            title: t("licenseFeature.feature.price"),
            dataIndex: "price",
            key: "price"
        },
    ];

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepsComponent
                        currentStep={currentStep}
                        title={t("licenseFeature.assignFeaturesPage.title")}
                        steps={[
                            {
                                title: t("licenseFeature.assignFeaturesPage.step.title"),
                                description: t("licenseFeature.assignFeaturesPage.step.description"),
                            },
                        ]}
                        isComplete={true}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <Card bordered={true}>
                        <div>
                            <Title level={4}>{t("licenseFeature.assignFeaturesPage.card.title")}</Title>
                            <Text type="secondary">
                                {t("licenseFeature.assignFeaturesPage.card.description")}
                            </Text>
                        </div>
                        <Spacer size="m"/>
                        <Table
                            columns={columns}
                            rowSelection={rowSelection}
                            dataSource={licenseFeatures}
                            rowKey="id"
                            scroll={{ x: 800 }}
                            pagination={{ pageSize: 10 }} 
                        />
                        <div className={styles.ModalButtonsDiv}>
                            <Button 
                            type="primary" 
                            htmlType="submit"
                            disabled={!hasSelected}
                            onClick={() => {
                                onFinish()
                            }}
                            >
                                {t("licenseFeature.assignFeaturesPage.assignButton")}
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowOrganisation}
                isSecondaryButtonDisabled={true}
                localisedTexts={{
                    title: t("licenseFeature.assignFeaturesPage.resultModal.title"),
                    subtitle: t("licenseFeature.assignFeaturesPage.resultModal.subtitle"),
                    primaryButton: t("licenseFeature.assignFeaturesPage.resultModal.return"),
                    errorTitle: t("licenseFeature.assignFeaturesPage.resultModal.error"),
                    errorRetry: t("licenseFeature.assignFeaturesPage.resultModal.retry"),
                }}
            />
        </>
    );
};
