import { IOrganisationDetailsDto } from "../../../../features/organisation/domain/entities/organisationModel";
import { Card, Divider, Skeleton, Typography } from "antd";
import styles from "./organisationDetailsDescription.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Spacer } from "../../../appCore/spacer/spacer";

const { Title, Text } = Typography;

interface IProps {
    organisationDetails: IOrganisationDetailsDto | undefined;
}

export const OrganisationDetailsDescription = ({
    organisationDetails,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const space = " ";

    return (
        <>
            <Card>
                <Title level={4}>
                    {organisationDetails ? (
                        <>{organisationDetails.name}</>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.TitleSkeleton}
                        />
                    )}
                </Title>
                <Spacer size={"m"} />
                <Text>
                    {organisationDetails ? (
                        <>
                            {t("organisationDetails.cif") + space}
                            <b>{organisationDetails.cifAndOthers}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {organisationDetails ? (
                        <>
                            {t("organisationDetails.adminName") + space}
                            <b>{organisationDetails.adminName}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {organisationDetails ? (
                        <>
                            {t("organisationDetails.adminEmail") + space}
                            <b>{organisationDetails.email}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <div className={styles.ButtonContainer}>
                    <button
                        className={styles.Button}
                        onClick={() =>
                            navigate(
                                "/editOrganisation/" + organisationDetails?.id
                            )
                        }
                    >
                        {t("edit")}
                        <span className="kenko-icon-simple-right-arrow button-icon" />
                    </button>
                </div>
            </Card>
        </>
    );
};
