import { Button, Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction } from "react";
import { Spacer } from "../../appCore/spacer/spacer";

const { Title, Text } = Typography;

interface IProps {
    setIsSendCommentModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const HelpCard = ({ setIsSendCommentModalVisible }: IProps) => {
    const { t } = useTranslation();

    return (
        <Card>
            <div>
                <Title level={4}>{t("support.help.title")}</Title>
                <Text type="secondary">{t("support.help.subtitle")}</Text>
            </div>
            <Spacer size="s" />
            <Button
                type="primary"
                onClick={() => {
                    setIsSendCommentModalVisible(true);
                }}
            >
                {t("support.help.sendComment")}
            </Button>
        </Card>
    );
};
