import { Form, Input, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import {
    IUserBasicInfo,
    IUserReducedDto,
} from "../../domain/entities/userModel";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import styles from "./createUserForm.module.scss";
import { useEffect } from "react";
import { emailValidation } from "../../../../components/helpers/emailValidation/emailValidation";
import { useQuery } from "@apollo/client";
import { IsUserDeletedQuery } from "../../../../generated/graphql";
import fragments from "../../../../gql/fragments";

const { Title, Text } = Typography;

interface IProps {
    updateIsFormValid: (value: boolean) => void;
    title: string;
    subtitle: string;
    updateUserName: (value: string | undefined) => void;
    updateUserSurname: (value: string | undefined) => void;
    updateUserEmail: (value: string | undefined) => void;
    updatedUserDetails?: IUserBasicInfo | undefined;
    isEditPage: boolean;
    isUserDeleted: boolean | undefined;
    checkEmail: string | undefined;
    updateIsUserDeleted: (value: boolean | undefined) => void;
}

export const CreateUserForm = ({
    updateIsFormValid,
    title,
    subtitle,
    updatedUserDetails,
    updateUserName,
    updateUserSurname,
    updateUserEmail,
    isEditPage,
    isUserDeleted,
    checkEmail,
    updateIsUserDeleted,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const { data: isUserDeletedData } = useQuery<IsUserDeletedQuery>(
        fragments.IS_USER_DELETED,
        {
            variables: {
                email: checkEmail,
            },
        }
    );

    useEffect(() => {
        updateIsUserDeleted(isUserDeletedData?.isUserDeleted);
    }, [isUserDeletedData]);

    const checkIsFormValid = (input: IUserReducedDto | undefined) => {
        return (
            input?.firstName !== undefined &&
            input?.firstName !== "" &&
            input?.lastName !== undefined &&
            input?.lastName !== "" &&
            input?.email !== undefined &&
            input?.email !== "" &&
            emailValidation.test(input.email)
        );
    };

    const onChange = async (
        changedValues: IUserReducedDto | undefined,
        values: IUserReducedDto | undefined
    ) => {
        updateIsFormValid(checkIsFormValid(values));
        if (!!updateUserName && !!updateUserSurname && !!updateUserEmail) {
            updateUserName(values?.firstName);
            updateUserSurname(values?.lastName);
            updateUserEmail(values?.email);
        }
    };

    useEffect(() => {
        if (!updatedUserDetails) return;
        form.setFieldsValue({
            firstName: updatedUserDetails.name,
            lastName: updatedUserDetails.surname,
            email: updatedUserDetails.email,
        });
    }, [updatedUserDetails]);

    return (
        <Form layout="vertical" form={form} onValuesChange={onChange}>
            <div>
                <Title level={4}>{title}</Title>
                <Text type="secondary">{subtitle}</Text>
            </div>
            <Spacer size="m" />
            <div className={styles.row}>
                <div className={styles.col}>
                    <Form.Item
                        label={t("createUserLicense.inviteUser.invite.name")}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "createUserLicense.inviteUser.invite.namePrompt"
                            )}
                        />
                    </Form.Item>
                </div>
                <div className={styles.col}>
                    <Form.Item
                        label={t("createUserLicense.inviteUser.invite.surname")}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "createUserLicense.inviteUser.invite.surnamePrompt"
                            )}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Form.Item
                        label={t("createUserLicense.inviteUser.invite.email")}
                        name="email"
                        className={
                            isUserDeleted === true
                                ? styles.invalidEmail
                                : styles.normal
                        }
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            disabled={isEditPage && !!updatedUserDetails}
                            placeholder={t(
                                "createUserLicense.inviteUser.invite.emailPrompt"
                            )}
                        />
                    </Form.Item>
                    {isUserDeleted === false && (
                        <Text className={styles.invalidEmail}>
                            {t("invite.invalidEmail")}
                        </Text>
                    )}
                </div>
                <div className={styles.col}></div>
            </div>
        </Form>
    );
};
