import { Button, Card, Divider, Form, Input, Select, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISequencesCreationDto } from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { Spacer } from "../../../appCore/spacer/spacer";
import styles from "./sequencesInfoStep.module.scss";
const { Title, Text } = Typography;

interface IProps {
    sequencesDetails: Partial<ISequencesCreationDto>;
    setSequencesDetails: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    sequencesTemplate: Partial<ISequencesCreationDto>;
    setSequencesTemplate: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    contextDependentTexts?: IContextDependentTexts;
    onAddTecnique: () => void;
}

interface IFormDto {
    name: string;
}

interface IContextDependentTexts {
    firstSubtitle: string;
    secondSubtitle: string;
    buttonLabel: string;
}

export const SequencesInfoStep = ({
    sequencesDetails,
    setSequencesDetails,
    contextDependentTexts,
    sequencesTemplate,
    setSequencesTemplate,
    onAddTecnique,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [form] = useForm();

    // ? Validation logic
    const checkIsFormValid = (input: IFormDto) => {
        return !!input && input.name !== undefined && input.name !== "";
    };

    useEffect(() => {
        if (!sequencesDetails) return;
        form.setFieldsValue({ name: sequencesDetails.name });
    }, [sequencesDetails]);

    const onFinish = () => {};

    const onChange = (
        changedValues: IFormDto | undefined,
        values: IFormDto | undefined
    ) => {
        if (!values) return;
        setIsFormValid(
            checkIsFormValid(values) && values.name !== sequencesTemplate.name
        );
        if (!checkIsFormValid(values)) return;

        setSequencesDetails((prevState) => ({
            ...prevState,
            name: values?.name,
        }));
    };

    return (
        <Card>
            <Form
                layout="vertical"
                onValuesChange={onChange}
                onFinish={onFinish}
                form={form}
            >
                <div>
                    <Title level={4}>
                        {t("createSequences.infoStep.information")}
                    </Title>
                    <Text type="secondary">
                        {t(
                            contextDependentTexts
                                ? contextDependentTexts.firstSubtitle
                                : "createSequences.infoStep.informationSubtitle"
                        )}
                    </Text>
                </div>

                <Spacer size={"s"} />

                <div className={styles.Row}>
                    <div className={styles.Col}>
                        <Form.Item
                            label={t("createSequences.infoStep.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    "createSequences.infoStep.namePrompt"
                                )}
                            />
                        </Form.Item>
                    </div>
                </div>

                <Spacer size={"s"} />

                <Form.Item className={styles.StepNavigation}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!isFormValid}
                        onClick={() => {
                            onAddTecnique();
                            setSequencesTemplate(sequencesDetails);
                        }}
                    >
                        {t(
                            contextDependentTexts
                                ? contextDependentTexts.buttonLabel
                                : "createSequences.next"
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
