import { throwExceptionFromGqlQueryIfExists } from "../../../../core/data/dataSources/kenkoDataSource";
import {
    OrganisationListPresentation,
    useAllOrganisationsLazyQuery,
} from "../../../../generated/graphql";

export interface IOrganisationDataSource {
    getAllOrganisations: (
        pageSize: number,
        pageNumber: number
    ) => Promise<OrganisationListPresentation | undefined | null>;
}

export const OrganisationDataSource = (): IOrganisationDataSource => {
    const [allOrganisationsQuery] = useAllOrganisationsLazyQuery();

    const getAllOrganisations = async (
        pageSize: number,
        pageNumber: number
    ) => {
        const result = await allOrganisationsQuery({
            variables: {
                input: { pageSize: pageSize, pageNumber: pageNumber },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.organisations;
    };

    return {
        getAllOrganisations,
    };
};
