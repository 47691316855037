import React, { useState } from "react";
import { IBreadCrumb } from "../models/core/breadCrumbModel";

interface IHeaderContext {
    headerContent: IHeaderContent;
    setHeaderContent: React.Dispatch<React.SetStateAction<IHeaderContent>>;
}
export interface IHeaderContent {
    title: string;
    subtitle?: string;
    breadCrumb: IBreadCrumb[];
    buttonElement?: JSX.Element;
    hasCreateCaseButton?: boolean;
    hasInviteUserButton?: boolean;
    goBackLinkOverride?: string;
    isHeaderHidden?: boolean;
    enableBlockOnLeave?: boolean;
}

const initialState: IHeaderContext = {
    headerContent: {
        title: "",
        breadCrumb: [],
        isHeaderHidden: false,
        enableBlockOnLeave: false,
        hasCreateCaseButton: false,
        hasInviteUserButton: false,
    },
    setHeaderContent: () => ({}),
};

export const HeaderContext = React.createContext(initialState);

export const HeaderProvider = ({ children }: any) => {
    const [headerContent, setHeaderContent] = useState<IHeaderContent>({
        title: "",
        breadCrumb: [],
        enableBlockOnLeave: false,
    });
    return (
        <HeaderContext.Provider
            value={{
                setHeaderContent,
                headerContent,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};
