import { useTranslation } from "react-i18next";
import { Button, Card, Row, Typography } from "antd";
import { Spacer } from "../../appCore/spacer/spacer";
import { ViewFinalDiagnosis } from "./viewFinalDiagnosis/viewFinalDiagnosis";
import { AddFinalDiagnosis } from "./addFinalDiagnosis/addFinalDiagnosis";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FinalDiagnosisModal } from "../../../features/case/presentation/finalDiagnosisModal";

const { Title, Text } = Typography;

interface IProps {
    caseId?: string;
    isFinalDiagnosisComplete: boolean;
    caseDetails: any;
    setCaseDetails: Dispatch<SetStateAction<any>>;
    isOrgAdmin: boolean;
}

export const CaseDetailsFinalDiagnosis = ({
    caseId,
    isFinalDiagnosisComplete,
    caseDetails,
    setCaseDetails,
    isOrgAdmin,
}: IProps) => {
    const { t } = useTranslation();
    const [
        isAddFinalDiagnosisModalVisible,
        setIsAddFinalDiagnosisModalVisible,
    ] = useState<boolean>(false);

    return (
        <>
            <Title level={4}>{t("caseDetails.finalDiagnosis.title")} </Title>
            <Card>
                {isFinalDiagnosisComplete ? (
                    <ViewFinalDiagnosis caseDetails={caseDetails} />
                ) : (
                    <AddFinalDiagnosis
                        setIsModalVisible={setIsAddFinalDiagnosisModalVisible}
                        isOrgAdmin={isOrgAdmin}
                    />
                )}

                <Spacer size={"s"} />

                <Row justify="end">
                    {
                        (
                            <Button disabled>
                                {t("caseDetails.finalDiagnosis.download")}
                                <span className="kenko-icon-download button-icon" />
                            </Button>
                        ) && false // TODO: Enable this button once download is actually implemented
                    }
                </Row>
            </Card>
            <FinalDiagnosisModal
                isVisible={isAddFinalDiagnosisModalVisible}
                setIsVisible={setIsAddFinalDiagnosisModalVisible}
                caseId={caseId}
                caseDetails={caseDetails}
                setCaseDetails={setCaseDetails}
            />
        </>
    );
};
