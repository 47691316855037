import { Button, Card, Form, Row, Select, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ISequencesCreationDto,
    IStepSequenceTemplate,
    IStepTemplate,
} from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { Spacer } from "../../../appCore/spacer/spacer";
import styles from "./addTecnique.module.scss";
import { TaskInfo } from "./taskInfo";
import { TasksQuery } from "../../../../generated/graphql";
import { areSequencesEqual } from "../../../helpers/areSequencesEqual/areSequencesEqual";
const { Title, Text } = Typography;

interface IProps {
    setCurrentStep: Dispatch<SetStateAction<number>>;
    sequencesDetails: Partial<ISequencesCreationDto>;
    setSequencesDetails: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    sequencesTemplate?: Partial<ISequencesCreationDto>;
    setSequencesTemplate: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    contextDependentTexts?: IContextDependentTexts;
    onButtonClick?: () => void;
    sequenceIndex: number;
    isFormValid: boolean;
    isSequenceDelete: boolean;
    isStepDelete: boolean;
    setIsStepDelete: Dispatch<SetStateAction<boolean>>;
    onRemoveTecnique: (removedTecniqueIndex: number) => void;
    sequenceType: string;
    setSequenceType: Dispatch<SetStateAction<string>>;
    tasks: TasksQuery | undefined;
}

interface IContextDependentTexts {
    firstSubtitle: string;
    secondSubtitle: string;
    buttonLabel: string;
}

export const AddTecnique = ({
    setCurrentStep,
    sequencesDetails,
    setSequencesDetails,
    contextDependentTexts,
    sequenceIndex,
    isFormValid,
    onRemoveTecnique,
    isSequenceDelete,
    isStepDelete,
    setIsStepDelete,
    sequencesTemplate,
    setSequencesTemplate,
    sequenceType,
    setSequenceType,
    tasks,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [stepsTemplate, setStepsTemplate] =
        useState<Partial<IStepTemplate>[]>();

    const onChange = (value: "Dti" | "Perfusion" | "Perfusion_uf" |  "Diffusion") => {
        if (!sequencesDetails || !sequencesDetails.stepSequencesTemplate)
            return;
        const newArray = [...sequencesDetails.stepSequencesTemplate];
        newArray[sequenceIndex] = {
            ...newArray[sequenceIndex],
            sequenceType: value,
        };
        convertToEcsTask(value);

        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: newArray,
        }));
    };

    const convertToEcsTask = (value: string) => {
        let type = "";
        if (value === "Dti") type = "dti";
        else if (value === "Perfusion_uf") type = "perf_uf";
        else type = "dif_perf";
        setSequenceType(type);
    };

    const onSaveTecnique = () => {
        setSequencesTemplate(sequencesDetails);
    };

    const onSearch = (value: string) => {};

    const checkCanDelete = (stepSequences: Partial<IStepSequenceTemplate>) => {
        let result = true;
        if (stepSequences.stepTemplates?.length! <= 1) {
            result = false;
        }
        return result;
    };

    useEffect(() => {
        if (!sequencesTemplate || !sequencesTemplate.stepSequencesTemplate)
            return;
        form.setFieldsValue(
            sequencesTemplate.stepSequencesTemplate[sequenceIndex]
        );
        if (
            !sequencesTemplate.stepSequencesTemplate[sequenceIndex] ||
            !sequencesTemplate.stepSequencesTemplate[sequenceIndex].sequenceType
        )
            return;
        convertToEcsTask(
            sequencesTemplate.stepSequencesTemplate[sequenceIndex].sequenceType!
        );
    }, [sequencesTemplate]);

    useEffect(() => {
        setIsStepDelete(
            checkCanDelete(
                sequencesDetails.stepSequencesTemplate![sequenceIndex]
            )
        );
    }, [sequencesDetails.stepSequencesTemplate]);

    const onAddTask = () => {
        if (
            !sequencesDetails ||
            !sequencesDetails.stepSequencesTemplate ||
            !sequencesDetails.stepSequencesTemplate[sequenceIndex].stepTemplates
        )
            return;
        const newStep = [
            ...sequencesDetails.stepSequencesTemplate[sequenceIndex]
                .stepTemplates!,
        ];
        convertToEcsTask(
            sequencesDetails.stepSequencesTemplate[sequenceIndex].sequenceType!
        );
        newStep.push({
            id:"",
            unversionedEcsTaskName: "",
            version: 0,
            type: "",
        });

        let newStepSequence = sequencesDetails.stepSequencesTemplate.map(
            (item, i) => {
                if (i === sequenceIndex) {
                    return {
                        sequenceType: item.sequenceType,
                        stepTemplates: newStep,
                    };
                }
                return item;
            }
        );

        setStepsTemplate(newStepSequence[sequenceIndex].stepTemplates);

        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: [...newStepSequence],
        }));
    };

    const onRemoveTask = (removedTaskIndex: number) => {
        if (!sequencesDetails || !sequencesDetails.stepSequencesTemplate)
            return;

        let stepsArray: Partial<IStepTemplate>[] = [];
        let newStepSequence = sequencesDetails.stepSequencesTemplate.map(
            (item, i) => {
                if (i === sequenceIndex) {
                    item.stepTemplates?.map((step, j) => {
                        if (j !== removedTaskIndex) {
                            stepsArray.push(step);
                        }
                        return stepsArray;
                    });
                    return {
                        sequenceType: item.sequenceType,
                        stepTemplates: stepsArray,
                    };
                }
                return item;
            }
        );

        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: newStepSequence,
        }));
    };

    const options = [
        { label: t("biomarkerTypes.dti"), value: "Dti" },
        { label: t("biomarkerTypes.perfusion"), value: "Perfusion" },
        { label: t("biomarkerTypes.perfusion_uf"), value: "Perfusion_uf" },
        { label: t("biomarkerTypes.diffusion"), value: "Diffusion" },
    ];

    return (
        <Card>
            <Form layout="vertical" onValuesChange={onChange} form={form}>
                <div>
                    <Title level={4} className={styles.Title}>
                        {t("createSequences.steps.2.title")}
                        <Button
                            type="text"
                            disabled={!isSequenceDelete}
                            onClick={() => {
                                onRemoveTecnique(sequenceIndex);
                            }}
                        >
                            {t("createSequences.addSequencesStep.delete")}
                            <span
                                className={`kenko-icon-bin button-icon ${styles.Trash}`}
                            />
                        </Button>
                    </Title>

                    <Text type="secondary">
                        {t(
                            contextDependentTexts
                                ? contextDependentTexts.firstSubtitle
                                : "createSequences.addSequencesStep.subtitle"
                        )}
                    </Text>
                </div>

                <Spacer size={"m"} />

                <div className={styles.Row}>
                    <div className={styles.Col}>
                        <Form.Item
                            label={t(
                                "createSequences.addSequencesStep.tecnicType"
                            )}
                            name="sequenceType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={t("createSequences.select")}
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={options}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <Spacer size={"m"} />
            {sequencesDetails!.stepSequencesTemplate![
                sequenceIndex
            ].stepTemplates?.map(
                (value: Partial<IStepTemplate>, taskIndex: number) => {
                    return (
                        <div className={styles.TecniqueInfo} key={taskIndex}>
                            <TaskInfo
                                onRemoveTask={onRemoveTask}
                                setSequencesDetails={setSequencesDetails}
                                sequencesDetails={sequencesDetails}
                                isDisabled={
                                    !sequencesDetails ||
                                    !sequencesDetails.stepSequencesTemplate ||
                                    !sequencesDetails.stepSequencesTemplate[
                                        sequenceIndex
                                    ].sequenceType
                                }
                                taskIndex={taskIndex}
                                sequenceIndex={sequenceIndex}
                                isStepDelete={isStepDelete}
                                tasks={tasks}
                            />
                        </div>
                    );
                }
            )}

            <Button
                className={styles.Button}
                onClick={() => onAddTask()}
                type="text"
                disabled={!isFormValid}
            >
                {t("createSequences.addSequencesStep.addTask")}
                <span className={`kenko-icon-plus ${styles.ButtonIcon}`} />
            </Button>
            <Row justify="end">
                <Button
                    disabled={
                        !isFormValid ||
                        areSequencesEqual(sequencesDetails, sequencesTemplate)
                    }
                    onClick={() => onSaveTecnique()}
                >
                    {t("createSequences.saveTecnique")}
                </Button>
            </Row>
        </Card>
    );
};
