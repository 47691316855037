import { Content } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHeader } from "../../hooks/headerHook";
import { CaseDetailsDescription } from "../../components/caseDetailsComponents/caseDetailsDescription/caseDetailsDescription";
import { CaseDetailsFolders } from "../../components/caseDetailsComponents/caseDetailsFolders/caseDetailsFolders";
import { Button, Col, Row } from "antd";
import { CaseDetailsFinalDiagnosis } from "../../components/caseDetailsComponents/caseDetailsFinalDiagnosis/caseDetailsFinalDiagnosis";
import { Spacer } from "../../components/appCore/spacer/spacer";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
    CaseQuery,
    CaseChangedSubscription,
    RemainingItemsQuery,
} from "../../generated/graphql";
import fragments from "../../gql/fragments";
import styles from "../../components/styles/plusIcon.module.scss";
import { useCaseById } from "../../features/case/domain/hooks/useCaseById";
import {
    ICaseDetails,
    StatusType,
    ZipStatusType,
    caseDetailsFromPartialCase,
} from "../../features/case/domain/entities/case";

interface IProps {
    isOrgAdmin?: boolean;
}

const CaseDetailsPage = ({ isOrgAdmin = false }: IProps) => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();
    const [caseDetails, setCaseDetails] = useState<ICaseDetails>();
    const [remainingCases, setRemainingCases] = useState<number>(0);

    let { caseId } = useParams<any>();

    //===============HOOKS==================
    const {
        data: currentCase,
        errorMessage: caseErrorMessage,
        getCaseById,
    } = useCaseById();

    const { subscribeToMore } = useQuery<CaseQuery>(fragments.GET_CASE_BY_ID, {
        variables: { id: caseId },
    });
    const { data: remainingItemsData } = useQuery<RemainingItemsQuery>(
        fragments.GET_REMAINING_ITEMS
    );

    useEffect(() => {
        if (!remainingItemsData) return;
        setRemainingCases(remainingItemsData.remainingItems.remainingCases);
    }, [remainingItemsData]);

    useEffect(() => {
        if (!currentCase) return;
        setHeaderContent({
            title: t("caseDetails.exitHeader"),
            breadCrumb: [
                { label: t("caseDetails.exitHeader"), link: "/" },
                {
                    label: t("case") + " " + currentCase.reference,
                },
            ],
            goBackLinkOverride: "/",
            buttonElement: !isOrgAdmin ? (
                <Button
                    type="primary"
                    size="large"
                    key="caseListBasicUser"
                    onClick={() => {
                        navigate("/createCase");
                    }}
                    disabled={remainingCases === 0}
                >
                    {t("caseList.createCase")}
                    <span className={`kenko-icon-plus ${styles.buttonIcon}`} />
                </Button>
            ) : (
                <div></div>
            ),
        });

        setCaseDetails(caseDetailsFromPartialCase(currentCase));
    }, [remainingCases, currentCase]);

    subscribeToMore<CaseChangedSubscription>({
        document: fragments.CASE_CHANGED,
        variables: { id: caseId },
        updateQuery: (prev, { subscriptionData }) => {
            if (!caseDetails) return prev;

            setCaseDetails({
                finalNotes: caseDetails.finalNotes,
                state: subscriptionData.data.case.newState
                    ? (subscriptionData.data.case.newState as StatusType)
                    : caseDetails.state,
                reference: caseDetails.reference,
                initialNotes: caseDetails.initialNotes,
                isPositivePrediction: caseDetails.isPositivePrediction,
                isPositive: caseDetails.isPositive,
                creationDate: caseDetails.creationDate,
                organ: caseDetails.organ,
                percentage: subscriptionData.data.case.percentage,
                zipState: subscriptionData.data.case.zipState
                    ? (subscriptionData.data.case.zipState as ZipStatusType)
                    : caseDetails.zipState,
                zipLink: subscriptionData.data.case.zipLink
                    ? subscriptionData.data.case.zipLink
                    : caseDetails.zipLink,
            });

            return prev;
        },
    });

    if (caseErrorMessage) {
        console.info(caseErrorMessage);
    }

    useEffect(() => {
        if (!caseId) return;
        getCaseById(caseId);
    }, [caseId]);

    useEffect(() => {
        if (!caseDetails) return;
        setCaseDetails(caseDetails);
    }, [caseDetails]);

    return (
        <Content>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                    <CaseDetailsDescription
                        caseDetails={caseDetails}
                        caseId={caseId}
                        isOrganisation={isOrgAdmin}
                    />
                </Col>
                <Col xs={24} md={16}>
                    <CaseDetailsFolders
                        caseId={caseId}
                        caseDetails={caseDetails}
                        isOrgAdmin={isOrgAdmin}
                    />
                    <Spacer size="s" />
                    {caseDetails &&
                        (caseDetails.state === StatusType.Closed ||
                            caseDetails.state === StatusType.Finished) && (
                            <CaseDetailsFinalDiagnosis
                                caseId={caseId}
                                isFinalDiagnosisComplete={
                                    caseDetails.finalNotes != null
                                }
                                caseDetails={caseDetails}
                                setCaseDetails={setCaseDetails}
                                isOrgAdmin={isOrgAdmin}
                            />
                        )}
                </Col>
            </Row>
        </Content>
    );
};

export default CaseDetailsPage;
