import { Button, Card, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./organisationAddLicenseStep.module.scss";
import React, { Dispatch, SetStateAction } from "react";
import { Spacer } from "../../../appCore/spacer/spacer";
import {
    ILicenseListPresentation,
    ILicensePresentation,
} from "../../../../features/licenseTemplate/domain/entities/licenseModel";
import { ColumnsType } from "antd/es/table";
import { IOrganisationCreationDto } from "../../../../features/organisation/domain/entities/organisationModel";

const { Title, Text } = Typography;

interface IProps {
    onFinish: () => void;
    organisationDetails: IOrganisationCreationDto | undefined;
    setOrganisationDetails: Dispatch<
        SetStateAction<IOrganisationCreationDto | undefined>
    >;
    licenseList: ILicenseListPresentation | undefined | null;
    onPaginate: (pageNumber: number) => void;
    elementsPage: number;
}

export const OrganisationAddLicenseStep = ({
    onFinish,
    organisationDetails,
    setOrganisationDetails,
    licenseList,
    onPaginate,
    elementsPage,
}: IProps) => {
    const { t } = useTranslation();
    const columns: ColumnsType<ILicensePresentation> = [
        {
            title: t("licenseList.table.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("licenseList.table.organ"),
            dataIndex: "organ",
            key: "organ",
        },
        {
            title: t("licenseList.table.price"),
            dataIndex: "totalPrice",
            key: "totalPrice",
        },
        {
            title: t("licenseList.table.maxCases"),
            dataIndex: "maxNumberOfCases",
            key: "maxNumberOfCases",
        },
        {
            title: t("licenseList.table.maxUsers"),
            dataIndex: "maxNumberOfUsers",
            key: "maxNumberOfUsers",
        },
    ];

    const onSelectionChange = (newSelection: React.Key[]) => {
        if (!organisationDetails) return;
        setOrganisationDetails({
            name: organisationDetails.name,
            firstName: organisationDetails.firstName,
            lastName: organisationDetails.lastName,
            email: organisationDetails.email,
            cifAndOthers: organisationDetails.cifAndOthers,
            licenseId: newSelection[0] as string,
        });
    };

    const rowSelection = {
        onChange: onSelectionChange,
    };

    return (
        <Card className={styles.Container}>
            <Title level={4}>
                {t("createOrganisation.addLicenseStep.title")}
            </Title>
            <Text type="secondary">
                {t("createOrganisation.addLicenseStep.subtitle")}
            </Text>
            <Spacer size="s" />
            {licenseList ? (
                <Table
                    rowSelection={{ type: "radio", ...rowSelection }}
                    columns={columns}
                    dataSource={licenseList.licensePresentations}
                    rowKey="id"
                    scroll={{ x: 800 }}
                    pagination={{
                        pageSize: elementsPage,
                        total: licenseList.totalResults,
                        onChange: (page) => {
                            onPaginate(page - 1);
                        },
                    }}
                />
            ) : (
                <Table />
            )}
            <Spacer size="s" />
            <div className={styles.StepNavigation}>
                <Button className={styles.AssignLaterButton} disabled>
                    {t("createOrganisation.addLicenseStep.assignLater")}
                </Button>

                <Button
                    type="primary"
                    disabled={organisationDetails?.licenseId === ""}
                    onClick={onFinish}
                >
                    {t("createOrganisation.addLicenseStep.createNew")}
                </Button>
            </div>
        </Card>
    );
};
