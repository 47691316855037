import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Modal, Row } from "antd";
import { Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LicenseAddSequenceStep } from "../../../components/adminComponents/createLicenseComponents/licenseAddSequenceStep/licenseAddSequenceStep";
import { LicenseInfoStep } from "../../../components/adminComponents/createLicenseComponents/licenseInfoStep/licenseInfoStep";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import {
    LicenseQuery,
    TasksQuery,
    UpdateLicenseMutation,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import {
    IDistributor,
    ILicenseTemplateModel,
    IMemoryLimits,
} from "../../../features/licenseTemplate/domain/entities/licenseModel";
import {
    ISequenceTemplateTable,
    ISequencesTemplateTable,
} from "../../../features/sequencesTemplate/domain/entities/sequencesModel";
import styles from "./editLicensePage.module.scss";
import { LicenseAddDistributorStep } from "../../../components/adminComponents/createLicenseComponents/licenseAddDistributorStep/licenseAddDistributorStep";
import { useAllSequencesTemplates } from "../../../features/sequencesTemplate/domain/hooks/useAllSequencesTemplates";

export const EditLicensePage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [distributorDetails, setDistributorDetails] = useState<IDistributor>({
        name: "",
        unversionedDistributorName: "",
        version: 0,
    });
    const [memoryLimitsDetails, setMemoryLimitsDetails] =
        useState<IMemoryLimits>({
            currentEcsMemorySize: 8192, //8 GB is the current for ECS task with 4 CPU's
            maxEcsMemorySize: 8192,
        });
    const [licenseDetails, setLicenseDetails] = useState<ILicenseTemplateModel>(
        {
            id: "",
            name: "",
            totalPrice: 0,
            organ: "",
            maxNumberOfCases: 0,
            maxNumberOfUsers: 0,
            sequencesTemplateId: "",
            distributor: distributorDetails,
            memoryLimits: memoryLimitsDetails,
        }
    );
    const [sequencesTemplateList, setSequencesTemplateList] = useState<
        ISequencesTemplateTable[]
    >([]);
    const [sequenceDistributorType] = useState<string>("dst");
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(false);
    const [isDistributorChosen, setIsDistributorChosen] =
        useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isIconVisible, setIsIconVisible] = useState<boolean>(true);
    let { licenseId } = useParams<any>();
    const [isConfirmModalVisible, setIsConfirmModalVisible] =
        useState<boolean>(false);
    const [numOrg, setNumOrg] = useState<number>(0);
    const [sequencesIdKey, setSequencesIdKey] = useState<Key | undefined>();
    const [distributorIdKey, setDistributorIdKey] = useState<Key>();
    const [initialSequencesIdKey, setInitialSequencesIdKey] = useState<
        Key | undefined
    >();
    const [isLicensInfoValid, setIsLicenseInfoValid] = useState<boolean>(true);
    const [tasks, setTasks] = useState<ISequenceTemplateTable[]>([]);
    const ELEMENTS_PAGE = 6;

    //===============HOOKS==================
    const {
        data: sequencesListData,
        isLoading,
        errorMessage,
        getAllSequencesTemplates,
    } = useAllSequencesTemplates();

    const [UpdateLicenseMutation] = useMutation<UpdateLicenseMutation>(
        fragments.UPDATE_LICENSE,
        {
            variables: {
                templateInput: {
                    id: licenseId,
                    name: licenseDetails.name,
                    maxNumberOfCases: licenseDetails.maxNumberOfCases,
                    maxNumberOfUsers: licenseDetails.maxNumberOfUsers,
                    totalPrice: licenseDetails.totalPrice,
                    organ: {
                        name: licenseDetails.organ,
                        value: licenseDetails.organ,
                    },
                    sequenceTemplateId: licenseDetails.sequencesTemplateId,
                    distributor: distributorDetails,
                    memoryLimits: licenseDetails.memoryLimits,
                },
            },
        }
    );

    const { data: LicenseTemplateData } = useQuery<LicenseQuery>(
        fragments.GET_LICENSE_BY_ID,
        {
            variables: { licenseId: licenseId },
        }
    );

    const { data: TaskTemplateData } = useQuery<TasksQuery>(
        fragments.GET_TASKS_BY_SEQUENCE,
        {
            variables: {
                input: sequenceDistributorType,
            },
        }
    );

    useEffect(() => {
        getAllSequencesTemplates(ELEMENTS_PAGE, 0);
    }, []);

    const onPaginate = (pageNumber: number) => {
        getAllSequencesTemplates(ELEMENTS_PAGE, pageNumber);
    };

    useEffect(() => {
        if (!TaskTemplateData) return;
        setTasks(TaskTemplateData.tasks.tasks as ISequenceTemplateTable[]);
    }, [TaskTemplateData]);

    useEffect(() => {
        if (!sequencesListData) return;
        setSequencesTemplateList(
            sequencesListData.sequencesTemplatePresentations as ISequencesTemplateTable[]
        );
    }, [sequencesListData]);

    useEffect(() => {
        if (!LicenseTemplateData) return;
        setLicenseDetails({
            id: LicenseTemplateData.licenseTemplate.id,
            name: LicenseTemplateData.licenseTemplate.name,
            totalPrice: LicenseTemplateData.licenseTemplate.totalPrice,
            organ: LicenseTemplateData.licenseTemplate.organ,
            maxNumberOfCases:
                LicenseTemplateData.licenseTemplate.maxNumberOfCases,
            maxNumberOfUsers:
                LicenseTemplateData.licenseTemplate.maxNumberOfUsers,
            sequencesTemplateId:
                LicenseTemplateData.licenseTemplate.sequencesTemplate?.id!,
            distributor: null,
            memoryLimits: {
                currentEcsMemorySize:
                    LicenseTemplateData.licenseTemplate.memoryLimits
                        .currentEcsMemorySize!,
                maxEcsMemorySize:
                    LicenseTemplateData.licenseTemplate.memoryLimits
                        .maxEcsMemorySize!,
            },
        });

        setMemoryLimitsDetails((prevState) => ({
            ...prevState,
            currentEcsMemorySize:
                LicenseTemplateData.licenseTemplate.memoryLimits
                    .currentEcsMemorySize!,
            maxEcsMemorySize:
                LicenseTemplateData.licenseTemplate.memoryLimits
                    .maxEcsMemorySize!,
        }));
        setNumOrg(LicenseTemplateData.organisationsForALicense.length);

        setDistributorIdKey(
            LicenseTemplateData.licenseTemplate.distributor?.name
        );

        if (LicenseTemplateData.licenseTemplate.sequencesTemplate) {
            setInitialSequencesIdKey(
                LicenseTemplateData.licenseTemplate.sequencesTemplate?.id
            );
            setSequencesIdKey(
                LicenseTemplateData.licenseTemplate.sequencesTemplate?.id
            );
        }
        setDistributorDetails((prevState) => ({
            ...prevState,
            name: LicenseTemplateData.licenseTemplate.distributor?.name!,
            version: Number(
                LicenseTemplateData.licenseTemplate.distributor?.version
            ),
            unversionedDistributorName:
                LicenseTemplateData.licenseTemplate.distributor
                    ?.unversionedDistributorName!,
        }));
    }, [LicenseTemplateData]);

    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };

    const onFinish = () => {
        setIsConfirmModalVisible(false);
        setIsSuccessModalVisible(true);
        setIsSuccessModalLoading(true);
        UpdateLicenseMutation({ variables: licenseDetails }).then((result) => {
            setIsSuccessModalLoading(false);
        });
    };

    const onConfirm = () => {
        if (!numOrg) {
            onFinish();
        } else {
            setIsConfirmModalVisible(true);
        }
    };

    const onShowLicense = () => {
        navigate("../license/" + licenseId);
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isEditStep={true}
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        isComplete={true}
                        title={t("editLicense.title")}
                        steps={[
                            {
                                title: t("createLicense.steps.1.title"),
                                description: t(
                                    "createLicense.steps.1.description"
                                ),
                            },
                            {
                                title: t("createLicense.steps.2.title"),
                                description: t(
                                    "createLicense.steps.2.description"
                                ),
                            },
                            {
                                title: t("createLicense.steps.3.title"),
                                description: t(
                                    "createLicense.steps.3.description"
                                ),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    {(currentStep === 0 && (
                        <LicenseInfoStep
                            licenseDetails={licenseDetails}
                            setLicenseDetails={setLicenseDetails}
                            setCurrentStep={setCurrentStep}
                            setIsStepIconVisible={setIsIconVisible}
                            setIsLicenseInfoValid={setIsLicenseInfoValid}
                            isLicenseInfoValid={isLicensInfoValid}
                        />
                    )) ||
                        (currentStep === 1 && (
                            <LicenseAddDistributorStep
                                tasks={tasks}
                                setLicenseDetails={setLicenseDetails}
                                setCurrentStep={setCurrentStep}
                                setDistributorDetails={setDistributorDetails}
                                distributorDetails={distributorDetails}
                                setIsDistributorChosen={setIsDistributorChosen}
                                isDistributorChosen={isDistributorChosen}
                                distributorIdKey={distributorIdKey}
                                setDistributorIdKey={setDistributorIdKey}
                            />
                        )) ||
                        (currentStep === 2 && (
                            <LicenseAddSequenceStep
                                sequencesTemplateList={sequencesTemplateList}
                                setLicenseDetails={setLicenseDetails}
                                onFinish={onFinish}
                                licenseDetails={licenseDetails}
                                textButton={t("createLicense.create")}
                                sequencesIdKey={sequencesIdKey}
                                setSequencesIdKey={setSequencesIdKey}
                                isFormValid={!!sequencesIdKey}
                                memoryLimitsDetails={memoryLimitsDetails}
                                setMemoryLimitsDetails={setMemoryLimitsDetails}
                                onPaginate={onPaginate}
                                totalResults={sequencesListData?.totalResults}
                                elementsPage={ELEMENTS_PAGE}
                            />
                        ))}
                </Col>
            </Row>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                onPrimary={onShowLicense}
                localisedTexts={{
                    title: t("editLicense.successModal.title"),
                    subtitle: t("editLicense.successModal.subtitle"),
                    secondaryButton: t(
                        "editLicense.successModal.createAnother"
                    ),
                    primaryButton: t("editLicense.successModal.showCase"),
                }}
            />
            <Modal
                title={t("editLicense.modal.title")}
                visible={isConfirmModalVisible}
                width={550}
                closable={false}
                footer={[
                    <Button
                        key={"cancel" + licenseId}
                        onClick={() => setIsConfirmModalVisible(false)}
                    >
                        {t("cancel")}
                    </Button>,
                    <Button
                        key={"edit" + licenseId}
                        onClick={onFinish}
                        type="primary"
                    >
                        {t("editLicense.edit")}
                    </Button>,
                ]}
            >
                <p>
                    {t("editLicense.modal.firstDescripPart")}
                    <b>{licenseDetails.name}</b>
                    {t("editLicense.modal.secondDescripPart") +
                        numOrg +
                        t("editLicense.modal.thirdDescripPart")}
                </p>
            </Modal>
        </>
    );
};
