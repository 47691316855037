import { Col, Row } from "antd";
import { StepWithEditIconComponent } from "../../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserLicenseInfo } from "../userLicenseInfo/userLicenseInfo";
import { AssignUser } from "../assignUser/assignUser";
import { ResultModal } from "../../../../components/appCore/resultModal/resultModal";
import {
    IUserLicenseCreate,
    IUserLicenseModel,
} from "../../domain/entities/userLicenseModel";
import { useEffect, useState } from "react";
import {
    IUserBasicInfo,
    IUserListPresentation,
} from "../../../user/domain/entities/userModel";
import styles from "./userLicenseComponent.module.scss";
import { useHeader } from "../../../../hooks/headerHook";
import { IOrganisationLicenseCounters } from "../../../organisation/domain/entities/organisationModel";

export interface IProps {
    userLicenseDetails: IUserLicenseCreate | undefined;
    updateUserLicenseUserId: (value: string | undefined | null) => void;
    updateUserLicenseNumCases: (value: number | undefined) => void;
    usersWithoutLicense: IUserListPresentation | undefined;
    remainingCounters: IOrganisationLicenseCounters | undefined;
    onCreateFullLicense: () => void;
    onCreateSimpleLicense: () => void;
    checkValue: number | undefined;
    updateCheckValue: (value: number) => void;
    isResultModalVisible: boolean;
    isResultModalLoading: boolean;
    isResultModalError: boolean;
    titleModal: string;
    subtitleModal: string;
    titleStep: string;
    isEditPage: boolean;
    updateUserName: (value: string | undefined) => void;
    updateUserSurname: (value: string | undefined) => void;
    updateUserEmail: (value: string | undefined) => void;
    email: string | undefined;
    userLicenseModel?: IUserLicenseModel | undefined;
    onLoadMoreUsers: (pageNumber: number) => void;
    onFilterUser: (textInput: string) => void;
    textSearchUser: string;
    unfilteredUsers: IUserBasicInfo[] | undefined;
    filteredUsers: IUserBasicInfo[] | undefined;
}
export const UserLicenseComponent = ({
    userLicenseDetails,
    updateUserLicenseUserId,
    updateUserLicenseNumCases,
    usersWithoutLicense,
    remainingCounters,
    onCreateFullLicense,
    onCreateSimpleLicense,
    checkValue,
    updateCheckValue,
    isResultModalVisible,
    isResultModalLoading,
    isResultModalError,
    titleModal,
    subtitleModal,
    titleStep,
    isEditPage,
    updateUserName,
    updateUserSurname,
    updateUserEmail,
    email,
    userLicenseModel,
    onLoadMoreUsers,
    onFilterUser,
    textSearchUser,
    unfilteredUsers,
    filteredUsers,
}: IProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { setHeaderContent } = useHeader();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isIconVisible, setIsIconVisible] = useState<boolean>(isEditPage);

    useEffect(() => {
        setHeaderContent({
            title: t("createLicense.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/userLicenses",
        });
    }, []);

    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };

    const updateCurrentStep = (value: number) => {
        setCurrentStep((prevStep) => prevStep + value);
    };
    const updateIsIconVisible = (value: boolean) => {
        setIsIconVisible(value);
    };

    const onShowUserLicenses = () => {
        navigate("/userLicenses");
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        isComplete={isEditPage}
                        title={titleStep}
                        steps={[
                            {
                                title: t("createUserLicense.steps.1.title"),
                                description: t(
                                    "createUserLicense.steps.1.description"
                                ),
                            },
                            {
                                title: t("createUserLicense.steps.2.title"),
                                description: t(
                                    "createUserLicense.steps.2.description"
                                ),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                        isEditStep={isEditPage}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    {(currentStep === 0 && (
                        <UserLicenseInfo
                            remainingCases={remainingCounters?.remainingCases}
                            updateUserLicenseNumCases={
                                updateUserLicenseNumCases
                            }
                            updateCurrentStep={updateCurrentStep}
                            updateIsIconVisible={updateIsIconVisible}
                            userLicenseDetails={userLicenseDetails}
                            userLicenseModel={userLicenseModel}
                            buttonText={t(
                                "createUserLicense.userLicenseInfo.next"
                            )}
                            subtitle={t(
                                "createUserLicense.userLicenseInfo.subtitle"
                            )}
                        />
                    )) ||
                        (currentStep === 1 && !isEditPage && (
                            <AssignUser
                                updateCheckValue={updateCheckValue}
                                checkValue={checkValue}
                                onCreateFullLicense={onCreateFullLicense}
                                onCreateSimpleLicense={onCreateSimpleLicense}
                                usersWithoutLicense={usersWithoutLicense}
                                updateUserLicenseUserId={
                                    updateUserLicenseUserId
                                }
                                isEditPage={isEditPage}
                                updateUserName={updateUserName}
                                updateUserSurname={updateUserSurname}
                                updateUserEmail={updateUserEmail}
                                email={email}
                                onLoadMoreUsers={onLoadMoreUsers}
                                textSearchUser={textSearchUser}
                                unfilteredUsers={unfilteredUsers}
                                filteredUsers={filteredUsers}
                                onFilterUser={onFilterUser}
                            />
                        ))}
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowUserLicenses}
                hasSecondary={!isEditPage}
                localisedTexts={{
                    title: titleModal,
                    subtitle: subtitleModal,
                    secondaryButton: t(
                        "createUserLicense.successModal.createAnother"
                    ),
                    primaryButton: t("createUserLicense.successModal.list"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
        </>
    );
};
