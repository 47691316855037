import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "antd";
import { ICaseDetails, StatusType } from "../../domain/entities/case";
import styles from "./visualizerHeader.module.scss";
import { VisualizerLayoutSelector } from "./visualizerLayoutSelector/visualizerLayoutSelector";
import { VisualizerMIPCutBtn } from "./visualizerMIPCutBtn/visualizerMIPCutBtn";
import { VisualizerMultiplanarBtn } from "./visualizerMultiplanarBtn/visualizerMultiplanarButton";
import { VisualizerPixelAnalysisBtn } from "./visualizerPixelAnalizeBtn/visualizerPixelAnalizeBtn";
import { VisualizerHeaderDivider } from "./visualizerHeaderDivider";
import { VisualizerFinalDiagnosisBtn } from "./visualizerFinalDiagnosisBtn/visualizerFinalDiagnosisBtn";
import {
    ISequenceSliceController,
    ISequenceSliceReference,
} from "../../domain/entities/sequence";
import { IVisualizerWindow } from "../../domain/entities/modelImages";

interface IProps {
    caseId: string;
    currentCase: ICaseDetails | undefined;
    isMultiplanar: boolean;
    setVisualizerLayout: Dispatch<SetStateAction<number>>;
    emptyVisualizerWindowByIndex: (index: number) => void;
    activeWindow: number;
    setActiveWindow: Dispatch<SetStateAction<number>>;
    setActiveMultiplanar: Dispatch<SetStateAction<boolean>>;
    allowMultiplanarView: boolean;
    visualizerWindows: IVisualizerWindow[];
    singleImages: Set<string> | undefined;
    isMultiViewAvailable: boolean;
    setPixelAnalizeActive: Dispatch<SetStateAction<boolean>>;
    isPixelAnalizeActive: boolean;
    isFinalDiagnosisModalVisible: boolean;
    setIsFinalDiagnosisModalVisible: Dispatch<SetStateAction<boolean>>;
    sequenceSliceReference: ISequenceSliceReference;
    setSequenceSliceReference: Dispatch<
        SetStateAction<ISequenceSliceReference>
    >;
    sequenceSliceController: ISequenceSliceController;
    setSequenceSliceController: Dispatch<
        SetStateAction<ISequenceSliceController>
    >;
    isOrgAdmin: boolean;
}

export const VisualizerHeader = ({
    caseId,
    currentCase,
    isMultiplanar,
    setVisualizerLayout,
    emptyVisualizerWindowByIndex,
    activeWindow,
    setActiveWindow,
    setActiveMultiplanar,
    allowMultiplanarView,
    visualizerWindows,
    singleImages,
    isMultiViewAvailable,
    isPixelAnalizeActive,
    setPixelAnalizeActive,
    isFinalDiagnosisModalVisible,
    setIsFinalDiagnosisModalVisible,
    sequenceSliceReference,
    setSequenceSliceReference,
    sequenceSliceController,
    setSequenceSliceController,
    isOrgAdmin,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const checkIsFinalDiagnosisBtnEnabled = () => {
        return !isOrgAdmin && currentCase?.state === StatusType.Finished;
    };

    return (
        <>
            <PageHeader
                onBack={() => navigate("/case/" + caseId)}
                title={
                    t("case") + " " + (currentCase ? currentCase.reference : "")
                }
                className={styles.Header}
                extra={[
                    <VisualizerPixelAnalysisBtn
                        isMultiplanarActive={isMultiplanar}
                        isPixelAnalizeActive={isPixelAnalizeActive}
                        setPixelAnalizeActive={setPixelAnalizeActive}
                        key={6}
                    />,
                    <VisualizerLayoutSelector
                        key={5}
                        setVisualizerLayout={setVisualizerLayout}
                        emptyVisualizerWindowByIndex={
                            emptyVisualizerWindowByIndex
                        }
                        activeWindow={activeWindow}
                        setActiveWindow={setActiveWindow}
                        isMultiplanar={isMultiplanar}
                    />,
                    <VisualizerHeaderDivider key={4} />,
                    <VisualizerMultiplanarBtn
                        key={3}
                        setActiveMultiplanar={setActiveMultiplanar}
                        isMultiplanarActive={isMultiplanar}
                        allowMultiplanarView={allowMultiplanarView}
                        visualizerWindows={visualizerWindows}
                        activeWindow={activeWindow}
                        singleImages={singleImages}
                        isMultiViewAvailable={isMultiViewAvailable}
                        setPixelAnalizeActive={setPixelAnalizeActive}
                    />,
                    <VisualizerMIPCutBtn
                        key={2}
                        sequenceSliceReference={sequenceSliceReference}
                        setSequenceSliceReference={setSequenceSliceReference}
                        sequenceSliceController={sequenceSliceController}
                        setSequenceSliceController={setSequenceSliceController}
                        isMultiplanar={isMultiplanar}
                    />,
                    <VisualizerFinalDiagnosisBtn
                        key={1}
                        isFinalDiagnosisBtnEnabled={checkIsFinalDiagnosisBtnEnabled()}
                        isFinalDiagnosisModalVisible={
                            isFinalDiagnosisModalVisible
                        }
                        setIsFinalDiagnosisModalVisible={
                            setIsFinalDiagnosisModalVisible
                        }
                    />,
                ]}
            ></PageHeader>
        </>
    );
};
