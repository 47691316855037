import { Button, Card, Form } from "antd";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import styles from "./inviteInfoStep.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserBasicInfo } from "../../domain/entities/userModel";
import { CreateUserForm } from "../createUserForm/createUserForm";

interface IProps {
    updateCurrentStep: (value: number) => void;
    updateUserName: (value: string | undefined) => void;
    updateUserSurname: (value: string | undefined) => void;
    updateUserEmail: (value: string | undefined) => void;
    updateIsIconVisible: (value: boolean) => void;
    updatedUserDetails?: IUserBasicInfo | undefined;
    isEditPage: boolean;
    email: string | undefined | null;
    onUpdateUser?: () => void;
}

export const InviteInfoStep = ({
    updateCurrentStep,
    updateUserName,
    updateUserSurname,
    updateUserEmail,
    updateIsIconVisible,
    updatedUserDetails,
    isEditPage,
    email,
    onUpdateUser,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isUserDeleted, setIsUserDeleted] = useState<boolean>();
    const [checkEmail, setCheckEmail] = useState<string | undefined>("");
    const [isEmailChecked, setIsEmailChecked] = useState<boolean>();

    useEffect(() => {
        if (!!isEmailChecked) {
            if (isUserDeleted === true) {
                updateCurrentStep(1);
                updateIsIconVisible(true);
            } else if (isUserDeleted === false) {
                setIsFormValid(false);
            }
        }
    }, [isUserDeleted]);

    const onClick = () => {
        if (isEditPage && !!onUpdateUser) {
            onUpdateUser();
        } else if (!isEditPage) {
            if (!email) return;
            setCheckEmail(email);
            setIsEmailChecked(true);
        }
    };
    const updateIsFormValid = (value: boolean) => {
        setIsFormValid(value);
    };
    const updateIsUserDeleted = (value: boolean | undefined) => {
        setIsUserDeleted(value);
    };

    return (
        <>
            <Card>
                <CreateUserForm
                    title={t("invite.details.title")}
                    subtitle={t("invite.details.subtitle")}
                    updateIsFormValid={updateIsFormValid}
                    updateUserName={updateUserName}
                    updateUserSurname={updateUserSurname}
                    updateUserEmail={updateUserEmail}
                    updatedUserDetails={updatedUserDetails}
                    isEditPage={isEditPage}
                    isUserDeleted={isUserDeleted}
                    checkEmail={checkEmail}
                    updateIsUserDeleted={updateIsUserDeleted}
                />

                <Spacer size={"s"} />

                <Form.Item className={styles.StepNavigation}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!isFormValid}
                        onClick={onClick}
                    >
                        {!isEditPage
                            ? t("invite.details.next")
                            : t("invite.details.save")}
                    </Button>
                </Form.Item>
            </Card>
        </>
    );
};
