import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    Typography,
    UploadProps,
} from "antd";
import { Spacer } from "../../../appCore/spacer/spacer";
import React, { useState } from "react";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import Dragger from "antd/lib/upload/Dragger";
import styles from "../../../createCaseComponents/createCaseUploadStep/createCaseUploadStep.module.scss";
import { FileListComponent } from "../../../createCaseComponents/createCaseUploadStep/fileListComponent/fileListComponent";
import CaseService from "../../../../services/caseService";
import { UploadModal } from "../../../createCaseComponents/createCaseUploadStep/uploadModal/uploadModal";
import { ResultModal } from "../../../appCore/resultModal/resultModal";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface IProps {
    caseId: string | undefined;
}

export const CaseUploadError = ({ caseId }: IProps) => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [percent, setPercent] = useState<number>(0);
    const [isError, setIsError] = useState<boolean>(false);

    const handleUpload = () => {
        let fileUpload: RcFile;
        fileUpload = fileList[0] as RcFile;

        setIsModalVisible(true);

        CaseService.getCaseUrl(caseId, fileList[0].fileName)
            .then((urlResponse) => {
                // ? Start Upload
                CaseService.postCaseFile(fileUpload, urlResponse, setPercent)
                    .then(() => {
                        // ? On finish
                        // * Show <SuccessModal />
                        setTimeout(() => {
                            setIsSuccessModalVisible(true);
                        }, 1000);
                        // * Empty file list
                        setFileList([]);
                        // * Empty case details
                    })
                    .catch((e) => {
                        setIsError(true);
                        console.error(e);
                    });
            })
            .catch(() => {
                setIsError(true);
            });
    };

    const onDelete = () => {
        setFileList([]);
    };

    const props: UploadProps = {
        name: "file",
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const onRetryCallback = () => {
        navigate(0);
    };

    const onShowCase = () => {
        navigate(0);
    };

    return (
        <>
            <Alert
                type="error"
                message={t("caseDetails.resultFolders.errorUploading.title")}
                description={t(
                    "caseDetails.resultFolders.errorUploading.description"
                )}
                showIcon
            />
            <Spacer size="m" />
            <Text>
                {t(
                    "caseDetails.resultFolders.errorUploading.supportDescription"
                )}
            </Text>
            <Spacer size="s" />

            <Dragger
                style={{ display: fileList.length === 0 ? "" : "none" }}
                {...props}
                maxCount={1}
                accept="application/zip"
            >
                <Spacer size="m" />
                <p className="ant-upload-drag-icon">
                    <span
                        className={`${styles.ArchiveIcon} kenko-icon-archive-empty`}
                    />
                </p>
                <p className="ant-upload-text">
                    {t("createCase.uploadStep.clickOrDropFile")}
                </p>
                <p className="ant-upload-hint">
                    {t("createCase.uploadStep.mustBeZip")}
                </p>
                <Spacer size="m" />
            </Dragger>
            <div>
                {fileList.length === 1 ? (
                    <FileListComponent file={fileList[0]} onDelete={onDelete} />
                ) : (
                    ""
                )}
            </div>

            <Spacer size="s" />
            <Button disabled={fileList.length !== 1} onClick={handleUpload}>
                {t("caseDetails.resultFolders.errorUploading.supportButton")}
                <span className="kenko-icon-right-arrow button-icon"></span>
            </Button>

            <UploadModal
                isVisible={isModalVisible}
                percent={percent}
                isError={isError}
                onRetryCallback={onRetryCallback}
            />
            <ResultModal
                isVisible={isSuccessModalVisible}
                onPrimary={onShowCase}
                localisedTexts={{
                    title: t("createCase.successModal.title"),
                    subtitle: t("createCase.successModal.description"),
                    secondaryButton: t("createCase.successModal.uploadAnother"),
                    primaryButton: t("createCase.successModal.showCase"),
                }}
            />
        </>
    );
};
