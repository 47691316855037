import styles from "./profileFetcher.module.scss";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useProfile } from "../../../hooks/profileHook";
import { useEffect } from "react";
import { useAsync } from "react-async";
import { AsyncTokenLookup } from "../../../App";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { RoleType } from "../../../models/core/profileModel";

interface JwtPayloadWithExtension extends JwtPayload {
    extension_kenkousertype: string;
    extension_kenkoorgadmin: boolean;
}

export const ProfileFetcher = () => {
    const { t } = useTranslation();
    const { setRole } = useProfile();

    const token = useAsync(AsyncTokenLookup);

    useEffect(() => {
        if (!token.data) return;
        let role = jwtDecode<JwtPayloadWithExtension>(
            token.data
        ).extension_kenkousertype;
        if (
            role === RoleType.User &&
            jwtDecode<JwtPayloadWithExtension>(token.data)
                .extension_kenkoorgadmin
        ) {
            role = RoleType.OrganisationAdmin;
        }
        setRole(role);
    }, [token.data]);

    return (
        <div className={styles.CenteredContainer}>
            <p>{t("loading")}</p> <Spin size="large" />
        </div>
    );
};
