import { useMutation } from "@apollo/client";
import { Modal } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteSequencesTemplateMutation } from "../../../../generated/graphql";
import fragments from "../../../../gql/fragments";
import {
    ISequencesCreationDto,
    ISequenceTemplateModel,
} from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { ResultModal } from "../../../appCore/resultModal/resultModal";

interface IProps {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    isVisible: boolean;
    sequencesDetails: ISequenceTemplateModel | undefined;
}

export const DeleteSequencesModal = ({
    setIsVisible,
    isVisible,
    sequencesDetails,
}: IProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);

    const [DeleteSequencesTemplate] =
        useMutation<DeleteSequencesTemplateMutation>(
            fragments.DELETE_SEQUENCES
        );

    const onDelete = () => {
        setIsResultModalVisible(true);
        DeleteSequencesTemplate({
            variables: {
                id: sequencesDetails?.id,
            },
        }).then((res) => {
            setIsResultModalLoading(false);
        });
    };

    const onShowSequences = () => {
        setIsVisible(false);
        setIsResultModalVisible(false);
        navigate("../sequences");
    };

    return (
        <>
            <Modal
                title={t("sequencesDetails.description.modal.title")}
                visible={isVisible && !isResultModalVisible}
                cancelText={t("cancel")}
                onCancel={() => setIsVisible(false)}
                okText={t("sequencesDetails.description.modal.delete")}
                onOk={onDelete}
            >
                <p>
                    {t("sequencesDetails.description.modal.firstDescrip")}
                    <b>{sequencesDetails?.name}</b>
                    {t("sequencesDetails.description.modal.secondDescrip")}
                </p>
            </Modal>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                key={sequencesDetails?.id}
                onPrimary={onShowSequences}
                localisedTexts={{
                    title: t("sequencesDetails.description.resultModal.title"),
                    subtitle: t(
                        "sequencesDetails.description.resultModal.description"
                    ),
                    primaryButton: t(
                        "sequencesDetails.description.resultModal.close"
                    ),
                }}
                hasSecondary={false}
            />
        </>
    );
};
