import { gql } from "@apollo/client";

const CREATE_USER = gql`
    mutation addUser($firstName: String!, $lastName: String!, $email: String!) {
        addUser(
            input: { firstName: $firstName, lastName: $lastName, email: $email }
        ) {
            id
            orgId
            name
            surname
            email
            isOrgAdmin
        }
    }
`;

const RESET_PASSWORD = gql`
    mutation resetPassword {
        resetPassword {
            id
            orgId
            name
            surname
            email
            isOrgAdmin
        }
    }
`;

const GET_ALL_USERS = gql`
    query allUsers($input: GetUserInput!) {
        users(input: $input) {
            userPresentations {
                id
                name
                surname
                orgId
                email
                isOrgAdmin
                totalCases
                userLicenseId
                creationOrder
                maxNumberOfCases
                numberOfRemainingCases
            }
            totalResults
        }
    }
`;

const GET_ME = gql`
    query me {
        me {
            id
            orgId
            name
            surname
            email
            isOrgAdmin
            numberOfRemainingCases
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser(
        $id: String!
        $email: String
        $name: String
        $surname: String
        $orgId: String
        $isOrgAdmin: Boolean
    ) {
        updateUser(
            input: {
                id: $id
                email: $email
                name: $name
                surname: $surname
                orgId: $orgId
                isOrgAdmin: $isOrgAdmin
            }
        ) {
            id
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            id
            orgId
            name
            surname
            email
            isOrgAdmin
        }
    }
`;

const GET_USER = gql`
    query user($input: String!) {
        user(userId: $input) {
            id
            name
            surname
            orgId
            email
            isOrgAdmin
            totalCases
            remainingCases
        }
    }
`;

const IS_USER_DELETED = gql`
    query isUserDeleted($email: String!) {
        isUserDeleted(email: $email)
    }
`;

export const userFragments = {
    CREATE_USER,
    RESET_PASSWORD,
    GET_ME,
    GET_ALL_USERS,
    UPDATE_USER,
    DELETE_USER,
    GET_USER,
    IS_USER_DELETED,
};
