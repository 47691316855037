import { Col, Row } from "antd";
import styles from "./createLicenseFeaturePage.module.scss";
import { StepsComponent } from "../../../components/appCore/stepsComponent/stepsComponent";
import  { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useNavigate } from "react-router-dom";
import { ILicenseFeatureInput } from "../../../features/licenseFeature/domain/entities/licenseFeature";
import { useAddLicenseFeature } from "../../../features/licenseFeature/domain/hooks/useAddLicenseFeature";
import { CreateEditLicenseFeatureForm } from "../../../features/licenseFeature/presentation/createAndEditLicenseFeatureForm/createAndEditLicenseFeatureForm";

export const CreateLicenseFeaturePage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [licenseFeatureInput, setLicenseFeatureInput] =
        useState<ILicenseFeatureInput>();
    const { setHeaderContent } = useHeader();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [featureId, setFeatureId] = useState<string>("");

    useEffect(() => {
        setHeaderContent({
            title: t("licenseFeature.exitHeader"),
            breadCrumb: [
                { label: t("licenseFeature.exitHeaderSub"), link: "/features" },
            ],
            goBackLinkOverride: "/features",
        });
    }, []);

    const { data: licenseFeatureData, isLoading, errorMessage,  addLicenseFeature} 
    = useAddLicenseFeature()

    const onFinish = () => {
        if(!licenseFeatureInput) return
        addLicenseFeature(licenseFeatureInput!)
    };

    useEffect(()=> {
        if(errorMessage) setIsResultModalError(true);
        if(!licenseFeatureData) return;
        setIsResultModalVisible(true);
        setFeatureId(licenseFeatureData.id);
    }, [licenseFeatureData, errorMessage])

    const onShowLicenseFeature = () => {
        navigate("../feature/" + featureId);
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepsComponent
                        currentStep={0}
                        title={t("licenseFeature.create.title")}
                        steps={[
                            {
                                title: t("licenseFeature.create.step.title"),
                                description: t(
                                    "licenseFeature.create.step.description"
                                ),
                            }
                        ]}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <CreateEditLicenseFeatureForm
                            onButtonClick={onFinish}
                            setLicenseFeatureInput={setLicenseFeatureInput!}
                            featureId={undefined}
                            licenseFeature={undefined}
                            setLicenseFeature={undefined}
                            action="create"
                        />
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isLoading}
                isError={isResultModalError}
                onPrimary={onShowLicenseFeature}
                localisedTexts={{
                    title: t("licenseFeature.create.resultModal.title"),
                    subtitle: t("licenseFeature.create.resultModal.subtitle"),
                    primaryButton: t(
                        "licenseFeature.create.resultModal.close"
                    ),
                    errorTitle: t("licenseFeature.create.resultModal.errorTitle"),
                    errorRetry: t("licenseFeature.create.resultModal.errorRetry"),
                }}
            />
        </>
    );
};
