import { Button, Steps, Typography } from "antd";
import React from "react";
import { Spacer } from "../spacer/spacer";

const { Step } = Steps;

interface IProps {
    currentStep: number;
    title: string;
    steps: IStep[];
    isComplete?: boolean;
}

interface IStep {
    title: string;
    description: string;
}

export const StepsComponent = ({
    currentStep,
    title,
    steps,
    isComplete = false,
}: IProps) => {
    const { Title } = Typography;

    return (
        <>
            <Title level={3}>{title}</Title>
            <Spacer size="s" />
            <Steps direction="vertical" current={currentStep}>
                {steps.map((step, key) =>
                    isComplete ? (
                        <Step
                            key={key}
                            title={step.title}
                            description={step.description}
                            status={key === currentStep ? "process" : "finish"}
                        ></Step>
                    ) : (
                        <Step
                            key={key}
                            title={step.title}
                            description={step.description}
                        ></Step>
                    )
                )}
            </Steps>
        </>
    );
};
