import React, { useState } from "react";

interface IProfileContext {
    role: string | null;
    setRole: React.Dispatch<React.SetStateAction<string | null>>;
}

const initialState: IProfileContext = {
    role: null,
    setRole: () => ({}),
};

export const ProfileContext = React.createContext(initialState);

export const ProfileProvider = ({ children }: any) => {
    const [role, setRole] = useState<string | null>(null);
    return (
        <ProfileContext.Provider
            value={{
                role,
                setRole,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};
