import { Content } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { IOrganisationReducedTableDto } from "../../../features/organisation/domain/entities/organisationModel";
import { useGetLicenseFeature } from "../../../features/licenseFeature/domain/hooks/useLicenseFeature";
import { ILicenseFeature } from "../../../features/licenseFeature/domain/entities/licenseFeature";
import { LicenseFeatureDetails } from "../../../features/licenseFeature/presentation/licenseFeatureDetails/licenseFeatureDetails";
import { LicenseFeatureDetailsOrganisations } from "../../../features/licenseFeature/presentation/licenseFeatureOrganisations/licenseFeatureOrganisations";

export const LicenseFeatureDetailsPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const [licenseFeatureDetails, setLicenseFeatureDetails] =
        useState<ILicenseFeature>();
    const [organisationsWithLicense, setOrganisationsWithLicense] =
        useState<IOrganisationReducedTableDto[]>();
    const [isDeleteActionAllowed, setIsDeleteActionAllowed] =
        useState<boolean>(false);

    let { featureId } = useParams<any>();

    const { data: licenseFeature, getLicenseFeature } = useGetLicenseFeature();

    useEffect(() => {
        setHeaderContent({
            title: t("licenseFeature.exitHeader"),
            breadCrumb: [
                { label: t("licenseFeature.exitHeaderSub"), link: "/features" },
            ],
            goBackLinkOverride: "/features",
        });
        if (!featureId) return;
        getLicenseFeature(featureId);
    }, []);

    useEffect(() => {
        if (!licenseFeature) return;
        setLicenseFeatureDetails(licenseFeature.licenseFeature);
        setOrganisationsWithLicense(licenseFeature.organisationsWithFeature);
        setIsDeleteActionAllowed(
            licenseFeature.organisationsWithFeature.length! === 0
        );
    }, [licenseFeature]);

    return (
        <Content>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                    <LicenseFeatureDetails
                        licenseFeatureDetails={licenseFeatureDetails}
                        isDeleteActionAllowed={isDeleteActionAllowed}
                    />
                </Col>
                <Col xs={24} md={16}>
                    <LicenseFeatureDetailsOrganisations
                        organisationsWithLicense={organisationsWithLicense}
                    />
                </Col>
            </Row>
        </Content>
    );
};
