import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./reloadOrgLicenceUsersCasesPage.module.scss";
import { StepsComponent } from "../../../components/appCore/stepsComponent/stepsComponent";
import { useHeader } from "../../../hooks/headerHook";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import { ReloadOrganisationLicense } from "../../../components/adminComponents/ReloadOrganisationLicenseComponent/ReloadOrganisationLicense/reloadOrganisationLicense";
import {
    IOrganisationDetailsDto,
    IOrganisationLicense,
    IReloadOrgLicence,
} from "../../../features/organisation/domain/entities/organisationModel";
import { useMutation, useQuery } from "@apollo/client";
import {
    ReloadOrgLicenseMutation,
    OrganisationQuery,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";

export const ReloadOrgLicenceCasesUsersPage = () => {
    let navigate = useNavigate();
    const [organisationDetailsCombined, setOrganisationDetailsCombined] =
        useState<IOrganisationDetailsDto>();
    const [licenseDetails, setLicenseDetails] =
        useState<IOrganisationLicense>();
    const [reloadOrgLicense, setReloadOrgLicense] =
        useState<IReloadOrgLicence>();
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const { setHeaderContent } = useHeader();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] =
        useState<boolean>(false);
    const [isValidToReload, setIsValidToReload] = useState<boolean>(false);
    let { organisationId } = useParams<any>();

    const { data: getOrganisationData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION,
        { variables: { orgId: organisationId } }
    );

    const [ReloadOrgLicenseMutation] = useMutation<ReloadOrgLicenseMutation>(
        fragments.RELOAD_ORGANISATION_LICENSE
    );

    useEffect(() => {
        setHeaderContent({
            title: t("editOrganisation.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/organisation/" + organisationId,
        });
    }, []);

    useEffect(() => {
        if (
            !getOrganisationData ||
            !getOrganisationData.organisation ||
            !getOrganisationData.organisation.admin ||
            !getOrganisationData.organisation.organisationLicense
        ) {
            return;
        }

        let org = getOrganisationData.organisation;

        setOrganisationDetailsCombined({
            id: org.id,
            name: org.name,
            cifAndOthers: org.cifAndOthers,
            adminName: org.admin!.name,
            email: org.admin!.email,
        } as IOrganisationDetailsDto);

        setLicenseDetails(org.organisationLicense!);
    }, [getOrganisationData]);

    const onShowOrganisation = () => {
        navigate("../organisation/" + organisationId);
    };

    const onConfirm = () => {
        setIsConfirmModalVisible(true);
    };

    const onFinish = () => {
        setIsResultModalVisible(true);
        if (!reloadOrgLicense) return;

        ReloadOrgLicenseMutation({
            variables: {
                orgLicenseId: reloadOrgLicense.orgLicenseId,
                priceIncrement: reloadOrgLicense.priceIncrement,
                numberOfCases: reloadOrgLicense.numberOfCases,
                numberOfUsers: reloadOrgLicense.numberOfUsers,
            },
        })
            .then((resOrg) => {
                if (!resOrg.data) {
                    setIsResultModalError(true);
                }
                setIsResultModalLoading(false);
            })
            .catch(() => {
                setIsResultModalLoading(false);
                setIsResultModalError(true);
            });
    };

    const cancel = () => {
        setIsConfirmModalVisible(false);
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepsComponent
                        currentStep={currentStep}
                        title={t("reloadOrgLicense.title")}
                        steps={[
                            {
                                title: t("reloadOrgLicense.step.title"),
                                description: t(
                                    "reloadOrgLicense.step.description"
                                ),
                            },
                        ]}
                        isComplete={true}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <ReloadOrganisationLicense
                        organisationDetails={organisationDetailsCombined}
                        orgLicenseDetails={licenseDetails}
                        setReloadOrgLicense={setReloadOrgLicense}
                        onButtonClick={onConfirm}
                        isValidToReload={isValidToReload}
                        setIsValidToReload={setIsValidToReload}
                    />
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowOrganisation}
                isSecondaryButtonDisabled={true}
                localisedTexts={{
                    title: t("reloadOrgLicense.successModal.title"),
                    subtitle: t("reloadOrgLicense.successModal.subtitle"),
                    primaryButton: t("reloadOrgLicense.successModal.return"),
                    errorTitle: t("reloadOrgLicense.successModal.errorTitle"),
                    errorRetry: t("reloadOrgLicense.successModal.errorRetry"),
                }}
            />
            <Modal
                title={t("reloadOrgLicense.modal.title")}
                visible={isConfirmModalVisible}
                width={550}
                closable={false}
                footer={null}
            >
                <div className={styles.ModalButtonsDiv}>
                    <Button
                        key={"cancel" + organisationId}
                        className={styles.ModalButton}
                        onClick={() => setIsConfirmModalVisible(false)}
                    >
                        {t("reloadOrgLicense.modal.cancel")}
                    </Button>
                    <Button
                        className={styles.ModalButton}
                        key={"edit" + organisationId}
                        onClick={onFinish}
                        type="primary"
                    >
                        {t("reloadOrgLicense.modal.confirm")}
                    </Button>
                </div>
            </Modal>
        </>
    );
};
