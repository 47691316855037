import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import {
    ILicenseFeatureSimpleData,
    licenseFeatureFromPartialFeature,
} from "../entities/licenseFeature";
import { LicenseFeatureDataSource } from "../../data/dataSources/featureDataSource";
import { faro } from "@grafana/faro-web-sdk";
import { ILicenseFeatureListPresentation } from "../entities/licenseFeature";

interface IAdditionalMethods {
    getAllLicenseFeatures: (
        pageSize: number,
        pageNumber: number
    ) => Promise<void>;
}

export const useAllLicenseFeature: IUseDomainHook<
    ILicenseFeatureListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = LicenseFeatureDataSource();
    const [features, setFeatures] = useState<
        ILicenseFeatureListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllLicenseFeatures = async (
        pageSize: number,
        pageNumber: number
    ) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getAllLicenseFeatures(
                pageSize,
                pageNumber
            );
            let simplifiedResults: ILicenseFeatureSimpleData[] = [];
            result?.licenseFeatures.forEach((feature) => {
                simplifiedResults.push(
                    licenseFeatureFromPartialFeature(feature)
                );
            });
            if (result?.totalResults) {
                setFeatures({
                    licenseFeatures: simplifiedResults,
                    totalResults: result.totalResults,
                });
            }
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: features,
        isLoading,
        errorMessage,
        getAllLicenseFeatures,
    };
};
