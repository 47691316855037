import { useTranslation } from "react-i18next";
import { Alert, Progress } from "antd";
import styles from "./caseValidating.module.css";
import { Spacer } from "../../../appCore/spacer/spacer";
import { useEffect, useState } from "react";

export const CaseValidating = () => {
    const { t } = useTranslation();
    const [percent, setPercent] = useState<number>(0);

    useEffect(() => {
        (function timeLoop(i: number) {
            setTimeout(() => {
                setPercent(i);
                if ((i = i + Math.random() * 10)) {
                    if (i < 101) {
                        timeLoop(i);
                    } else {
                        i = 0;
                        timeLoop(i);
                    }
                }
            }, 2000);
        })(0);
    }, []);

    return (
        <div className={styles.Container}>
            <Alert
                type="info"
                message={t(
                    "caseDetails.resultFolders.caseValidating.alertTitle"
                )}
                description={t(
                    "caseDetails.resultFolders.caseValidating.alertDescription"
                )}
                showIcon
            />
            <Spacer size="m" />
            <p>
                {t("caseDetails.resultFolders.caseValidating.validationState")}
            </p>
            <Progress percent={percent} status="active" showInfo={false} />
        </div>
    );
};
