export interface IModelImages{
    biomarkers: IBiomarkerViews[];
    model: string;
    singleImages: ISingleImages[];
}

export interface IBiomarkerViews{
    key: string;
    value: IViewImages[];
}

export interface IViewImages{
    key: string;
    value: string[];
}

export interface ISingleImages{
    key: string;
    value: string[];
}

export interface ISequenceImages {
    [model: string]: {
        [view: string]: {
            [biomarker:string]: string[]
        };
    };
}

export interface IVisualizerWindow {
    activeImageSrc: string;
    activeType: string;
    activeModel: string;
    activeView: string;
    activeBiomarker: string;
}


export const sequenceImagesFromListOfIModelImages = (modelImages:IModelImages[]):ISequenceImages => {
    const sequenceImages: ISequenceImages = {};
    modelImages?.forEach((biomarker) => {
        const {model, biomarkers, singleImages} = biomarker
        sequenceImages[model] = sequenceImages[model] || {}
        biomarkers.forEach((view) => {
            sequenceImages[model][view.key] = sequenceImages[model][view.key] || {}
            view.value.forEach((bio) => {
                sequenceImages[model][view.key][bio.key] = sequenceImages[model][view.key][bio.key] || {}
                sequenceImages[model][view.key][bio.key] = bio.value
            })
        })
        singleImages.forEach((image) => {
            sequenceImages[model]["singleImages"] = sequenceImages[model]["singleImages"] || {}
            sequenceImages[model]["singleImages"][image.key] = sequenceImages[model]["singleImages"][image.key] || {}
            sequenceImages[model]["singleImages"][image.key] = [image.value[0]]
        })
    })
    return sequenceImages
}





