import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { Link, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { IOrganisationModel } from "../../../features/organisation/domain/entities/organisationModel";
import { useAllOrganisations } from "../../../features/organisation/domain/hooks/useAllOrganisations";

export const OrganisationListPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();
    const ELEMENTS_PAGE = 10;

    //===============HOOKS==================
    const {
        data: organisationListData,
        isLoading,
        errorMessage,
        getAllOrganisations,
    } = useAllOrganisations();

    const [organisationList, setOrganisationList] = useState<
        IOrganisationModel[]
    >([]);

    //===============FUNCTIONS==================
    const onPaginate = (pageNumber: number) => {
        getAllOrganisations(ELEMENTS_PAGE, pageNumber);
    };

    const columns: ColumnsType<IOrganisationModel> = [
        {
            title: t("organisationList.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("organisationList.table.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("organisationList.table.administrator"),
            dataIndex: "admin",
            key: "admin",
            render: (admin) => <>{admin && admin.name}</>,
        },
        {
            title: t("organisationList.table.license"),
            dataIndex: "organisationLicense",
            key: "organisationLicense",
            render: (organisationLicense) => (
                <>
                    {organisationLicense &&
                        organisationLicense.licenseTemplateReference.name}
                </>
            ),
        },
        {
            title: t("organisationList.table.actions"),
            dataIndex: "id",
            key: "id",
            render: (id, organisation) => {
                return (
                    <Link to={"/organisation/" + organisation.id}>
                        <span className="kenko-icon-eye"></span>
                    </Link>
                );
            },
        },
    ];

    //===============USEEFFECTS==================
    useEffect(() => {
        getAllOrganisations(ELEMENTS_PAGE, 0);
    }, []);

    useEffect(() => {
        if (!organisationListData) {
            return;
        }
        setOrganisationList(organisationListData.organisationPresentations);
    }, [organisationListData]);

    useEffect(() => {
        setHeaderContent({
            title: t("organisationList.title"),
            breadCrumb: [],
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="createOrganisation"
                    onClick={() => {
                        navigate("/createOrganisation");
                    }}
                >
                    {t("organisationList.createOrganisation")}
                    <PlusOutlined />
                </Button>
            ),
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t]);

    return (
        <>
            <Table
                columns={columns}
                dataSource={organisationList}
                rowKey="id"
                scroll={{ x: 800 }}
                pagination={{
                    total: organisationListData?.totalResults,
                    onChange: (page) => {
                        onPaginate(page - 1);
                    },
                }}
            />
        </>
    );
};
