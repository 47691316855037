import * as React from "react";
import sanitize from "sanitize-html";

interface IProps {
    content: string;
}

const HtmlPrinter = ({ content }: IProps): JSX.Element => {
    const clean = sanitize(content, {
        allowedTags: ["sub", "b", "a"],
    });

    return <span dangerouslySetInnerHTML={{ __html: clean }} />;
};

export default HtmlPrinter;
