import { ISequencesCreationDto } from "../../../features/sequencesTemplate/domain/entities/sequencesModel";

export const areSequencesEqual = (
    sequences1: Partial<ISequencesCreationDto> | undefined,
    sequences2: Partial<ISequencesCreationDto> | undefined
): boolean => {
    let result = true;
    if (!sequences1 || !sequences2) return false;
    if (sequences1.name !== sequences2.name) {
        return false;
    }
    if (sequences1.stepSequencesTemplate && sequences2.stepSequencesTemplate) {
        if (
            sequences1.stepSequencesTemplate.length !==
            sequences2.stepSequencesTemplate.length
        ) {
            return false;
        }
        let isOk: boolean = true;
        for (
            let i = 0;
            i < sequences1.stepSequencesTemplate.length && isOk;
            i++
        ) {
            const sequence1 = sequences1.stepSequencesTemplate[i];
            const sequence2 = sequences2.stepSequencesTemplate[i];
            if (sequence1.sequenceType && sequence2.sequenceType) {
                if (sequence1.sequenceType !== sequence2.sequenceType) {
                    result = false;
                    isOk = false;
                }
                if (sequence1.stepTemplates && sequence2.stepTemplates) {
                    if (
                        sequence1.stepTemplates.length !==
                        sequence2.stepTemplates.length
                    ) {
                        result = false;
                        isOk = false;
                    }
                    for (
                        let j = 0;
                        j < sequence1.stepTemplates.length && isOk;
                        j++
                    ) {
                        const step1 = sequence1.stepTemplates[j];
                        const step2 = sequence2.stepTemplates[j];
                        if (step1.type !== step2.type) {
                            result = false;
                            isOk = false;
                        }
                        if (
                            step1.unversionedEcsTaskName !==
                            step2.unversionedEcsTaskName
                        ) {
                            result = false;
                            isOk = false;
                        }
                        if (step1.version !== step2.version) {
                            result = false;
                            isOk = false;
                        }
                    }
                }
            }
        }
    }
    return result;
};
