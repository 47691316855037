import { useTranslation } from "react-i18next";
import { Col, Row, Switch, Typography } from "antd";
import styles from "./visualizerMultiplanarBtn.module.scss";
import { Dispatch, SetStateAction } from "react";
import { IVisualizerWindow } from "../../../domain/entities/modelImages";

interface IProps {
    setActiveMultiplanar: Dispatch<SetStateAction<boolean>>;
    isMultiplanarActive: boolean;
    allowMultiplanarView: boolean;
    activeWindow: number;
    visualizerWindows: IVisualizerWindow[];
    singleImages: Set<string> | undefined;
    isMultiViewAvailable: boolean; 
    setPixelAnalizeActive: Dispatch<SetStateAction<boolean>>;
}

const { Text } = Typography;

export const VisualizerMultiplanarBtn = ({
    setActiveMultiplanar,
    isMultiplanarActive,
    allowMultiplanarView,
    visualizerWindows,
    activeWindow,
    singleImages,
    isMultiViewAvailable,
    setPixelAnalizeActive,
}: IProps) => {
    const { t } = useTranslation();

    const onClick = () => {

        if(!isMultiplanarActive && (visualizerWindows[activeWindow].activeImageSrc === "" || 
            singleImages?.has(visualizerWindows[activeWindow].activeBiomarker)) ) return

        setActiveMultiplanar(!isMultiplanarActive)

        if(!isMultiplanarActive === false) setPixelAnalizeActive(false)
        
    }

    return (
        <>
            { isMultiViewAvailable ? (
            <>
                <div className={styles.Container}>
                    <Row>
                        <Col>
                            <Text className={styles.Text}>{t("visualizer.header.multiplanar")+":"}</Text>
                        </Col>
                        <Col>
                            <div className={styles.Button}>
                                <Switch 
                                    className={styles.Switch}
                                    onChange={onClick} 
                                    disabled={!allowMultiplanarView} 
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </>) : (<></>) }
        </>        
    );
};
