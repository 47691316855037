import { gql } from "@apollo/client";

const CREATE_SUPPORT_MESSAGE = gql`
    mutation createSupportMessage($title: String!, $content: String!) {
        createSupportMessage(input: { title: $title, content: $content })
    }
`;

export const supportFragments = {
    CREATE_SUPPORT_MESSAGE,
};
