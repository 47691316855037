import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    Radio,
    Tooltip,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import styles from "./createCaseInfoStep.module.scss";
import TextArea from "antd/es/input/TextArea";
import { Spacer } from "../../appCore/spacer/spacer";
import { useHeader } from "../../../hooks/headerHook";
import { ICaseDetails, ICreationCaseDetails } from "../../../features/case/domain/entities/case";

const { Title, Text } = Typography;

interface IProps {
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    setCaseDetails: React.Dispatch<React.SetStateAction<ICreationCaseDetails>>;
    caseDetails: ICreationCaseDetails;
}

export const CreateCaseInfoStep = ({
    setCurrent,
    setCaseDetails,
    caseDetails,
}: IProps) => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        if (
            caseDetails.reference !== "" &&
            caseDetails.reference !== undefined &&
            caseDetails.isPositivePrediction !== undefined &&
            caseDetails.initialNotes !== "" &&
            caseDetails.initialNotes !== undefined
        ) {
            setIsFormValid(true);
        }
    }, []);

    const validateMessages = {
        required: t("fieldValidation.required"),
    };

    const onFinish = (values: ICaseDetails) => {
        setCaseDetails(values);
        setCurrent(1);
    };

    const onChange = (
        changedValues: ICaseDetails | undefined,
        values: ICaseDetails | undefined
    ) => {
        setHeaderContent({
            title: t("createCase.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/",
            enableBlockOnLeave: true,
        });
        if (
            values?.reference !== "" &&
            values?.reference !== undefined &&
            values?.isPositivePrediction !== undefined &&
            values?.initialNotes !== "" &&
            values?.initialNotes !== undefined
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const options = [
        { label: t("positive"), value: true },
        { label: t("negative"), value: false },
    ];

    return (
        <Card>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onChange}
                initialValues={caseDetails}
                validateMessages={validateMessages}
            >
                <div>
                    <Title level={4}>
                        {t("createCase.infoStep.caseInformation")}
                    </Title>
                    <Text type="secondary">
                        {t("createCase.infoStep.caseInformationSubtitle")}
                    </Text>
                </div>

                <Spacer size={"m"} />

                <Form.Item
                    label={t("createCase.infoStep.referenceCode")}
                    name="reference"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input
                        placeholder={t(
                            "createCase.infoStep.referenceCodePrompt"
                        )}
                        suffix={
                            <Tooltip title={t("createCase.infoStep.idTooltip")}>
                                <span className="kenko-icon-info"></span>
                            </Tooltip>
                        }
                        style={{ maxWidth: "300px" }}
                    />
                </Form.Item>

                <Divider />

                <div>
                    <Title level={4}>
                        {t("createCase.infoStep.initialDiagnosis")}
                    </Title>
                    <Text type="secondary">
                        {t("createCase.infoStep.initialDiagnosisParagraph")}
                    </Text>
                </div>

                <Spacer size={"m"} />

                <Form.Item
                    label={t("createCase.infoStep.selectInitialDiagnosis")}
                    name="isPositivePrediction"
                    className={styles.HorizontalFormItem}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group
                        options={options}
                        optionType="button"
                        buttonStyle="solid"
                        className={styles.RadioSelect}
                    />
                </Form.Item>

                <Form.Item
                    label={t("createCase.infoStep.caseObservationsTitle")}
                    name="initialNotes"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea
                        placeholder={t(
                            "createCase.infoStep.caseObservationsPrompt"
                        )}
                        maxLength={200}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                </Form.Item>

                <Spacer size={"s"} />

                <Form.Item className={styles.StepNavigation}>
                    <Button
                        type="primary"
                        onClick={() => {}}
                        htmlType="submit"
                        disabled={!isFormValid}
                    >
                        {t("createCase.next")}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
