import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { CaseDataSource } from "../../data/dataSources/caseDataSource";
import { IFinalDiagnosis, finalDiagnosisFromPresentation } from "../entities/case";

interface IAdditionalMethods {
    updateFinalDiagnosisCase: (caseId:string, finalNotes: string, isPositive: boolean) => Promise<void>;
}

export const useUpdateCase: IUseDomainHook<
    IFinalDiagnosis | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = CaseDataSource();
    const [finalDiagnosis, setFinalDiagnosis] = useState<IFinalDiagnosis | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const updateFinalDiagnosisCase = async (caseId:string, finalNotes: string, isPositive: boolean) => {
        setIsLoading(true);

        try {
            const result = await dataSource.updateFinalDiagnosisCase(caseId, finalNotes, isPositive);
            if (!result) {
                setErrorMessage("Not found");   
            }
            else{
                setFinalDiagnosis(finalDiagnosisFromPresentation(result));
            }
        } catch (e: any) {
            setErrorMessage(e.message);
        }

        setIsLoading(false);
    };

    return {
        data: finalDiagnosis,
        isLoading,
        errorMessage,
        updateFinalDiagnosisCase,
    };
};