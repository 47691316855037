import { useTranslation } from "react-i18next";
import { Button, Card, Typography, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import Dragger from "antd/lib/upload/Dragger";
import CaseService from "../../../services/caseService";
import styles from "./createCaseUploadStep.module.scss";
import { Spacer } from "../../appCore/spacer/spacer";
import { RcFile } from "antd/es/upload/interface";
import { FileListComponent } from "./fileListComponent/fileListComponent";
import { UploadModal } from "./uploadModal/uploadModal";
import { ResultModal } from "../../appCore/resultModal/resultModal";
import { useMutation } from "@apollo/client";
import fragments from "../../../gql/fragments";
import { AddCaseMutation } from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../hooks/headerHook";
import { ICreationCaseDetails } from "../../../features/case/domain/entities/case";

const { Title, Text } = Typography;

interface IProps {
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    caseDetails: ICreationCaseDetails;
}

export const CreateCaseUploadStep = ({ setCurrent, caseDetails }: IProps) => {
    let navigate = useNavigate();
    const { setHeaderContent } = useHeader();
    const { t } = useTranslation();

    const [fileList, setFileList] = useState<RcFile[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [percent, setPercent] = useState<number>(0);
    const [caseId, setCaseId] = useState<string>("");

    const [
        AddCaseMutation,
        { data: addCaseData, loading: addCaseLoading, error: addCaseError },
    ] = useMutation<AddCaseMutation>(fragments.CREATE_CASE);

    useEffect(() => {
        setHeaderContent({
            title: t("createCase.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/",
            enableBlockOnLeave: true,
        });
    }, []);

    const onPrev = () => {
        setCurrent(0);
    };

    const handleUpload = () => {
        let fileUpload: RcFile;
        fileUpload = fileList[0] as RcFile;
        
        setUploading(true);
        setIsModalVisible(true);

        // ? Post Case
        AddCaseMutation({ variables: caseDetails })
            .then((result) => {
                setCaseId(result!.data!.addCase.id);
                // ? Get AWS Upload URL
                CaseService.getCaseUrl(
                    result!.data!.addCase.id,
                    fileList[0].name
                )
                    .then((urlResponse) => {
                        // ? Start Upload
                        CaseService.postCaseFile(
                            fileUpload,
                            urlResponse,
                            setPercent
                        )
                            .then(() => {
                                // ? On finish
                                // * Show <SuccessModal />
                                setTimeout(() => {
                                    setIsSuccessModalVisible(true);
                                }, 1000);
                                // * Empty file list
                                setFileList([]);
                                // * Empty case details
                            })
                            .catch((e) => {
                                setIsError(true);
                                console.error(e);
                            });
                    })
                    .catch(() => {
                        setIsError(true);
                    });
            })
            .catch((error) => {
                setIsError(true);
                console.error(error);
            });
    };

    const onDelete = () => {
        setFileList([]);
    };

    const props: UploadProps = {
        name: "file",
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const onRetryCallback = () => {
        if (!!caseId) {
            navigate("/case/" + caseId);
        } else {
            navigate(0);
        }
    };

    const onShowCase = () => {
        navigate("../case/" + caseId);
    };

    return (
        <Card>
            <Title level={4}>
                {t("createCase.uploadStep.fileUploadTitle")}
            </Title>
            <Text type="secondary">
                {t("createCase.uploadStep.fileUploadSubtitle")}
            </Text>

            <Spacer size="m" />

            <Dragger
                style={{ display: fileList.length === 0 ? "" : "none" }}
                {...props}
                maxCount={1}
                accept="application/zip"
            >
                <Spacer size="m" />
                <p className="ant-upload-drag-icon">
                    <span
                        className={`${styles.ArchiveIcon} kenko-icon-archive-empty`}
                    />
                </p>
                <p className="ant-upload-text">
                    {t("createCase.uploadStep.clickOrDropFile")}
                </p>
                <p className="ant-upload-hint">
                    {t("createCase.uploadStep.mustBeZip")}
                </p>
                <Spacer size="m" />
            </Dragger>
            <div>
                {fileList.length === 1 ? (
                    <FileListComponent file={fileList[0]} onDelete={onDelete} />
                ) : (
                    ""
                )}
            </div>

            <Spacer size="m" />

            <div className={styles.StepNavigation}>
                <Button style={{ margin: "0 8px" }} onClick={onPrev}>
                    {t("createCase.goBack")}
                </Button>

                <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length !== 1}
                >
                    {t("createCase.uploadStep.uploadAndAnalyze")}
                </Button>
            </div>
            <UploadModal
                isVisible={isModalVisible}
                percent={percent}
                isError={isError}
                onRetryCallback={onRetryCallback}
            />
            <ResultModal
                isVisible={isSuccessModalVisible}
                onPrimary={onShowCase}
                localisedTexts={{
                    title: t("createCase.successModal.title"),
                    subtitle: t("createCase.successModal.description"),
                    secondaryButton: t("createCase.successModal.uploadAnother"),
                    primaryButton: t("createCase.successModal.showCase"),
                }}
            />
        </Card>
    );
};
