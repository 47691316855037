import { Col, Row } from "antd";
import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useHeader } from "../../../hooks/headerHook";
import { useEffect, useState } from "react";
import styles from "./assignUserToUserLicensePage.module.scss";
import { AssignUser } from "../../../features/userLicense/presentation/assignUser/assignUser";
import { useMutation, useQuery } from "@apollo/client";
import { AddUserMutation, OrganisationQuery } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import {
    IUserBasicInfo,
    IUserListPresentation,
    IUserReducedDto,
} from "../../../features/user/domain/entities/userModel";
import {
    IUserLicense,
    IUserLicenseCreate,
} from "../../../features/userLicense/domain/entities/userLicenseModel";
import { IOrganisationLicenseCounters } from "../../../features/organisation/domain/entities/organisationModel";
import { useAssignUserToUserLicense } from "../../../features/userLicense/domain/hooks/useAssignUserToUserLicense";
import { useUserLicense } from "../../../features/userLicense/domain/hooks/useUserLicense";
import { useAllUsers } from "../../../features/user/domain/hooks/useAllUsers";

export const AssignUserToUserLicensePage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { setHeaderContent } = useHeader();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isIconVisible, setIsIconVisible] = useState<boolean>(true);
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [createUserDetails, setCreateUserDetails] = useState<
        IUserReducedDto | undefined
    >();
    const [checkValue, setCheckValue] = useState<number>(0);
    let { userLicenseId } = useParams<any>();
    const [userLicenseDetails, setUserLicenseDetails] = useState<
        IUserLicenseCreate | undefined
    >();
    const [updatedUserDetails, setUpdateUserDetails] = useState<
        IUserBasicInfo | undefined
    >();
    const [userLicenseModel, setUserLicenseModel] = useState<IUserLicense>();
    const [usersWithoutLicense, setUsersWithoutLicense] = useState<
        IUserListPresentation | undefined
    >();
    const [remainingCounters, setRemainingCounters] =
        useState<IOrganisationLicenseCounters>();
    const [unfilteredUsers, setUnfilteredUsers] = useState<IUserBasicInfo[]>();
    const [filteredUsers, setFilteredUsers] = useState<IUserBasicInfo[]>();
    const [textSearchUser, setTextSeachUser] = useState<string>("");
    const ELEMENTS_PAGE = 10;
    const PAGE_NUMBER = 0;

    const { data: organisationLicenseData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION
    );

    const [AddUserMutation] = useMutation<AddUserMutation>(
        fragments.CREATE_USER,
        {
            variables: {
                firstName: createUserDetails?.firstName,
                lastName: createUserDetails?.lastName,
                email: createUserDetails?.email,
            },
        }
    );

    //===============HOOKS==================
    const {
        data: userLicenseAssign,
        isLoading: isLoadingAssign,
        errorMessage: errorMessageAssign,
        assignUserToUserLicense,
    } = useAssignUserToUserLicense();
    const {
        data: userLicenseData,
        isLoading,
        errorMessage,
        getUserLicense,
    } = useUserLicense();
    const {
        data: usersWithoutLicenseData,
        isLoading: isLoadingAllUsers,
        errorMessage: errorMessageAllUsers,
        getAllUsers,
    } = useAllUsers();

    //===============UPDATES==================
    const updateUserLicenseUserId = (value: string | undefined | null) => {
        setUserLicenseDetails((prevState) => ({
            ...prevState,
            numberOfCases: prevState?.numberOfCases,
            id: value,
        }));
    };
    const updateCheckValue = (value: number) => {
        setCheckValue(value);
    };
    const updateUserName = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: value,
            lastName: prevState?.lastName,
            email: prevState?.email,
        }));
    };
    const updateUserSurname = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: prevState?.firstName,
            lastName: value,
            email: prevState?.email,
        }));
    };
    const updateUserEmail = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: prevState?.firstName,
            lastName: prevState?.lastName,
            email: value,
        }));
    };
    const updateUpdatedUserDetails = (value: IUserBasicInfo | undefined) => {
        setUpdateUserDetails(value);
    };

    //===============FUNCTIONS==================
    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };

    const onShowUserLicenses = () => {
        navigate("/userLicenses");
    };

    const onFullUpdate = () => {
        if (checkValue === 1) {
            //choose
            if (!userLicenseId || !userLicenseDetails || !userLicenseDetails.id)
                return;
            assignUserToUserLicense(userLicenseId, userLicenseDetails.id);
        } else if (checkValue === 2) {
            //create
            setIsResultModalVisible(true);
            AddUserMutation({
                variables: createUserDetails,
            }).then((res) => {
                if (!userLicenseId || !res.data) return;
                assignUserToUserLicense(userLicenseId, res.data?.addUser.id);
            });
        }
    };

    const onLoadMoreUsers = (pageNumber: number) => {
        getAllUsers(
            true,
            ELEMENTS_PAGE,
            pageNumber,
            undefined,
            textSearchUser
        ).then((res: IUserListPresentation | null | undefined) => {
            if (!res) {
                return;
            }
            if (!textSearchUser) {
                setUnfilteredUsers(
                    unfilteredUsers?.concat(res.userPresentations)
                );
            } else {
                setFilteredUsers(filteredUsers?.concat(res.userPresentations));
            }
        });
    };

    const onFilterUser = (textInput: string) => {
        setTextSeachUser(textInput);
        getAllUsers(
            true,
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            undefined,
            textInput
        ).then((res) => {
            if (!res) return;
            setFilteredUsers(res?.userPresentations);
        });
    };

    //===============USEEFFECTS==================
    useEffect(() => {
        getAllUsers(true, ELEMENTS_PAGE, PAGE_NUMBER).then((res) => {
            if (!res) return;
            setUnfilteredUsers(res?.userPresentations);
        });
    }, []);

    useEffect(() => {
        if (!userLicenseId) return;
        getUserLicense(userLicenseId);
    }, [userLicenseData]);

    useEffect(() => {
        if (organisationLicenseData) {
            setRemainingCounters({
                remainingCases:
                    organisationLicenseData?.organisation.organisationLicense
                        .remainingCases,
                remainingUsers:
                    organisationLicenseData?.organisation.organisationLicense
                        .remainingUsers,
            });
        }
        if (usersWithoutLicenseData) {
            setUsersWithoutLicense(usersWithoutLicenseData);
        }
    }, [usersWithoutLicenseData, userLicenseData, organisationLicenseData]);

    useEffect(() => {
        if (!userLicenseData) return;
        updateUserLicenseUserId(userLicenseData.user?.id);
        updateUpdatedUserDetails(userLicenseData.user as IUserBasicInfo);
        setUserLicenseModel(userLicenseData);
    }, [userLicenseData]);

    useEffect(() => {
        setHeaderContent({
            title: t("createLicense.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/userLicenses",
        });
    }, []);

    useEffect(() => {
        if (errorMessageAssign) setIsResultModalError(true);
        if (!userLicenseAssign) return;
        setIsResultModalLoading(isLoadingAssign);
        setIsResultModalVisible(true);
    }, [userLicenseAssign, errorMessageAssign]);

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        isComplete={true}
                        title={
                            t("editUserLicense.title") +
                            "LIC-" +
                            userLicenseModel?.creationOrder
                        }
                        steps={[
                            {
                                title: t("createUserLicense.steps.2.title"),
                                description: t(
                                    "createUserLicense.steps.2.description"
                                ),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                        isEditStep={true}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <AssignUser
                        updateCheckValue={updateCheckValue}
                        checkValue={checkValue}
                        onCreateFullLicense={onFullUpdate}
                        usersWithoutLicense={usersWithoutLicense}
                        updateUserLicenseUserId={updateUserLicenseUserId}
                        updatedUserDetails={updatedUserDetails}
                        isEditPage={true}
                        updateUserName={updateUserName}
                        updateUserSurname={updateUserSurname}
                        updateUserEmail={updateUserEmail}
                        email={createUserDetails?.email}
                        onLoadMoreUsers={onLoadMoreUsers}
                        textSearchUser={textSearchUser}
                        unfilteredUsers={unfilteredUsers}
                        filteredUsers={filteredUsers}
                        onFilterUser={onFilterUser}
                    />
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowUserLicenses}
                hasSecondary={false}
                localisedTexts={{
                    title: t("editUserLicense.successModal.titleAssignUser"),
                    subtitle: "",
                    secondaryButton: t(
                        "createUserLicense.successModal.createAnother"
                    ),
                    primaryButton: t("createUserLicense.successModal.list"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
        </>
    );
};
