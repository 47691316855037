import { useForm } from "antd/es/form/Form";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TasksQuery } from "../../../../generated/graphql";
import {
    ISequencesCreationDto,
    IStepSequenceTemplate,
} from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { AddTecnique } from "./addTecnique";

interface IProps {
    setCurrentStep: Dispatch<SetStateAction<number>>;
    currentStep: number;
    sequencesDetails?: Partial<ISequencesCreationDto>;
    setSequencesDetails: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    sequencesTemplate?: Partial<ISequencesCreationDto>;
    setSequencesTemplate: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    onButtonClick?: () => void;
    sequenceIndex: number;
    isFormValid: boolean;
    isSequenceDelete: boolean;
    isStepDelete: boolean;
    setIsStepDelete: Dispatch<SetStateAction<boolean>>;
    setSteps: Dispatch<SetStateAction<IStep[]>>;
    steps: IStep[];
    sequenceType: string;
    setSequenceType: Dispatch<SetStateAction<string>>;
    tasks: TasksQuery | undefined;
}

interface IStep {
    title: string;
    description: string;
}

export const AddSequencesStep = ({
    setCurrentStep,
    currentStep,
    sequencesDetails,
    setSequencesDetails,
    sequenceIndex,
    setSteps,
    steps,
    isFormValid,
    isSequenceDelete,
    isStepDelete,
    setIsStepDelete,
    sequencesTemplate,
    setSequencesTemplate,
    sequenceType,
    setSequenceType,
    tasks,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const onRemoveTecnique = (removedTecniqueIndex: number) => {
        if (!sequencesDetails) return;

        let newStepSequences = sequencesDetails.stepSequencesTemplate!.filter(
            (stepSequences, i) => i !== removedTecniqueIndex
        );

        const newSteps = steps;
        newSteps.splice(removedTecniqueIndex - 1, 1);
        if (removedTecniqueIndex === steps.length - 1) {
            setCurrentStep(currentStep - 1);
        }
        setSteps(newSteps);

        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: newStepSequences,
        }));
        setSequencesTemplate((prevState) => ({
            ...prevState,
            stepSequencesTemplate: newStepSequences,
        }));
    };

    return (
        <div>
            {sequencesDetails?.stepSequencesTemplate?.map(
                (
                    value: Partial<IStepSequenceTemplate>,
                    sequenceIndex: number
                ) => {
                    if (currentStep - 1 === sequenceIndex) {
                        return (
                            <AddTecnique
                                setCurrentStep={setCurrentStep}
                                setSequencesDetails={setSequencesDetails}
                                sequencesDetails={sequencesDetails}
                                sequenceIndex={sequenceIndex}
                                key={sequenceIndex}
                                isFormValid={isFormValid}
                                onRemoveTecnique={onRemoveTecnique}
                                isSequenceDelete={isSequenceDelete}
                                isStepDelete={isStepDelete}
                                setIsStepDelete={setIsStepDelete}
                                sequencesTemplate={sequencesTemplate}
                                setSequencesTemplate={setSequencesTemplate}
                                sequenceType={sequenceType}
                                setSequenceType={setSequenceType}
                                tasks={tasks}
                            />
                        );
                    }
                }
            )}
        </div>
    );
};
