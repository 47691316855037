import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  Long: any;
};

export type AddCaseInput = {
  initialNotes: Scalars['String'];
  isPositivePrediction: Scalars['Boolean'];
  reference: Scalars['String'];
};

export type AddLicenseFeatureInput = {
  description: Scalars['String'];
  environKey: Scalars['String'];
  environValue: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Decimal'];
};

export type AddLicenseTemplateInput = {
  distributor: DistributorTypeInput;
  maxNumberOfCases: Scalars['Int'];
  maxNumberOfUsers: Scalars['Int'];
  memoryLimits: MemoryLimitsInput;
  name: Scalars['String'];
  organ: Scalars['String'];
  sequencesTemplateId: Scalars['String'];
  totalPrice: Scalars['Decimal'];
};

export type AddOrganisationInput = {
  cifAndOthers: Scalars['String'];
  email: Scalars['String'];
  expiryDate?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  licenseId: Scalars['String'];
  name: Scalars['String'];
};

export type AddUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type AssignUserLicenseToUserInput = {
  userId: Scalars['String'];
  userLicenseId: Scalars['String'];
};

export type CaseChangedPushNotification = {
  __typename?: 'CaseChangedPushNotification';
  doctorReference: Scalars['String'];
  newState: Scalars['String'];
  notificationType: Scalars['String'];
  numberIfRelevant: Scalars['Long'];
  percentage: Scalars['Int'];
  relevantId: Scalars['String'];
  textIfRelevant: Scalars['String'];
  zipLink: Scalars['String'];
  zipState: Scalars['String'];
};

export enum CaseFilteredFields {
  CreationDate1 = 'CREATION_DATE1',
  CreationDate2 = 'CREATION_DATE2',
  FinalDiagnosis = 'FINAL_DIAGNOSIS',
  InitialDiagnosis = 'INITIAL_DIAGNOSIS',
  State = 'STATE'
}

export type CaseListPresentation = {
  __typename?: 'CaseListPresentation';
  casePresentations: Array<CasePresentation>;
  totalResults: Scalars['Int'];
};

export type CasePresentation = {
  __typename?: 'CasePresentation';
  creationDate: Scalars['DateTime'];
  finalNotes?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  initialNotes: Scalars['String'];
  isPositive?: Maybe<Scalars['Boolean']>;
  isPositivePrediction: Scalars['Boolean'];
  organ: Scalars['String'];
  pdfState?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Int']>;
  reference: Scalars['String'];
  state: Scalars['String'];
  user: UserPresentation;
  zipLink?: Maybe<Scalars['String']>;
  zipState?: Maybe<Scalars['String']>;
};

export enum CaseSortableFields {
  CreationDate = 'CREATION_DATE',
  DoctorReference = 'DOCTOR_REFERENCE',
  FinalDiagnosis = 'FINAL_DIAGNOSIS',
  InitialDiagnosis = 'INITIAL_DIAGNOSIS'
}

export type CreateSequencesTemplateInput = {
  name: Scalars['String'];
  stepSequenceTemplates: Array<StepSequenceTemplateDtoInput>;
};

export type CreateSupportMessageInput = {
  content: Scalars['String'];
  title: Scalars['String'];
};

export type DeleteUserInput = {
  id: Scalars['String'];
};

export type DistributorPresentation = {
  __typename?: 'DistributorPresentation';
  name: Scalars['String'];
  unversionedDistributorName: Scalars['String'];
  version: Scalars['Int'];
};

export type DistributorTypeInput = {
  name: Scalars['String'];
  unversionedDistributorName: Scalars['String'];
  version: Scalars['Int'];
};

export type EditLicenseFeatureInput = {
  description: Scalars['String'];
  environKey: Scalars['String'];
  environValue: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Decimal'];
};

export type EditLicenseTemplateInput = {
  distributor: DistributorTypeInput;
  id: Scalars['String'];
  maxNumberOfCases: Scalars['Int'];
  maxNumberOfUsers: Scalars['Int'];
  memoryLimits: MemoryLimitsInput;
  name: Scalars['String'];
  organ: OrganInput;
  sequenceTemplateId: Scalars['String'];
  totalPrice: Scalars['Decimal'];
};

export type EditOrganisationInput = {
  cifAndOthers: Scalars['String'];
  name: Scalars['String'];
  orgId: Scalars['String'];
};

export type EditSequencesTemplateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  stepSequenceTemplates: Array<StepSequenceTemplateDtoInput>;
};

export type ExportInput = {
  exportType: Scalars['String'];
  id: Scalars['String'];
};

export type FilterInputOfCaseFilteredFieldsInput = {
  fieldName: CaseFilteredFields;
  values: Array<Scalars['String']>;
};

export type GetCaseInput = {
  filterInputs?: InputMaybe<Array<FilterInputOfCaseFilteredFieldsInput>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortInputs?: InputMaybe<Array<SortInputOfCaseSortableFieldsInput>>;
  textSearchInput?: InputMaybe<Scalars['String']>;
};

export type GetLicenseFeatureInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetLicenseTemplateInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetOrganisationInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetSequencesTemplateInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetUserInput = {
  isUsersWithoutLicense: Scalars['Boolean'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortInputs?: InputMaybe<Array<SortInputOfUserSortableFieldsInput>>;
  textSearchInput?: InputMaybe<Scalars['String']>;
};

export type GetUserLicensesInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortInputs?: InputMaybe<Array<SortInputOfUserLicenseSortableFieldsInput>>;
  textSearchInput?: InputMaybe<Scalars['String']>;
};

export type KeyValuePairOfStringAndDictionaryOfStringAndListOfString = {
  __typename?: 'KeyValuePairOfStringAndDictionaryOfStringAndListOfString';
  key: Scalars['String'];
  value: Array<KeyValuePairOfStringAndListOfString>;
};

export type KeyValuePairOfStringAndIEnumerableOfString = {
  __typename?: 'KeyValuePairOfStringAndIEnumerableOfString';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type KeyValuePairOfStringAndInt32 = {
  __typename?: 'KeyValuePairOfStringAndInt32';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type KeyValuePairOfStringAndListOfSequenceTaskPresentation = {
  __typename?: 'KeyValuePairOfStringAndListOfSequenceTaskPresentation';
  key: Scalars['String'];
  value: Array<SequenceTaskPresentation>;
};

export type KeyValuePairOfStringAndListOfString = {
  __typename?: 'KeyValuePairOfStringAndListOfString';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type LicenseFeature = {
  __typename?: 'LicenseFeature';
  description: Scalars['String'];
  environKey: Scalars['String'];
  environValue: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Decimal'];
};

export type LicenseFeatureListPresentation = {
  __typename?: 'LicenseFeatureListPresentation';
  licenseFeatures: Array<LicenseFeature>;
  totalResults: Scalars['Int'];
};

export type LicenseListPresentation = {
  __typename?: 'LicenseListPresentation';
  licensePresentations: Array<LicensePresentation>;
  totalResults: Scalars['Int'];
};

export type LicensePresentation = {
  __typename?: 'LicensePresentation';
  distributor?: Maybe<DistributorPresentation>;
  id: Scalars['String'];
  maxNumberOfCases: Scalars['Long'];
  maxNumberOfUsers: Scalars['Long'];
  memoryLimits: MemoryLimitsPresentation;
  name: Scalars['String'];
  numberOfRemainingCases: Scalars['Long'];
  numberOfRemainingUsers: Scalars['Long'];
  organ: Scalars['String'];
  sequencesTemplate?: Maybe<SequencesTemplateReducedPresentation>;
  totalPrice: Scalars['Decimal'];
};

export type LicenseTemplateReference = {
  __typename?: 'LicenseTemplateReference';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MemoryLimitsInput = {
  currentEcsMemorySize: Scalars['Int'];
  maxEcsMemorySize: Scalars['Int'];
};

export type MemoryLimitsPresentation = {
  __typename?: 'MemoryLimitsPresentation';
  currentEcsMemorySize: Scalars['Int'];
  maxEcsMemorySize: Scalars['Int'];
};

export type ModelImagesPresentation = {
  __typename?: 'ModelImagesPresentation';
  biomarkers: Array<KeyValuePairOfStringAndDictionaryOfStringAndListOfString>;
  model: Scalars['String'];
  singleImages: Array<KeyValuePairOfStringAndListOfString>;
};

export type MoveNumCasesBetweenUserLicensesInput = {
  numberOfCases: Scalars['Int'];
  userLicenseIdDestiny: Scalars['String'];
  userLicenseIdOrigin: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCase: CasePresentation;
  addLicenseFeature: LicenseFeature;
  addLicenseTemplate: LicensePresentation;
  addOrganisation: OrganisationPresentation;
  addSequencesTemplate: SequencesTemplatePresentation;
  addUser: UserPresentation;
  assignLicenseFeatureToOrg: OrganisationPresentation;
  assignUserLicenseToUser: UserLicensePresentation;
  createSupportMessage: Scalars['String'];
  createUserLicense: UserLicensePresentation;
  deleteCase: CasePresentation;
  deleteLicenseFeature: LicenseFeature;
  deleteLicenseTemplate: LicensePresentation;
  deleteSequencesTemplate: SequencesTemplatePresentation;
  deleteUser: UserPresentation;
  moveNumCasesBetweenUserLicenses: UserLicenseMovedCases;
  reloadOrgLicense: OrganisationPresentation;
  reloadUserLicense: UserLicensePresentation;
  requestExport: CasePresentation;
  resetPassword: UserPresentation;
  returnCasesToOrgLicense: UserLicensePresentation;
  unassignLicenseFeatureToOrg: OrganisationPresentation;
  unassignUserLicenseToUser: UserLicensePresentation;
  updateCase: CasePresentation;
  updateLicenseFeature: LicenseFeature;
  updateLicenseTemplate: LicensePresentation;
  updateOrganisation: OrganisationPresentation;
  updateSequencesTemplate: SequencesTemplatePresentation;
  updateUser: UserPresentation;
};


export type MutationAddCaseArgs = {
  input: AddCaseInput;
};


export type MutationAddLicenseFeatureArgs = {
  featureInput: AddLicenseFeatureInput;
};


export type MutationAddLicenseTemplateArgs = {
  templateInput: AddLicenseTemplateInput;
};


export type MutationAddOrganisationArgs = {
  input: AddOrganisationInput;
};


export type MutationAddSequencesTemplateArgs = {
  sequencesInput: CreateSequencesTemplateInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAssignLicenseFeatureToOrgArgs = {
  licenseFeatureIds: Array<Scalars['String']>;
  orgId: Scalars['String'];
};


export type MutationAssignUserLicenseToUserArgs = {
  input: AssignUserLicenseToUserInput;
};


export type MutationCreateSupportMessageArgs = {
  input: CreateSupportMessageInput;
};


export type MutationCreateUserLicenseArgs = {
  input: UserLicenseInput;
};


export type MutationDeleteCaseArgs = {
  caseId: Scalars['String'];
};


export type MutationDeleteLicenseFeatureArgs = {
  licenseFeatureid: Scalars['String'];
};


export type MutationDeleteLicenseTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSequencesTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationMoveNumCasesBetweenUserLicensesArgs = {
  input: MoveNumCasesBetweenUserLicensesInput;
};


export type MutationReloadOrgLicenseArgs = {
  input: ReloadOrgLicenseInput;
};


export type MutationReloadUserLicenseArgs = {
  input: ReloadLicenseInput;
};


export type MutationRequestExportArgs = {
  input: ExportInput;
};


export type MutationReturnCasesToOrgLicenseArgs = {
  input: ReloadLicenseInput;
};


export type MutationUnassignLicenseFeatureToOrgArgs = {
  licenseFeatureId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationUnassignUserLicenseToUserArgs = {
  input: UnassignUserLicenseToUserInput;
};


export type MutationUpdateCaseArgs = {
  input: UpdateCaseInput;
};


export type MutationUpdateLicenseFeatureArgs = {
  featureInput: EditLicenseFeatureInput;
};


export type MutationUpdateLicenseTemplateArgs = {
  templateInput: EditLicenseTemplateInput;
};


export type MutationUpdateOrganisationArgs = {
  input: EditOrganisationInput;
};


export type MutationUpdateSequencesTemplateArgs = {
  templateInput: EditSequencesTemplateInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type OrganInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OrganisationLicensePresentation = {
  __typename?: 'OrganisationLicensePresentation';
  id: Scalars['String'];
  licenseFeatures?: Maybe<Array<LicenseFeature>>;
  licenseTemplateReference: LicenseTemplateReference;
  maxNumberOfCases: Scalars['Int'];
  maxNumberOfUsers: Scalars['Int'];
  organ: Scalars['String'];
  remainingCases: Scalars['Int'];
  remainingUsers: Scalars['Int'];
  totalPrice: Scalars['Decimal'];
};

export type OrganisationListPresentation = {
  __typename?: 'OrganisationListPresentation';
  organisationPresentations: Array<OrganisationPresentation>;
  totalResults: Scalars['Int'];
};

export type OrganisationPresentation = {
  __typename?: 'OrganisationPresentation';
  admin?: Maybe<UserPresentation>;
  cifAndOthers: Scalars['String'];
  distributor?: Maybe<DistributorPresentation>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  organisationLicense: OrganisationLicensePresentation;
  sequencesTemplate?: Maybe<SequencesTemplatePresentation>;
};

export type PushNotification = {
  __typename?: 'PushNotification';
  doctorReference: Scalars['String'];
  notificationType: Scalars['String'];
  numberIfRelevant: Scalars['Long'];
  relevantId: Scalars['String'];
  textIfRelevant: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  case: CasePresentation;
  cases: CaseListPresentation;
  images: Array<ModelImagesPresentation>;
  isUserDeleted: Scalars['Boolean'];
  licenseFeature: LicenseFeature;
  licenseFeatures: LicenseFeatureListPresentation;
  licenseTemplate: LicensePresentation;
  licenses: LicenseListPresentation;
  licensesForASequencesTemplate: Array<LicensePresentation>;
  me: UserPresentation;
  organisation: OrganisationPresentation;
  organisations: OrganisationListPresentation;
  organisationsForAFeature: Array<OrganisationPresentation>;
  organisationsForALicense: Array<OrganisationPresentation>;
  profile: Scalars['String'];
  remainingItems: RemainingItems;
  sequencesByCase: Array<SequencePresentation>;
  sequencesTemplate: SequencesTemplatePresentation;
  sequencesTemplates: SequencesListPresentation;
  stats: StatsPresentation;
  tasks: SequencesTasksPresentation;
  unAssignedLicenseFeatures: Array<LicenseFeature>;
  user: UserPresentation;
  userLicense: UserLicensePresentation;
  userLicenses: UserLicenseListPresentation;
  userLicensesWithoutUser: UserLicenseListPresentation;
  users: UserListPresentation;
};


export type QueryCaseArgs = {
  id: Scalars['String'];
};


export type QueryCasesArgs = {
  input: GetCaseInput;
};


export type QueryImagesArgs = {
  caseId: Scalars['String'];
};


export type QueryIsUserDeletedArgs = {
  email: Scalars['String'];
};


export type QueryLicenseFeatureArgs = {
  licenseFeatureId: Scalars['String'];
};


export type QueryLicenseFeaturesArgs = {
  input: GetLicenseFeatureInput;
};


export type QueryLicenseTemplateArgs = {
  licenseId: Scalars['String'];
};


export type QueryLicensesArgs = {
  input: GetLicenseTemplateInput;
};


export type QueryLicensesForASequencesTemplateArgs = {
  seqTemplateId: Scalars['String'];
};


export type QueryOrganisationArgs = {
  orgId?: InputMaybe<Scalars['String']>;
};


export type QueryOrganisationsArgs = {
  input: GetOrganisationInput;
};


export type QueryOrganisationsForAFeatureArgs = {
  licenseFeatureId: Scalars['String'];
};


export type QueryOrganisationsForALicenseArgs = {
  licenseId: Scalars['String'];
};


export type QuerySequencesByCaseArgs = {
  caseId: Scalars['String'];
};


export type QuerySequencesTemplateArgs = {
  sequencesTemplateId: Scalars['String'];
};


export type QuerySequencesTemplatesArgs = {
  input: GetSequencesTemplateInput;
};


export type QueryTasksArgs = {
  sequenceType: Scalars['String'];
};


export type QueryUnAssignedLicenseFeaturesArgs = {
  orgId: Scalars['String'];
};


export type QueryUserArgs = {
  userId: Scalars['String'];
};


export type QueryUserLicenseArgs = {
  userLicenseId: Scalars['String'];
};


export type QueryUserLicensesArgs = {
  input: GetUserLicensesInput;
};


export type QueryUserLicensesWithoutUserArgs = {
  input: GetUserLicensesInput;
};


export type QueryUsersArgs = {
  input: GetUserInput;
};

export type ReloadLicenseInput = {
  numberOfCases: Scalars['Int'];
  userLicenseId: Scalars['String'];
};

export type ReloadOrgLicenseInput = {
  numberOfCases?: Scalars['Int'];
  numberOfUsers?: Scalars['Int'];
  orgLicenseId: Scalars['String'];
  priceIncrement: Scalars['Decimal'];
};

export type RemainingItems = {
  __typename?: 'RemainingItems';
  remainingCases: Scalars['Long'];
  remainingUsers: Scalars['Long'];
};

export type SequencePresentation = {
  __typename?: 'SequencePresentation';
  biomarkers: Array<KeyValuePairOfStringAndIEnumerableOfString>;
  singleImages: Array<Scalars['String']>;
  type: Scalars['String'];
  views: Array<KeyValuePairOfStringAndInt32>;
};

export type SequenceTaskPresentation = {
  __typename?: 'SequenceTaskPresentation';
  description: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type SequencesListPresentation = {
  __typename?: 'SequencesListPresentation';
  sequencesTemplatePresentations: Array<SequencesTemplatePresentation>;
  totalResults: Scalars['Int'];
};

export type SequencesTasksPresentation = {
  __typename?: 'SequencesTasksPresentation';
  tasks: Array<KeyValuePairOfStringAndListOfSequenceTaskPresentation>;
};

export type SequencesTemplatePresentation = {
  __typename?: 'SequencesTemplatePresentation';
  id: Scalars['String'];
  name: Scalars['String'];
  stepSequenceTemplates: Array<StepSequenceTemplateDto>;
};

export type SequencesTemplateReducedPresentation = {
  __typename?: 'SequencesTemplateReducedPresentation';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SortInputOfCaseSortableFieldsInput = {
  fieldName: CaseSortableFields;
  isAsc: Scalars['Boolean'];
};

export type SortInputOfUserLicenseSortableFieldsInput = {
  fieldName: UserLicenseSortableFields;
  isAsc: Scalars['Boolean'];
};

export type SortInputOfUserSortableFieldsInput = {
  fieldName: UserSortableFields;
  isAsc: Scalars['Boolean'];
};

export type StatsPresentation = {
  __typename?: 'StatsPresentation';
  maxNumberOfCases: Scalars['Long'];
  maxNumberOfUsers: Scalars['Long'];
  negativeFalse: Scalars['Long'];
  numberOfRemainingCases: Scalars['Long'];
  numberOfRemainingUsers: Scalars['Long'];
  positiveFalse: Scalars['Long'];
  totalCreatedCases: Scalars['Long'];
  totalCreatedUsers: Scalars['Long'];
  totalNegative: Scalars['Int'];
  totalPositive: Scalars['Int'];
};

export type StepSequenceTemplateDto = {
  __typename?: 'StepSequenceTemplateDto';
  sequenceType: Scalars['String'];
  stepTemplates: Array<StepTemplateDto>;
};

export type StepSequenceTemplateDtoInput = {
  sequenceType: Scalars['String'];
  stepTemplates: Array<StepTemplateDtoInput>;
};

export type StepTemplateDto = {
  __typename?: 'StepTemplateDto';
  id?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unversionedEcsTaskName: Scalars['String'];
  version: Scalars['Long'];
};

export type StepTemplateDtoInput = {
  id?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  unversionedEcsTaskName: Scalars['String'];
  version: Scalars['Long'];
};

export type Subscription = {
  __typename?: 'Subscription';
  case: CaseChangedPushNotification;
  global: PushNotification;
};


export type SubscriptionCaseArgs = {
  id: Scalars['String'];
};


export type SubscriptionGlobalArgs = {
  userId: Scalars['String'];
};

export type UnassignUserLicenseToUserInput = {
  userLicenseId: Scalars['String'];
};

export type UpdateCaseInput = {
  finalNotes: Scalars['String'];
  id: Scalars['String'];
  isPositive: Scalars['Boolean'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isOrgAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  organ?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type UserLicenseInput = {
  numberOfCases: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
};

export type UserLicenseListPresentation = {
  __typename?: 'UserLicenseListPresentation';
  totalResults: Scalars['Int'];
  userLicensePresentations: Array<UserLicensePresentation>;
};

export type UserLicenseMovedCases = {
  __typename?: 'UserLicenseMovedCases';
  userLicenseDestiny: UserLicensePresentation;
  userLicenseOrigin: UserLicensePresentation;
};

export type UserLicensePresentation = {
  __typename?: 'UserLicensePresentation';
  active: Scalars['Boolean'];
  creationOrder: Scalars['Int'];
  id: Scalars['String'];
  maxNumOfCases: Scalars['Long'];
  numberOfRemainingCases: Scalars['Long'];
  organ: Scalars['String'];
  totalCasesHistory: Scalars['Long'];
  user?: Maybe<UserPresentation>;
};

export enum UserLicenseSortableFields {
  CreationOrder = 'CREATION_ORDER',
  MaxNumberOfCases = 'MAX_NUMBER_OF_CASES',
  TotalCasesHistory = 'TOTAL_CASES_HISTORY',
  User = 'USER'
}

export type UserListPresentation = {
  __typename?: 'UserListPresentation';
  totalResults: Scalars['Int'];
  userPresentations: Array<UserPresentation>;
};

export type UserPresentation = {
  __typename?: 'UserPresentation';
  creationOrder?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOrgAdmin?: Maybe<Scalars['Boolean']>;
  maxNumberOfCases?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  numberOfRemainingCases?: Maybe<Scalars['Long']>;
  orgId?: Maybe<Scalars['String']>;
  remainingCases?: Maybe<Scalars['Long']>;
  surname: Scalars['String'];
  totalCases?: Maybe<Scalars['Long']>;
  userLicenseId?: Maybe<Scalars['String']>;
};

export enum UserSortableFields {
  Name = 'NAME',
  Surname = 'SURNAME'
}

export type CaseQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CaseQuery = { __typename?: 'Query', case: { __typename?: 'CasePresentation', id: string, reference: string, creationDate: any, organ: string, isPositivePrediction: boolean, state: string, isPositive?: boolean | null, initialNotes: string, finalNotes?: string | null, percentage?: number | null, zipState?: string | null, zipLink?: string | null } };

export type AddCaseMutationVariables = Exact<{
  initialNotes: Scalars['String'];
  isPositivePrediction: Scalars['Boolean'];
  reference: Scalars['String'];
}>;


export type AddCaseMutation = { __typename?: 'Mutation', addCase: { __typename?: 'CasePresentation', id: string } };

export type UpdateCaseMutationVariables = Exact<{
  id: Scalars['String'];
  finalNotes: Scalars['String'];
  isPositive: Scalars['Boolean'];
}>;


export type UpdateCaseMutation = { __typename?: 'Mutation', updateCase: { __typename?: 'CasePresentation', id: string, finalNotes?: string | null, isPositive?: boolean | null, state: string } };

export type CaseChangedSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type CaseChangedSubscription = { __typename?: 'Subscription', case: { __typename?: 'CaseChangedPushNotification', relevantId: string, newState: string, percentage: number, zipState: string, zipLink: string } };

export type AllCasesQueryVariables = Exact<{
  input: GetCaseInput;
}>;


export type AllCasesQuery = { __typename?: 'Query', cases: { __typename?: 'CaseListPresentation', totalResults: number, casePresentations: Array<{ __typename?: 'CasePresentation', id: string, reference: string, creationDate: any, organ: string, isPositivePrediction: boolean, state: string, isPositive?: boolean | null, initialNotes: string, user: { __typename?: 'UserPresentation', id: string, name: string, surname: string } }> } };

export type RequestExportMutationVariables = Exact<{
  id: Scalars['String'];
  exportType: Scalars['String'];
}>;


export type RequestExportMutation = { __typename?: 'Mutation', requestExport: { __typename?: 'CasePresentation', zipState?: string | null, zipLink?: string | null } };

export type DeleteCaseMutationVariables = Exact<{
  caseId: Scalars['String'];
}>;


export type DeleteCaseMutation = { __typename?: 'Mutation', deleteCase: { __typename?: 'CasePresentation', id: string, initialNotes: string, state: string, creationDate: any, user: { __typename?: 'UserPresentation', id: string, name: string, surname: string } } };

export type LicenseFeatureQueryVariables = Exact<{
  licenseFeatureId: Scalars['String'];
}>;


export type LicenseFeatureQuery = { __typename?: 'Query', licenseFeature: { __typename?: 'LicenseFeature', id: string, name: string, description: string, environKey: string, environValue: string, price: any }, organisationsForAFeature: Array<{ __typename?: 'OrganisationPresentation', id: string, name: string, email: string }> };

export type LicenseFeaturesQueryVariables = Exact<{
  input: GetLicenseFeatureInput;
}>;


export type LicenseFeaturesQuery = { __typename?: 'Query', licenseFeatures: { __typename?: 'LicenseFeatureListPresentation', totalResults: number, licenseFeatures: Array<{ __typename?: 'LicenseFeature', id: string, name: string, description: string, price: any, environKey: string, environValue: string }> } };

export type UnassignedLicenseFeaturesQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type UnassignedLicenseFeaturesQuery = { __typename?: 'Query', unAssignedLicenseFeatures: Array<{ __typename?: 'LicenseFeature', id: string, name: string, description: string, price: any }> };

export type AddLicenseFeatureMutationVariables = Exact<{
  featureInput: AddLicenseFeatureInput;
}>;


export type AddLicenseFeatureMutation = { __typename?: 'Mutation', addLicenseFeature: { __typename?: 'LicenseFeature', id: string, name: string, description: string, environKey: string, environValue: string, price: any } };

export type UpdateLicenseFeatureMutationVariables = Exact<{
  featureInput: EditLicenseFeatureInput;
}>;


export type UpdateLicenseFeatureMutation = { __typename?: 'Mutation', updateLicenseFeature: { __typename?: 'LicenseFeature', id: string, name: string, description: string, environKey: string, environValue: string, price: any } };

export type DeleteLicenseFeatureMutationVariables = Exact<{
  licenseFeatureid: Scalars['String'];
}>;


export type DeleteLicenseFeatureMutation = { __typename?: 'Mutation', deleteLicenseFeature: { __typename?: 'LicenseFeature', id: string, name: string, description: string, environKey: string, environValue: string, price: any } };

export type UnassignLicenseFeatureToOrgMutationVariables = Exact<{
  licenseFeatureId: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type UnassignLicenseFeatureToOrgMutation = { __typename?: 'Mutation', unassignLicenseFeatureToOrg: { __typename?: 'OrganisationPresentation', id: string, name: string, cifAndOthers: string, admin?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null } | null } };

export type AssignLicenseFeatureToOrgMutationVariables = Exact<{
  licenseFeatureIds: Array<Scalars['String']> | Scalars['String'];
  orgId: Scalars['String'];
}>;


export type AssignLicenseFeatureToOrgMutation = { __typename?: 'Mutation', assignLicenseFeatureToOrg: { __typename?: 'OrganisationPresentation', id: string, name: string, cifAndOthers: string, admin?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null } | null } };

export type LicenseQueryVariables = Exact<{
  licenseId: Scalars['String'];
}>;


export type LicenseQuery = { __typename?: 'Query', licenseTemplate: { __typename?: 'LicensePresentation', id: string, name: string, totalPrice: any, organ: string, maxNumberOfCases: any, maxNumberOfUsers: any, numberOfRemainingCases: any, numberOfRemainingUsers: any, sequencesTemplate?: { __typename?: 'SequencesTemplateReducedPresentation', id: string, name: string } | null, memoryLimits: { __typename?: 'MemoryLimitsPresentation', currentEcsMemorySize: number, maxEcsMemorySize: number }, distributor?: { __typename?: 'DistributorPresentation', name: string, unversionedDistributorName: string, version: number } | null }, organisationsForALicense: Array<{ __typename?: 'OrganisationPresentation', id: string, name: string, email: string }> };

export type GetLicensesQueryVariables = Exact<{
  input: GetLicenseTemplateInput;
}>;


export type GetLicensesQuery = { __typename?: 'Query', licenses: { __typename?: 'LicenseListPresentation', totalResults: number, licensePresentations: Array<{ __typename?: 'LicensePresentation', id: string, name: string, totalPrice: any, organ: string, numberOfRemainingCases: any, numberOfRemainingUsers: any, maxNumberOfCases: any, maxNumberOfUsers: any, sequencesTemplate?: { __typename?: 'SequencesTemplateReducedPresentation', id: string, name: string } | null, distributor?: { __typename?: 'DistributorPresentation', name: string, version: number, unversionedDistributorName: string } | null, memoryLimits: { __typename?: 'MemoryLimitsPresentation', currentEcsMemorySize: number, maxEcsMemorySize: number } }> } };

export type AddLicenseTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  totalPrice: Scalars['Decimal'];
  organ: Scalars['String'];
  maxNumberOfCases: Scalars['Int'];
  maxNumberOfUsers: Scalars['Int'];
  sequencesTemplateId: Scalars['String'];
  distributor: DistributorTypeInput;
  memoryLimits: MemoryLimitsInput;
}>;


export type AddLicenseTemplateMutation = { __typename?: 'Mutation', addLicenseTemplate: { __typename?: 'LicensePresentation', id: string, sequencesTemplate?: { __typename?: 'SequencesTemplateReducedPresentation', id: string, name: string } | null } };

export type UpdateLicenseMutationVariables = Exact<{
  templateInput: EditLicenseTemplateInput;
}>;


export type UpdateLicenseMutation = { __typename?: 'Mutation', updateLicenseTemplate: { __typename?: 'LicensePresentation', id: string, name: string, organ: string, totalPrice: any, maxNumberOfCases: any, maxNumberOfUsers: any, sequencesTemplate?: { __typename?: 'SequencesTemplateReducedPresentation', id: string, name: string } | null } };

export type DeleteLicenseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteLicenseMutation = { __typename?: 'Mutation', deleteLicenseTemplate: { __typename?: 'LicensePresentation', id: string, name: string } };

export type GlobalSubscriptionVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GlobalSubscription = { __typename?: 'Subscription', global: { __typename?: 'PushNotification', notificationType: string, relevantId: string, numberIfRelevant: any, textIfRelevant: string, doctorReference: string } };

export type RemainingItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type RemainingItemsQuery = { __typename?: 'Query', remainingItems: { __typename?: 'RemainingItems', remainingCases: any, remainingUsers: any } };

export type AllOrganisationsQueryVariables = Exact<{
  input: GetOrganisationInput;
}>;


export type AllOrganisationsQuery = { __typename?: 'Query', organisations: { __typename?: 'OrganisationListPresentation', totalResults: number, organisationPresentations: Array<{ __typename?: 'OrganisationPresentation', id: string, name: string, cifAndOthers: string, email: string, admin?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null, organisationLicense: { __typename?: 'OrganisationLicensePresentation', id: string, totalPrice: any, maxNumberOfCases: number, maxNumberOfUsers: number, remainingCases: number, remainingUsers: number, organ: string, licenseTemplateReference: { __typename?: 'LicenseTemplateReference', id: string, name: string } } }> } };

export type OrganisationQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']>;
}>;


export type OrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'OrganisationPresentation', id: string, name: string, cifAndOthers: string, admin?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null } | null, organisationLicense: { __typename?: 'OrganisationLicensePresentation', id: string, totalPrice: any, maxNumberOfCases: number, maxNumberOfUsers: number, remainingCases: number, remainingUsers: number, organ: string, licenseTemplateReference: { __typename?: 'LicenseTemplateReference', id: string, name: string }, licenseFeatures?: Array<{ __typename?: 'LicenseFeature', id: string, name: string, description: string, environKey: string, environValue: string, price: any }> | null }, distributor?: { __typename?: 'DistributorPresentation', unversionedDistributorName: string, version: number, name: string } | null, sequencesTemplate?: { __typename?: 'SequencesTemplatePresentation', id: string, name: string } | null } };

export type OrganisationForUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationForUserProfileQuery = { __typename?: 'Query', organisation: { __typename?: 'OrganisationPresentation', name: string, admin?: { __typename?: 'UserPresentation', name: string, surname: string } | null } };

export type AddOrganisationMutationVariables = Exact<{
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  cifAndOthers: Scalars['String'];
  licenseId: Scalars['String'];
}>;


export type AddOrganisationMutation = { __typename?: 'Mutation', addOrganisation: { __typename?: 'OrganisationPresentation', id: string, name: string, email: string, cifAndOthers: string } };

export type StatsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsQuery = { __typename?: 'Query', stats: { __typename?: 'StatsPresentation', numberOfRemainingCases: any, numberOfRemainingUsers: any, maxNumberOfCases: any, maxNumberOfUsers: any, positiveFalse: any, negativeFalse: any, totalPositive: number, totalNegative: number, totalCreatedCases: any, totalCreatedUsers: any } };

export type UpdateOrganisationMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  cifAndOthers: Scalars['String'];
}>;


export type UpdateOrganisationMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'OrganisationPresentation', id: string } };

export type ReloadOrgLicenseMutationVariables = Exact<{
  orgLicenseId: Scalars['String'];
  priceIncrement: Scalars['Decimal'];
  numberOfCases: Scalars['Int'];
  numberOfUsers: Scalars['Int'];
}>;


export type ReloadOrgLicenseMutation = { __typename?: 'Mutation', reloadOrgLicense: { __typename?: 'OrganisationPresentation', id: string, name: string, cifAndOthers: string, admin?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null } | null, organisationLicense: { __typename?: 'OrganisationLicensePresentation', id: string, totalPrice: any, maxNumberOfCases: number, maxNumberOfUsers: number, remainingCases: number, remainingUsers: number, organ: string, licenseTemplateReference: { __typename?: 'LicenseTemplateReference', id: string, name: string } } } };

export type SequencesByCaseQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;


export type SequencesByCaseQuery = { __typename?: 'Query', sequencesByCase: Array<{ __typename?: 'SequencePresentation', type: string, singleImages: Array<string>, biomarkers: Array<{ __typename?: 'KeyValuePairOfStringAndIEnumerableOfString', key: string, value: Array<string> }>, views: Array<{ __typename?: 'KeyValuePairOfStringAndInt32', key: string, value: number }> }> };

export type ImagesQueryVariables = Exact<{
  caseId: Scalars['String'];
}>;


export type ImagesQuery = { __typename?: 'Query', images: Array<{ __typename?: 'ModelImagesPresentation', model: string, biomarkers: Array<{ __typename?: 'KeyValuePairOfStringAndDictionaryOfStringAndListOfString', key: string, value: Array<{ __typename?: 'KeyValuePairOfStringAndListOfString', key: string, value: Array<string> }> }>, singleImages: Array<{ __typename?: 'KeyValuePairOfStringAndListOfString', key: string, value: Array<string> }> }> };

export type AddSequencesTemplateMutationVariables = Exact<{
  input: CreateSequencesTemplateInput;
}>;


export type AddSequencesTemplateMutation = { __typename?: 'Mutation', addSequencesTemplate: { __typename?: 'SequencesTemplatePresentation', id: string, name: string, stepSequenceTemplates: Array<{ __typename?: 'StepSequenceTemplateDto', sequenceType: string, stepTemplates: Array<{ __typename?: 'StepTemplateDto', id?: string | null, type: string, unversionedEcsTaskName: string, version: any }> }> } };

export type AllSequencesQueryVariables = Exact<{
  input: GetSequencesTemplateInput;
}>;


export type AllSequencesQuery = { __typename?: 'Query', sequencesTemplates: { __typename?: 'SequencesListPresentation', totalResults: number, sequencesTemplatePresentations: Array<{ __typename?: 'SequencesTemplatePresentation', id: string, name: string, stepSequenceTemplates: Array<{ __typename?: 'StepSequenceTemplateDto', sequenceType: string, stepTemplates: Array<{ __typename?: 'StepTemplateDto', type: string, unversionedEcsTaskName: string, version: any }> }> }> } };

export type SequencesTemplateQueryVariables = Exact<{
  sequencesTemplateId: Scalars['String'];
}>;


export type SequencesTemplateQuery = { __typename?: 'Query', sequencesTemplate: { __typename?: 'SequencesTemplatePresentation', id: string, name: string, stepSequenceTemplates: Array<{ __typename?: 'StepSequenceTemplateDto', sequenceType: string, stepTemplates: Array<{ __typename?: 'StepTemplateDto', id?: string | null, type: string, unversionedEcsTaskName: string, version: any }> }> }, licensesForASequencesTemplate: Array<{ __typename?: 'LicensePresentation', id: string, name: string, organ: string, totalPrice: any, maxNumberOfCases: any, maxNumberOfUsers: any, sequencesTemplate?: { __typename?: 'SequencesTemplateReducedPresentation', id: string, name: string } | null }> };

export type UpdateSequencesTemplateMutationVariables = Exact<{
  templateInput: EditSequencesTemplateInput;
}>;


export type UpdateSequencesTemplateMutation = { __typename?: 'Mutation', updateSequencesTemplate: { __typename?: 'SequencesTemplatePresentation', id: string, name: string, stepSequenceTemplates: Array<{ __typename?: 'StepSequenceTemplateDto', sequenceType: string, stepTemplates: Array<{ __typename?: 'StepTemplateDto', id?: string | null, type: string, unversionedEcsTaskName: string, version: any }> }> } };

export type DeleteSequencesTemplateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSequencesTemplateMutation = { __typename?: 'Mutation', deleteSequencesTemplate: { __typename?: 'SequencesTemplatePresentation', id: string, name: string } };

export type TasksQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type TasksQuery = { __typename?: 'Query', tasks: { __typename?: 'SequencesTasksPresentation', tasks: Array<{ __typename?: 'KeyValuePairOfStringAndListOfSequenceTaskPresentation', key: string, value: Array<{ __typename?: 'SequenceTaskPresentation', name: string, version: string, description: string }> }> } };

export type CreateSupportMessageMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
}>;


export type CreateSupportMessageMutation = { __typename?: 'Mutation', createSupportMessage: string };

export type CreateUserLicenseMutationVariables = Exact<{
  input: UserLicenseInput;
}>;


export type CreateUserLicenseMutation = { __typename?: 'Mutation', createUserLicense: { __typename?: 'UserLicensePresentation', id: string, organ: string, creationOrder: number, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean } };

export type ReloadUserLicenseMutationVariables = Exact<{
  input: ReloadLicenseInput;
}>;


export type ReloadUserLicenseMutation = { __typename?: 'Mutation', reloadUserLicense: { __typename?: 'UserLicensePresentation', id: string, organ: string, maxNumOfCases: any, creationOrder: number, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean, user?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null } };

export type AssignUserLicenseToUserMutationVariables = Exact<{
  input: AssignUserLicenseToUserInput;
}>;


export type AssignUserLicenseToUserMutation = { __typename?: 'Mutation', assignUserLicenseToUser: { __typename?: 'UserLicensePresentation', id: string, organ: string, maxNumOfCases: any, creationOrder: number, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean, user?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null } };

export type UnassignUserLicenseToUserMutationVariables = Exact<{
  input: UnassignUserLicenseToUserInput;
}>;


export type UnassignUserLicenseToUserMutation = { __typename?: 'Mutation', unassignUserLicenseToUser: { __typename?: 'UserLicensePresentation', id: string, organ: string, maxNumOfCases: any, creationOrder: number, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean, user?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null } };

export type MoveNumCasesBetweenUserLicensesMutationVariables = Exact<{
  input: MoveNumCasesBetweenUserLicensesInput;
}>;


export type MoveNumCasesBetweenUserLicensesMutation = { __typename?: 'Mutation', moveNumCasesBetweenUserLicenses: { __typename?: 'UserLicenseMovedCases', userLicenseOrigin: { __typename?: 'UserLicensePresentation', id: string, organ: string, creationOrder: number, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean }, userLicenseDestiny: { __typename?: 'UserLicensePresentation', id: string, organ: string, creationOrder: number, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean } } };

export type UserLicensesWithoutUserQueryVariables = Exact<{
  input: GetUserLicensesInput;
}>;


export type UserLicensesWithoutUserQuery = { __typename?: 'Query', userLicensesWithoutUser: { __typename?: 'UserLicenseListPresentation', totalResults: number, userLicensePresentations: Array<{ __typename?: 'UserLicensePresentation', id: string, creationOrder: number, organ: string, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean, user?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null }> } };

export type UserLicensesQueryVariables = Exact<{
  input: GetUserLicensesInput;
}>;


export type UserLicensesQuery = { __typename?: 'Query', userLicenses: { __typename?: 'UserLicenseListPresentation', totalResults: number, userLicensePresentations: Array<{ __typename?: 'UserLicensePresentation', id: string, creationOrder: number, organ: string, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean, user?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null }> } };

export type UserLicenseQueryVariables = Exact<{
  userLicenseId: Scalars['String'];
}>;


export type UserLicenseQuery = { __typename?: 'Query', userLicense: { __typename?: 'UserLicensePresentation', id: string, creationOrder: number, organ: string, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean, user?: { __typename?: 'UserPresentation', id: string, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, orgId?: string | null } | null } };

export type ReturnCasesToOrgLicenseMutationVariables = Exact<{
  input: ReloadLicenseInput;
}>;


export type ReturnCasesToOrgLicenseMutation = { __typename?: 'Mutation', returnCasesToOrgLicense: { __typename?: 'UserLicensePresentation', id: string, organ: string, creationOrder: number, maxNumOfCases: any, totalCasesHistory: any, numberOfRemainingCases: any, active: boolean } };

export type AddUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'UserPresentation', id: string, orgId?: string | null, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null } };

export type ResetPasswordMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'UserPresentation', id: string, orgId?: string | null, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null } };

export type AllUsersQueryVariables = Exact<{
  input: GetUserInput;
}>;


export type AllUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserListPresentation', totalResults: number, userPresentations: Array<{ __typename?: 'UserPresentation', id: string, name: string, surname: string, orgId?: string | null, email?: string | null, isOrgAdmin?: boolean | null, totalCases?: any | null, userLicenseId?: string | null, creationOrder?: number | null, maxNumberOfCases?: any | null, numberOfRemainingCases?: any | null }> } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'UserPresentation', id: string, orgId?: string | null, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null, numberOfRemainingCases?: any | null } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  isOrgAdmin?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserPresentation', id: string } };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'UserPresentation', id: string, orgId?: string | null, name: string, surname: string, email?: string | null, isOrgAdmin?: boolean | null } };

export type UserQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'UserPresentation', id: string, name: string, surname: string, orgId?: string | null, email?: string | null, isOrgAdmin?: boolean | null, totalCases?: any | null, remainingCases?: any | null } };

export type IsUserDeletedQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsUserDeletedQuery = { __typename?: 'Query', isUserDeleted: boolean };


export const CaseDocument = gql`
    query case($id: String!) {
  case(id: $id) {
    id
    reference
    creationDate
    organ
    isPositivePrediction
    state
    isPositive
    initialNotes
    finalNotes
    percentage
    zipState
    zipLink
  }
}
    `;

/**
 * __useCaseQuery__
 *
 * To run a query within a React component, call `useCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseQuery(baseOptions: Apollo.QueryHookOptions<CaseQuery, CaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseQuery, CaseQueryVariables>(CaseDocument, options);
      }
export function useCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseQuery, CaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseQuery, CaseQueryVariables>(CaseDocument, options);
        }
export type CaseQueryHookResult = ReturnType<typeof useCaseQuery>;
export type CaseLazyQueryHookResult = ReturnType<typeof useCaseLazyQuery>;
export type CaseQueryResult = Apollo.QueryResult<CaseQuery, CaseQueryVariables>;
export const AddCaseDocument = gql`
    mutation addCase($initialNotes: String!, $isPositivePrediction: Boolean!, $reference: String!) {
  addCase(
    input: {initialNotes: $initialNotes, isPositivePrediction: $isPositivePrediction, reference: $reference}
  ) {
    id
  }
}
    `;
export type AddCaseMutationFn = Apollo.MutationFunction<AddCaseMutation, AddCaseMutationVariables>;

/**
 * __useAddCaseMutation__
 *
 * To run a mutation, you first call `useAddCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCaseMutation, { data, loading, error }] = useAddCaseMutation({
 *   variables: {
 *      initialNotes: // value for 'initialNotes'
 *      isPositivePrediction: // value for 'isPositivePrediction'
 *      reference: // value for 'reference'
 *   },
 * });
 */
export function useAddCaseMutation(baseOptions?: Apollo.MutationHookOptions<AddCaseMutation, AddCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCaseMutation, AddCaseMutationVariables>(AddCaseDocument, options);
      }
export type AddCaseMutationHookResult = ReturnType<typeof useAddCaseMutation>;
export type AddCaseMutationResult = Apollo.MutationResult<AddCaseMutation>;
export type AddCaseMutationOptions = Apollo.BaseMutationOptions<AddCaseMutation, AddCaseMutationVariables>;
export const UpdateCaseDocument = gql`
    mutation updateCase($id: String!, $finalNotes: String!, $isPositive: Boolean!) {
  updateCase(input: {id: $id, finalNotes: $finalNotes, isPositive: $isPositive}) {
    id
    finalNotes
    isPositive
    state
  }
}
    `;
export type UpdateCaseMutationFn = Apollo.MutationFunction<UpdateCaseMutation, UpdateCaseMutationVariables>;

/**
 * __useUpdateCaseMutation__
 *
 * To run a mutation, you first call `useUpdateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseMutation, { data, loading, error }] = useUpdateCaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      finalNotes: // value for 'finalNotes'
 *      isPositive: // value for 'isPositive'
 *   },
 * });
 */
export function useUpdateCaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaseMutation, UpdateCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaseMutation, UpdateCaseMutationVariables>(UpdateCaseDocument, options);
      }
export type UpdateCaseMutationHookResult = ReturnType<typeof useUpdateCaseMutation>;
export type UpdateCaseMutationResult = Apollo.MutationResult<UpdateCaseMutation>;
export type UpdateCaseMutationOptions = Apollo.BaseMutationOptions<UpdateCaseMutation, UpdateCaseMutationVariables>;
export const CaseChangedDocument = gql`
    subscription caseChanged($id: String!) {
  case(id: $id) {
    relevantId
    newState
    percentage
    zipState
    zipLink
  }
}
    `;

/**
 * __useCaseChangedSubscription__
 *
 * To run a query within a React component, call `useCaseChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCaseChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<CaseChangedSubscription, CaseChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CaseChangedSubscription, CaseChangedSubscriptionVariables>(CaseChangedDocument, options);
      }
export type CaseChangedSubscriptionHookResult = ReturnType<typeof useCaseChangedSubscription>;
export type CaseChangedSubscriptionResult = Apollo.SubscriptionResult<CaseChangedSubscription>;
export const AllCasesDocument = gql`
    query allCases($input: GetCaseInput!) {
  cases(input: $input) {
    casePresentations {
      id
      reference
      creationDate
      organ
      isPositivePrediction
      state
      isPositive
      initialNotes
      user {
        id
        name
        surname
      }
    }
    totalResults
  }
}
    `;

/**
 * __useAllCasesQuery__
 *
 * To run a query within a React component, call `useAllCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCasesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllCasesQuery(baseOptions: Apollo.QueryHookOptions<AllCasesQuery, AllCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCasesQuery, AllCasesQueryVariables>(AllCasesDocument, options);
      }
export function useAllCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCasesQuery, AllCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCasesQuery, AllCasesQueryVariables>(AllCasesDocument, options);
        }
export type AllCasesQueryHookResult = ReturnType<typeof useAllCasesQuery>;
export type AllCasesLazyQueryHookResult = ReturnType<typeof useAllCasesLazyQuery>;
export type AllCasesQueryResult = Apollo.QueryResult<AllCasesQuery, AllCasesQueryVariables>;
export const RequestExportDocument = gql`
    mutation requestExport($id: String!, $exportType: String!) {
  requestExport(input: {id: $id, exportType: $exportType}) {
    zipState
    zipLink
  }
}
    `;
export type RequestExportMutationFn = Apollo.MutationFunction<RequestExportMutation, RequestExportMutationVariables>;

/**
 * __useRequestExportMutation__
 *
 * To run a mutation, you first call `useRequestExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportMutation, { data, loading, error }] = useRequestExportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exportType: // value for 'exportType'
 *   },
 * });
 */
export function useRequestExportMutation(baseOptions?: Apollo.MutationHookOptions<RequestExportMutation, RequestExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestExportMutation, RequestExportMutationVariables>(RequestExportDocument, options);
      }
export type RequestExportMutationHookResult = ReturnType<typeof useRequestExportMutation>;
export type RequestExportMutationResult = Apollo.MutationResult<RequestExportMutation>;
export type RequestExportMutationOptions = Apollo.BaseMutationOptions<RequestExportMutation, RequestExportMutationVariables>;
export const DeleteCaseDocument = gql`
    mutation deleteCase($caseId: String!) {
  deleteCase(caseId: $caseId) {
    id
    user {
      id
      name
      surname
    }
    initialNotes
    state
    creationDate
  }
}
    `;
export type DeleteCaseMutationFn = Apollo.MutationFunction<DeleteCaseMutation, DeleteCaseMutationVariables>;

/**
 * __useDeleteCaseMutation__
 *
 * To run a mutation, you first call `useDeleteCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaseMutation, { data, loading, error }] = useDeleteCaseMutation({
 *   variables: {
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useDeleteCaseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCaseMutation, DeleteCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCaseMutation, DeleteCaseMutationVariables>(DeleteCaseDocument, options);
      }
export type DeleteCaseMutationHookResult = ReturnType<typeof useDeleteCaseMutation>;
export type DeleteCaseMutationResult = Apollo.MutationResult<DeleteCaseMutation>;
export type DeleteCaseMutationOptions = Apollo.BaseMutationOptions<DeleteCaseMutation, DeleteCaseMutationVariables>;
export const LicenseFeatureDocument = gql`
    query licenseFeature($licenseFeatureId: String!) {
  licenseFeature(licenseFeatureId: $licenseFeatureId) {
    id
    name
    description
    environKey
    environValue
    price
  }
  organisationsForAFeature(licenseFeatureId: $licenseFeatureId) {
    id
    name
    email
  }
}
    `;

/**
 * __useLicenseFeatureQuery__
 *
 * To run a query within a React component, call `useLicenseFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseFeatureQuery({
 *   variables: {
 *      licenseFeatureId: // value for 'licenseFeatureId'
 *   },
 * });
 */
export function useLicenseFeatureQuery(baseOptions: Apollo.QueryHookOptions<LicenseFeatureQuery, LicenseFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseFeatureQuery, LicenseFeatureQueryVariables>(LicenseFeatureDocument, options);
      }
export function useLicenseFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseFeatureQuery, LicenseFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseFeatureQuery, LicenseFeatureQueryVariables>(LicenseFeatureDocument, options);
        }
export type LicenseFeatureQueryHookResult = ReturnType<typeof useLicenseFeatureQuery>;
export type LicenseFeatureLazyQueryHookResult = ReturnType<typeof useLicenseFeatureLazyQuery>;
export type LicenseFeatureQueryResult = Apollo.QueryResult<LicenseFeatureQuery, LicenseFeatureQueryVariables>;
export const LicenseFeaturesDocument = gql`
    query licenseFeatures($input: GetLicenseFeatureInput!) {
  licenseFeatures(input: $input) {
    licenseFeatures {
      id
      name
      description
      price
      environKey
      environValue
    }
    totalResults
  }
}
    `;

/**
 * __useLicenseFeaturesQuery__
 *
 * To run a query within a React component, call `useLicenseFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseFeaturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLicenseFeaturesQuery(baseOptions: Apollo.QueryHookOptions<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>(LicenseFeaturesDocument, options);
      }
export function useLicenseFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>(LicenseFeaturesDocument, options);
        }
export type LicenseFeaturesQueryHookResult = ReturnType<typeof useLicenseFeaturesQuery>;
export type LicenseFeaturesLazyQueryHookResult = ReturnType<typeof useLicenseFeaturesLazyQuery>;
export type LicenseFeaturesQueryResult = Apollo.QueryResult<LicenseFeaturesQuery, LicenseFeaturesQueryVariables>;
export const UnassignedLicenseFeaturesDocument = gql`
    query unassignedLicenseFeatures($orgId: String!) {
  unAssignedLicenseFeatures(orgId: $orgId) {
    id
    name
    description
    price
  }
}
    `;

/**
 * __useUnassignedLicenseFeaturesQuery__
 *
 * To run a query within a React component, call `useUnassignedLicenseFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedLicenseFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedLicenseFeaturesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useUnassignedLicenseFeaturesQuery(baseOptions: Apollo.QueryHookOptions<UnassignedLicenseFeaturesQuery, UnassignedLicenseFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnassignedLicenseFeaturesQuery, UnassignedLicenseFeaturesQueryVariables>(UnassignedLicenseFeaturesDocument, options);
      }
export function useUnassignedLicenseFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassignedLicenseFeaturesQuery, UnassignedLicenseFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnassignedLicenseFeaturesQuery, UnassignedLicenseFeaturesQueryVariables>(UnassignedLicenseFeaturesDocument, options);
        }
export type UnassignedLicenseFeaturesQueryHookResult = ReturnType<typeof useUnassignedLicenseFeaturesQuery>;
export type UnassignedLicenseFeaturesLazyQueryHookResult = ReturnType<typeof useUnassignedLicenseFeaturesLazyQuery>;
export type UnassignedLicenseFeaturesQueryResult = Apollo.QueryResult<UnassignedLicenseFeaturesQuery, UnassignedLicenseFeaturesQueryVariables>;
export const AddLicenseFeatureDocument = gql`
    mutation addLicenseFeature($featureInput: AddLicenseFeatureInput!) {
  addLicenseFeature(featureInput: $featureInput) {
    id
    name
    description
    environKey
    environValue
    price
  }
}
    `;
export type AddLicenseFeatureMutationFn = Apollo.MutationFunction<AddLicenseFeatureMutation, AddLicenseFeatureMutationVariables>;

/**
 * __useAddLicenseFeatureMutation__
 *
 * To run a mutation, you first call `useAddLicenseFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLicenseFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLicenseFeatureMutation, { data, loading, error }] = useAddLicenseFeatureMutation({
 *   variables: {
 *      featureInput: // value for 'featureInput'
 *   },
 * });
 */
export function useAddLicenseFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddLicenseFeatureMutation, AddLicenseFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLicenseFeatureMutation, AddLicenseFeatureMutationVariables>(AddLicenseFeatureDocument, options);
      }
export type AddLicenseFeatureMutationHookResult = ReturnType<typeof useAddLicenseFeatureMutation>;
export type AddLicenseFeatureMutationResult = Apollo.MutationResult<AddLicenseFeatureMutation>;
export type AddLicenseFeatureMutationOptions = Apollo.BaseMutationOptions<AddLicenseFeatureMutation, AddLicenseFeatureMutationVariables>;
export const UpdateLicenseFeatureDocument = gql`
    mutation updateLicenseFeature($featureInput: EditLicenseFeatureInput!) {
  updateLicenseFeature(featureInput: $featureInput) {
    id
    name
    description
    environKey
    environValue
    price
  }
}
    `;
export type UpdateLicenseFeatureMutationFn = Apollo.MutationFunction<UpdateLicenseFeatureMutation, UpdateLicenseFeatureMutationVariables>;

/**
 * __useUpdateLicenseFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseFeatureMutation, { data, loading, error }] = useUpdateLicenseFeatureMutation({
 *   variables: {
 *      featureInput: // value for 'featureInput'
 *   },
 * });
 */
export function useUpdateLicenseFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicenseFeatureMutation, UpdateLicenseFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicenseFeatureMutation, UpdateLicenseFeatureMutationVariables>(UpdateLicenseFeatureDocument, options);
      }
export type UpdateLicenseFeatureMutationHookResult = ReturnType<typeof useUpdateLicenseFeatureMutation>;
export type UpdateLicenseFeatureMutationResult = Apollo.MutationResult<UpdateLicenseFeatureMutation>;
export type UpdateLicenseFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateLicenseFeatureMutation, UpdateLicenseFeatureMutationVariables>;
export const DeleteLicenseFeatureDocument = gql`
    mutation deleteLicenseFeature($licenseFeatureid: String!) {
  deleteLicenseFeature(licenseFeatureid: $licenseFeatureid) {
    id
    name
    description
    environKey
    environValue
    price
  }
}
    `;
export type DeleteLicenseFeatureMutationFn = Apollo.MutationFunction<DeleteLicenseFeatureMutation, DeleteLicenseFeatureMutationVariables>;

/**
 * __useDeleteLicenseFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseFeatureMutation, { data, loading, error }] = useDeleteLicenseFeatureMutation({
 *   variables: {
 *      licenseFeatureid: // value for 'licenseFeatureid'
 *   },
 * });
 */
export function useDeleteLicenseFeatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseFeatureMutation, DeleteLicenseFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseFeatureMutation, DeleteLicenseFeatureMutationVariables>(DeleteLicenseFeatureDocument, options);
      }
export type DeleteLicenseFeatureMutationHookResult = ReturnType<typeof useDeleteLicenseFeatureMutation>;
export type DeleteLicenseFeatureMutationResult = Apollo.MutationResult<DeleteLicenseFeatureMutation>;
export type DeleteLicenseFeatureMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseFeatureMutation, DeleteLicenseFeatureMutationVariables>;
export const UnassignLicenseFeatureToOrgDocument = gql`
    mutation unassignLicenseFeatureToOrg($licenseFeatureId: String!, $orgId: String!) {
  unassignLicenseFeatureToOrg(orgId: $orgId, licenseFeatureId: $licenseFeatureId) {
    id
    name
    cifAndOthers
    admin {
      id
      name
      surname
      email
    }
  }
}
    `;
export type UnassignLicenseFeatureToOrgMutationFn = Apollo.MutationFunction<UnassignLicenseFeatureToOrgMutation, UnassignLicenseFeatureToOrgMutationVariables>;

/**
 * __useUnassignLicenseFeatureToOrgMutation__
 *
 * To run a mutation, you first call `useUnassignLicenseFeatureToOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignLicenseFeatureToOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignLicenseFeatureToOrgMutation, { data, loading, error }] = useUnassignLicenseFeatureToOrgMutation({
 *   variables: {
 *      licenseFeatureId: // value for 'licenseFeatureId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useUnassignLicenseFeatureToOrgMutation(baseOptions?: Apollo.MutationHookOptions<UnassignLicenseFeatureToOrgMutation, UnassignLicenseFeatureToOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignLicenseFeatureToOrgMutation, UnassignLicenseFeatureToOrgMutationVariables>(UnassignLicenseFeatureToOrgDocument, options);
      }
export type UnassignLicenseFeatureToOrgMutationHookResult = ReturnType<typeof useUnassignLicenseFeatureToOrgMutation>;
export type UnassignLicenseFeatureToOrgMutationResult = Apollo.MutationResult<UnassignLicenseFeatureToOrgMutation>;
export type UnassignLicenseFeatureToOrgMutationOptions = Apollo.BaseMutationOptions<UnassignLicenseFeatureToOrgMutation, UnassignLicenseFeatureToOrgMutationVariables>;
export const AssignLicenseFeatureToOrgDocument = gql`
    mutation assignLicenseFeatureToOrg($licenseFeatureIds: [String!]!, $orgId: String!) {
  assignLicenseFeatureToOrg(orgId: $orgId, licenseFeatureIds: $licenseFeatureIds) {
    id
    name
    cifAndOthers
    admin {
      id
      name
      surname
      email
    }
  }
}
    `;
export type AssignLicenseFeatureToOrgMutationFn = Apollo.MutationFunction<AssignLicenseFeatureToOrgMutation, AssignLicenseFeatureToOrgMutationVariables>;

/**
 * __useAssignLicenseFeatureToOrgMutation__
 *
 * To run a mutation, you first call `useAssignLicenseFeatureToOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignLicenseFeatureToOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignLicenseFeatureToOrgMutation, { data, loading, error }] = useAssignLicenseFeatureToOrgMutation({
 *   variables: {
 *      licenseFeatureIds: // value for 'licenseFeatureIds'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useAssignLicenseFeatureToOrgMutation(baseOptions?: Apollo.MutationHookOptions<AssignLicenseFeatureToOrgMutation, AssignLicenseFeatureToOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignLicenseFeatureToOrgMutation, AssignLicenseFeatureToOrgMutationVariables>(AssignLicenseFeatureToOrgDocument, options);
      }
export type AssignLicenseFeatureToOrgMutationHookResult = ReturnType<typeof useAssignLicenseFeatureToOrgMutation>;
export type AssignLicenseFeatureToOrgMutationResult = Apollo.MutationResult<AssignLicenseFeatureToOrgMutation>;
export type AssignLicenseFeatureToOrgMutationOptions = Apollo.BaseMutationOptions<AssignLicenseFeatureToOrgMutation, AssignLicenseFeatureToOrgMutationVariables>;
export const LicenseDocument = gql`
    query license($licenseId: String!) {
  licenseTemplate(licenseId: $licenseId) {
    id
    name
    totalPrice
    organ
    maxNumberOfCases
    maxNumberOfUsers
    sequencesTemplate {
      id
      name
    }
    memoryLimits {
      currentEcsMemorySize
      maxEcsMemorySize
    }
    numberOfRemainingCases
    numberOfRemainingUsers
    distributor {
      name
      unversionedDistributorName
      version
    }
  }
  organisationsForALicense(licenseId: $licenseId) {
    id
    name
    email
  }
}
    `;

/**
 * __useLicenseQuery__
 *
 * To run a query within a React component, call `useLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseQuery({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *   },
 * });
 */
export function useLicenseQuery(baseOptions: Apollo.QueryHookOptions<LicenseQuery, LicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseQuery, LicenseQueryVariables>(LicenseDocument, options);
      }
export function useLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseQuery, LicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseQuery, LicenseQueryVariables>(LicenseDocument, options);
        }
export type LicenseQueryHookResult = ReturnType<typeof useLicenseQuery>;
export type LicenseLazyQueryHookResult = ReturnType<typeof useLicenseLazyQuery>;
export type LicenseQueryResult = Apollo.QueryResult<LicenseQuery, LicenseQueryVariables>;
export const GetLicensesDocument = gql`
    query getLicenses($input: GetLicenseTemplateInput!) {
  licenses(input: $input) {
    licensePresentations {
      id
      name
      totalPrice
      organ
      numberOfRemainingCases
      numberOfRemainingUsers
      sequencesTemplate {
        id
        name
      }
      maxNumberOfCases
      maxNumberOfUsers
      distributor {
        name
        version
        unversionedDistributorName
      }
      memoryLimits {
        currentEcsMemorySize
        maxEcsMemorySize
      }
    }
    totalResults
  }
}
    `;

/**
 * __useGetLicensesQuery__
 *
 * To run a query within a React component, call `useGetLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLicensesQuery(baseOptions: Apollo.QueryHookOptions<GetLicensesQuery, GetLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLicensesQuery, GetLicensesQueryVariables>(GetLicensesDocument, options);
      }
export function useGetLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicensesQuery, GetLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLicensesQuery, GetLicensesQueryVariables>(GetLicensesDocument, options);
        }
export type GetLicensesQueryHookResult = ReturnType<typeof useGetLicensesQuery>;
export type GetLicensesLazyQueryHookResult = ReturnType<typeof useGetLicensesLazyQuery>;
export type GetLicensesQueryResult = Apollo.QueryResult<GetLicensesQuery, GetLicensesQueryVariables>;
export const AddLicenseTemplateDocument = gql`
    mutation addLicenseTemplate($name: String!, $totalPrice: Decimal!, $organ: String!, $maxNumberOfCases: Int!, $maxNumberOfUsers: Int!, $sequencesTemplateId: String!, $distributor: DistributorTypeInput!, $memoryLimits: MemoryLimitsInput!) {
  addLicenseTemplate(
    templateInput: {name: $name, totalPrice: $totalPrice, organ: $organ, maxNumberOfCases: $maxNumberOfCases, maxNumberOfUsers: $maxNumberOfUsers, sequencesTemplateId: $sequencesTemplateId, distributor: $distributor, memoryLimits: $memoryLimits}
  ) {
    id
    sequencesTemplate {
      id
      name
    }
  }
}
    `;
export type AddLicenseTemplateMutationFn = Apollo.MutationFunction<AddLicenseTemplateMutation, AddLicenseTemplateMutationVariables>;

/**
 * __useAddLicenseTemplateMutation__
 *
 * To run a mutation, you first call `useAddLicenseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLicenseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLicenseTemplateMutation, { data, loading, error }] = useAddLicenseTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      totalPrice: // value for 'totalPrice'
 *      organ: // value for 'organ'
 *      maxNumberOfCases: // value for 'maxNumberOfCases'
 *      maxNumberOfUsers: // value for 'maxNumberOfUsers'
 *      sequencesTemplateId: // value for 'sequencesTemplateId'
 *      distributor: // value for 'distributor'
 *      memoryLimits: // value for 'memoryLimits'
 *   },
 * });
 */
export function useAddLicenseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddLicenseTemplateMutation, AddLicenseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLicenseTemplateMutation, AddLicenseTemplateMutationVariables>(AddLicenseTemplateDocument, options);
      }
export type AddLicenseTemplateMutationHookResult = ReturnType<typeof useAddLicenseTemplateMutation>;
export type AddLicenseTemplateMutationResult = Apollo.MutationResult<AddLicenseTemplateMutation>;
export type AddLicenseTemplateMutationOptions = Apollo.BaseMutationOptions<AddLicenseTemplateMutation, AddLicenseTemplateMutationVariables>;
export const UpdateLicenseDocument = gql`
    mutation updateLicense($templateInput: EditLicenseTemplateInput!) {
  updateLicenseTemplate(templateInput: $templateInput) {
    id
    name
    organ
    totalPrice
    maxNumberOfCases
    maxNumberOfUsers
    sequencesTemplate {
      id
      name
    }
  }
}
    `;
export type UpdateLicenseMutationFn = Apollo.MutationFunction<UpdateLicenseMutation, UpdateLicenseMutationVariables>;

/**
 * __useUpdateLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseMutation, { data, loading, error }] = useUpdateLicenseMutation({
 *   variables: {
 *      templateInput: // value for 'templateInput'
 *   },
 * });
 */
export function useUpdateLicenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicenseMutation, UpdateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicenseMutation, UpdateLicenseMutationVariables>(UpdateLicenseDocument, options);
      }
export type UpdateLicenseMutationHookResult = ReturnType<typeof useUpdateLicenseMutation>;
export type UpdateLicenseMutationResult = Apollo.MutationResult<UpdateLicenseMutation>;
export type UpdateLicenseMutationOptions = Apollo.BaseMutationOptions<UpdateLicenseMutation, UpdateLicenseMutationVariables>;
export const DeleteLicenseDocument = gql`
    mutation deleteLicense($id: String!) {
  deleteLicenseTemplate(id: $id) {
    id
    name
  }
}
    `;
export type DeleteLicenseMutationFn = Apollo.MutationFunction<DeleteLicenseMutation, DeleteLicenseMutationVariables>;

/**
 * __useDeleteLicenseMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseMutation, { data, loading, error }] = useDeleteLicenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicenseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseMutation, DeleteLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseMutation, DeleteLicenseMutationVariables>(DeleteLicenseDocument, options);
      }
export type DeleteLicenseMutationHookResult = ReturnType<typeof useDeleteLicenseMutation>;
export type DeleteLicenseMutationResult = Apollo.MutationResult<DeleteLicenseMutation>;
export type DeleteLicenseMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseMutation, DeleteLicenseMutationVariables>;
export const GlobalDocument = gql`
    subscription global($userId: String!) {
  global(userId: $userId) {
    notificationType
    relevantId
    numberIfRelevant
    textIfRelevant
    doctorReference
  }
}
    `;

/**
 * __useGlobalSubscription__
 *
 * To run a query within a React component, call `useGlobalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGlobalSubscription(baseOptions: Apollo.SubscriptionHookOptions<GlobalSubscription, GlobalSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GlobalSubscription, GlobalSubscriptionVariables>(GlobalDocument, options);
      }
export type GlobalSubscriptionHookResult = ReturnType<typeof useGlobalSubscription>;
export type GlobalSubscriptionResult = Apollo.SubscriptionResult<GlobalSubscription>;
export const RemainingItemsDocument = gql`
    query remainingItems {
  remainingItems {
    remainingCases
    remainingUsers
  }
}
    `;

/**
 * __useRemainingItemsQuery__
 *
 * To run a query within a React component, call `useRemainingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemainingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemainingItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemainingItemsQuery(baseOptions?: Apollo.QueryHookOptions<RemainingItemsQuery, RemainingItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemainingItemsQuery, RemainingItemsQueryVariables>(RemainingItemsDocument, options);
      }
export function useRemainingItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemainingItemsQuery, RemainingItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemainingItemsQuery, RemainingItemsQueryVariables>(RemainingItemsDocument, options);
        }
export type RemainingItemsQueryHookResult = ReturnType<typeof useRemainingItemsQuery>;
export type RemainingItemsLazyQueryHookResult = ReturnType<typeof useRemainingItemsLazyQuery>;
export type RemainingItemsQueryResult = Apollo.QueryResult<RemainingItemsQuery, RemainingItemsQueryVariables>;
export const AllOrganisationsDocument = gql`
    query allOrganisations($input: GetOrganisationInput!) {
  organisations(input: $input) {
    organisationPresentations {
      id
      name
      cifAndOthers
      email
      admin {
        id
        name
        surname
        email
        isOrgAdmin
        orgId
      }
      organisationLicense {
        id
        totalPrice
        maxNumberOfCases
        maxNumberOfUsers
        remainingCases
        remainingUsers
        organ
        licenseTemplateReference {
          id
          name
        }
      }
    }
    totalResults
  }
}
    `;

/**
 * __useAllOrganisationsQuery__
 *
 * To run a query within a React component, call `useAllOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganisationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllOrganisationsQuery(baseOptions: Apollo.QueryHookOptions<AllOrganisationsQuery, AllOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOrganisationsQuery, AllOrganisationsQueryVariables>(AllOrganisationsDocument, options);
      }
export function useAllOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOrganisationsQuery, AllOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOrganisationsQuery, AllOrganisationsQueryVariables>(AllOrganisationsDocument, options);
        }
export type AllOrganisationsQueryHookResult = ReturnType<typeof useAllOrganisationsQuery>;
export type AllOrganisationsLazyQueryHookResult = ReturnType<typeof useAllOrganisationsLazyQuery>;
export type AllOrganisationsQueryResult = Apollo.QueryResult<AllOrganisationsQuery, AllOrganisationsQueryVariables>;
export const OrganisationDocument = gql`
    query organisation($orgId: String) {
  organisation(orgId: $orgId) {
    id
    name
    cifAndOthers
    admin {
      id
      name
      surname
      email
    }
    organisationLicense {
      id
      totalPrice
      maxNumberOfCases
      maxNumberOfUsers
      remainingCases
      remainingUsers
      organ
      licenseTemplateReference {
        id
        name
      }
      licenseFeatures {
        id
        name
        description
        environKey
        environValue
        price
      }
    }
    distributor {
      unversionedDistributorName
      version
      name
    }
    sequencesTemplate {
      id
      name
    }
  }
}
    `;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
      }
export function useOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
        }
export type OrganisationQueryHookResult = ReturnType<typeof useOrganisationQuery>;
export type OrganisationLazyQueryHookResult = ReturnType<typeof useOrganisationLazyQuery>;
export type OrganisationQueryResult = Apollo.QueryResult<OrganisationQuery, OrganisationQueryVariables>;
export const OrganisationForUserProfileDocument = gql`
    query organisationForUserProfile {
  organisation {
    name
    admin {
      name
      surname
    }
  }
}
    `;

/**
 * __useOrganisationForUserProfileQuery__
 *
 * To run a query within a React component, call `useOrganisationForUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationForUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationForUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationForUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationForUserProfileQuery, OrganisationForUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationForUserProfileQuery, OrganisationForUserProfileQueryVariables>(OrganisationForUserProfileDocument, options);
      }
export function useOrganisationForUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationForUserProfileQuery, OrganisationForUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationForUserProfileQuery, OrganisationForUserProfileQueryVariables>(OrganisationForUserProfileDocument, options);
        }
export type OrganisationForUserProfileQueryHookResult = ReturnType<typeof useOrganisationForUserProfileQuery>;
export type OrganisationForUserProfileLazyQueryHookResult = ReturnType<typeof useOrganisationForUserProfileLazyQuery>;
export type OrganisationForUserProfileQueryResult = Apollo.QueryResult<OrganisationForUserProfileQuery, OrganisationForUserProfileQueryVariables>;
export const AddOrganisationDocument = gql`
    mutation addOrganisation($name: String!, $firstName: String!, $lastName: String!, $email: String!, $cifAndOthers: String!, $licenseId: String!) {
  addOrganisation(
    input: {name: $name, firstName: $firstName, lastName: $lastName, email: $email, cifAndOthers: $cifAndOthers, licenseId: $licenseId}
  ) {
    id
    name
    email
    cifAndOthers
  }
}
    `;
export type AddOrganisationMutationFn = Apollo.MutationFunction<AddOrganisationMutation, AddOrganisationMutationVariables>;

/**
 * __useAddOrganisationMutation__
 *
 * To run a mutation, you first call `useAddOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganisationMutation, { data, loading, error }] = useAddOrganisationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      cifAndOthers: // value for 'cifAndOthers'
 *      licenseId: // value for 'licenseId'
 *   },
 * });
 */
export function useAddOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganisationMutation, AddOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganisationMutation, AddOrganisationMutationVariables>(AddOrganisationDocument, options);
      }
export type AddOrganisationMutationHookResult = ReturnType<typeof useAddOrganisationMutation>;
export type AddOrganisationMutationResult = Apollo.MutationResult<AddOrganisationMutation>;
export type AddOrganisationMutationOptions = Apollo.BaseMutationOptions<AddOrganisationMutation, AddOrganisationMutationVariables>;
export const StatsDocument = gql`
    query stats {
  stats {
    numberOfRemainingCases
    numberOfRemainingUsers
    maxNumberOfCases
    maxNumberOfUsers
    positiveFalse
    negativeFalse
    totalPositive
    totalNegative
    totalCreatedCases
    totalCreatedUsers
  }
}
    `;

/**
 * __useStatsQuery__
 *
 * To run a query within a React component, call `useStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsQuery(baseOptions?: Apollo.QueryHookOptions<StatsQuery, StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatsQuery, StatsQueryVariables>(StatsDocument, options);
      }
export function useStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatsQuery, StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatsQuery, StatsQueryVariables>(StatsDocument, options);
        }
export type StatsQueryHookResult = ReturnType<typeof useStatsQuery>;
export type StatsLazyQueryHookResult = ReturnType<typeof useStatsLazyQuery>;
export type StatsQueryResult = Apollo.QueryResult<StatsQuery, StatsQueryVariables>;
export const UpdateOrganisationDocument = gql`
    mutation updateOrganisation($orgId: String!, $name: String!, $cifAndOthers: String!) {
  updateOrganisation(
    input: {orgId: $orgId, name: $name, cifAndOthers: $cifAndOthers}
  ) {
    id
  }
}
    `;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      cifAndOthers: // value for 'cifAndOthers'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, options);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const ReloadOrgLicenseDocument = gql`
    mutation reloadOrgLicense($orgLicenseId: String!, $priceIncrement: Decimal!, $numberOfCases: Int!, $numberOfUsers: Int!) {
  reloadOrgLicense(
    input: {orgLicenseId: $orgLicenseId, priceIncrement: $priceIncrement, numberOfCases: $numberOfCases, numberOfUsers: $numberOfUsers}
  ) {
    id
    name
    cifAndOthers
    admin {
      id
      name
      surname
      email
    }
    organisationLicense {
      id
      totalPrice
      maxNumberOfCases
      maxNumberOfUsers
      remainingCases
      remainingUsers
      organ
      licenseTemplateReference {
        id
        name
      }
    }
  }
}
    `;
export type ReloadOrgLicenseMutationFn = Apollo.MutationFunction<ReloadOrgLicenseMutation, ReloadOrgLicenseMutationVariables>;

/**
 * __useReloadOrgLicenseMutation__
 *
 * To run a mutation, you first call `useReloadOrgLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReloadOrgLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reloadOrgLicenseMutation, { data, loading, error }] = useReloadOrgLicenseMutation({
 *   variables: {
 *      orgLicenseId: // value for 'orgLicenseId'
 *      priceIncrement: // value for 'priceIncrement'
 *      numberOfCases: // value for 'numberOfCases'
 *      numberOfUsers: // value for 'numberOfUsers'
 *   },
 * });
 */
export function useReloadOrgLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ReloadOrgLicenseMutation, ReloadOrgLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReloadOrgLicenseMutation, ReloadOrgLicenseMutationVariables>(ReloadOrgLicenseDocument, options);
      }
export type ReloadOrgLicenseMutationHookResult = ReturnType<typeof useReloadOrgLicenseMutation>;
export type ReloadOrgLicenseMutationResult = Apollo.MutationResult<ReloadOrgLicenseMutation>;
export type ReloadOrgLicenseMutationOptions = Apollo.BaseMutationOptions<ReloadOrgLicenseMutation, ReloadOrgLicenseMutationVariables>;
export const SequencesByCaseDocument = gql`
    query sequencesByCase($caseId: String!) {
  sequencesByCase(caseId: $caseId) {
    type
    biomarkers {
      key
      value
    }
    views {
      key
      value
    }
    singleImages
  }
}
    `;

/**
 * __useSequencesByCaseQuery__
 *
 * To run a query within a React component, call `useSequencesByCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSequencesByCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSequencesByCaseQuery({
 *   variables: {
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useSequencesByCaseQuery(baseOptions: Apollo.QueryHookOptions<SequencesByCaseQuery, SequencesByCaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SequencesByCaseQuery, SequencesByCaseQueryVariables>(SequencesByCaseDocument, options);
      }
export function useSequencesByCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SequencesByCaseQuery, SequencesByCaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SequencesByCaseQuery, SequencesByCaseQueryVariables>(SequencesByCaseDocument, options);
        }
export type SequencesByCaseQueryHookResult = ReturnType<typeof useSequencesByCaseQuery>;
export type SequencesByCaseLazyQueryHookResult = ReturnType<typeof useSequencesByCaseLazyQuery>;
export type SequencesByCaseQueryResult = Apollo.QueryResult<SequencesByCaseQuery, SequencesByCaseQueryVariables>;
export const ImagesDocument = gql`
    query images($caseId: String!) {
  images(caseId: $caseId) {
    model
    biomarkers {
      key
      value {
        key
        value
      }
    }
    singleImages {
      key
      value
    }
  }
}
    `;

/**
 * __useImagesQuery__
 *
 * To run a query within a React component, call `useImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImagesQuery({
 *   variables: {
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useImagesQuery(baseOptions: Apollo.QueryHookOptions<ImagesQuery, ImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImagesQuery, ImagesQueryVariables>(ImagesDocument, options);
      }
export function useImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImagesQuery, ImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImagesQuery, ImagesQueryVariables>(ImagesDocument, options);
        }
export type ImagesQueryHookResult = ReturnType<typeof useImagesQuery>;
export type ImagesLazyQueryHookResult = ReturnType<typeof useImagesLazyQuery>;
export type ImagesQueryResult = Apollo.QueryResult<ImagesQuery, ImagesQueryVariables>;
export const AddSequencesTemplateDocument = gql`
    mutation AddSequencesTemplate($input: CreateSequencesTemplateInput!) {
  addSequencesTemplate(sequencesInput: $input) {
    id
    name
    stepSequenceTemplates {
      sequenceType
      stepTemplates {
        id
        type
        unversionedEcsTaskName
        version
      }
    }
  }
}
    `;
export type AddSequencesTemplateMutationFn = Apollo.MutationFunction<AddSequencesTemplateMutation, AddSequencesTemplateMutationVariables>;

/**
 * __useAddSequencesTemplateMutation__
 *
 * To run a mutation, you first call `useAddSequencesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSequencesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSequencesTemplateMutation, { data, loading, error }] = useAddSequencesTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSequencesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddSequencesTemplateMutation, AddSequencesTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSequencesTemplateMutation, AddSequencesTemplateMutationVariables>(AddSequencesTemplateDocument, options);
      }
export type AddSequencesTemplateMutationHookResult = ReturnType<typeof useAddSequencesTemplateMutation>;
export type AddSequencesTemplateMutationResult = Apollo.MutationResult<AddSequencesTemplateMutation>;
export type AddSequencesTemplateMutationOptions = Apollo.BaseMutationOptions<AddSequencesTemplateMutation, AddSequencesTemplateMutationVariables>;
export const AllSequencesDocument = gql`
    query allSequences($input: GetSequencesTemplateInput!) {
  sequencesTemplates(input: $input) {
    sequencesTemplatePresentations {
      id
      name
      stepSequenceTemplates {
        sequenceType
        stepTemplates {
          type
          unversionedEcsTaskName
          version
        }
      }
    }
    totalResults
  }
}
    `;

/**
 * __useAllSequencesQuery__
 *
 * To run a query within a React component, call `useAllSequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSequencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllSequencesQuery(baseOptions: Apollo.QueryHookOptions<AllSequencesQuery, AllSequencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSequencesQuery, AllSequencesQueryVariables>(AllSequencesDocument, options);
      }
export function useAllSequencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSequencesQuery, AllSequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSequencesQuery, AllSequencesQueryVariables>(AllSequencesDocument, options);
        }
export type AllSequencesQueryHookResult = ReturnType<typeof useAllSequencesQuery>;
export type AllSequencesLazyQueryHookResult = ReturnType<typeof useAllSequencesLazyQuery>;
export type AllSequencesQueryResult = Apollo.QueryResult<AllSequencesQuery, AllSequencesQueryVariables>;
export const SequencesTemplateDocument = gql`
    query sequencesTemplate($sequencesTemplateId: String!) {
  sequencesTemplate(sequencesTemplateId: $sequencesTemplateId) {
    id
    name
    stepSequenceTemplates {
      sequenceType
      stepTemplates {
        id
        type
        unversionedEcsTaskName
        version
      }
    }
  }
  licensesForASequencesTemplate(seqTemplateId: $sequencesTemplateId) {
    id
    name
    organ
    totalPrice
    maxNumberOfCases
    maxNumberOfUsers
    sequencesTemplate {
      id
      name
    }
  }
}
    `;

/**
 * __useSequencesTemplateQuery__
 *
 * To run a query within a React component, call `useSequencesTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSequencesTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSequencesTemplateQuery({
 *   variables: {
 *      sequencesTemplateId: // value for 'sequencesTemplateId'
 *   },
 * });
 */
export function useSequencesTemplateQuery(baseOptions: Apollo.QueryHookOptions<SequencesTemplateQuery, SequencesTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SequencesTemplateQuery, SequencesTemplateQueryVariables>(SequencesTemplateDocument, options);
      }
export function useSequencesTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SequencesTemplateQuery, SequencesTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SequencesTemplateQuery, SequencesTemplateQueryVariables>(SequencesTemplateDocument, options);
        }
export type SequencesTemplateQueryHookResult = ReturnType<typeof useSequencesTemplateQuery>;
export type SequencesTemplateLazyQueryHookResult = ReturnType<typeof useSequencesTemplateLazyQuery>;
export type SequencesTemplateQueryResult = Apollo.QueryResult<SequencesTemplateQuery, SequencesTemplateQueryVariables>;
export const UpdateSequencesTemplateDocument = gql`
    mutation updateSequencesTemplate($templateInput: EditSequencesTemplateInput!) {
  updateSequencesTemplate(templateInput: $templateInput) {
    id
    name
    stepSequenceTemplates {
      sequenceType
      stepTemplates {
        id
        type
        unversionedEcsTaskName
        version
      }
    }
  }
}
    `;
export type UpdateSequencesTemplateMutationFn = Apollo.MutationFunction<UpdateSequencesTemplateMutation, UpdateSequencesTemplateMutationVariables>;

/**
 * __useUpdateSequencesTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSequencesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSequencesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSequencesTemplateMutation, { data, loading, error }] = useUpdateSequencesTemplateMutation({
 *   variables: {
 *      templateInput: // value for 'templateInput'
 *   },
 * });
 */
export function useUpdateSequencesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSequencesTemplateMutation, UpdateSequencesTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSequencesTemplateMutation, UpdateSequencesTemplateMutationVariables>(UpdateSequencesTemplateDocument, options);
      }
export type UpdateSequencesTemplateMutationHookResult = ReturnType<typeof useUpdateSequencesTemplateMutation>;
export type UpdateSequencesTemplateMutationResult = Apollo.MutationResult<UpdateSequencesTemplateMutation>;
export type UpdateSequencesTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateSequencesTemplateMutation, UpdateSequencesTemplateMutationVariables>;
export const DeleteSequencesTemplateDocument = gql`
    mutation deleteSequencesTemplate($id: String!) {
  deleteSequencesTemplate(id: $id) {
    id
    name
  }
}
    `;
export type DeleteSequencesTemplateMutationFn = Apollo.MutationFunction<DeleteSequencesTemplateMutation, DeleteSequencesTemplateMutationVariables>;

/**
 * __useDeleteSequencesTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSequencesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSequencesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSequencesTemplateMutation, { data, loading, error }] = useDeleteSequencesTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSequencesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSequencesTemplateMutation, DeleteSequencesTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSequencesTemplateMutation, DeleteSequencesTemplateMutationVariables>(DeleteSequencesTemplateDocument, options);
      }
export type DeleteSequencesTemplateMutationHookResult = ReturnType<typeof useDeleteSequencesTemplateMutation>;
export type DeleteSequencesTemplateMutationResult = Apollo.MutationResult<DeleteSequencesTemplateMutation>;
export type DeleteSequencesTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteSequencesTemplateMutation, DeleteSequencesTemplateMutationVariables>;
export const TasksDocument = gql`
    query tasks($input: String!) {
  tasks(sequenceType: $input) {
    tasks {
      key
      value {
        name
        version
        description
      }
    }
  }
}
    `;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTasksQuery(baseOptions: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const CreateSupportMessageDocument = gql`
    mutation createSupportMessage($title: String!, $content: String!) {
  createSupportMessage(input: {title: $title, content: $content})
}
    `;
export type CreateSupportMessageMutationFn = Apollo.MutationFunction<CreateSupportMessageMutation, CreateSupportMessageMutationVariables>;

/**
 * __useCreateSupportMessageMutation__
 *
 * To run a mutation, you first call `useCreateSupportMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportMessageMutation, { data, loading, error }] = useCreateSupportMessageMutation({
 *   variables: {
 *      title: // value for 'title'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateSupportMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupportMessageMutation, CreateSupportMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupportMessageMutation, CreateSupportMessageMutationVariables>(CreateSupportMessageDocument, options);
      }
export type CreateSupportMessageMutationHookResult = ReturnType<typeof useCreateSupportMessageMutation>;
export type CreateSupportMessageMutationResult = Apollo.MutationResult<CreateSupportMessageMutation>;
export type CreateSupportMessageMutationOptions = Apollo.BaseMutationOptions<CreateSupportMessageMutation, CreateSupportMessageMutationVariables>;
export const CreateUserLicenseDocument = gql`
    mutation createUserLicense($input: UserLicenseInput!) {
  createUserLicense(input: $input) {
    id
    organ
    creationOrder
    maxNumOfCases
    totalCasesHistory
    numberOfRemainingCases
    active
  }
}
    `;
export type CreateUserLicenseMutationFn = Apollo.MutationFunction<CreateUserLicenseMutation, CreateUserLicenseMutationVariables>;

/**
 * __useCreateUserLicenseMutation__
 *
 * To run a mutation, you first call `useCreateUserLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLicenseMutation, { data, loading, error }] = useCreateUserLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLicenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserLicenseMutation, CreateUserLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserLicenseMutation, CreateUserLicenseMutationVariables>(CreateUserLicenseDocument, options);
      }
export type CreateUserLicenseMutationHookResult = ReturnType<typeof useCreateUserLicenseMutation>;
export type CreateUserLicenseMutationResult = Apollo.MutationResult<CreateUserLicenseMutation>;
export type CreateUserLicenseMutationOptions = Apollo.BaseMutationOptions<CreateUserLicenseMutation, CreateUserLicenseMutationVariables>;
export const ReloadUserLicenseDocument = gql`
    mutation reloadUserLicense($input: ReloadLicenseInput!) {
  reloadUserLicense(input: $input) {
    id
    organ
    maxNumOfCases
    creationOrder
    totalCasesHistory
    numberOfRemainingCases
    user {
      id
      name
      surname
      email
      isOrgAdmin
      orgId
    }
    active
  }
}
    `;
export type ReloadUserLicenseMutationFn = Apollo.MutationFunction<ReloadUserLicenseMutation, ReloadUserLicenseMutationVariables>;

/**
 * __useReloadUserLicenseMutation__
 *
 * To run a mutation, you first call `useReloadUserLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReloadUserLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reloadUserLicenseMutation, { data, loading, error }] = useReloadUserLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReloadUserLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ReloadUserLicenseMutation, ReloadUserLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReloadUserLicenseMutation, ReloadUserLicenseMutationVariables>(ReloadUserLicenseDocument, options);
      }
export type ReloadUserLicenseMutationHookResult = ReturnType<typeof useReloadUserLicenseMutation>;
export type ReloadUserLicenseMutationResult = Apollo.MutationResult<ReloadUserLicenseMutation>;
export type ReloadUserLicenseMutationOptions = Apollo.BaseMutationOptions<ReloadUserLicenseMutation, ReloadUserLicenseMutationVariables>;
export const AssignUserLicenseToUserDocument = gql`
    mutation assignUserLicenseToUser($input: AssignUserLicenseToUserInput!) {
  assignUserLicenseToUser(input: $input) {
    id
    organ
    maxNumOfCases
    creationOrder
    totalCasesHistory
    numberOfRemainingCases
    user {
      id
      name
      surname
      email
      isOrgAdmin
      orgId
    }
    active
  }
}
    `;
export type AssignUserLicenseToUserMutationFn = Apollo.MutationFunction<AssignUserLicenseToUserMutation, AssignUserLicenseToUserMutationVariables>;

/**
 * __useAssignUserLicenseToUserMutation__
 *
 * To run a mutation, you first call `useAssignUserLicenseToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserLicenseToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserLicenseToUserMutation, { data, loading, error }] = useAssignUserLicenseToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignUserLicenseToUserMutation(baseOptions?: Apollo.MutationHookOptions<AssignUserLicenseToUserMutation, AssignUserLicenseToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignUserLicenseToUserMutation, AssignUserLicenseToUserMutationVariables>(AssignUserLicenseToUserDocument, options);
      }
export type AssignUserLicenseToUserMutationHookResult = ReturnType<typeof useAssignUserLicenseToUserMutation>;
export type AssignUserLicenseToUserMutationResult = Apollo.MutationResult<AssignUserLicenseToUserMutation>;
export type AssignUserLicenseToUserMutationOptions = Apollo.BaseMutationOptions<AssignUserLicenseToUserMutation, AssignUserLicenseToUserMutationVariables>;
export const UnassignUserLicenseToUserDocument = gql`
    mutation unassignUserLicenseToUser($input: UnassignUserLicenseToUserInput!) {
  unassignUserLicenseToUser(input: $input) {
    id
    organ
    maxNumOfCases
    creationOrder
    totalCasesHistory
    numberOfRemainingCases
    user {
      id
      name
      surname
      email
      isOrgAdmin
      orgId
    }
    active
  }
}
    `;
export type UnassignUserLicenseToUserMutationFn = Apollo.MutationFunction<UnassignUserLicenseToUserMutation, UnassignUserLicenseToUserMutationVariables>;

/**
 * __useUnassignUserLicenseToUserMutation__
 *
 * To run a mutation, you first call `useUnassignUserLicenseToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUserLicenseToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUserLicenseToUserMutation, { data, loading, error }] = useUnassignUserLicenseToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignUserLicenseToUserMutation(baseOptions?: Apollo.MutationHookOptions<UnassignUserLicenseToUserMutation, UnassignUserLicenseToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignUserLicenseToUserMutation, UnassignUserLicenseToUserMutationVariables>(UnassignUserLicenseToUserDocument, options);
      }
export type UnassignUserLicenseToUserMutationHookResult = ReturnType<typeof useUnassignUserLicenseToUserMutation>;
export type UnassignUserLicenseToUserMutationResult = Apollo.MutationResult<UnassignUserLicenseToUserMutation>;
export type UnassignUserLicenseToUserMutationOptions = Apollo.BaseMutationOptions<UnassignUserLicenseToUserMutation, UnassignUserLicenseToUserMutationVariables>;
export const MoveNumCasesBetweenUserLicensesDocument = gql`
    mutation moveNumCasesBetweenUserLicenses($input: MoveNumCasesBetweenUserLicensesInput!) {
  moveNumCasesBetweenUserLicenses(input: $input) {
    userLicenseOrigin {
      id
      organ
      creationOrder
      maxNumOfCases
      totalCasesHistory
      numberOfRemainingCases
      active
    }
    userLicenseDestiny {
      id
      organ
      creationOrder
      maxNumOfCases
      totalCasesHistory
      numberOfRemainingCases
      active
    }
  }
}
    `;
export type MoveNumCasesBetweenUserLicensesMutationFn = Apollo.MutationFunction<MoveNumCasesBetweenUserLicensesMutation, MoveNumCasesBetweenUserLicensesMutationVariables>;

/**
 * __useMoveNumCasesBetweenUserLicensesMutation__
 *
 * To run a mutation, you first call `useMoveNumCasesBetweenUserLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveNumCasesBetweenUserLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveNumCasesBetweenUserLicensesMutation, { data, loading, error }] = useMoveNumCasesBetweenUserLicensesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveNumCasesBetweenUserLicensesMutation(baseOptions?: Apollo.MutationHookOptions<MoveNumCasesBetweenUserLicensesMutation, MoveNumCasesBetweenUserLicensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveNumCasesBetweenUserLicensesMutation, MoveNumCasesBetweenUserLicensesMutationVariables>(MoveNumCasesBetweenUserLicensesDocument, options);
      }
export type MoveNumCasesBetweenUserLicensesMutationHookResult = ReturnType<typeof useMoveNumCasesBetweenUserLicensesMutation>;
export type MoveNumCasesBetweenUserLicensesMutationResult = Apollo.MutationResult<MoveNumCasesBetweenUserLicensesMutation>;
export type MoveNumCasesBetweenUserLicensesMutationOptions = Apollo.BaseMutationOptions<MoveNumCasesBetweenUserLicensesMutation, MoveNumCasesBetweenUserLicensesMutationVariables>;
export const UserLicensesWithoutUserDocument = gql`
    query userLicensesWithoutUser($input: GetUserLicensesInput!) {
  userLicensesWithoutUser(input: $input) {
    userLicensePresentations {
      id
      creationOrder
      organ
      maxNumOfCases
      totalCasesHistory
      numberOfRemainingCases
      user {
        id
        name
        surname
        email
        isOrgAdmin
        orgId
      }
      active
    }
    totalResults
  }
}
    `;

/**
 * __useUserLicensesWithoutUserQuery__
 *
 * To run a query within a React component, call `useUserLicensesWithoutUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLicensesWithoutUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLicensesWithoutUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLicensesWithoutUserQuery(baseOptions: Apollo.QueryHookOptions<UserLicensesWithoutUserQuery, UserLicensesWithoutUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLicensesWithoutUserQuery, UserLicensesWithoutUserQueryVariables>(UserLicensesWithoutUserDocument, options);
      }
export function useUserLicensesWithoutUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLicensesWithoutUserQuery, UserLicensesWithoutUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLicensesWithoutUserQuery, UserLicensesWithoutUserQueryVariables>(UserLicensesWithoutUserDocument, options);
        }
export type UserLicensesWithoutUserQueryHookResult = ReturnType<typeof useUserLicensesWithoutUserQuery>;
export type UserLicensesWithoutUserLazyQueryHookResult = ReturnType<typeof useUserLicensesWithoutUserLazyQuery>;
export type UserLicensesWithoutUserQueryResult = Apollo.QueryResult<UserLicensesWithoutUserQuery, UserLicensesWithoutUserQueryVariables>;
export const UserLicensesDocument = gql`
    query userLicenses($input: GetUserLicensesInput!) {
  userLicenses(input: $input) {
    userLicensePresentations {
      id
      creationOrder
      organ
      maxNumOfCases
      totalCasesHistory
      numberOfRemainingCases
      user {
        id
        name
        surname
        email
        isOrgAdmin
        orgId
      }
      active
    }
    totalResults
  }
}
    `;

/**
 * __useUserLicensesQuery__
 *
 * To run a query within a React component, call `useUserLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLicensesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLicensesQuery(baseOptions: Apollo.QueryHookOptions<UserLicensesQuery, UserLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLicensesQuery, UserLicensesQueryVariables>(UserLicensesDocument, options);
      }
export function useUserLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLicensesQuery, UserLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLicensesQuery, UserLicensesQueryVariables>(UserLicensesDocument, options);
        }
export type UserLicensesQueryHookResult = ReturnType<typeof useUserLicensesQuery>;
export type UserLicensesLazyQueryHookResult = ReturnType<typeof useUserLicensesLazyQuery>;
export type UserLicensesQueryResult = Apollo.QueryResult<UserLicensesQuery, UserLicensesQueryVariables>;
export const UserLicenseDocument = gql`
    query userLicense($userLicenseId: String!) {
  userLicense(userLicenseId: $userLicenseId) {
    id
    creationOrder
    organ
    maxNumOfCases
    totalCasesHistory
    numberOfRemainingCases
    user {
      id
      name
      surname
      email
      isOrgAdmin
      orgId
    }
    active
  }
}
    `;

/**
 * __useUserLicenseQuery__
 *
 * To run a query within a React component, call `useUserLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLicenseQuery({
 *   variables: {
 *      userLicenseId: // value for 'userLicenseId'
 *   },
 * });
 */
export function useUserLicenseQuery(baseOptions: Apollo.QueryHookOptions<UserLicenseQuery, UserLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLicenseQuery, UserLicenseQueryVariables>(UserLicenseDocument, options);
      }
export function useUserLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLicenseQuery, UserLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLicenseQuery, UserLicenseQueryVariables>(UserLicenseDocument, options);
        }
export type UserLicenseQueryHookResult = ReturnType<typeof useUserLicenseQuery>;
export type UserLicenseLazyQueryHookResult = ReturnType<typeof useUserLicenseLazyQuery>;
export type UserLicenseQueryResult = Apollo.QueryResult<UserLicenseQuery, UserLicenseQueryVariables>;
export const ReturnCasesToOrgLicenseDocument = gql`
    mutation returnCasesToOrgLicense($input: ReloadLicenseInput!) {
  returnCasesToOrgLicense(input: $input) {
    id
    organ
    creationOrder
    maxNumOfCases
    totalCasesHistory
    numberOfRemainingCases
    active
  }
}
    `;
export type ReturnCasesToOrgLicenseMutationFn = Apollo.MutationFunction<ReturnCasesToOrgLicenseMutation, ReturnCasesToOrgLicenseMutationVariables>;

/**
 * __useReturnCasesToOrgLicenseMutation__
 *
 * To run a mutation, you first call `useReturnCasesToOrgLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnCasesToOrgLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnCasesToOrgLicenseMutation, { data, loading, error }] = useReturnCasesToOrgLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReturnCasesToOrgLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ReturnCasesToOrgLicenseMutation, ReturnCasesToOrgLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReturnCasesToOrgLicenseMutation, ReturnCasesToOrgLicenseMutationVariables>(ReturnCasesToOrgLicenseDocument, options);
      }
export type ReturnCasesToOrgLicenseMutationHookResult = ReturnType<typeof useReturnCasesToOrgLicenseMutation>;
export type ReturnCasesToOrgLicenseMutationResult = Apollo.MutationResult<ReturnCasesToOrgLicenseMutation>;
export type ReturnCasesToOrgLicenseMutationOptions = Apollo.BaseMutationOptions<ReturnCasesToOrgLicenseMutation, ReturnCasesToOrgLicenseMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($firstName: String!, $lastName: String!, $email: String!) {
  addUser(input: {firstName: $firstName, lastName: $lastName, email: $email}) {
    id
    orgId
    name
    surname
    email
    isOrgAdmin
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword {
  resetPassword {
    id
    orgId
    name
    surname
    email
    isOrgAdmin
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const AllUsersDocument = gql`
    query allUsers($input: GetUserInput!) {
  users(input: $input) {
    userPresentations {
      id
      name
      surname
      orgId
      email
      isOrgAdmin
      totalCases
      userLicenseId
      creationOrder
      maxNumberOfCases
      numberOfRemainingCases
    }
    totalResults
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    orgId
    name
    surname
    email
    isOrgAdmin
    numberOfRemainingCases
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $email: String, $name: String, $surname: String, $orgId: String, $isOrgAdmin: Boolean) {
  updateUser(
    input: {id: $id, email: $email, name: $name, surname: $surname, orgId: $orgId, isOrgAdmin: $isOrgAdmin}
  ) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      orgId: // value for 'orgId'
 *      isOrgAdmin: // value for 'isOrgAdmin'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    orgId
    name
    surname
    email
    isOrgAdmin
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserDocument = gql`
    query user($input: String!) {
  user(userId: $input) {
    id
    name
    surname
    orgId
    email
    isOrgAdmin
    totalCases
    remainingCases
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const IsUserDeletedDocument = gql`
    query isUserDeleted($email: String!) {
  isUserDeleted(email: $email)
}
    `;

/**
 * __useIsUserDeletedQuery__
 *
 * To run a query within a React component, call `useIsUserDeletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserDeletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserDeletedQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsUserDeletedQuery(baseOptions: Apollo.QueryHookOptions<IsUserDeletedQuery, IsUserDeletedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUserDeletedQuery, IsUserDeletedQueryVariables>(IsUserDeletedDocument, options);
      }
export function useIsUserDeletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserDeletedQuery, IsUserDeletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUserDeletedQuery, IsUserDeletedQueryVariables>(IsUserDeletedDocument, options);
        }
export type IsUserDeletedQueryHookResult = ReturnType<typeof useIsUserDeletedQuery>;
export type IsUserDeletedLazyQueryHookResult = ReturnType<typeof useIsUserDeletedLazyQuery>;
export type IsUserDeletedQueryResult = Apollo.QueryResult<IsUserDeletedQuery, IsUserDeletedQueryVariables>;