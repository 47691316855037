import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { Content } from "antd/es/layout/layout";
import { MyAdminProfileDetails } from "../../../components/organisationAdminComponents/myAdminProfileComponents/myAdminProfileDetails/myAdminProfileDetails";
import { MyAdminProfileLicenseDetails } from "../../../components/organisationAdminComponents/myAdminProfileComponents/myAdminProfileLicenseDetails/myAdminProfileLicenseDetails";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import { ChangePasswordModal } from "../../../components/appCore/changePasswordModal/changePasswordModal";
import { useQuery } from "@apollo/client";
import { OrganisationQuery } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { ChangeLicenseRequestModal } from "../../../components/organisationAdminComponents/myAdminProfileComponents/changeLicenseRequestModal/changeLicenseRequestModal";
import { ExtendLicenseRequestModal } from "../../../components/organisationAdminComponents/myAdminProfileComponents/extendLicenseRequestModal/extendLicenseRequestModal";
import { PreferencesCard } from "../../../components/myProfileComponents/preferencesCard/preferencesCard";

export const MyAdminProfilePage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
        useState<boolean>(false);
    const [
        isChangeLicenseRequestModalVisible,
        setIsChangeLicenseRequestModalVisible,
    ] = useState<boolean>(false);
    const [
        isExtendLicenseRequestModalVisible,
        setIsExtendLicenseRequestModalVisible,
    ] = useState<boolean>(false);

    useEffect(() => {
        setHeaderContent({
            title: t("myAdminProfile.myProfile"),
            breadCrumb: [],
        });
    }, []);

    const { data: getOrganisationData, refetch: refetchOrganisationData } =
        useQuery<OrganisationQuery>(fragments.GET_ORGANISATION);

    return (
        <>
            <Content>
                <MyAdminProfileDetails
                    setIsChangePasswordModalVisible={
                        setIsChangePasswordModalVisible
                    }
                    getOrganisationData={getOrganisationData}
                    refetchOrganisationData={refetchOrganisationData}
                />
                <Spacer size="s" />
                <PreferencesCard />
                <Spacer size="s" />
                <MyAdminProfileLicenseDetails
                    getOrganisationData={getOrganisationData}
                    setIsChangeLicenseRequestModalVisible={
                        setIsChangeLicenseRequestModalVisible
                    }
                    setIsExtendLicenseRequestModalVisible={
                        setIsExtendLicenseRequestModalVisible
                    }
                />
                <Spacer size="s" />
            </Content>
            {isChangePasswordModalVisible && (
                <ChangePasswordModal
                    setIsChangePasswordModalVisible={
                        setIsChangePasswordModalVisible
                    }
                />
            )}
            {isChangeLicenseRequestModalVisible && (
                <ChangeLicenseRequestModal
                    setIsChangeLicenseRequestModalVisible={
                        setIsChangeLicenseRequestModalVisible
                    }
                />
            )}
            {isExtendLicenseRequestModalVisible && (
                <ExtendLicenseRequestModal
                    setIsExtendLicenseRequestModalVisible={
                        setIsExtendLicenseRequestModalVisible
                    }
                />
            )}
        </>
    );
};
