import { useQuery } from "@apollo/client";
import { Button, Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SequencesDetailsDescription } from "../../../components/adminComponents/sequencesDetailsComponents/sequencesDetailsDescription/sequencesDetailsDescription";
import { SequencesDetailsLicenses } from "../../../components/adminComponents/sequencesDetailsComponents/sequencesDetailsLicenses/sequencesDetailsLicenses";
import { SequencesTemplateQuery } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { useHeader } from "../../../hooks/headerHook";
import { ILicensePresentation } from "../../../features/licenseTemplate/domain/entities/licenseModel";
import { ISequenceTemplateModel } from "../../../features/sequencesTemplate/domain/entities/sequencesModel";

export const SequencesDetailsPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const [sequencesDetails, setSequencesDetails] =
        useState<ISequenceTemplateModel>();
    const [licenseWithSequences, setLicenseWithSequences] =
        useState<ILicensePresentation[]>();
    const [numLicenses, setNumLicenses] = useState<number>();
    let { sequencesTemplateId } = useParams<any>();

    const { data: sequencesTemplateData } = useQuery<SequencesTemplateQuery>(
        fragments.GET_SEQUENCES_BY_ID,
        {
            variables: { sequencesTemplateId: sequencesTemplateId },
        }
    );

    useEffect(() => {
        setHeaderContent({
            title: t("sequencesDetails.exitHeader"),
            breadCrumb: [
                { label: t("sequencesDetails.exitHeader"), link: "/sequences" },
            ],
            goBackLinkOverride: "/sequences",
        });
    }, []);

    useEffect(() => {
        if (!sequencesTemplateData) return;
        setSequencesDetails(sequencesTemplateData.sequencesTemplate);
        setLicenseWithSequences(
            sequencesTemplateData.licensesForASequencesTemplate as ILicensePresentation[]
        );
        setNumLicenses(
            sequencesTemplateData.licensesForASequencesTemplate.length
        );
    }, [sequencesTemplateData]);

    return (
        <Content>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                    <SequencesDetailsDescription
                        sequencesDetails={sequencesDetails}
                        numLicenses={numLicenses}
                    />
                </Col>
                <Col xs={24} md={16}>
                    <SequencesDetailsLicenses
                        licenseWithSequences={licenseWithSequences}
                    />
                </Col>
            </Row>
        </Content>
    );
};
