import {
    ApolloQueryResult,
    OperationVariables,
    useMutation,
} from "@apollo/client";
import { Modal } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    OrganisationQuery, UnassignLicenseFeatureToOrgMutation,
} from "../../../../../generated/graphql";
import fragments from "../../../../../gql/fragments";
import { ResultModal } from "../../../../appCore/resultModal/resultModal";

interface IProps {
    orgId: string | undefined;
    licenseFeatureId: string | undefined;
    refetchOrganisationData?: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<OrganisationQuery>>;
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const UnassignLicenseFeatureModal = ({
    orgId,
    licenseFeatureId,
    refetchOrganisationData,
    isVisible,
    setIsVisible
}: IProps) => {
    const { t } = useTranslation();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);

    const [UnassignFeatureMutation] = useMutation<UnassignLicenseFeatureToOrgMutation>(
        fragments.UNASSIGN_LICENSE_FEATURE_TO_ORG
    );

    const onUnassignFeature = (licenseFeatureId: string, orgId: string) => {
        setIsResultModalVisible(true);
        UnassignFeatureMutation({
            variables: {
                licenseFeatureId: licenseFeatureId,
                orgId: orgId
            },
        }).then((res) => {
            setIsResultModalLoading(false);
            if (!!refetchOrganisationData) refetchOrganisationData();
        });
    };

    return (
        <>
            <Modal
                title={t("licenseFeature.unassign.modal.text")}
                visible={isVisible && !isResultModalVisible}
                cancelText={t("cancel")}
                onCancel={() => setIsVisible(false)}
                okText={t("licenseFeature.unassign.modal.okButton")}
                onOk={() => {
                    onUnassignFeature(licenseFeatureId!, orgId!);
                }}
            >
                <p>
                    {t("licenseFeature.unassign.modal.description")}
                </p>
            </Modal>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                onPrimary={() => {
                    setIsVisible(false);
                    setIsResultModalVisible(false);
                }}
                localisedTexts={{
                    title: t("licenseFeature.unassign.resultModal.title"),
                    subtitle: t("licenseFeature.unassign.resultModal.subtitle"),
                    primaryButton: t("licenseFeature.unassign.resultModal.close"),
                }}
                hasSecondary={false}
            />
        </>
    );
};