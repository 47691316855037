export enum PushNotificationType {
    UserCreated = "UserCreated",
    AdminCreated = "AdminCreated",
    CaseProgressed = "CaseProgressed",
    PasswordReset = "PasswordReset",
    SupportMessage = "SupportMessage",
    CaseCompleted = "CaseCompleted",
    FewCasesLeft = "FewCasesLeft",
    FewUsersLeft = "FewUsersLeft",
    ZipStateChanged = "ZipStateChanged",
}
