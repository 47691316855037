import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import {
    IUserLicenseModel,
} from "../entities/userLicenseModel";
import { UserLicenseDataSource } from "../../data/dataSource/userLicenseDataSource";
import { faro } from '@grafana/faro-web-sdk';

interface IAdditionalMethods {
    assignUserToUserLicense: (
        userLicenseId: string,
        userId: string
    ) => Promise<void>;
}

export const useAssignUserToUserLicense: IUseDomainHook<
    IUserLicenseModel | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = UserLicenseDataSource();
    const [userLicense, setUserLicense] = useState<
        IUserLicenseModel | undefined
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const assignUserToUserLicense = async (
        userLicenseId: string,
        userId: string
    ) => {
        setIsLoading(true);

        try {
            const result = await dataSource.assignUserToUserLicense(
                userLicenseId,
                userId
            );
            setUserLicense(result);
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: userLicense,
        isLoading,
        errorMessage,
        assignUserToUserLicense,
    };
};
