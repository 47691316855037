import { Card, Divider, Skeleton, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./basicInfoCard.module.scss";
import { Spacer } from "../../appCore/spacer/spacer";
import { IUserReducedDto } from "../../../features/user/domain/entities/userModel";

const { Title, Text } = Typography;

interface IProps {
    userDetails: IUserReducedDto | undefined;
}

export const BasicInfoCard = ({ userDetails }: IProps) => {
    const { t } = useTranslation();

    return (
        <Card>
            <div>
                <Title level={4}>{t("myProfile.basicInfo.title")}</Title>
            </div>
            <Spacer size="s" />

            {userDetails ? (
                <div className={styles.Data}>
                    <div>
                        <Text>{t("myProfile.basicInfo.name")}</Text>
                    </div>
                    <div>
                        <Text strong>
                            {userDetails?.firstName +
                                " " +
                                userDetails?.lastName}
                        </Text>
                    </div>
                </div>
            ) : (
                <Skeleton paragraph={false} active />
            )}
            <Divider />
            {userDetails ? (
                <div className={styles.Data}>
                    <div>
                        <Text>{t("myProfile.basicInfo.email")}</Text>
                    </div>
                    <div>
                        <Text strong>{userDetails?.email}</Text>
                    </div>
                </div>
            ) : (
                <Skeleton paragraph={false} active />
            )}
            <Divider />
            <div className={styles.InfoText}>
                <span className="kenko-icon-info" />
                <Text type="secondary">
                    {t("myProfile.basicInfo.infoText")}
                </Text>
            </div>
        </Card>
    );
};
