import { Button, Card, Divider, Form, Select, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TasksQuery } from "../../../../generated/graphql";
import {
    ISequencesCreationDto,
    IStepTemplate,
} from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import styles from "./taskInfo.module.scss";

const { Title } = Typography;

interface IProps {
    isDisabled: boolean;
    setSequencesDetails: Dispatch<
        SetStateAction<Partial<ISequencesCreationDto>>
    >;
    sequencesDetails: Partial<ISequencesCreationDto | undefined>;
    sequenceIndex: number;
    taskIndex: number;
    onRemoveTask: (removedTaskIndex: number) => void;
    isStepDelete: boolean;
    tasks: TasksQuery | undefined;
}

export const TaskInfo = ({
    isDisabled,
    setSequencesDetails,
    sequencesDetails,
    sequenceIndex,
    taskIndex,
    onRemoveTask,
    isStepDelete,
    tasks,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    useEffect(() => {
        if (
            !sequencesDetails ||
            !sequencesDetails.stepSequencesTemplate ||
            !sequencesDetails.stepSequencesTemplate[sequenceIndex] ||
            !sequencesDetails.stepSequencesTemplate[sequenceIndex]
                .stepTemplates ||
            !sequencesDetails.stepSequencesTemplate[sequenceIndex]
                .stepTemplates![taskIndex] ||
            sequencesDetails.stepSequencesTemplate[sequenceIndex]
                .stepTemplates![taskIndex].version === 0
        )
            return;
        form.setFieldsValue(
            sequencesDetails.stepSequencesTemplate[sequenceIndex]
                .stepTemplates![taskIndex]
        );
    }, [sequencesDetails]);

    const onChange = (
        changedValues: Partial<IStepTemplate>,
        values: Partial<IStepTemplate>
    ) => {
        if (
            !sequencesDetails ||
            !sequencesDetails.stepSequencesTemplate ||
            !sequencesDetails.stepSequencesTemplate[sequenceIndex].stepTemplates
        )
            return;

        // Hardcoding
        let type;
        if (values.unversionedEcsTaskName?.includes("matrix")) {
            type = "DataMatrix";
        } else if (values.unversionedEcsTaskName?.includes("algorithm")) {
            type = "Analysis";
        } else {
            type = "BioMarkers";
        }

        let newStepTemplate: IStepTemplate = {
            id: values.id!,
            unversionedEcsTaskName: values.unversionedEcsTaskName!,
            version: values.version!,
            type: type,
        };

        let newStepSequence = sequencesDetails.stepSequencesTemplate.map(
            (item, i) => {
                if (i === sequenceIndex) {
                    let newTemplate = item.stepTemplates?.map((step, j) => {
                        if (j === taskIndex) {
                            return newStepTemplate;
                        }
                        return step;
                    });
                    return {
                        sequenceType: item.sequenceType,
                        stepTemplates: newTemplate,
                    };
                }
                return item;
            }
        );

        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: [...newStepSequence],
        }));
    };

    const selectTasks: { [key: string]: any } = [];
    tasks?.tasks.tasks.forEach((task, i) => {
        selectTasks[i] = { key: task.key, value: task.value[0].name };
    });
    const optionsTasks = selectTasks.map((item: { key: any; value: any }) => ({
        label: item.key,
        value: item.value,
    }));

    const selectValues: number[] = [];
    tasks?.tasks.tasks.forEach((task, i) => {
        if (!selectValues.includes(Number(task.value[0].version))) {
            selectValues[i] = Number(task.value[0].version);
        }
    });
    const optionsVersions = selectValues.map((item) => ({
        label: item,
        value: item,
    }));

    return (
        <Card className={styles.Card}>
            <Form layout="vertical" onValuesChange={onChange} form={form}>
                <div className={styles.TitleBar}>
                    <Title level={4}>
                        {t("createSequences.addSequencesStep.title")}
                    </Title>
                    <Button
                        onClick={() => onRemoveTask(taskIndex)}
                        disabled={!isStepDelete}
                        type="text"
                    >
                        {t("createSequences.addSequencesStep.delete")}
                        <span
                            className={`kenko-icon-bin button-icon ${styles.Trash}`}
                        />
                    </Button>
                </div>

                <Divider />

                <div className={styles.Row}>
                    <div className={styles.Col}>
                        <Form.Item
                            label={t("createSequences.addSequencesStep.task")}
                            name="unversionedEcsTaskName"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={t("createSequences.select")}
                                optionFilterProp="children"
                                disabled={isDisabled}
                                options={optionsTasks}
                            />
                        </Form.Item>
                    </div>

                    <div className={styles.Col}>
                        <Form.Item
                            label={t(
                                "createSequences.addSequencesStep.version"
                            )}
                            name="version"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder={t("createSequences.select")}
                                optionFilterProp="children"
                                disabled={isDisabled}
                                options={optionsVersions}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <Form.Item
                        label={t("createSequences.addSequencesStep.parameter")}
                        name="parameter"
                    >
                        <TextArea
                            showCount
                            //disabled={isDisabled}
                            disabled={true}
                            maxLength={200}
                            style={{ height: 10, resize: "none" }}
                            placeholder={t(
                                "createSequences.addSequencesStep.parameterPrompt"
                            )}
                        />
                    </Form.Item>
                </div>
            </Form>
        </Card>
    );
};
