import { Col, Row } from "antd";
import { StepWithEditIconComponent } from "../../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { InviteInfoStep } from "../inviteInfoStep/inviteInfoStep";
import { ResultModal } from "../../../../components/appCore/resultModal/resultModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styles from "./userFormComponent.module.scss";
import {
    IUserLicenseCreate,
    IUserLicenseListPresentation,
    IUserLicenseModel,
} from "../../../userLicense/domain/entities/userLicenseModel";
import { AssignUserLicense } from "../assingUserLicense/assignUserLicense";

export interface IProps {
    isEditPage: boolean;
    updateUserName: (value: string | undefined) => void;
    updateUserSurname: (value: string | undefined) => void;
    updateUserEmail: (value: string | undefined) => void;
    updateUserLicenseUserId: (value: string | undefined | null) => void;
    updateCheckValue: (value: number | undefined) => void;
    checkValue: number | undefined;
    isResultModalVisible: boolean;
    isResultModalLoading: boolean;
    isResultModalError: boolean;
    titleModal: string;
    subtitleModal: string;
    titleStep: string;
    buttonText: string;
    userLicensesWithoutUser: IUserLicenseListPresentation | undefined;
    remainingCases: number | undefined;
    remainingUsers: number | undefined;
    updateUserLicenseNumCases: (value: number | undefined) => void;
    onCreateUser: () => void;
    onCreateComplete: () => void;
    createUserLicenseDetails: IUserLicenseCreate | undefined;
    email: string | undefined;
    onLoadMoreUserLicenses: (pageNumber: number) => void;
    onFilterUserLicense: (textInput: string) => void;
    textSearchLicenses: string;
    unfilteredLicenses: IUserLicenseModel[] | undefined;
    filteredLicenses: IUserLicenseModel[] | undefined;
}

export const UserFormComponent = ({
    isEditPage,
    updateUserLicenseUserId,
    updateUserName,
    updateUserSurname,
    updateUserEmail,
    updateCheckValue,
    checkValue,
    isResultModalError,
    isResultModalLoading,
    isResultModalVisible,
    userLicensesWithoutUser,
    remainingCases,
    updateUserLicenseNumCases,
    onCreateUser,
    onCreateComplete,
    createUserLicenseDetails,
    titleModal,
    subtitleModal,
    titleStep,
    buttonText,
    remainingUsers,
    email,
    onLoadMoreUserLicenses,
    onFilterUserLicense,
    textSearchLicenses,
    unfilteredLicenses,
    filteredLicenses,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isIconVisible, setIsIconVisible] = useState<boolean>(isEditPage);

    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };
    const updateCurrentStep = (value: number) => {
        setCurrentStep((prevStep) => prevStep + value);
    };
    const updateIsIconVisible = (value: boolean) => {
        setIsIconVisible(value);
    };
    const onShowUsers = () => {
        navigate("/users");
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        title={titleStep}
                        isComplete={isEditPage}
                        steps={[
                            {
                                title: t("invite.steps.1.title"),
                                description: t("invite.steps.1.description"),
                            },
                            {
                                title: t("invite.steps.2.title"),
                                description: t("invite.steps.2.description"),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                        isEditStep={isEditPage}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    {currentStep === 0 && !isEditPage && (
                        <InviteInfoStep
                            updateCurrentStep={updateCurrentStep}
                            updateIsIconVisible={updateIsIconVisible}
                            updateUserName={updateUserName}
                            updateUserSurname={updateUserSurname}
                            updateUserEmail={updateUserEmail}
                            isEditPage={isEditPage}
                            email={email}
                        />
                    )}
                    {currentStep === 1 && !isEditPage && (
                        <AssignUserLicense
                            updateCheckValue={updateCheckValue}
                            checkValue={checkValue}
                            updateUserLicenseUserId={updateUserLicenseUserId}
                            userLicensesWithoutUser={userLicensesWithoutUser}
                            remainingCases={remainingCases}
                            remainingUsers={remainingUsers}
                            updateUserLicenseNumCases={
                                updateUserLicenseNumCases
                            }
                            onCreateUser={onCreateUser}
                            onCreateComplete={onCreateComplete}
                            userLicenseDetails={createUserLicenseDetails}
                            buttonText={buttonText}
                            onLoadMoreUserLicenses={onLoadMoreUserLicenses}
                            textSearchLicenses={textSearchLicenses}
                            unfilteredLicenses={unfilteredLicenses}
                            filteredLicenses={filteredLicenses}
                            onFilterUserLicense={onFilterUserLicense}
                        />
                    )}
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowUsers}
                hasSecondary={!isEditPage}
                localisedTexts={{
                    title: titleModal,
                    subtitle: subtitleModal,
                    secondaryButton: t("invite.successModal.another"),
                    primaryButton: t("invite.successModal.viewList"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
        </>
    );
};
