import { useTranslation } from "react-i18next";
import { Spacer } from "../../../appCore/spacer/spacer";
import React, { Dispatch, SetStateAction } from "react";
import { Button, InputNumber, Form, Typography, Card, Divider } from "antd";
import { useForm } from "antd/es/form/Form";
import {
    IReloadOrgLicence,
    IOrganisationDetailsDto,
    IOrganisationLicense,
} from "../../../../features/organisation/domain/entities/organisationModel";
import { useNavigate } from "react-router-dom";
import styles from "./reloadOrgranisationLisence.module.scss";

interface IProps {
    organisationDetails?: IOrganisationDetailsDto | undefined;
    orgLicenseDetails: IOrganisationLicense | undefined;
    setReloadOrgLicense: Dispatch<
        SetStateAction<IReloadOrgLicence | undefined>
    >;
    onButtonClick?: () => void;
    isValidToReload: boolean;
    setIsValidToReload: Dispatch<SetStateAction<boolean>>;
}

const { Title, Text } = Typography;

export const ReloadOrganisationLicense = ({
    organisationDetails,
    orgLicenseDetails,
    setReloadOrgLicense,
    onButtonClick,
    isValidToReload,
    setIsValidToReload,
}: IProps) => {
    const [form] = useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onChange = () => {
        const values = form.getFieldsValue();
        setReloadOrgLicense({
            orgLicenseId: orgLicenseDetails?.id!,
            numberOfCases: values.numberOfCases,
            numberOfUsers: values.numberOfUsers,
            priceIncrement: values.priceIncrement,
        });
        if (
            (values.numberOfCases > 0 || values.numberOfUsers > 0) &&
            values.priceIncrement > 0
        ) {
            setIsValidToReload(true);
            return;
        }
        setIsValidToReload(false);
    };

    return (
        <>
            <Card bordered={true}>
                <Form
                    layout="vertical"
                    labelCol={{ lg: { span: 24 }, md: { span: 24 } }}
                    labelWrap={true}
                    labelAlign="left"
                    form={form}
                    onValuesChange={onChange}
                    initialValues={{
                        ["numberOfCases"]: 0,
                        ["numberOfUsers"]: 0,
                        ["priceIncrement"]: 0,
                    }}
                >
                    <div>
                        <Title level={4}>
                            {t("reloadOrgLicense.form.title")}
                        </Title>
                        <Text type="secondary">
                            {t("reloadOrgLicense.form.description")}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                className={styles.LabelForm}
                                label={t("reloadOrgLicense.form.orgName")}
                                name="nameOfLicense"
                            >
                                <Text strong={true}>
                                    {organisationDetails?.name}
                                </Text>
                            </Form.Item>
                            <Form.Item
                                className={styles.LabelForm}
                                label={t("reloadOrgLicense.form.licenseName")}
                                name="nameOfLicense"
                            >
                                <Text strong={true}>
                                    {
                                        orgLicenseDetails
                                            ?.licenseTemplateReference.name!
                                    }
                                </Text>
                            </Form.Item>
                        </div>
                    </div>

                    <Divider />

                    <div>
                        <Title level={4}>
                            {t("reloadOrgLicense.form.fields.title")}
                        </Title>
                        <Text type="secondary">
                            {t("reloadOrgLicense.form.fields.description")}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                className={styles.LabelForm}
                                label={t(
                                    "reloadOrgLicense.form.fields.numCases"
                                )}
                                name="numberOfCases"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            "reloadOrgLicense.form.fields.requiredNumCases"
                                        ),
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className={styles.InputForm}
                                    defaultValue={0}
                                    controls={false}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.Col}>
                            <Form.Item
                                className={styles.LabelForm}
                                label={t(
                                    "reloadOrgLicense.form.fields.numUsers"
                                )}
                                name="numberOfUsers"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            "reloadOrgLicense.form.fields.requiredNumUsers"
                                        ),
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className={styles.InputForm}
                                    defaultValue={0}
                                    controls={false}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                className={styles.LabelForm}
                                label={t(
                                    "reloadOrgLicense.form.fields.priceIncrement"
                                )}
                                name="priceIncrement"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            "reloadOrgLicense.form.fields.requiredPriceIncrement"
                                        ),
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className={styles.InputForm}
                                    defaultValue={0.0}
                                    controls={false}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.Col}></div>
                    </div>
                </Form>

                <Spacer size={"m"} />

                <div className={styles.ReloadLicenceButtonContainer}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!isValidToReload}
                        onClick={
                            onButtonClick
                                ? onButtonClick
                                : () => {
                                      return;
                                  }
                        }
                    >
                        {t("reloadOrgLicense.reloadButton")}
                    </Button>
                </div>
            </Card>
        </>
    );
};
