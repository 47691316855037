import "./antdTheme/antdThemeGenerated.css";
import "./App.scss";

import { AppLayout } from "./components/appLayout/appLayout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CreateCasePage } from "./pages/createCasePage/createCasePage";
import CaseListPage from "./pages/caseListPage/caseListPage";
import MyProfilePage from "./pages/myProfilePage/myProfilePage";
import SupportPage from "./pages/supportPage/supportPage";
import ErrorPage from "./pages/errorPage/errorPage";
import CaseDetailsPage from "./pages/caseDetailsPage/caseDetailsPage";
import { getMainDefinition } from "@apollo/client/utilities";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    MsalAuthenticationTemplate,
} from "@azure/msal-react";
import "./kenko-font-icons/style.css";
import {
    InteractionRequiredAuthError,
    InteractionType,
    PublicClientApplication,
} from "@azure/msal-browser";
import { HeaderProvider } from "./provider/headerProvider";
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
    from,
    split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";

import { loginRequest, msalConfig } from "./authConfig";
import { setContext } from "@apollo/client/link/context";
import { CaseVisualizerPage } from "./pages/caseVisualizerPage/caseVisualizerPage";
import { useProfile } from "./hooks/profileHook";
import { RoleType } from "./models/core/profileModel";
import { ProfileFetcher } from "./components/appCore/profileFetcher/profileFetcher";
import { OrganisationListPage } from "./pages/adminPages/organisationListPage/organisationListPage";
import { OrganisationDetailsPage } from "./pages/adminPages/organisationDetailsPage/organisationDetailsPage";
import { LicenseListPage } from "./pages/adminPages/licenseListPage/licenseListPage";
import { CreateLicensePage } from "./pages/adminPages/createLicensePage/createLicensePage";
import { LicenseDetailsPage } from "./pages/adminPages/licenseDetailsPage/licenseDetailsPage";
import { CreateOrganisationPage } from "./pages/adminPages/createOrganisationPage/createOrganisationPage";
import { OrganisationCaseListPage } from "./pages/organisationAdminPages/organisationCaseListPage/organisationCaseListPage";
import { MyAdminProfilePage } from "./pages/organisationAdminPages/myAdminProfilePage/myAdminProfilePage";
import { StatisticsPage } from "./pages/organisationAdminPages/statisticsPage/statisticsPage";
import { UserListPage } from "./pages/organisationAdminPages/userListPage/userListPage";
import { InviteUserPage } from "./pages/organisationAdminPages/iniviteUserPage/inviteUserPage";
import { EditOrganisationPage } from "./pages/adminPages/editOrganisationPage/editOrganisationPage";
import OrganisationSupportPage from "./pages/organisationAdminPages/organisationSupportPage/organisationSupportPage";
import { CreateSequencesPage } from "./pages/adminPages/createSequencesPage/createSequencesPage";
import { SequencesListPage } from "./pages/adminPages/sequencesListPage/sequencesListPage";
import { SequencesDetailsPage } from "./pages/adminPages/sequencesDetailsPage/sequencesDetailsPage";
import { EditSequencesPage } from "./pages/adminPages/editSequencesPage/editSequencesPage";
import { EditLicensePage } from "./pages/adminPages/editLicensePage/editLicensePage";
import { ReloadOrgLicenceCasesUsersPage } from "./pages/adminPages/reloadOrgLicenceUsersCasesPage/reloadOrgLicenceUsersCasesPage";
import { UserLicenseListPage } from "./pages/organisationAdminPages/userLicenseListPage/userLicenseListPage";
import { CreateUserLicensePage } from "./pages/organisationAdminPages/createUserLicensePage/createUserLicensePage";
import { EditUserPage } from "./pages/organisationAdminPages/editUserPage/editUserPage";
import { ReloadUserLicensePage } from "./pages/organisationAdminPages/reloadUserLicensePage/reloadUserLicensePage";
import { AssignUserToUserLicensePage } from "./pages/organisationAdminPages/assignUserToUserLicensePage/assignUserToUserLicensePage";
import { AssignLicenceFeaturesPage } from "./pages/adminPages/assignLicenseFeaturesPage/assignLicenseFeaturePage";
import { LicenseFeatureListPage } from "./pages/adminPages/licenseFeatureListPage/licenseFeatureListPage";
import { LicenseFeatureDetailsPage } from "./pages/adminPages/licenseFeatureDetailsPage/licenseFeatureDetailsPage";
import { CreateLicenseFeaturePage } from "./pages/adminPages/createLicenseFeaturePage/createLicenseFeaturePage";
import { EditLicenseFeaturePage } from "./pages/adminPages/editLicenseFeaturePage/editLicenseFeaturePage";

const withToken = setContext(async (_, { headers }) => {
    const token = await AsyncTokenLookup();

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null,
        },
    };
});
//TODO Change it for HotChocolate 13
/*const wsLink = new GraphQLWsLink(createClient({
    
    url: process.env.REACT_APP_GRAPHQL_WS_URL!,
    on: {
        connected: () => {
            console.log('connected');
        },
        error: (e) => {
            console.log(e);
        },
    },
}));*/

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_URL!,
    options: {
        reconnect: true,
    },
});
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL!,
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);

        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    link: from([withToken, splitLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
        },
    },
});

const instance = new PublicClientApplication(msalConfig);

const accounts = instance.getAllAccounts();
instance.setActiveAccount(accounts[0]);

var request = {
    scopes: [process.env.REACT_APP_LOGIN_REQUEST_SCOPES!],
    account: instance.getAllAccounts()[0],
};

export const AsyncTokenLookup = async () => {
    const account = accounts[0];
    if (account) {
        try {
            const result = await instance.acquireTokenSilent(request);
            return result.accessToken;
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(loginRequest);
            }
        }
    } else if (!account) {
        return instance.acquireTokenRedirect(loginRequest);
    }
};

const UserRoutes = () => (
    <Routes>
        <Route path="/" element={<CaseListPage />} />
        <Route path="/createCase" element={<CreateCasePage />} />
        <Route path="/case/:caseId" element={<CaseDetailsPage />} />
        <Route path="/visualizer/:caseId" element={<CaseVisualizerPage />} />
        <Route path="/myProfile" element={<MyProfilePage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="*" element={<ErrorPage />} />
    </Routes>
);

const AdminRoutes = () => (
    <Routes>
        <Route path="/" element={<OrganisationListPage />} />
        <Route
            path="/organisation/:organisationId"
            element={<OrganisationDetailsPage />}
        />
        <Route path="/licenses" element={<LicenseListPage />} />
        <Route path="/license/:licenseId" element={<LicenseDetailsPage />} />
        <Route path="/createLicense" element={<CreateLicensePage />} />
        <Route path="/editLicense/:licenseId" element={<EditLicensePage />} />
        <Route
            path="/createOrganisation"
            element={<CreateOrganisationPage />}
        />
        <Route
            path="/editOrganisation/:organisationId"
            element={<EditOrganisationPage />}
        />
        <Route path="/sequences" element={<SequencesListPage />} />
        <Route
            path="/sequence/:sequencesTemplateId"
            element={<SequencesDetailsPage />}
        />
        <Route path="/createSequences" element={<CreateSequencesPage />} />
        <Route
            path="/editSequence/:sequencesTemplateId"
            element={<EditSequencesPage />}
        />
        <Route
            path="/reloadOrgLicense/:organisationId"
            element={<ReloadOrgLicenceCasesUsersPage />}
        />
        <Route
            path="/assignLicenseFeatures/:organisationId"
            element={<AssignLicenceFeaturesPage />}
        />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/features" element={<LicenseFeatureListPage />} />
        <Route path="/feature/:featureId" element={<LicenseFeatureDetailsPage />} />
        <Route path="/createFeature" element={<CreateLicenseFeaturePage />} />
        <Route path="/editFeature/:featureId" element={<EditLicenseFeaturePage />} />

    </Routes>
);

const OrganisationAdminRoutes = () => (
    <Routes>
        <Route path="/" element={<OrganisationCaseListPage />} />
        <Route path="/case/:caseId" element={<CaseDetailsPage isOrgAdmin />} />
        <Route path="/visualizer/:caseId" element={<CaseVisualizerPage isOrgAdmin/>} />
        <Route path="/statistics" element={<StatisticsPage />} />
        <Route path="/myProfile" element={<MyAdminProfilePage />} />
        <Route path="/users" element={<UserListPage />} />
        <Route path="/userLicenses" element={<UserLicenseListPage />} />
        <Route path="/createuserlicense" element={<CreateUserLicensePage />} />
        <Route
            path="/reloadUserLicense/:userLicenseId"
            element={<ReloadUserLicensePage />}
        />
        <Route
            path="/assignUser/:userLicenseId"
            element={<AssignUserToUserLicensePage />}
        />
        <Route path="/invite" element={<InviteUserPage />} />
        <Route path="/editUser/:userId" element={<EditUserPage />} />
        <Route path="/support" element={<OrganisationSupportPage />} />
        <Route path="*" element={<ErrorPage />} />
    </Routes>
);

const App = () => {
    const { role } = useProfile();

    return (
        <>
            <AuthenticatedTemplate>
                <ApolloProvider client={client}>
                    <Router>
                        <HeaderProvider>
                            <AppLayout>
                                <>
                                    {role === RoleType.User ? (
                                        <UserRoutes />
                                    ) : role === RoleType.Admin ? (
                                        <AdminRoutes />
                                    ) : role === RoleType.OrganisationAdmin ? (
                                        <OrganisationAdminRoutes />
                                    ) : (
                                        <ProfileFetcher />
                                    )}
                                </>
                            </AppLayout>
                        </HeaderProvider>
                    </Router>
                </ApolloProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                />
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
