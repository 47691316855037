import { gql } from "@apollo/client";

const GET_LICENSE_BY_ID = gql`
    query license($licenseId: String!) {
        licenseTemplate(licenseId: $licenseId) {
            id
            name
            totalPrice
            organ
            maxNumberOfCases
            maxNumberOfUsers
            sequencesTemplate {
                id
                name
            }
            memoryLimits {
                currentEcsMemorySize
                maxEcsMemorySize
            }
            numberOfRemainingCases
            numberOfRemainingUsers
            distributor {
                name
                unversionedDistributorName
                version
            }
        }
        organisationsForALicense(licenseId: $licenseId) {
            id
            name
            email
        }
    }
`;

const GET_LICENSE_TEMPLATES = gql`
    query getLicenses($input: GetLicenseTemplateInput!) {
        licenses(input: $input) {
            licensePresentations {
                id
                name
                totalPrice
                organ
                numberOfRemainingCases
                numberOfRemainingUsers
                sequencesTemplate {
                    id
                    name
                }
                maxNumberOfCases
                maxNumberOfUsers
                distributor {
                    name
                    version
                    unversionedDistributorName
                }
                memoryLimits {
                    currentEcsMemorySize
                    maxEcsMemorySize
                }
            }
            totalResults
        }
    }
`;

const CREATE_LICENSE_TEMPLATE = gql`
    mutation addLicenseTemplate(
        $name: String!
        $totalPrice: Decimal!
        $organ: String!
        $maxNumberOfCases: Int!
        $maxNumberOfUsers: Int!
        $sequencesTemplateId: String!
        $distributor: DistributorTypeInput!
        $memoryLimits: MemoryLimitsInput!
    ) {
        addLicenseTemplate(
            templateInput: {
                name: $name
                totalPrice: $totalPrice
                organ: $organ
                maxNumberOfCases: $maxNumberOfCases
                maxNumberOfUsers: $maxNumberOfUsers
                sequencesTemplateId: $sequencesTemplateId
                distributor: $distributor
                memoryLimits: $memoryLimits
            }
        ) {
            id
            sequencesTemplate {
                id
                name
            }
        }
    }
`;

const UPDATE_LICENSE = gql`
    mutation updateLicense($templateInput: EditLicenseTemplateInput!) {
        updateLicenseTemplate(templateInput: $templateInput) {
            id
            name
            organ
            totalPrice
            maxNumberOfCases
            maxNumberOfUsers
            sequencesTemplate {
                id
                name
            }
        }
    }
`;

const DELETE_LICENSE = gql`
    mutation deleteLicense($id: String!) {
        deleteLicenseTemplate(id: $id) {
            id
            name
        }
    }
`;

export const licenseFragments = {
    GET_LICENSE_BY_ID,
    GET_LICENSE_TEMPLATES,
    CREATE_LICENSE_TEMPLATE,
    UPDATE_LICENSE,
    DELETE_LICENSE,
};
