import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row, Typography } from "antd";
import { CaseProcessing } from "./caseProcessing/caseProcesssing";
import { FolderDisplay } from "./folderDisplay/folderDisplay";
import { CaseProcessingError } from "./caseProcessingError/caseProcessingError";
import { useNavigate } from "react-router-dom";
import { CaseUploadError } from "./caseUploadError/caseUploadError";
import { CaseValidating } from "./caseValidating/caseValidating";
import { useEffect, useState } from "react";
import {
    ICaseDetails,
    StatusType,
} from "../../../features/case/domain/entities/case";
import { ISequence } from "../../../features/case/domain/entities/sequence";
import { ISequenceImages } from "../../../features/case/domain/entities/modelImages";
import { useImagesByCase } from "../../../features/case/domain/hooks/useImagesByCase";
import { useSequencesByCase } from "../../../features/case/domain/hooks/useSequenceByCase";
import visualizerService from "../../../services/visualizerService";

const { Title } = Typography;

const ALLOW_STATUS = [
    StatusType.Finished,
    StatusType.Closed,
    StatusType.Incomplete,
];

interface IProps {
    caseId: string | undefined;
    caseDetails: ICaseDetails | undefined;
    isOrgAdmin: boolean;
}

export const CaseDetailsFolders = ({
    caseId,
    caseDetails,
    isOrgAdmin,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    //===============HOOKS==================
    const {
        data: sequencesData,
        isLoading: loadingSequences,
        getSequencesByCase,
    } = useSequencesByCase();
    const {
        data: sequenceImages,
        isLoading: loadingImages,
        getImagesByCase,
    } = useImagesByCase();

    const [currentSequencesData, setCurrentSequencesData] = useState<
        ISequence[]
    >([]);
    const [currentSequenceImages, setCurrentSequenceImages] =
        useState<ISequenceImages>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // TODO: openVisualizer disable while loading, enabled once loaded
    //       (Do this once the visualizer is complete)

    const getCookiesAndImages = async (caseId: string) => {
        await visualizerService.getCookies(caseId);
        getImagesByCase(caseId);
    };

    useEffect(() => {
        if (!caseDetails || !caseId) return;
        let isNotValid = ALLOW_STATUS.indexOf(caseDetails.state) !== -1;
        if (!isNotValid) return;
        getCookiesAndImages(caseId);
        getSequencesByCase(caseId);
    }, [caseDetails, caseId]);

    useEffect(() => {
        if (!sequenceImages) return;
        setCurrentSequenceImages(sequenceImages);
    }, [sequenceImages]);

    useEffect(() => {
        if (!sequencesData) return;
        setCurrentSequencesData(sequencesData);
    }, [sequencesData]);

    useEffect(() => {
        if (!loadingImages && !loadingSequences) {
            setIsLoading(false);
        }
    }, [caseDetails, loadingImages, loadingSequences]);

    let isAvailable = true;
    if (!!caseDetails) {
        isAvailable = ALLOW_STATUS.indexOf(caseDetails.state) !== -1;
    }

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Title level={4}>
                        {t("caseDetails.resultFolders.title")}{" "}
                    </Title>
                </Col>
                <Col>
                    {!!caseDetails && (
                        <Button
                            type="primary"
                            disabled={!isAvailable || isLoading}
                            onClick={() => {
                                navigate("/visualizer/" + caseId, {
                                    state: {
                                        sequences: currentSequencesData,
                                        images: currentSequenceImages,
                                    },
                                });
                            }}
                        >
                            {t("caseDetails.resultFolders.openVisualizer")}
                            <span className="kenko-icon-image button-icon"></span>
                        </Button>
                    )}
                </Col>
            </Row>
            <Card>
                {caseDetails &&
                    (caseDetails.state === StatusType.Created ? (
                        <CaseUploadError caseId={caseId} />
                    ) : caseDetails.state === StatusType.Processing ? (
                        <CaseProcessing percentage={caseDetails.percentage} />
                    ) : caseDetails.state === StatusType.Ready ? (
                        <CaseValidating />
                    ) : caseDetails.state === StatusType.Error ? (
                        <CaseProcessingError />
                    ) : (
                        <FolderDisplay
                            caseId={caseId}
                            caseDetails={caseDetails}
                            currentSequencesData={currentSequencesData}
                            currentSequenceImages={currentSequenceImages}
                            isLoading={loadingImages || loadingSequences}
                            isOrgAdmin={isOrgAdmin}
                        />
                    ))}
            </Card>
        </>
    );
};
