import { Button, Card, Divider, Form, Select, Typography } from "antd";
import { Dispatch, Key, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Spacer } from "../../../appCore/spacer/spacer";
import { SequencesExpandedTable } from "../../sequencesListComponents/sequencesExpandedTable";
import styles from "./licenseAddSequenceStep.module.scss";
import { ISequencesTemplateTable } from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import {
    ILicenseTemplateModel,
    IMemoryLimits,
} from "../../../../features/licenseTemplate/domain/entities/licenseModel";

const { Title, Text } = Typography;

interface IProps {
    sequencesTemplateList: ISequencesTemplateTable[];
    setLicenseDetails: Dispatch<SetStateAction<ILicenseTemplateModel>>;
    licenseDetails: ILicenseTemplateModel;
    onFinish: () => void;
    textButton: string;
    sequencesIdKey: Key | undefined;
    setSequencesIdKey: Dispatch<SetStateAction<Key | undefined>>;
    isFormValid?: boolean;
    memoryLimitsDetails: IMemoryLimits;
    setMemoryLimitsDetails: Dispatch<SetStateAction<IMemoryLimits>>;
    totalResults: number | undefined;
    onPaginate: (pageNumber: number) => void;
    elementsPage: number;
}

interface options {
    value: number;
    label: string;
}

export const LicenseAddSequenceStep = ({
    sequencesTemplateList,
    setLicenseDetails,
    licenseDetails,
    onFinish,
    textButton,
    sequencesIdKey,
    setSequencesIdKey,
    isFormValid,
    memoryLimitsDetails,
    setMemoryLimitsDetails,
    totalResults,
    onPaginate,
    elementsPage,
}: IProps) => {
    const { t } = useTranslation();
    //Memory size limits in GB
    const MIN_MEMORY_SIZE = 8;
    const MAX_MEMORY_SIZE = 30;
    const MBs_IN_1GB = 1024;
    const [isMemoryValid, setIsMemoryValid] = useState<boolean>();

    useEffect(() => {
        if (!licenseDetails) return;
        checkIsCurrentMemoryLowerOrEqualThanMax(licenseDetails.memoryLimits);
    }, [licenseDetails]);

    const memoryOptions = () => {
        let memoryValues: options[] = [];

        for (let i = MIN_MEMORY_SIZE; i <= MAX_MEMORY_SIZE; i++) {
            memoryValues.push({ value: i * MBs_IN_1GB, label: i.toString() });
        }
        return memoryValues;
    };

    const onCurrentMemoryChange = (value: number) => {
        setMemoryLimitsDetails({
            ...memoryLimitsDetails,
            currentEcsMemorySize: value,
        });
        let memoryLimits: IMemoryLimits = {
            maxEcsMemorySize: memoryLimitsDetails.maxEcsMemorySize,
            currentEcsMemorySize: value,
        };
        setLicenseDetails({ ...licenseDetails, memoryLimits });
    };

    const onMaxMemoryChange = (value: number) => {
        setMemoryLimitsDetails({
            ...memoryLimitsDetails,
            maxEcsMemorySize: value,
        });
        let memoryLimits: IMemoryLimits = {
            currentEcsMemorySize: memoryLimitsDetails.currentEcsMemorySize,
            maxEcsMemorySize: value,
        };
        setLicenseDetails({ ...licenseDetails, memoryLimits });
    };

    const checkIsCurrentMemoryLowerOrEqualThanMax = (
        memoryLimits: IMemoryLimits
    ) => {
        if (memoryLimits.currentEcsMemorySize! > memoryLimits.maxEcsMemorySize!)
            setIsMemoryValid(false);
        else setIsMemoryValid(true);
    };

    return (
        <Card>
            <Form layout="vertical">
                <div>
                    <Title level={4}>
                        {t("createLicense.infoStep.sequenceInformation")}
                    </Title>
                    <Text type="secondary">
                        {t(
                            "createLicense.infoStep.sequenceInformationSubtitle"
                        )}
                    </Text>
                </div>

                <Spacer size={"m"} />

                <SequencesExpandedTable
                    sequencesTemplateList={sequencesTemplateList}
                    isSequenceListPage={false}
                    setLicenseDetails={setLicenseDetails}
                    sequencesIdKey={sequencesIdKey}
                    setSequencesIdKey={setSequencesIdKey}
                    onPaginate={onPaginate}
                    totalResults={totalResults}
                    elementsPage={elementsPage}
                />

                <Divider />
                <Spacer size={"s"} />

                <div>
                    <Title level={4}>
                        {t("createLicense.addMemory.title")}
                    </Title>
                    <Text type="secondary">
                        {t("createLicense.addMemory.description")}
                    </Text>
                </div>

                <Spacer size={"s"} />

                <div className={styles.Row}>
                    <div className={styles.Col}>
                        <Form.Item
                            label={t("createLicense.addMemory.defaultMemory")}
                            name="currenMemory"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            initialValue={
                                licenseDetails.memoryLimits
                                    .currentEcsMemorySize! / MBs_IN_1GB
                            }
                        >
                            <Select
                                key={1}
                                options={memoryOptions()}
                                onChange={onCurrentMemoryChange}
                            />
                        </Form.Item>
                    </div>

                    <div className={styles.Col}>
                        <Form.Item
                            label={t("createLicense.addMemory.maxMemory")}
                            name="maxMemory"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            initialValue={
                                licenseDetails.memoryLimits.maxEcsMemorySize! /
                                MBs_IN_1GB
                            }
                        >
                            <Select
                                key={2}
                                options={memoryOptions()}
                                onChange={onMaxMemoryChange}
                            />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item className={styles.StepNavigation}>
                    <Button
                        type="primary"
                        onClick={onFinish}
                        disabled={!isFormValid || !isMemoryValid}
                    >
                        {textButton}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
