import { Dispatch, SetStateAction } from "react";

export const loadMore = (
    e: React.UIEvent<HTMLDivElement>,
    selectList: any,
    entityList: any,
    page: number,
    setPage: Dispatch<SetStateAction<Partial<number>>>,
    onLoadMore: (pageNumber: number) => void
) => {
    const element = e.currentTarget;
    const isAtBottom =
        element.scrollTop + element.clientHeight === element.scrollHeight;

    if (!entityList || !selectList) return;
    if (isAtBottom && selectList?.length < entityList?.totalResults) {
        setPage(page + 1);
        onLoadMore(page + 1);
    }
};
