import { IVisualizerWindow } from "../../../domain/entities/modelImages";
import {
    ISequenceSliceController,
    ISequenceSliceReference,
} from "../../../domain/entities/sequence";
import styles from "./visualizerSlider.module.scss";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

interface IProps {
    activeSlice: number;
    sliceCount: number;
    isSingleImage: boolean;
    sequenceSliceReference: ISequenceSliceReference;
    setSequenceSliceReference: Dispatch<
        SetStateAction<ISequenceSliceReference>
    >;
    isMultiplanar: boolean;
    visualizerWindow: IVisualizerWindow;
    sequenceSliceController: ISequenceSliceController;
    setSequenceSliceController: Dispatch<
        SetStateAction<ISequenceSliceController>
    >;
}

export const VisualizerSlider = ({
    activeSlice,
    sliceCount,
    isSingleImage,
    sequenceSliceReference,
    setSequenceSliceReference,
    isMultiplanar,
    sequenceSliceController,
    setSequenceSliceController,
    visualizerWindow,
}: IProps) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        let targetSlice = parseInt(e.target.value);

        if (isMultiplanar) {
            sequenceSliceController[visualizerWindow.activeType][
                visualizerWindow.activeView
            ].activeSlice = targetSlice;
            setSequenceSliceController({ ...sequenceSliceController });
        }

        if (visualizerWindow.activeType !== sequenceSliceReference.type)
            targetSlice = Math.round(
                sequenceSliceReference.sliceCount *
                    (parseInt(e.target.value) /
                        sequenceSliceController[visualizerWindow.activeType][
                            visualizerWindow.activeView
                        ].sliceCount)
            );

        if (visualizerWindow.activeView === "axial")
            setSequenceSliceReference({
                ...sequenceSliceReference,
                activeSlice: targetSlice,
            });
    };

    return (
        <div className={styles.Container} hidden={isSingleImage}>
            <input
                type="range"
                step={1}
                min={1}
                max={sliceCount}
                value={activeSlice}
                onChange={onChange}
                className={styles.Slider}
                id="myRange"
            />
            <div className={styles.StepBar}>
                {sliceCount < 150
                    ? [...Array(sliceCount)].map((_, i) => (
                          <span className={styles.Step} key={i}></span>
                      ))
                    : [...Array(150)].map((_, i) => (
                          <span className={styles.Step} key={i}></span>
                      ))}
            </div>
        </div>
    );
};
