import { Badge, Popover } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./statusBadge.module.scss";
import { CaseStatusType } from "../../../features/case/domain/entities/case";

interface IProps {
    status: CaseStatusType;
    popover?: boolean;
}
interface IBadgeConfig {
    color: string;
    warningText?: boolean;
    status: CaseStatusType;
}

const badgeConfig: IBadgeConfig[] = [
    {
        color: "#FA8C16",
        warningText: true,
        status: CaseStatusType.Created,
    },
    {
        color: "#FDE169",
        status: CaseStatusType.Ready,
    },
    {
        color: "#38B6FF",
        status: CaseStatusType.Processing,
    },
    {
        color: "#D12E2E",
        warningText: true,
        status: CaseStatusType.Error,
    },
    {
        color: "#64C255",
        status: CaseStatusType.Finished,
    },
    {
        color: "#D12E2E",
        status: CaseStatusType.Incomplete,
    },
    {
        color: "#A9B6BF",
        status: CaseStatusType.Closed,
    },
];

export const StatusBadge = ({ status, popover = true }: IProps) => {
    const { t } = useTranslation();
    const config = badgeConfig.find((element) => element.status === status);

    if (!config) {
        return <></>;
    }

    return (
        <Badge
            color={config.color}
            text={
                <span>
                    {t("status." + CaseStatusType[config.status].toLowerCase())}
                    {config.warningText && popover && (
                        <Popover
                            content={
                                <p className={styles.Popover}>
                                    {t(
                                        "status.warning." +
                                            CaseStatusType[
                                                config.status
                                            ].toLowerCase()
                                    )}
                                </p>
                            }
                        >
                            <span
                                className="kenko-icon-warning button-icon"
                                style={{ color: config.color }}
                            />
                        </Popover>
                    )}
                </span>
            }
        />
    );
};
