import { Button, Card, Form, Input, Typography } from "antd";
import { Spacer } from "../../../appCore/spacer/spacer";
import styles from "./myAdminProfileDetails.module.scss";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    OrganisationQuery,
    UpdateUserMutation,
} from "../../../../generated/graphql";
import { useForm } from "antd/es/form/Form";
import {
    ApolloQueryResult,
    OperationVariables,
    useMutation,
} from "@apollo/client";
import fragments from "../../../../gql/fragments";
import { ResultModal } from "../../../appCore/resultModal/resultModal";

const { Title, Text } = Typography;

interface IProps {
    setIsChangePasswordModalVisible: Dispatch<SetStateAction<boolean>>;
    getOrganisationData: OrganisationQuery | undefined;
    refetchOrganisationData: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<OrganisationQuery>>;
}

interface IUserForm {
    name: string;
    surname: string;
}

export const MyAdminProfileDetails = ({
    setIsChangePasswordModalVisible,
    getOrganisationData,
    refetchOrganisationData,
}: IProps) => {
    const { t } = useTranslation();
    let { admin } = getOrganisationData?.organisation || {};
    const [form] = useForm();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<IUserForm>();
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(true);

    useEffect(() => {
        if (!admin) return;
        form.setFieldsValue({
            name: admin.name,
            surname: admin.surname,
            email: admin.email,
        });
    }, [admin]);

    const checkIsFormValid = (values: IUserForm | undefined) => {
        return (
            !!values &&
            !!admin &&
            values.name !== undefined &&
            values.name !== "" &&
            values.surname !== undefined &&
            values.surname !== "" &&
            (values.surname !== admin.surname || values.name !== admin.name)
        );
    };

    const onChange = (
        changedValues: IUserForm | undefined,
        values: IUserForm | undefined
    ) => {
        if (!values) {
            setIsFormValid(false);
            return;
        }
        setIsFormValid(checkIsFormValid(values));
        if (checkIsFormValid(values)) {
            setFormValues(values);
        }
    };

    const [UpdateUserMutation] = useMutation<UpdateUserMutation>(
        fragments.UPDATE_USER
    );

    const sendChanges = () => {
        if (!admin) return;
        if (!formValues) return;
        if (!checkIsFormValid(formValues)) return;

        setIsSuccessModalVisible(true);
        UpdateUserMutation({
            variables: {
                id: admin.id,
                name: formValues.name,
                surname: formValues.surname,
            },
        }).then((res) => {
            // TODO: Check if res is empty, if it is, set error
            setIsSuccessModalLoading(false);
            refetchOrganisationData();
            setIsFormValid(false);
        }); // TODO: Catch error and set error state for the modal
    };

    return (
        <>
            <Card>
                <Form layout="vertical" form={form} onValuesChange={onChange}>
                    <div>
                        <Title level={4}>
                            {t("myAdminProfile.profileDetails.title")}
                        </Title>
                        <Text type="secondary">
                            {t("myAdminProfile.profileDetails.subtitle")}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t(
                                    "myAdminProfile.profileDetails.nameTitle"
                                )}
                                name="name"
                            >
                                <Input
                                    placeholder={t(
                                        "myAdminProfile.profileDetails.namePrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.Col}>
                            <Form.Item
                                label={t(
                                    "myAdminProfile.profileDetails.emailTitle"
                                )}
                                name="email"
                            >
                                <Input
                                    placeholder={t(
                                        "myAdminProfile.profileDetails.emailPrompt"
                                    )}
                                    disabled
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Spacer size={"xs"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t(
                                    "myAdminProfile.profileDetails.lastNameTitle"
                                )}
                                name="surname"
                            >
                                <Input
                                    placeholder={t(
                                        "myAdminProfile.profileDetails.lastNamePrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.Col}>
                            <Form.Item
                                label={t(
                                    "myAdminProfile.profileDetails.passwordTitle"
                                )}
                                name="password"
                            >
                                <>
                                    <Input
                                        placeholder={t(
                                            "myAdminProfile.profileDetails.passwordPrompt"
                                        )}
                                        disabled
                                        type="password"
                                    />
                                    <Spacer size="xs" />
                                    <a
                                        className={styles.Link}
                                        onClick={() =>
                                            setIsChangePasswordModalVisible(
                                                true
                                            )
                                        }
                                    >
                                        {t(
                                            "myAdminProfile.passwordChange.button"
                                        )}
                                    </a>
                                </>
                            </Form.Item>
                        </div>
                    </div>

                    <div>
                        <Button
                            size="large"
                            type="primary"
                            className={styles.ChangeButton}
                            onClick={sendChanges}
                            disabled={!isFormValid}
                        >
                            {t("myAdminProfile.profileDetails.save")}
                        </Button>
                    </div>
                </Form>
            </Card>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                onPrimary={() => {
                    setIsSuccessModalVisible(false);
                    setIsSuccessModalLoading(true);
                }}
                hasSecondary={false}
                localisedTexts={{
                    title: t("myAdminProfile.profileDetails.success.title"),
                    subtitle: t(
                        "myAdminProfile.profileDetails.success.subtitle"
                    ),
                    primaryButton: t(
                        "myAdminProfile.profileDetails.success.back"
                    ),
                }}
            />
        </>
    );
};
