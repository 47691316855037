import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Input, InputNumber, Row, Typography } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./createAndEditLicenseFeatureForm.module.scss";
import { useForm } from "antd/es/form/Form";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import { ILicenseFeature, ILicenseFeatureInput } from "../../domain/entities/licenseFeature";
import TextArea from "antd/lib/input/TextArea";

const { Title, Text } = Typography;

interface IProps {
    featureId : string | undefined;
    licenseFeature?: ILicenseFeature | undefined;
    setLicenseFeature: Dispatch<
        SetStateAction<ILicenseFeature | undefined>
    > | undefined;
    setLicenseFeatureInput: Dispatch<
        SetStateAction<ILicenseFeatureInput | undefined>
    > | undefined;
    onButtonClick?: () => void;
    action: string;
}

export const CreateEditLicenseFeatureForm = ({
    featureId,
    licenseFeature,
    setLicenseFeature,
    setLicenseFeatureInput,
    onButtonClick,
    action,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [form] = useForm();

    const validateMessages = {
        required: t("fieldValidation.required"),
    };

    const checkIsFormValid = (input: ILicenseFeatureInput) => {
        return (
            input &&
            input.name !== undefined &&
            input.name !== "" &&
            input.price !== null &&
            input.price >= 0 &&
            input.environKey !== undefined &&
            input.environKey !== "" &&
            input.environValue !== undefined &&
            input.environValue !== "" &&
            input.description !== undefined &&
            input.description !== ""
        );
    };

    const onChange = (
        changedValues: ILicenseFeatureInput | undefined,
        values: ILicenseFeatureInput | undefined
    ) => {
        if (!values) return;
        setIsFormValid(checkIsFormValid(values));
        if (!checkIsFormValid(values)) return;

        if(featureId){
            setLicenseFeature!({
                id: featureId,
                name: values?.name!,
                description: values?.description!,
                environKey: values?.environKey!,
                environValue: values?.environValue!,
                price: values?.price!
            });
        }
        else{
            setLicenseFeatureInput!({
                name: values?.name!,
                description: values?.description!,
                environKey: values?.environKey!,
                environValue: values?.environValue!,
                price: values?.price!
            });
        }
        
    };

    useEffect(() => {
        if (!licenseFeature) return;
        form.setFieldsValue({
            name: licenseFeature.name,
            description: licenseFeature.description,
            environKey: licenseFeature.environKey,
            environValue: licenseFeature.environValue,
            price: licenseFeature.price,
        });
    }, [licenseFeature]);

    return (
        <>
            <Card>
                <Form
                    layout="vertical"
                    onValuesChange={onChange}
                    validateMessages={validateMessages}
                    form={form}
                >
                    <div>
                        <Title level={4}>
                            {t("licenseFeature."+action+".title")}
                        </Title>
                        <Text type="secondary">
                            {""}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("licenseFeature."+action+".form.name")}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                className={styles.Input}
                                    placeholder={""}
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("licenseFeature."+action+".form.price")}
                                name="price"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={styles.InputNumber}
                                    placeholder={""}
                                    min={0}
                                    defaultValue={0}
                                    controls={false} 
                                    type="number"
                                />
                            </Form.Item>
                            
                        </div>
                    </div>

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("licenseFeature."+action+".form.environKey")}
                                name="environKey"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    className={styles.Input}
                                    placeholder={""}
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("licenseFeature."+action+".form.environValue")}
                                name="environValue"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    className={styles.Input}
                                    placeholder={""}
                                />
                            </Form.Item>
                            
                        </div>
                    </div>

                    <Row>
                        <Col xs={24} md={24}>
                        <Form.Item
                                label={t("licenseFeature."+action+".form.description")}
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <TextArea
                                    placeholder={t("licenseFeature."+action+".form.descriptionPlaceholder")}
                                    maxLength={200}
                                    showCount
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Spacer size={"s"} />

                    <Form.Item className={styles.StepNavigation}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!isFormValid}
                            onClick={
                                onButtonClick
                            }
                        >
                            {t("licenseFeature."+action+".executeButton")}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};
