import { Form, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import {
    IUserBasicInfo,
    IUserListPresentation,
} from "../../../user/domain/entities/userModel";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import styles from "./chooseUser.module.scss";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/lib/select";
import { FilteredSelect } from "../../../user/presentation/filteredSelect/filteredSelect";

const { Title, Text } = Typography;

interface IProps {
    usersWithoutLicense: IUserListPresentation | undefined;
    updateUserLicenseUserId: (value: string | undefined) => void;
    updateIsFormValid: (value: boolean) => void;
    updatedUserDetails: IUserBasicInfo | undefined;
    onLoadMoreUsers: (pageNumber: number) => void;
    onFilterUser: (textInput: string) => void;
    textSearchUser: string;
    unfilteredUsers: IUserBasicInfo[] | undefined;
    filteredUsers: IUserBasicInfo[] | undefined;
}

export const ChooseUser = ({
    usersWithoutLicense,
    updateUserLicenseUserId,
    updateIsFormValid,
    updatedUserDetails,
    onLoadMoreUsers,
    onFilterUser,
    textSearchUser,
    unfilteredUsers,
    filteredUsers,
}: IProps) => {
    const [usersOptions, setUserOptions] = useState<DefaultOptionType[]>([]);

    const { t } = useTranslation();
    const [form] = useForm();

    useEffect(() => {
        refreshSelectOptions();
    }, [unfilteredUsers, filteredUsers]);

    useEffect(() => {
        if (!updatedUserDetails) return;
        const updatedOptions = [
            {
                value: updatedUserDetails.id,
                label: (
                    <span>
                        {updatedUserDetails.name} {updatedUserDetails.surname}
                        <span className={styles.email}>
                            {" "}
                            {updatedUserDetails.email}
                        </span>
                    </span>
                ),
            },
            ...usersOptions,
        ];
        setUserOptions(updatedOptions);

        form.setFieldsValue({
            name: updatedUserDetails.id,
        });
    }, [updatedUserDetails]);

    const refreshSelectOptions = () => {
        if (!textSearchUser) {
            if (!unfilteredUsers) return;
            setUserOptions(
                unfilteredUsers.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {u.name} {u.surname}
                            <span className={styles.email}> {u.email}</span>
                        </span>
                    ),
                }))
            );
        } else {
            if (!filteredUsers) return;
            setUserOptions(
                filteredUsers.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {u.name} {u.surname}
                            <span className={styles.email}> {u.email}</span>
                        </span>
                    ),
                }))
            );
        }
    };

    const onChange = (value: string) => {
        if (!value) return;
        updateIsFormValid(true);
        updateUserLicenseUserId(value);
    };

    return (
        <>
            <Form layout="vertical" form={form}>
                <div>
                    <Title level={4}>
                        {t("createUserLicense.inviteUser.invite.title")}
                    </Title>
                    <Text type="secondary">
                        {t("createUserLicense.inviteUser.invite.subtitle")}
                    </Text>
                </div>
                <Spacer size="m" />
                <Form.Item
                    className={styles.select}
                    label={t("createUserLicense.inviteUser.invite.name")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t("form.required"),
                        },
                    ]}
                >
                    <FilteredSelect
                        onChange={onChange}
                        options={usersOptions}
                        refreshSelectOptions={refreshSelectOptions}
                        textSearchUser={textSearchUser}
                        unfilteredUsers={unfilteredUsers}
                        filteredUsers={filteredUsers}
                        onLoadMoreUsers={onLoadMoreUsers}
                        onFilterUser={onFilterUser}
                        dataList={usersWithoutLicense}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
