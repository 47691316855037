import { Card, Progress, Typography } from "antd";
import styles from "./ringStatComponent.module.scss";
import { Spacer } from "../../../appCore/spacer/spacer";

const { Text, Title } = Typography;

interface IRingComponentTexts {
    title: string;
    description: string;
    detailOne: string;
    detailTwo: string;
    ringDataName: string;
    underRingText: string;
    cases: string;
}

interface IProps {
    localisedTexts: IRingComponentTexts;
    dataOne: number;
    dataTwo: number;
}

export const RingStatComponent = ({
    localisedTexts,
    dataOne,
    dataTwo,
}: IProps) => {
    const percentage =
        ((dataTwo / dataOne) * 100).toString() === "NaN"
            ? 0
            : (dataTwo / dataOne) * 100;
    return (
        <Card className={styles.Container}>
            <div className={styles.Column}>
                <Title level={4}>{localisedTexts.title}</Title>
                <Text>{localisedTexts.description}</Text>
                <Spacer size="s" />
                <div>
                    <DetailComponent
                        color={"#132D4A"}
                        text={localisedTexts.detailOne}
                        value={dataOne}
                        valueText={localisedTexts.cases}
                    />
                    <DetailComponent
                        color={"#38B6FF"}
                        text={localisedTexts.detailTwo}
                        value={dataTwo}
                        valueText={localisedTexts.cases}
                    />
                </div>
            </div>
            <div className={styles.ColumnRing}>
                {/* TODO: ADD a Text over the progress*/}
                <div className={styles.ProgressContainer}>
                    {" "}
                    <Progress
                        type="circle"
                        percent={100}
                        success={{
                            percent: percentage,
                            strokeColor: "#38B6FF",
                        }}
                        strokeColor={"#0C3A6F"}
                        strokeWidth={8}
                        width={200}
                        strokeLinecap="butt"
                        showInfo={false}
                    />
                    <div className={styles.Progress}>
                        <h1>
                            {percentage.toFixed(1).replace(/[.,]0$/, "") + "%"}
                        </h1>
                        <p>{localisedTexts.ringDataName}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

interface IDetailProps {
    color: string;
    text: string;
    value: number;
    valueText: string;
}

const DetailComponent = ({ color, text, value, valueText }: IDetailProps) => {
    return (
        <div className={styles.DetailContainer}>
            <div className={styles.Circle} style={{ backgroundColor: color }} />
            <Text>{text}</Text>
            <Text strong>{value + " " + valueText}</Text>
        </div>
    );
};
