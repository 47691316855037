import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { reportWebVitals, reportWebVitalsToGrafanaFaro } from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import "./i18n";
import { ProfileProvider } from "./provider/profileProvider";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Suspense fallback="Loading...">
        <MsalProvider instance={msalInstance}>
            <ProfileProvider>
                <App />
            </ProfileProvider>
        </MsalProvider>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitalsToGrafanaFaro()
reportWebVitals();
