import { faro } from "@grafana/faro-web-sdk";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { useState } from "react";
import { OrganisationDataSource } from "../../data/dataSource/organisationDataSource";
import { IOrganisationListPresentation } from "../entities/organisationModel";

interface IAdditionalMethods {
    getAllOrganisations: (
        pageSize: number,
        pageNumber: number
    ) => Promise<void>;
}

export const useAllOrganisations: IUseDomainHook<
    IOrganisationListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = OrganisationDataSource();
    const [organisationList, setOrganisationList] = useState<
        IOrganisationListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllOrganisations = async (
        pageSize: number,
        pageNumber: number
    ) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getAllOrganisations(
                pageSize,
                pageNumber
            );
            setOrganisationList(result);
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: organisationList,
        isLoading,
        errorMessage,
        getAllOrganisations,
    };
};
