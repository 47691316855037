import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    InputNumber,
    Select,
    Typography,
} from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./licenseInfoStep.module.scss";
import { ILicenseTemplateModel } from "../../../../features/licenseTemplate/domain/entities/licenseModel";
import { Spacer } from "../../../appCore/spacer/spacer";
import { useForm } from "antd/lib/form/Form";

const { Title, Text } = Typography;

interface IProps {
    licenseDetails: ILicenseTemplateModel;
    setLicenseDetails: Dispatch<SetStateAction<ILicenseTemplateModel>>;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    setIsStepIconVisible: Dispatch<SetStateAction<boolean>>;
    setIsLicenseInfoValid: Dispatch<SetStateAction<boolean>>;
    isLicenseInfoValid: boolean;
}

export const LicenseInfoStep = ({
    licenseDetails,
    setLicenseDetails,
    setCurrentStep,
    setIsStepIconVisible,
    setIsLicenseInfoValid,
    isLicenseInfoValid,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [infoDetails, setInfoDetails] =
        useState<ILicenseTemplateModel>(licenseDetails);

    const validateMessages = {
        required: t("fieldValidation.required"),
    };

    useEffect(() => {
        if (!checkIsFormValid(licenseDetails)) return;
        setInfoDetails(licenseDetails);
        form.setFieldsValue(licenseDetails);
    }, [licenseDetails]);

    // ? Validation logic
    const checkIsFormValid = (input: ILicenseTemplateModel | undefined) => {
        return (
            input?.name !== undefined &&
            input?.name !== "" &&
            input?.totalPrice !== undefined &&
            input?.totalPrice !== null &&
            input?.totalPrice >= 0 &&
            input?.organ !== undefined &&
            input?.maxNumberOfCases !== undefined &&
            input?.maxNumberOfCases !== null &&
            input?.maxNumberOfCases > 0 &&
            input?.maxNumberOfUsers !== undefined &&
            input?.maxNumberOfUsers !== null &&
            input?.maxNumberOfUsers > 0
        );
    };

    // ? Form validation while typing
    const onChange = (
        changedValues: ILicenseTemplateModel | undefined,
        values: ILicenseTemplateModel
    ) => {
        setInfoDetails(values);
        setIsLicenseInfoValid(checkIsFormValid(values));
    };

    const options = [
        { label: t("breast"), value: "Breast" },
        { label: t("prostate"), value: "Prostate" },
    ];

    const onClick = () => {
        setLicenseDetails((prevState) => ({
            ...prevState,
            name: infoDetails.name,
            totalPrice: infoDetails.totalPrice,
            maxNumberOfCases: infoDetails.maxNumberOfCases,
            maxNumberOfUsers: infoDetails.maxNumberOfUsers,
            organ: infoDetails.organ,
        }));
        setCurrentStep((prevStep) => prevStep + 1);
        setIsStepIconVisible(true);
    };

    return (
        <>
            <Card>
                <Form
                    layout="vertical"
                    onValuesChange={onChange}
                    validateMessages={validateMessages}
                    form={form}
                >
                    <div>
                        <Title level={4}>
                            {t("createLicense.infoStep.generalInformation")}
                        </Title>
                        <Text type="secondary">
                            {t(
                                "createLicense.infoStep.generalInformationSubtitle"
                            )}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <Form.Item
                        label={t("createLicense.infoStep.licenseName")}
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            placeholder={t("createLicense.infoStep.namePrompt")}
                        />
                    </Form.Item>

                    <Divider />

                    <div>
                        <Title level={4}>
                            {t("createLicense.infoStep.licenseSpecifications")}
                        </Title>
                        <Text type="secondary">
                            {t(
                                "createLicense.infoStep.licenseSpecificationsSubtitle"
                            )}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createLicense.infoStep.price")}
                                name="totalPrice"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={styles.InputNumber}
                                    placeholder={t(
                                        "createLicense.infoStep.pricePrompt"
                                    )}
                                    maxLength={200}
                                    min={0}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createLicense.infoStep.organ")}
                                name="organ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                initialValue="Breast"
                            >
                                <Select disabled options={options} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createLicense.infoStep.maxCases")}
                                name="maxNumberOfCases"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={styles.InputNumber}
                                    placeholder={t(
                                        "createLicense.infoStep.maxCasesPrompt"
                                    )}
                                    min={1}
                                    maxLength={200}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createLicense.infoStep.maxUsers")}
                                name="maxNumberOfUsers"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={styles.InputNumber}
                                    placeholder={t(
                                        "createLicense.infoStep.maxUsersPrompt"
                                    )}
                                    min={1}
                                    maxLength={200}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Spacer size={"s"} />

                    <Form.Item className={styles.StepNavigation}>
                        <Button
                            onClick={onClick}
                            type="primary"
                            htmlType="submit"
                            disabled={!isLicenseInfoValid}
                        >
                            {t("createLicense.next")}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};
