import { UploadFile } from "antd/es/upload/interface";
import styles from "./fileListComponent.module.scss";

interface IProps {
    file: UploadFile;
    onDelete: () => void;
}

export const FileListComponent = ({ file, onDelete }: IProps) => {
    return (
        <div className={styles.FileListContainer}>
            <span className="kenko-icon-clip" />
            <p>{file.name}</p>
            <a className="kenko-icon-bin" onClick={onDelete} />
        </div>
    );
};
