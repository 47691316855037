import { Button, Steps, Typography } from "antd";
import React from "react";
import { Spacer } from "../spacer/spacer";
import styles from "./stepWithEditIconComponent.module.scss";

const { Step } = Steps;

interface IProps {
    currentStep: number;
    title: string;
    steps: IStep[];
    isComplete?: boolean;
    icon?: React.ReactNode;
    onItemClick: (index: number) => void;
    isIconVisible: boolean;
    isEditStep: boolean;
}

interface IStep {
    title: string;
    description: string;
}

const { Title } = Typography;

export const StepWithEditIconComponent = ({
    currentStep,
    title,
    steps,
    isComplete = false,
    icon,
    isIconVisible,
    onItemClick,
    isEditStep
}: IProps) => {
    const handleItemClick = (index: number, isVisible: boolean, currentStep:number) => {
        if (!isVisible) {
            return;
        }
        
        if (isEditStep){
            onItemClick(index);
        }else{
            if (index < currentStep){
                onItemClick(index);
            }
        }
            
    };
    return (
        <>
            <Title level={3}>{title}</Title>
            <Spacer size="s" />
            <Steps direction="vertical" current={currentStep}>
                {steps.map((step, key) => {
                    return isComplete ? (
                        <Step
                            onClick={() => handleItemClick(key, isIconVisible, currentStep)}
                            key={key}
                            title={
                                key === currentStep ? (
                                    step.title
                                ) : (
                                    <StepTitleWithIcon
                                        title={step.title}
                                        icon={icon}
                                    />    
                                )
                            }
                            description={step.description}
                            status={key === currentStep ? "process" : "finish"}
                        ></Step>
                    ) : (
                        <Step
                            onClick={() => handleItemClick(key, isIconVisible, currentStep)}
                            key={key}
                            title={
                                key === currentStep ? (
                                    step.title
                                ) : (
                                    key < currentStep ? (
                                        <StepTitleWithIcon
                                            title={step.title}
                                            icon={icon}
                                        />
                                    ) : (
                                        step.title
                                    )   
                                )
                            }
                            description={step.description}
                        ></Step>
                    );
                })}
            </Steps>
        </>
    );
};
interface IStepTitleProps {
    title: string;
    icon: React.ReactNode;
}
const StepTitleWithIcon = ({ title, icon }: IStepTitleProps) => {
    return (
        <div className={styles.StepWithIcon}>
            <Title level={5} type="secondary">
                {title}
            </Title>{" "}
            {icon}
        </div>
    );
};
