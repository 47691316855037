import { Button, Tooltip } from "antd";
import styles from "./visualizerMIPCutBtn.module.scss";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ISequenceSliceController, ISequenceSliceReference } from "../../../domain/entities/sequence";

interface IProps {
    sequenceSliceReference: ISequenceSliceReference;
    setSequenceSliceReference: Dispatch<SetStateAction<ISequenceSliceReference>>;
    sequenceSliceController: ISequenceSliceController;
    setSequenceSliceController: Dispatch<SetStateAction<ISequenceSliceController>>;
    isMultiplanar: boolean
}

export const VisualizerMIPCutBtn = ({
    sequenceSliceReference,
    setSequenceSliceReference,
    sequenceSliceController,
    setSequenceSliceController,
    isMultiplanar,
}: IProps) => {
    const { t } = useTranslation();

    const loadMIPImages = () => {
        if(isMultiplanar){
            Object.keys(sequenceSliceController).forEach((sequence) => {
                sequenceSliceController[sequence]["axial"].activeSlice = sequenceSliceController[sequence]["axial"].sliceCount +1;
                sequenceSliceController[sequence]["coronal"].activeSlice = sequenceSliceController[sequence]["coronal"].sliceCount +1;
                sequenceSliceController[sequence]["sagital"].activeSlice = sequenceSliceController[sequence]["sagital"].sliceCount +1;
            })

            setSequenceSliceController({...sequenceSliceController})
            return
        }
        setSequenceSliceReference({...sequenceSliceReference, activeSlice: sequenceSliceReference.sliceCount+1})
    }

    return (
        <div className={styles.Button}>
            <Tooltip placement="topLeft" title={t("visualizer.header.mipCut")}>
                <Button type="primary"
                style={
                    { backgroundColor: "#1f2127",
                    borderColor: "#e9e9e9" }
                }
                onClick={() => 
                    loadMIPImages()
                }
                >
                    <span
                        className="kenko-icon-image"
                    ></span>
                </Button>
            </Tooltip>
        </div>
    );
};
