import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import {
    ILicenseListPresentation,
} from "../entities/licenseModel";
import { LicenseDataSource } from "../../data/dataSource/licenseDataSource";
import { faro } from "@grafana/faro-web-sdk";

interface IAdditionalMethods {
    getAllLicenses: (pageSize: number, pageNumber: number) => Promise<void>;
}

export const useAllLicenses: IUseDomainHook<
    ILicenseListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = LicenseDataSource();
    const [licenseList, setLicenseList] = useState<
        ILicenseListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllLicenses = async (pageSize: number, pageNumber: number) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getAllLicenseTemplates(
                pageSize,
                pageNumber
            );
            setLicenseList(result);
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: licenseList,
        isLoading,
        errorMessage,
        getAllLicenses,
    };
};
