import { Table, Button, Card, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Dispatch, Key, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styles from "./licenseAddDistributorStep.module.scss";
import {
    IDistributor,
    ILicenseTemplateModel,
} from "../../../../features/licenseTemplate/domain/entities/licenseModel";
import { Spacer } from "../../../appCore/spacer/spacer";
import { ISequenceTemplateTable } from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";

interface IProps {
    setLicenseDetails: Dispatch<SetStateAction<ILicenseTemplateModel>>;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    distributorDetails: IDistributor;
    setDistributorDetails?: Dispatch<SetStateAction<IDistributor>>;
    setIsDistributorChosen: Dispatch<SetStateAction<boolean>>;
    isDistributorChosen: boolean;
    distributorIdKey: Key | undefined;
    setDistributorIdKey: Dispatch<SetStateAction<Key | undefined>>;
    tasks: ISequenceTemplateTable[];
}

const { Title, Text } = Typography;

export const LicenseAddDistributorStep = ({
    setLicenseDetails,
    setCurrentStep,
    distributorDetails,
    setDistributorDetails,
    setIsDistributorChosen,
    isDistributorChosen,
    distributorIdKey,
    setDistributorIdKey,
    tasks,
}: IProps) => {
    const { t } = useTranslation();

    const expandedRowRender = (record: ISequenceTemplateTable) => {
        const { value } = record;
        const parentRowKey = record.key;
        const subColumns = [
            {
                key: "normalizedName",
                width: "20%",
            },
            {
                dataIndex: "name",
                key: "name",
                width: "20%",
            },
            {
                dataIndex: "version",
                key: "version",
                width: "10%",
            },
            {
                dataIndex: "description",
                key: "description",
                width: "50%",
            },
        ];

        const subData = value
            .map((sequenceQuery) => {
                const { description, name, version } = sequenceQuery;
                const data = {
                    key: `${parentRowKey}_v${version}`,
                    parentRowKey,
                    description,
                    name,
                    version,
                };
                return data;
            })
            .flat();

        return (
            <Table
                rowClassName={styles.SubTable}
                columns={subColumns}
                dataSource={subData}
                pagination={false}
                showHeader={false}
                rowKey="key"
                rowSelection={{
                    type: "radio",
                    ...rowSelection,
                    selectedRowKeys: [distributorIdKey!],
                }}
            />
        );
    };

    const seqListColumns: ColumnsType<ISequenceTemplateTable> = [
        {
            title: t("createLicense.addDistributor.manufacturer"),
            dataIndex: "key",
            key: "nameseqListColumns",
            width: "20%",
        },
        {
            title: t("createLicense.addDistributor.name"),
            width: "20%",
        },
        {
            title: t("createLicense.addDistributor.version"),
            width: "10%",
        },
        {
            title: t("createLicense.addDistributor.description"),
            width: "50%",
            align: "left",
        },
    ];

    const rowSelection = {
        onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: {
                key: string;
                parentRowKey: string;
                description: string;
                name: string;
                version: string;
            }[]
        ) => {
            if (!!setDistributorIdKey && !!setDistributorDetails) {
                setDistributorDetails((prevState) => ({
                    ...prevState,
                    name:
                        selectedRows[0].parentRowKey +
                        "_v" +
                        Number(selectedRows[0].version),
                    version: Number(selectedRows[0].version),
                    unversionedDistributorName: selectedRows[0].name,
                }));
                setDistributorIdKey(selectedRows[0].key);
                setIsDistributorChosen(true);
            }
        },
    };

    const onClick = () => {
        setLicenseDetails((prevState) => ({
            ...prevState,
            distributor: distributorDetails,
        }));
        setCurrentStep((prevStep) => prevStep + 1);
    };

    return (
        <Card>
            <div>
                <Title level={4}>
                    {t("createLicense.infoStep.distributorInformation")}
                </Title>
                <Text type="secondary">
                    {t("createLicense.infoStep.distributorInformationSubtitle")}
                </Text>
            </div>

            <Spacer size={"m"} />
            <Table
                columns={seqListColumns}
                dataSource={tasks}
                expandedRowRender={expandedRowRender}
                rowKey="key"
                pagination={false}
            />

            <Spacer size={"m"} />

            <div className={styles.ButtonContainer}>
                <Button
                    type="primary"
                    onClick={onClick}
                    disabled={!isDistributorChosen}
                >
                    {t("createLicense.next")}
                </Button>
            </div>
        </Card>
    );
};
