import { Button, Card, Divider, Row, Skeleton, Typography } from "antd";
import { ILicensePresentation } from "../../../../features/licenseTemplate/domain/entities/licenseModel";
import { IOrganisationDetailsDto } from "../../../../features/organisation/domain/entities/organisationModel";
import { useTranslation } from "react-i18next";
import styles from "./licenseDetailsDescription.module.scss";
import { OrganTag } from "../../../appCore/organTag/organTag";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { DeleteLicenseModal } from "../deleteLicenseModal/deleteLicenseModal";
import { Spacer } from "../../../appCore/spacer/spacer";
import { CounterComponent } from "../../../organisationAdminComponents/statisticsComponents/counterComponent/counterComponent";

const { Title, Text } = Typography;

interface IProps {
    organisationDetails: IOrganisationDetailsDto | undefined;
    licenseDetails: ILicensePresentation | undefined;
    numOrganisations: number | undefined;
    areButtonsVisible: boolean;
    isFromOrg: boolean;
    isVisible: boolean;
}

export const LicenseDetailsDescription = ({
    licenseDetails,
    organisationDetails,
    numOrganisations,
    areButtonsVisible,
    isFromOrg,
    isVisible,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let space = " ";
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);
    const MBs_IN_1GB = 1024

    return (
        <>
            <Card>
                <Title level={4}>{t("licenseDetails.license")}</Title>
                <Spacer size={"m"} />
                <Text>
                    {licenseDetails ? (
                        <b>{licenseDetails.name}</b>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseDetails && licenseDetails.sequencesTemplate ? (
                        <>
                            {t("licenseDetails.description.secuence") + space}
                            <b>{licenseDetails.sequencesTemplate.name}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseDetails && licenseDetails.distributor ? (
                        <>
                            {t("licenseDetails.description.distributor") +
                                space}
                            <b>{licenseDetails.distributor.name}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseDetails ? (
                        <>
                            {t("licenseDetails.description.price") + space}
                            <b>{licenseDetails.totalPrice + "€"}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseDetails ? (
                        <>
                            {t("licenseDetails.description.organ") + space}
                            <OrganTag organ={licenseDetails.organ!} />
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseDetails ? (
                        <>
                            {t("licenseDetails.description.maxNumberOfCases") +
                                space}
                            <b>{licenseDetails.maxNumberOfCases}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseDetails ? (
                        <>
                            {t("licenseDetails.description.maxNumberOfUsers") +
                                space}
                            <b>{licenseDetails.maxNumberOfUsers}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                {licenseDetails && licenseDetails.memoryLimits ? 
                    (
                        <><Divider /><Text>
                                {licenseDetails ? (
                                    <>
                                        {t("licenseDetails.description.defaultMemory") + space}
                                        <b>{licenseDetails.memoryLimits?.currentEcsMemorySize! / MBs_IN_1GB + " GB"}</b>
                                    </>
                                ) : (
                                    <Skeleton
                                        paragraph={false}
                                        active
                                        className={styles.Skeleton} />
                                )}
                            </Text><Divider /><Text>
                                    {licenseDetails && licenseDetails.memoryLimits ? (
                                        <>
                                            {t("licenseDetails.description.maxMemory") + space}
                                            <b>{licenseDetails.memoryLimits?.maxEcsMemorySize! / MBs_IN_1GB + " GB"}</b>
                                        </>
                                    ) : (
                                        <Skeleton
                                            paragraph={false}
                                            active
                                            className={styles.Skeleton} />
                                    )}
                                </Text></>
                    ) : (<></>)
                }
                
                <Divider />
                {licenseDetails && isVisible && (
                    <>
                        <CounterComponent
                            title={t("organisationDetails.stats.cases.title")}
                            description={""}
                            count={
                                licenseDetails.maxNumberOfCases -
                                licenseDetails.numberOfRemainingCases
                            }
                            outOf={licenseDetails.maxNumberOfCases}
                            isCard={false}
                        />
                        <Divider />
                        <CounterComponent
                            title={t(
                                "organisationDetails.stats.licenses.title"
                            )}
                            description={""}
                            count={
                                licenseDetails.maxNumberOfUsers -
                                licenseDetails.numberOfRemainingUsers
                            }
                            outOf={licenseDetails.maxNumberOfUsers}
                            isCard={false}
                        />
                        <Divider />
                    </>
                )}

                <Spacer size="xs" />
                {isFromOrg && (
                    <div className={styles.ReloadLicenceButtonContainer}>
                        <Button
                            className={styles.ReloadButton}
                            type="default"
                            htmlType="submit"
                            onClick={() =>
                                navigate(
                                    "/ReloadOrgLicense/" +
                                        organisationDetails?.id!
                                )
                            }
                        >
                            {t("reloadOrgLicense.reloadButton")}
                        </Button>
                    </div>
                )}
                {areButtonsVisible && (
                    <Row className={styles.ButtonsRow}>
                        {!numOrganisations ? (
                            <>
                                <Button
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                    }}
                                    className={styles.DeleteButton}
                                >
                                    {t("sequencesDetails.delete")}
                                    <span
                                        className={`kenko-icon-bin ${styles.DeleteIcon}`}
                                    />
                                </Button>
                                <Button
                                    className={styles.EditButton}
                                    onClick={() =>
                                        navigate(
                                            "/editLicense/" + licenseDetails?.id
                                        )
                                    }
                                >
                                    {t("edit")}
                                    <span
                                        className={`kenko-icon-edit ${styles.EditIcon}`}
                                    />
                                </Button>
                            </>
                        ) : (
                            <Button
                                className={styles.AloneEditButton}
                                onClick={() =>
                                    navigate(
                                        "/editLicense/" + licenseDetails?.id
                                    )
                                }
                            >
                                {t("edit")}
                                <span
                                    className={`kenko-icon-edit ${styles.EditIcon}`}
                                />
                            </Button>
                        )}
                    </Row>
                )}
            </Card>
            <DeleteLicenseModal
                isVisible={isDeleteModalVisible}
                setIsVisible={setIsDeleteModalVisible}
                licenseDetails={licenseDetails}
            />
        </>
    );
};
