import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHeader } from "../../../hooks/headerHook";
import { useMutation, useQuery } from "@apollo/client";
import fragments from "../../../gql/fragments";
import { AddUserMutation, OrganisationQuery } from "../../../generated/graphql";
import { IUserReducedDto } from "../../../features/user/domain/entities/userModel";
import {
    IUserLicenseCreate,
    IUserLicenseListPresentation,
    IUserLicenseModel,
} from "../../../features/userLicense/domain/entities/userLicenseModel";
import { UserFormComponent } from "../../../features/user/presentation/userFormComponent/userFormComponent";
import { useCreateUserLicense } from "../../../features/userLicense/domain/hooks/useCreateUserLicense";
import { useAssignUserToUserLicense } from "../../../features/userLicense/domain/hooks/useAssignUserToUserLicense";
import { useUserLicensesWithoutUser } from "../../../features/userLicense/domain/hooks/useUserLicensesWithoutUser";

export const InviteUserPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isModalLoading, setIsModalLoading] = useState<boolean>(true);
    const [isModalError, setIsModalError] = useState<boolean>(false);
    const [createUserDetails, setCreateUserDetails] = useState<
        IUserReducedDto | undefined
    >();
    const [createUserLicenseDetails, setCreateUserLicenseDetails] = useState<
        IUserLicenseCreate | undefined
    >();
    const [userLicensesWithoutUser, setUserLicensesWithoutUser] =
        useState<IUserLicenseListPresentation>();
    const [checkValue, setCheckValue] = useState<number | undefined>(0);
    const [remainingCases, setRemainingCases] = useState<number>();
    const [remainingUsers, setRemainingUsers] = useState<number>();
    const [unfilteredLicenses, setUnfilteredLicensess] =
        useState<IUserLicenseModel[]>();
    const [filteredLicenses, setFilteredLicenses] =
        useState<IUserLicenseModel[]>();
    const [textSearchLicenses, setTextSeachLicenses] = useState<string>("");

    const PAGE_SIZE = 10;

    useEffect(() => {
        setHeaderContent({
            title: t("exit"),
            breadCrumb: [],
            goBackLinkOverride: "/users",
        });
    }, [setHeaderContent, t]);

    const [AddUserMutation] = useMutation<AddUserMutation>(
        fragments.CREATE_USER
    );
    const { data: organisationLicenseData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION
    );
    //===============HOOKS==================
    const {
        data: userLicenseData,
        isLoading: isLoadingCreate,
        errorMessage: errorMessageCreate,
        createUserLicense,
    } = useCreateUserLicense();
    const {
        data: userLicenseAssign,
        isLoading: isLoadingAssign,
        errorMessage: errorMessageAssign,
        assignUserToUserLicense,
    } = useAssignUserToUserLicense();
    const {
        data: userLicensesWithoutUserData,
        isLoading: isLoadingWithoutUser,
        errorMessage: errorMessageWithoutUser,
        getUserLicensesWithoutUser,
    } = useUserLicensesWithoutUser();

    //===============UPDATES==================
    const updateCreateUserDetails = (value: IUserReducedDto | undefined) => {
        setCreateUserDetails(value);
    };
    const updateUserLicenseUserId = (value: string | undefined | null) => {
        setCreateUserLicenseDetails((prevState) => ({
            ...prevState,
            numberOfCases: prevState?.numberOfCases,
            id: value,
        }));
    };
    const updateUserLicenseNumCases = (value: number | undefined) => {
        setCreateUserLicenseDetails((prevState) => ({
            ...prevState,
            numberOfCases: value,
            id: prevState?.id,
        }));
    };
    const updateCheckValue = (value: number | undefined) => {
        setCheckValue(value);
    };
    const updateUserName = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: value,
            lastName: prevState?.lastName,
            email: prevState?.email,
        }));
    };
    const updateUserSurname = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: prevState?.firstName,
            lastName: value,
            email: prevState?.email,
        }));
    };
    const updateUserEmail = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: prevState?.firstName,
            lastName: prevState?.lastName,
            email: value,
        }));
    };

    //===============FUNCTIONS==================
    const onLoadMoreUserLicenses = (pageNumber: number) => {
        getUserLicensesWithoutUser(
            PAGE_SIZE,
            pageNumber,
            undefined,
            textSearchLicenses
        ).then((res: IUserLicenseListPresentation | null | undefined) => {
            if (!res) {
                return;
            }
            if (!textSearchLicenses) {
                setUnfilteredLicensess(
                    unfilteredLicenses?.concat(res.userLicensePresentations)
                );
            } else {
                setFilteredLicenses(
                    filteredLicenses?.concat(res.userLicensePresentations)
                );
            }
        });
    };

    const onFilterUserLicense = (textInput: string) => {
        setTextSeachLicenses(textInput);
        getUserLicensesWithoutUser(PAGE_SIZE, 0, undefined, textInput).then(
            (res) => {
                if (!res) return;
                setFilteredLicenses(res?.userLicensePresentations);
            }
        );
    };

    const onCreateUser = () => {
        if (!createUserDetails) {
            return;
        }
        setIsModalVisible(true);
        AddUserMutation({
            variables: {
                firstName: createUserDetails?.firstName,
                lastName: createUserDetails?.lastName,
                email: createUserDetails?.email,
            },
        }).then((res) => {
            if (!res.data) {
                setIsModalError(true);
            }
            setIsModalLoading(false);
        });
    };

    const onCreateComplete = () => {
        if (!createUserDetails) {
            return;
        }
        setIsModalVisible(true);
        AddUserMutation({
            variables: {
                firstName: createUserDetails?.firstName,
                lastName: createUserDetails?.lastName,
                email: createUserDetails?.email,
            },
        }).then((res) => {
            if (checkValue === 1) {
                //choose
                if (!createUserLicenseDetails?.id || !res.data) return;
                assignUserToUserLicense(
                    createUserLicenseDetails?.id,
                    res.data?.addUser.id
                );
            } else if (checkValue === 2) {
                //create
                if (!createUserLicenseDetails?.numberOfCases || !res.data)
                    return;
                createUserLicense(
                    createUserLicenseDetails?.numberOfCases,
                    res.data?.addUser.id
                );
            }
        });
    };

    //===============USEEFFECTS==================
    useEffect(() => {
        getUserLicensesWithoutUser(PAGE_SIZE, 0).then((res) => {
            if (!res) return;
            setUnfilteredLicensess(res?.userLicensePresentations);
        });
    }, []);

    useEffect(() => {
        if (errorMessageCreate) setIsModalError(true);
        if (!userLicenseData) return;
        setIsModalLoading(isLoadingCreate);
        setIsModalVisible(true);
    }, [userLicenseData, errorMessageCreate]);

    useEffect(() => {
        if (errorMessageAssign) setIsModalError(true);
        if (!userLicenseAssign) return;
        setIsModalLoading(isLoadingAssign);
        setIsModalVisible(true);
    }, [userLicenseAssign, errorMessageAssign]);

    useEffect(() => {
        if (!userLicensesWithoutUserData) {
            return;
        }
        setUserLicensesWithoutUser(userLicensesWithoutUserData);
        if (!organisationLicenseData) {
            return;
        }
        setRemainingCases(
            organisationLicenseData?.organisation.organisationLicense
                .remainingCases
        );
        setRemainingUsers(
            organisationLicenseData?.organisation.organisationLicense
                .remainingUsers
        );
    }, [userLicensesWithoutUserData, organisationLicenseData]);

    return (
        <UserFormComponent
            isEditPage={false}
            updateUserLicenseUserId={updateUserLicenseUserId}
            updateCheckValue={updateCheckValue}
            checkValue={checkValue}
            isResultModalVisible={isModalVisible}
            isResultModalLoading={isModalLoading}
            isResultModalError={isModalError}
            titleModal={t("invite.successModal.title")}
            subtitleModal={t("invite.successModal.description")}
            userLicensesWithoutUser={userLicensesWithoutUser}
            remainingCases={remainingCases}
            remainingUsers={remainingUsers}
            updateUserLicenseNumCases={updateUserLicenseNumCases}
            onCreateUser={onCreateUser}
            onCreateComplete={onCreateComplete}
            createUserLicenseDetails={createUserLicenseDetails}
            updateUserName={updateUserName}
            updateUserSurname={updateUserSurname}
            updateUserEmail={updateUserEmail}
            buttonText={t("invite.license.invite")}
            titleStep={t("invite.steps.title")}
            email={createUserDetails?.email}
            onLoadMoreUserLicenses={onLoadMoreUserLicenses}
            textSearchLicenses={textSearchLicenses}
            unfilteredLicenses={unfilteredLicenses}
            filteredLicenses={filteredLicenses}
            onFilterUserLicense={onFilterUserLicense}
        />
    );
};
