import { gql } from "@apollo/client";

const GET_ALL_ORGANISATIONS = gql`
    query allOrganisations($input: GetOrganisationInput!) {
        organisations(input: $input) {
            organisationPresentations {
                id
                name
                cifAndOthers
                email
                admin {
                    id
                    name
                    surname
                    email
                    isOrgAdmin
                    orgId
                }
                organisationLicense {
                    id
                    totalPrice
                    maxNumberOfCases
                    maxNumberOfUsers
                    remainingCases
                    remainingUsers
                    organ
                    licenseTemplateReference {
                        id
                        name
                    }
                }
            }
            totalResults
        }
    }
`;

const GET_ORGANISATION = gql`
    query organisation($orgId: String) {
        organisation(orgId: $orgId) {
            id
            name
            cifAndOthers
            admin {
                id
                name
                surname
                email
            }
            organisationLicense {
                id
                totalPrice
                maxNumberOfCases
                maxNumberOfUsers
                remainingCases
                remainingUsers
                organ
                licenseTemplateReference {
                    id
                    name
                }
                licenseFeatures {
                    id
                    name
                    description
                    environKey
                    environValue
                    price
                }
            }
            distributor {
                unversionedDistributorName
                version
                name
            }
            sequencesTemplate {
                id
                name
            }
        }
    }
`;

const GET_ORGANISATION_FOR_USER_PROFILE = gql`
    query organisationForUserProfile {
        organisation {
            name
            admin {
                name
                surname
            }
        }
    }
`;

const CREATE_ORGANISATION = gql`
    mutation addOrganisation(
        $name: String!
        $firstName: String!
        $lastName: String!
        $email: String!
        $cifAndOthers: String!
        $licenseId: String!
    ) {
        addOrganisation(
            input: {
                name: $name
                firstName: $firstName
                lastName: $lastName
                email: $email
                cifAndOthers: $cifAndOthers
                licenseId: $licenseId
            }
        ) {
            id
            name
            email
            cifAndOthers
        }
    }
`;

const GET_STATS = gql`
    query stats {
        stats {
            numberOfRemainingCases
            numberOfRemainingUsers
            maxNumberOfCases
            maxNumberOfUsers
            positiveFalse
            negativeFalse
            totalPositive
            totalNegative
            totalCreatedCases
            totalCreatedUsers
        }
    }
`;

const UPDATE_ORGANISATION = gql`
    mutation updateOrganisation(
        $orgId: String!
        $name: String!
        $cifAndOthers: String!
    ) {
        updateOrganisation(
            input: { orgId: $orgId, name: $name, cifAndOthers: $cifAndOthers }
        ) {
            id
        }
    }
`;

const RELOAD_ORGANISATION_LICENSE = gql`
    mutation reloadOrgLicense(
        $orgLicenseId: String!
        $priceIncrement: Decimal!
        $numberOfCases: Int!
        $numberOfUsers: Int!
    ) {
        reloadOrgLicense(
            input: {
                orgLicenseId: $orgLicenseId
                priceIncrement: $priceIncrement
                numberOfCases: $numberOfCases
                numberOfUsers: $numberOfUsers
            }
        ) {
            id
            name
            cifAndOthers
            admin {
                id
                name
                surname
                email
            }
            organisationLicense {
                id
                totalPrice
                maxNumberOfCases
                maxNumberOfUsers
                remainingCases
                remainingUsers
                organ
                licenseTemplateReference {
                    id
                    name
                }
            }
        }
    }
`;

export const organisationFragments = {
    GET_ALL_ORGANISATIONS,
    GET_ORGANISATION,
    GET_ORGANISATION_FOR_USER_PROFILE,
    CREATE_ORGANISATION,
    GET_STATS,
    UPDATE_ORGANISATION,
    RELOAD_ORGANISATION_LICENSE,
};
