import { Button, Tooltip } from "antd";
import styles from "./visualizePixelAnalizeBtn.module.scss";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    isMultiplanarActive: boolean;
    isPixelAnalizeActive: boolean;
    setPixelAnalizeActive: Dispatch<SetStateAction<boolean>>;
}

export const VisualizerPixelAnalysisBtn = ({
    isPixelAnalizeActive,
    isMultiplanarActive,
    setPixelAnalizeActive
}: IProps) => {
    const { t } = useTranslation();

    const onClick = () => {
        if(!isMultiplanarActive) return
        setPixelAnalizeActive(!isPixelAnalizeActive)       
    }

    return (
        <>
        { isMultiplanarActive ? (<div className={styles.Button}>
            <Tooltip placement="topLeft" title={t("visualizer.header.pixelSelector")}>
                <Button 
                    onClick={onClick} 
                    style={
                        {   backgroundColor: "#1f2127",
                            color: isPixelAnalizeActive ?  "#38b6ff" : "#d5d5d5",
                            borderColor: isPixelAnalizeActive ? "#38b6ff" : "#e9e9e9" 
                        }
                    }
                >
                    <span
                        className="kenko-icon-pixel"
                    ></span>
                </Button>
            </Tooltip>
        </div>
        ) : (<></>) }
        </>
        
    );
};