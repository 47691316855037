import { INotification } from "../../appHeader/appHeader";
import styles from "./notificationTile.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import HtmlPrinter from "../../helpers/htmlPrinter/htmlPrinter";

interface IProps {
    notification: INotification;
    text: string;
    setAsRead: () => void;
    deleteNotification: () => void;
}
export const NotificationTile = ({
    notification,
    setAsRead,
    text,
    deleteNotification,
}: IProps) => {
    return (
        <li className={styles.Container}>
            {!notification.read ? (
                <div className={styles.Content} onClick={() => setAsRead()}>
                    <span className={styles.Dot} />
                    <HtmlPrinter content={text} />
                </div>
            ) : (
                <div className={`${styles.Content} ${styles.Read}`}>
                    <span className={styles.NoDot} />
                    <HtmlPrinter content={text} />
                </div>
            )}
            <CloseOutlined onClick={() => deleteNotification()} />
        </li>
    );
};
