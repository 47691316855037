import { useTranslation } from "react-i18next";
import { Alert, Button, Typography } from "antd";
import { Spacer } from "../../../appCore/spacer/spacer";

const { Text } = Typography;

interface IProps {}

export const CaseProcessingError = ({}: IProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Alert
                type="error"
                message={t("caseDetails.resultFolders.errorAnalyzing.title")}
                description={t(
                    "caseDetails.resultFolders.errorAnalyzing.description"
                )}
                showIcon
            />
            <Spacer size="m" />
            <Text>
                {t(
                    "caseDetails.resultFolders.errorAnalyzing.supportDescription"
                )}
            </Text>
            <Spacer size="s" />
            <Button>
                {t("caseDetails.resultFolders.errorAnalyzing.supportButton")}
                <span className="kenko-icon-right-arrow button-icon"></span>
            </Button>
        </>
    );
};
