import {kenkoDataSource} from "../core/data/dataSources/kenkoDataSource";

const getCookies = (caseId: any) => {
    return kenkoDataSource({
        method: "GET",
        url: "/visualizer/" + caseId,
        withCredentials: true,
    });
};

// TODO: Possibly move some of these services to another file

const getBiomarkers = (caseId: any) => {
    return kenkoDataSource({
        method: "GET",
        url: "/visualizer/biomarkers/" + caseId,
    });
};

const getImages = (caseId: any, type: string) => {
    return kenkoDataSource({
        method: "GET",
        url: "/visualizer/images/" + caseId,
        params: {
            type: type,
        },
    });
};

const cookieSignedService = {
    getCookies,
    getBiomarkers,
    getImages,
};

export default cookieSignedService;
