import { Button, Card, Form, Input, Typography } from "antd";
import { Spacer } from "../../../appCore/spacer/spacer";
import styles from "./myAdminProfileLicenseDetails.module.scss";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OrganisationQuery } from "../../../../generated/graphql";
import { OrganTag } from "../../../appCore/organTag/organTag";
import { useForm } from "antd/es/form/Form";
import { LicensesProgressBar } from "../../common/licensesProgressBar/licensesProgressBar";
const { Title, Text } = Typography;

interface IProps {
    getOrganisationData: OrganisationQuery | undefined;
    setIsChangeLicenseRequestModalVisible: Dispatch<SetStateAction<boolean>>;
    setIsExtendLicenseRequestModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const MyAdminProfileLicenseDetails = ({
    getOrganisationData,
    setIsChangeLicenseRequestModalVisible,
    setIsExtendLicenseRequestModalVisible,
}: IProps) => {
    const { t } = useTranslation();
    let { name, organisationLicense } = getOrganisationData?.organisation || {};
    const [form] = useForm();

    useEffect(() => {
        if (!name) {
            return;
        }
        form.setFieldsValue({ orgName: name });
    }, [name]);

    return (
        <Card>
            <Form layout="vertical" form={form}>
                <div>
                    <Title level={4}>
                        {t("myAdminProfile.orgAndLicenseDetails.title")}
                    </Title>
                    <Text type="secondary">
                        {t("myAdminProfile.orgAndLicenseDetails.subtitle")}
                    </Text>
                </div>

                <Spacer size={"m"} />

                <div className={styles.Row}>
                    <div className={styles.Col}>
                        <Form.Item
                            label={t(
                                "myAdminProfile.orgAndLicenseDetails.organisationTitle"
                            )}
                            name="orgName"
                        >
                            <Input
                                placeholder={t(
                                    "myAdminProfile.orgAndLicenseDetails.organisationPrompt"
                                )}
                                disabled
                            />
                        </Form.Item>
                    </div>

                    <div className={styles.Col}></div>
                </div>
            </Form>

            <Spacer size={"xs"} />

            <Text>
                {t("myAdminProfile.orgAndLicenseDetails.licenseDetails.title")}
            </Text>

            <Spacer size={"xs"} />

            <div className={styles.LicenseBox}>
                <LicenseEntry
                    title={t(
                        "myAdminProfile.orgAndLicenseDetails.licenseDetails.type"
                    )}
                >
                    <Text strong>
                        {organisationLicense &&
                            organisationLicense.licenseTemplateReference.name}
                    </Text>
                </LicenseEntry>
                <LicenseEntry
                    title={t(
                        "myAdminProfile.orgAndLicenseDetails.licenseDetails.price"
                    )}
                >
                    <Text strong>
                        {organisationLicense &&
                            organisationLicense.totalPrice + "€"}
                    </Text>
                    {/* TODO: Improve price format */}
                </LicenseEntry>
                <LicenseEntry
                    title={t(
                        "myAdminProfile.orgAndLicenseDetails.licenseDetails.organ"
                    )}
                >
                    <Text strong>
                        {getOrganisationData && (
                            <OrganTag organ={organisationLicense!.organ} />
                        )}
                    </Text>
                </LicenseEntry>
                <LicenseEntry
                    title={t(
                        "myAdminProfile.orgAndLicenseDetails.licenseDetails.userLicenses"
                    )}
                >
                    <div className={styles.FractionContainer}>
                        <LicensesProgressBar
                            remainingNum={
                                organisationLicense
                                    ? organisationLicense.remainingUsers
                                    : 0
                            }
                            maxNum={organisationLicense?.maxNumberOfUsers!}
                            isSelect={false}
                        />
                    </div>
                </LicenseEntry>
                <LicenseEntry
                    title={t(
                        "myAdminProfile.orgAndLicenseDetails.licenseDetails.numberOfCases"
                    )}
                >
                    <div className={styles.FractionContainer}>
                        <LicensesProgressBar
                            remainingNum={
                                organisationLicense
                                    ? organisationLicense.remainingCases
                                    : 0
                            }
                            maxNum={organisationLicense?.maxNumberOfCases!}
                            isSelect={false}
                        />
                    </div>
                </LicenseEntry>
            </div>

            <Spacer size={"m"} />

            <div>
                <Button
                    size="large"
                    type="primary"
                    className={styles.ChangeButton}
                    onClick={() => setIsChangeLicenseRequestModalVisible(true)}
                >
                    {t(
                        "myAdminProfile.orgAndLicenseDetails.licenseExtensionRequest"
                    )}
                </Button>
            </div>
        </Card>
    );
};

interface ILicenseEntryProps {
    title: string;
    children: JSX.Element;
}

const LicenseEntry = ({ title, children }: ILicenseEntryProps) => {
    return (
        <div className={styles.LicenseEntry}>
            <Text>{title}</Text>
            <div>{children}</div>
        </div>
    );
};
