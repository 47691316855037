import { StepsComponent } from "../../../components/appCore/stepsComponent/stepsComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useNavigate, useParams } from "react-router-dom";
import { ILicenseFeature } from "../../../features/licenseFeature/domain/entities/licenseFeature";
import { CreateEditLicenseFeatureForm } from "../../../features/licenseFeature/presentation/createAndEditLicenseFeatureForm/createAndEditLicenseFeatureForm";
import { Col, Row } from "antd";
import { useEditLicenseFeature } from "../../../features/licenseFeature/domain/hooks/useEditLicenseFeature";
import styles from "./editLicenseFeaturePage.module.scss";
import { useGetLicenseFeature } from "../../../features/licenseFeature/domain/hooks/useLicenseFeature";


export const EditLicenseFeaturePage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [licenseFeature, setLicenseFeature] =
        useState<ILicenseFeature>();
    const { setHeaderContent } = useHeader();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalError, setIsResultModalError] = useState(false);
    let { featureId } = useParams<any>();
    
    const { data: licenseFeatureData, isLoading, errorMessage,  editLicenseFeature} = useEditLicenseFeature()
    const {data: getLicenseFeatureData, getLicenseFeature} = useGetLicenseFeature();

    useEffect(() => {
        setHeaderContent({
            title: t("licenseFeature.exitHeader"),
            breadCrumb: [
                { label: t("licenseFeature.exitHeaderSub"), link: "/features" },
            ],
            goBackLinkOverride: "/features",
        });

        if(!featureId) return;
        getLicenseFeature(featureId);
    }, []);

    useEffect(()=> {
        if(!getLicenseFeatureData) return;
        setLicenseFeature(getLicenseFeatureData.licenseFeature)
    }, [getLicenseFeatureData])

    const onFinish = () => {
        if(!licenseFeature) return
        editLicenseFeature(licenseFeature!)
    };

    useEffect(()=> {
        if(errorMessage) setIsResultModalError(true);
        if(!licenseFeatureData) return;
        setIsResultModalVisible(true);
        setLicenseFeature(licenseFeatureData)
    }, [licenseFeatureData, errorMessage])

    const onShowLicenseFeature = () => {
        navigate("../feature/" + featureId);
    };

    const onShowFeaturesList = () => {
        navigate("/features");
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepsComponent
                        currentStep={0}
                        title={t("licenseFeature.edit.title")}
                        steps={[
                            {
                                title: t("licenseFeature.edit.step.title"),
                                description: t(
                                    "licenseFeature.edit.step.description"
                                ),
                            }
                        ]}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <CreateEditLicenseFeatureForm
                            onButtonClick={onFinish}
                            setLicenseFeatureInput={undefined}
                            featureId={featureId}
                            licenseFeature={licenseFeature}
                            setLicenseFeature={setLicenseFeature}
                            action="edit"
                        />
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isLoading}
                isError={isResultModalError}
                onPrimary={onShowLicenseFeature}
                onErrorSecondary={onShowLicenseFeature}
                onErrorPrimary={onShowFeaturesList}
                localisedTexts={{
                    title: t("licenseFeature.edit.resultModal.title"),
                    subtitle: t("licenseFeature.edit.resultModal.subtitle"),
                    primaryButton: t(
                        "licenseFeature.edit.resultModal.close"
                    ),
                    errorTitle: t("licenseFeature.edit.resultModal.errorTitle"),
                    errorRetry: t("licenseFeature.edit.resultModal.errorRetry"),
                }}
            />
        </>
    );
};
