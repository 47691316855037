import { useTranslation } from "react-i18next";
import styles from "./preferencesCard.module.scss";
import { Card, Select, Typography } from "antd";
import { Spacer } from "../../appCore/spacer/spacer";
import { useState } from "react";
import { getCookie, setCookie } from "../../helpers/cookieUtils/cookieUtils";

const { Title, Text } = Typography;

export const PreferencesCard = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(
        getCookie("profileLanguage") ?? navigator.language
    );

    const handleLangChange = (
        value: string,
        option:
            | { label: string; value: string }
            | { label: string; value: string }[]
    ) => {
        const lang = value;
        setLanguage(lang);
        i18n.changeLanguage(lang);
        setCookie("profileLanguage", lang);
    };

    const options = [
        { label: t("myProfile.preferences.es"), value: "es" },
        { label: t("myProfile.preferences.en"), value: "en" },
    ];

    return (
        <Card className={styles.Card}>
            <div>
                <Title level={4}>{t("myProfile.preferences.title")}</Title>
                <Text type="secondary">
                    {t("myProfile.preferences.subtitle")}
                </Text>
            </div>
            <Spacer size="s" />
            <Text>{t("myProfile.preferences.language")}</Text>
            <div className={styles.Language}>
                <div>
                    <span className="kenko-icon-world" />
                </div>
                <div>
                    {/*<Title level={5}>{t("myProfile.preferences.es")}</Title>*/}
                    {/*TODO: Unhardcode this*/}
                    <Select
                        onChange={(value, option) =>
                            handleLangChange(value, option)
                        }
                        value={language}
                        options={options}
                    />
                </div>
            </div>
        </Card>
    );
};
