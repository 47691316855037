import { ReportHandler } from "web-vitals";
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import("web-vitals").then(
            ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);
            }
        );
    }
};

export const reportWebVitalsToGrafanaFaro = () => {
    return initializeFaro({
        url: process.env.REACT_APP_GRAFANA_FARO_URL,
        app: {
            name: "kenko-imalytics",
            version: "1.0.0",
            environment: process.env.REACT_APP_STAGE,
        },
        instrumentations: [
            // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
            ...getWebInstrumentations({
                captureConsoleDisabledLevels: [],
            }),
        ],
    });
};
