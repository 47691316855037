import { Button } from "antd";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    isFinalDiagnosisBtnEnabled: boolean | true;
    isFinalDiagnosisModalVisible: boolean; 
    setIsFinalDiagnosisModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const VisualizerFinalDiagnosisBtn = ({
    isFinalDiagnosisBtnEnabled,
    isFinalDiagnosisModalVisible,
    setIsFinalDiagnosisModalVisible
}: IProps) => {
    const { t } = useTranslation();

    const onClickFinalDiagnosisBtn = () => {
        setIsFinalDiagnosisModalVisible(!isFinalDiagnosisModalVisible)
    }

    return (
        <div >
            { !isFinalDiagnosisBtnEnabled ? (<></>) : 
            (
                <Button type="primary"
                    style={
                        { margin: "0 0 0 16px",
                          borderRadius: "10px"
                        }
                    }
                    onClick={() =>
                        onClickFinalDiagnosisBtn()
                    }
                    >
                        {t("visualizer.header.finalDiagnosis")}
                </Button>
            )}
            
        </div>
    );
};