import { gql } from "@apollo/client";

const GET_BIOMARKERS = gql`
    query sequencesByCase($caseId: String!) {
        sequencesByCase(caseId: $caseId) {
            type
            biomarkers {
                key
                value
            }
            views{
                key
                value
            }
            singleImages
        }
    }
`;

const GET_IMAGES = gql`
    query images($caseId: String!) {
        images(caseId: $caseId) {
            model
            biomarkers{
                key
                value {
                    key
                    value
                }
            }
            singleImages{
                key
                value
            }
        }
    }
`;

export const sequenceFragments = {
    GET_BIOMARKERS,
    GET_IMAGES,
};
