import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Typography } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Spacer } from "../spacer/spacer";
import { useMutation } from "@apollo/client";
import fragments from "../../../gql/fragments";
import { ResetPasswordMutation } from "../../../generated/graphql";
import { useForm } from "antd/es/form/Form";
import { ResultModal } from "../resultModal/resultModal";

const { Text } = Typography;

interface IProps {
    setIsChangePasswordModalVisible: Dispatch<SetStateAction<boolean>>;
}

interface IPasswordForm {
    newPassword: string;
    matchingPassword: string;
}

export const ChangePasswordModal = ({
    setIsChangePasswordModalVisible,
}: IProps) => {
    const { t } = useTranslation();
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(true);

    const [ResetPasswordMutation] = useMutation<ResetPasswordMutation>(
        fragments.RESET_PASSWORD
    );

    return (
        <>
            <Modal
                title={t("myAdminProfile.passwordChange.title")}
                visible={!isSuccessModalVisible}
                okText={t("myAdminProfile.passwordChange.accept")}
                cancelText={t("cancel")}
                onCancel={() => setIsChangePasswordModalVisible(false)}
                onOk={() => {
                    setIsSuccessModalVisible(true);
                    ResetPasswordMutation()
                        .then((res) => {
                            if (!res.data) return; // TODO: set error state for success modal here
                            setIsSuccessModalLoading(false);
                        })
                        .catch((e) => {}); // TODO: set error state for success modal here
                }}
            >
                <Text>{t("myAdminProfile.passwordChange.subtitle")}</Text>
            </Modal>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                onPrimary={() => {
                    setIsSuccessModalVisible(false);
                    setIsChangePasswordModalVisible(false);
                }}
                hasSecondary={false}
                localisedTexts={{
                    title: t("myAdminProfile.passwordChange.success.title"),
                    subtitle: t(
                        "myAdminProfile.passwordChange.success.subtitle"
                    ),
                    primaryButton: t(
                        "myAdminProfile.passwordChange.success.back"
                    ),
                }}
            />
        </>
    );
};
