import { Col, Row } from "antd";
import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserLicenseInfo } from "../../../features/userLicense/presentation/userLicenseInfo/userLicenseInfo";
import { useEffect, useState } from "react";
import { useHeader } from "../../../hooks/headerHook";
import { useQuery } from "@apollo/client";
import fragments from "../../../gql/fragments";
import { OrganisationQuery } from "../../../generated/graphql";
import { IOrganisationLicenseCounters } from "../../../features/organisation/domain/entities/organisationModel";
import {
    IUserLicense,
    IUserLicenseCreate,
} from "../../../features/userLicense/domain/entities/userLicenseModel";
import { IUserBasicInfo } from "../../../features/user/domain/entities/userModel";
import styles from "./reloadUserLicensePage.module.scss";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useReloadUserLicense } from "../../../features/userLicense/domain/hooks/useReloadUserLicense";
import { useUserLicense } from "../../../features/userLicense/domain/hooks/useUserLicense";

export const ReloadUserLicensePage = () => {
    let { userLicenseId } = useParams<any>();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { setHeaderContent } = useHeader();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isIconVisible, setIsIconVisible] = useState<boolean>(true);
    const [remainingCounters, setRemainingCounters] =
        useState<IOrganisationLicenseCounters>();
    const [userLicenseModel, setUserLicenseModel] = useState<IUserLicense>();
    const [updatedUserDetails, setUpdateUserDetails] = useState<
        IUserBasicInfo | undefined
    >();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [userLicenseDetails, setUserLicenseDetails] = useState<
        IUserLicenseCreate | undefined
    >();

    const { data: organisationLicenseData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION
    );

    //===============HOOKS==================
    const {
        data: userLicenseReload,
        isLoading: isLoadingReload,
        errorMessage: errorMessageReload,
        reloadUserLicense,
    } = useReloadUserLicense();
    const {
        data: userLicenseData,
        isLoading,
        errorMessage,
        getUserLicense,
    } = useUserLicense();

    //===============UPDATES==================
    const updateUpdatedUserDetails = (value: IUserBasicInfo | undefined) => {
        setUpdateUserDetails(value);
    };
    const updateUserLicenseNumCases = (value: number | undefined) => {
        setUserLicenseDetails((prevState) => ({
            ...prevState,
            numberOfCases: value,
            id: prevState?.id,
        }));
    };
    const updateCurrentStep = (value: number) => {
        setCurrentStep((prevStep) => prevStep + value);
    };
    const updateIsIconVisible = (value: boolean) => {
        setIsIconVisible(value);
    };

    //===============FUNCTIONS==================
    const onRealoadUserLicense = () => {
        if (!userLicenseId || !userLicenseDetails?.numberOfCases) return;
        reloadUserLicense(userLicenseId, userLicenseDetails?.numberOfCases);
    };

    const onShowUserLicenses = () => {
        navigate("/userLicenses");
    };

    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };

    //===============USEEFFECTS==================
    useEffect(() => {
        if (!userLicenseId) return;
        getUserLicense(userLicenseId);
    }, [userLicenseData]);

    useEffect(() => {
        if (organisationLicenseData) {
            setRemainingCounters({
                remainingCases:
                    organisationLicenseData?.organisation.organisationLicense
                        .remainingCases,
                remainingUsers:
                    organisationLicenseData?.organisation.organisationLicense
                        .remainingUsers,
            });
        }
        if (userLicenseData) {
            updateUserLicenseNumCases(0);
            updateUpdatedUserDetails(userLicenseData.user as IUserBasicInfo);
            setUserLicenseModel(userLicenseData);
        }
    }, [organisationLicenseData, userLicenseData]);

    useEffect(() => {
        if (errorMessageReload) setIsResultModalError(true);
        if (!userLicenseReload) return;
        setIsResultModalLoading(isLoadingReload);
        setIsResultModalVisible(true);
    }, [userLicenseReload, errorMessageReload]);

    useEffect(() => {
        setHeaderContent({
            title: t("createLicense.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/userLicenses",
        });
    }, []);

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        isComplete={true}
                        title={
                            t("editUserLicense.reloadTitle") +
                            "LIC-" +
                            userLicenseModel?.creationOrder
                        }
                        steps={[
                            {
                                title: t("editUserLicense.steps.1.title"),
                                description: t(
                                    "editUserLicense.steps.1.description"
                                ),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                        isEditStep={true}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <UserLicenseInfo
                        remainingCases={remainingCounters?.remainingCases}
                        updateUserLicenseNumCases={updateUserLicenseNumCases}
                        updateCurrentStep={updateCurrentStep}
                        updateIsIconVisible={updateIsIconVisible}
                        userLicenseDetails={userLicenseDetails}
                        userLicenseModel={userLicenseModel}
                        buttonText={t("editUserLicense.reloadUserLicense")}
                        onRealoadUserLicense={onRealoadUserLicense}
                        subtitle={t("editUserLicense.cardSubtitle")}
                    />
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowUserLicenses}
                hasSecondary={false}
                localisedTexts={{
                    title: t("editUserLicense.successModal.titleReloadCases"),
                    subtitle: "",
                    secondaryButton: t(
                        "createUserLicense.successModal.createAnother"
                    ),
                    primaryButton: t("createUserLicense.successModal.list"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
        </>
    );
};
