import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { UserDataSource } from "../../data/dataSources/userDataSource";
import { faro } from "@grafana/faro-web-sdk";
import { IUserListPresentation } from "../entities/userModel";
import { SortInputOfUserSortableFieldsInput } from "../../../../generated/graphql";

interface IAdditionalMethods {
    getAllUsers: (
        isUsersWithoutLicense: boolean,
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserSortableFieldsInput[],
        textSearchInput?: string
    ) => Promise<IUserListPresentation | undefined | null>;
}

export const useAllUsers: IUseDomainHook<
    IUserListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = UserDataSource();
    const [userList, setUserList] = useState<
        IUserListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllUsers = async (
        isUsersWithoutLicense: boolean,
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserSortableFieldsInput[],
        textSearchInput?: string
    ) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getAllUsers(
                isUsersWithoutLicense,
                pageSize,
                pageNumber,
                sortInputs,
                textSearchInput
            );
            setUserList(result);
            setIsLoading(false);
            return result;
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
            setIsLoading(false);
            return undefined;
        }
    };

    return {
        data: userList,
        isLoading,
        errorMessage,
        getAllUsers,
    };
};
