import { Form, Input } from "antd";
import styles from "./searchBar.module.scss";
import { SearchOutlined } from "@ant-design/icons";

export interface IProps {
    onFilter: (textInput: string) => void;
    placeholder: string;
}

export const SearchBar = ({ onFilter, placeholder }: IProps) => {
    const onSearch = (value: any) => {
        onFilter(value.textSearchInput);
    };

    return (
        <Form onValuesChange={onSearch} className={styles.search}>
            <Form.Item name="textSearchInput">
                <Input
                    prefix={<SearchOutlined />}
                    allowClear
                    placeholder={placeholder}
                />
            </Form.Item>
        </Form>
    );
};
