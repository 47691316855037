import {
    Button,
    Card,
    Divider,
    Radio,
    RadioChangeEvent,
    Space,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IUserBasicInfo,
    IUserListPresentation,
} from "../../../user/domain/entities/userModel";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import { ChooseUser } from "../chooseUser/chooseUser";
import styles from "./assignUser.module.scss";
import { CreateUserForm } from "../../../user/presentation/createUserForm/createUserForm";

const { Title, Text } = Typography;

interface IProps {
    updateCheckValue: (value: number) => void;
    checkValue: number | undefined;
    onCreateFullLicense: () => void;
    onCreateSimpleLicense?: () => void;
    usersWithoutLicense: IUserListPresentation | undefined;
    updateUserLicenseUserId: (value: string | undefined) => void;
    updatedUserDetails?: IUserBasicInfo | undefined;
    isEditPage: boolean;
    updateUserName: (value: string | undefined) => void;
    updateUserSurname: (value: string | undefined) => void;
    updateUserEmail: (value: string | undefined) => void;
    email: string | undefined;
    onLoadMoreUsers: (pageNumber: number) => void;
    onFilterUser: (textInput: string) => void;
    textSearchUser: string;
    unfilteredUsers: IUserBasicInfo[] | undefined;
    filteredUsers: IUserBasicInfo[] | undefined;
}
export const AssignUser = ({
    updateCheckValue,
    checkValue,
    onCreateFullLicense,
    usersWithoutLicense,
    updateUserLicenseUserId,
    onCreateSimpleLicense,
    updatedUserDetails,
    isEditPage,
    updateUserName,
    updateUserSurname,
    updateUserEmail,
    email,
    onLoadMoreUsers,
    onFilterUser,
    textSearchUser,
    unfilteredUsers,
    filteredUsers,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isUserDeleted, setIsUserDeleted] = useState<boolean>();
    const [checkEmail, setCheckEmail] = useState<string | undefined>("");
    const [isEmailChecked, setIsEmailChecked] = useState<boolean>();

    useEffect(() => {
        if (!!isEmailChecked) {
            if (isUserDeleted === true) {
                onCreateFullLicense();
            } else if (isUserDeleted === false) {
                setIsFormValid(false);
            }
        }
    }, [isUserDeleted]);

    useEffect(() => {}, [isUserDeleted]);

    const onChange = (e: RadioChangeEvent) => {
        if (!e) {
            return;
        }
        updateCheckValue(e.target.value);
        updateIsFormValid(false);
    };

    const updateIsFormValid = (value: boolean) => {
        setIsFormValid(value);
    };
    const updateIsUserDeleted = (value: boolean | undefined) => {
        setIsUserDeleted(value);
    };

    const onClick = () => {
        if (checkValue === 1) {
            onCreateFullLicense();
        } else if (checkValue === 2) {
            setCheckEmail(email);
            setIsEmailChecked(true);
        }
    };

    return (
        <>
            <Card>
                <div>
                    <Title level={4}>
                        {t("createUserLicense.inviteUser.title")}
                    </Title>
                    <Text type="secondary">
                        {t("createUserLicense.inviteUser.subtitle")}
                    </Text>
                </div>
                <Spacer size={"m"} />
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Text>
                            {t("createUserLicense.inviteUser.question.title")}
                        </Text>
                    </div>
                </div>
                <Spacer size="xs" />
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Radio.Group onChange={onChange}>
                            <Space direction="vertical">
                                <Radio value={1}>
                                    {t(
                                        "createUserLicense.inviteUser.question.yes"
                                    )}
                                </Radio>
                                <Radio value={2}>
                                    {t(
                                        "createUserLicense.inviteUser.question.no"
                                    )}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>

                <Divider />

                {(checkValue === 1 && (
                    <ChooseUser
                        usersWithoutLicense={usersWithoutLicense}
                        updateUserLicenseUserId={updateUserLicenseUserId}
                        updateIsFormValid={updateIsFormValid}
                        updatedUserDetails={updatedUserDetails}
                        onLoadMoreUsers={onLoadMoreUsers}
                        textSearchUser={textSearchUser}
                        unfilteredUsers={unfilteredUsers}
                        filteredUsers={filteredUsers}
                        onFilterUser={onFilterUser}
                    />
                )) ||
                    (checkValue === 2 && (
                        <CreateUserForm
                            title={t(
                                "createUserLicense.inviteUser.invite.title"
                            )}
                            subtitle={t(
                                "createUserLicense.inviteUser.invite.subtitle"
                            )}
                            updateIsFormValid={updateIsFormValid}
                            updateUserName={updateUserName}
                            updateUserSurname={updateUserSurname}
                            updateUserEmail={updateUserEmail}
                            isEditPage={isEditPage}
                            isUserDeleted={isUserDeleted}
                            checkEmail={checkEmail}
                            updateIsUserDeleted={updateIsUserDeleted}
                        />
                    ))}

                <div className={styles.stepNavigation}>
                    {!isEditPage && (
                        <Button
                            className={styles.buttonLater}
                            type="default"
                            htmlType="submit"
                            onClick={onCreateSimpleLicense}
                        >
                            {t("createUserLicense.inviteUser.postpone")}
                        </Button>
                    )}
                    <Button
                        className={styles.buttonCreate}
                        type="primary"
                        htmlType="submit"
                        disabled={!isFormValid}
                        onClick={onClick}
                    >
                        {!isEditPage
                            ? t("createUserLicense.inviteUser.create")
                            : t("editUserLicense.save")}
                    </Button>
                </div>
            </Card>
        </>
    );
};
