import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AddUserMutation,
    AllUsersQuery,
    OrganisationQuery,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { IOrganisationLicenseCounters } from "../../../features/organisation/domain/entities/organisationModel";
import { IUserLicenseCreate } from "../../../features/userLicense/domain/entities/userLicenseModel";
import {
    IUserBasicInfo,
    IUserListPresentation,
    IUserReducedDto,
} from "../../../features/user/domain/entities/userModel";
import { UserLicenseComponent } from "../../../features/userLicense/presentation/userLicenseComponent/userLicenseComponent";
import { useCreateUserLicense } from "../../../features/userLicense/domain/hooks/useCreateUserLicense";
import { useAllUsers } from "../../../features/user/domain/hooks/useAllUsers";

export const CreateUserLicensePage = () => {
    const { t } = useTranslation();
    const [userLicenseDetails, setUserLicenseDetails] = useState<
        IUserLicenseCreate | undefined
    >();
    const [usersWithoutLicense, setUsersWithoutLicense] = useState<
        IUserListPresentation | undefined
    >();
    const [createUserDetails, setCreateUserDetails] = useState<
        IUserReducedDto | undefined
    >();
    const [remainingCounters, setRemainingCounters] =
        useState<IOrganisationLicenseCounters>();
    const [checkValue, setCheckValue] = useState<number | undefined>();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [unfilteredUsers, setUnfilteredUsers] = useState<IUserBasicInfo[]>();
    const [filteredUsers, setFilteredUsers] = useState<IUserBasicInfo[]>();
    const [textSearchUser, setTextSeachUser] = useState<string>("");
    const ELEMENTS_PAGE = 10;
    const PAGE_NUMBER = 0;

    const { data: organisationLicenseData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION
    );
    const [AddUserMutation] = useMutation<AddUserMutation>(
        fragments.CREATE_USER,
        {
            variables: {
                firstName: createUserDetails?.firstName,
                lastName: createUserDetails?.lastName,
                email: createUserDetails?.email,
            },
        }
    );
    //===============HOOKS==================
    const {
        data: userLicenseData,
        isLoading,
        errorMessage,
        createUserLicense,
    } = useCreateUserLicense();
    const {
        data: usersWithoutLicenseData,
        isLoading: isLoadingAllUsers,
        errorMessage: errorMessageAllUsers,
        getAllUsers,
    } = useAllUsers();

    useEffect(() => {
        getAllUsers(true, ELEMENTS_PAGE, PAGE_NUMBER).then((res) => {
            if (!res) return;
            setUnfilteredUsers(res?.userPresentations);
        });
    }, []);

    useEffect(() => {
        if (!organisationLicenseData) {
            return;
        }
        if (!organisationLicenseData.organisation.organisationLicense) {
            return;
        }
        setRemainingCounters({
            remainingCases:
                organisationLicenseData.organisation.organisationLicense
                    .remainingCases,
            remainingUsers:
                organisationLicenseData.organisation.organisationLicense
                    .remainingUsers,
        });
        if (!usersWithoutLicenseData) {
            return;
        }
        setUsersWithoutLicense(usersWithoutLicenseData);
    }, [organisationLicenseData, usersWithoutLicenseData]);

    const updateUserLicenseUserId = (value: string | undefined | null) => {
        setUserLicenseDetails((prevState) => ({
            ...prevState,
            numberOfCases: prevState?.numberOfCases,
            id: value,
        }));
    };
    const updateUserLicenseNumCases = (value: number | undefined) => {
        setUserLicenseDetails((prevState) => ({
            ...prevState,
            numberOfCases: value,
            id: prevState?.id,
        }));
    };
    const updateCreateUserDetails = (value: IUserReducedDto | undefined) => {
        setCreateUserDetails(value);
    };
    const updateCheckValue = (value: number | undefined) => {
        setCheckValue(value);
    };
    const updateUserName = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: value,
            lastName: prevState?.lastName,
            email: prevState?.email,
        }));
    };
    const updateUserSurname = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: prevState?.firstName,
            lastName: value,
            email: prevState?.email,
        }));
    };
    const updateUserEmail = (value: string | undefined) => {
        setCreateUserDetails((prevState) => ({
            firstName: prevState?.firstName,
            lastName: prevState?.lastName,
            email: value,
        }));
    };

    const onLoadMoreUsers = (pageNumber: number) => {
        getAllUsers(
            true,
            ELEMENTS_PAGE,
            pageNumber,
            undefined,
            textSearchUser
        ).then((res: IUserListPresentation | null | undefined) => {
            if (!res) {
                return;
            }
            if (!textSearchUser) {
                setUnfilteredUsers(
                    unfilteredUsers?.concat(res.userPresentations)
                );
            } else {
                setFilteredUsers(filteredUsers?.concat(res.userPresentations));
            }
        });
    };

    const onFilterUser = (textInput: string) => {
        setTextSeachUser(textInput);
        getAllUsers(
            true,
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            undefined,
            textInput
        ).then((res) => {
            if (!res) return;
            setFilteredUsers(res?.userPresentations);
        });
    };

    const onCreateFullLicense = () => {
        if (checkValue === 1) {
            //choose
            if (!userLicenseDetails?.numberOfCases || !userLicenseDetails?.id)
                return;
            createUserLicense(
                userLicenseDetails?.numberOfCases,
                userLicenseDetails?.id
            );
        } else if (checkValue === 2) {
            //create
            AddUserMutation({
                variables: createUserDetails,
            }).then((res) => {
                if (!userLicenseDetails?.numberOfCases || !res.data) return;
                createUserLicense(
                    userLicenseDetails?.numberOfCases,
                    res.data?.addUser.id
                );
            });
        }
    };
    const onCreateSimpleLicense = () => {
        if (!userLicenseDetails?.numberOfCases) return;
        createUserLicense(userLicenseDetails?.numberOfCases, null);
    };
    useEffect(() => {
        if (errorMessage) setIsResultModalError(true);
        if (!userLicenseData) return;
        setIsResultModalLoading(isLoading);
        setIsResultModalVisible(true);
    }, [userLicenseData, errorMessage]);

    return (
        <UserLicenseComponent
            userLicenseDetails={userLicenseDetails}
            updateUserLicenseUserId={updateUserLicenseUserId}
            updateUserLicenseNumCases={updateUserLicenseNumCases}
            usersWithoutLicense={usersWithoutLicense}
            remainingCounters={remainingCounters}
            onCreateFullLicense={onCreateFullLicense}
            onCreateSimpleLicense={onCreateSimpleLicense}
            checkValue={checkValue}
            updateCheckValue={updateCheckValue}
            isResultModalVisible={isResultModalVisible}
            isResultModalError={isResultModalError}
            isResultModalLoading={isResultModalLoading}
            titleModal={t("createUserLicense.successModal.title")}
            subtitleModal={t("createUserLicense.successModal.subtitle")}
            isEditPage={false}
            updateUserName={updateUserName}
            updateUserSurname={updateUserSurname}
            updateUserEmail={updateUserEmail}
            titleStep={t("createUserLicense.title")}
            email={createUserDetails?.email}
            onLoadMoreUsers={onLoadMoreUsers}
            textSearchUser={textSearchUser}
            unfilteredUsers={unfilteredUsers}
            filteredUsers={filteredUsers}
            onFilterUser={onFilterUser}
        />
    );
};
