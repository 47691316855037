import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { Content } from "antd/es/layout/layout";
import { Col, Divider, Row } from "antd";
import { LicenseDetailsDescription } from "../../../components/adminComponents/licenseDetailsComponents/licenseDetailsDescription/licenseDetailsDescription";
import { useQuery } from "@apollo/client";
import { OrganisationQuery } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { useEffect, useState } from "react";
import { ILicensePresentation } from "../../../features/licenseTemplate/domain/entities/licenseModel";
import { OrganisationDetailsDescription } from "../../../components/adminComponents/organisationDetailsComponents/organisationDetailsDescription/organisationDetailsDescription";
import { IOrganisationDetailsDto } from "../../../features/organisation/domain/entities/organisationModel";
import { CounterComponent } from "../../../components/organisationAdminComponents/statisticsComponents/counterComponent/counterComponent";
import { OrganisationLicenseFeatures } from "../../../components/adminComponents/organisationDetailsComponents/organisationLicenseFeaturesComponents/organisationLicenseFeatures";
import { ILicenseFeature } from "../../../features/licenseFeature/domain/entities/licenseFeature";

export const OrganisationDetailsPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();

    const [licenseDetails, setLicenseDetails] =
        useState<ILicensePresentation>();

    const [organisationDetailsCombined, setOrganisationDetailsCombined] =
        useState<IOrganisationDetailsDto>();

    const [licenseFeatures, setLicenseFeatures] = useState<ILicenseFeature[]>();

    const location = useLocation();
    const [isFromOrg] = useState<boolean>(true);

    let { organisationId } = useParams<any>();

    const { data: getOrganisationData, refetch: refetchOrganisationData } =
        useQuery<OrganisationQuery>(fragments.GET_ORGANISATION, {
            variables: { orgId: organisationId },
        });

    useEffect(() => {
        refetchOrganisationData().then();
    }, [location]);

    useEffect(() => {
        setHeaderContent({
            title: t("organisationDetails.exitHeader"),
            breadCrumb: [
                {
                    label: t("organisationDetails.exitHeader"),
                    link: "/",
                },
            ],
            goBackLinkOverride: "/",
        });
    }, []);

    // TODO: Set header again with the updated breadcrumb

    useEffect(() => {
        if (
            !getOrganisationData ||
            !getOrganisationData.organisation ||
            !getOrganisationData.organisation.admin ||
            !getOrganisationData.organisation.organisationLicense
        ) {
            return;
        }
        let org = getOrganisationData.organisation;
        let orgLicense = getOrganisationData.organisation.organisationLicense;

        setOrganisationDetailsCombined({
            id: org.id,
            name: org.name,
            cifAndOthers: org.cifAndOthers,
            adminName: org.admin!.name,
            email: org.admin!.email,
        } as IOrganisationDetailsDto);

        if (!org.distributor || !org.sequencesTemplate) {
            return;
        }
        setLicenseDetails({
            id: orgLicense.licenseTemplateReference.id,
            name: orgLicense.licenseTemplateReference.name,
            totalPrice: orgLicense.totalPrice,
            maxNumberOfCases: orgLicense.maxNumberOfCases,
            maxNumberOfUsers: orgLicense.maxNumberOfUsers,
            numberOfRemainingCases: orgLicense.remainingCases,
            numberOfRemainingUsers: orgLicense.remainingUsers,
            organ: orgLicense.organ,
            sequencesTemplate: org.sequencesTemplate,
            distributor: org.distributor,
        });
        if (
            !getOrganisationData.organisation.organisationLicense
                .licenseFeatures
        )
            return;
        setLicenseFeatures(
            getOrganisationData.organisation.organisationLicense.licenseFeatures
        );
    }, [getOrganisationData]);

    return (
        <Content>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={16}>
                    <Row>
                        <Col xs={24} md={24}>
                            <OrganisationDetailsDescription
                                organisationDetails={
                                    organisationDetailsCombined
                                }
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col xs={24} md={24}>
                            <OrganisationLicenseFeatures
                                organisationLicenseFeatures={licenseFeatures}
                                organisationDetailsCombined={
                                    organisationDetailsCombined
                                }
                                refetchOrganisationData={
                                    refetchOrganisationData
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={8}>
                    <LicenseDetailsDescription
                        licenseDetails={licenseDetails}
                        numOrganisations={1}
                        areButtonsVisible={false}
                        organisationDetails={organisationDetailsCombined}
                        isFromOrg={isFromOrg}
                        isVisible={true}
                    />
                </Col>
            </Row>
        </Content>
    );
};
