import { Button, Divider, Form, InputNumber, Modal } from "antd";
import { useTranslation } from "react-i18next";
import {
    IMoveNumCasesBetweenUserLicensesModel,
    IUserLicenseListPresentation,
    IUserLicenseModel,
} from "../../domain/entities/userLicenseModel";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/lib/select";
import styles from "./transferCases.module.scss";
import { ResultModal } from "../../../../components/appCore/resultModal/resultModal";
import { useForm } from "antd/lib/form/Form";
import { LicensesProgressBar } from "../../../../components/organisationAdminComponents/common/licensesProgressBar/licensesProgressBar";
import { FilteredSelect } from "../../../user/presentation/filteredSelect/filteredSelect";

export interface IProps {
    userLicenseList: IUserLicenseListPresentation | undefined;
    isModalVisible: boolean;
    updateIsModalVisible: (value: boolean) => void;
    onTransferCases: () => void;
    updateMoveCasesInput: (
        value: IMoveNumCasesBetweenUserLicensesModel | undefined
    ) => void;
    moveCasesBetweenUserLicensesInput:
        | IMoveNumCasesBetweenUserLicensesModel
        | undefined;
    isResultModalVisible: boolean;
    isResultModalLoading: boolean;
    isResultModalError: boolean;
    updateIsResultModalVisible: (value: boolean) => void;
    selectedOption: number;
    modalTitle: string;
    onLoadMoreUserLicenses: (pageNumber: number) => void;
    onFilterUserLicense: (textInput: string, isOrigin: boolean) => void;
    textSearchOriginLicenses: string;
    textSearchDestLicenses: string;
    unfilteredLicenses: IUserLicenseModel[] | undefined;
    filteredLicenses: IUserLicenseModel[] | undefined;
    updateFiltersText: () => void;
    onRefetchSelectUserLicenses: () => void;
}

export const TransferCases = ({
    userLicenseList,
    isModalVisible,
    updateIsModalVisible,
    onTransferCases,
    updateMoveCasesInput,
    moveCasesBetweenUserLicensesInput,
    isResultModalError,
    isResultModalLoading,
    isResultModalVisible,
    updateIsResultModalVisible,
    selectedOption,
    modalTitle,
    onLoadMoreUserLicenses,
    onFilterUserLicense,
    textSearchOriginLicenses,
    textSearchDestLicenses,
    unfilteredLicenses,
    filteredLicenses,
    updateFiltersText,
    onRefetchSelectUserLicenses,
}: IProps) => {
    const { t } = useTranslation();
    const [userLicensesOriginListOptions, setUserLicensesOriginListOptions] =
        useState<DefaultOptionType[]>([]);
    const [userLicensesDestinyListOptions, setUserLicensesDestinyListOptions] =
        useState<DefaultOptionType[]>([]);
    const [selectList, setSelectList] = useState<IUserLicenseModel[]>();
    const [remainingCasesOrigin, setRemainingCasesOrigin] = useState<number>(0);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [unfilteredPage, setUnfilteredPage] = useState(0);
    const [form] = useForm();

    useEffect(() => {
        refreshOriginSelectOptions();
        refreshDestinySelectOptions();
    }, [unfilteredLicenses, filteredLicenses]);

    const refreshOriginSelectOptions = () => {
        if (!textSearchOriginLicenses) {
            if (!unfilteredLicenses) return;
            setSelectList(unfilteredLicenses);
            setUserLicensesOriginListOptions(
                unfilteredLicenses.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {"LIC-"} {u.creationOrder}
                            {(!!u.user && (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {u.user?.name} {u.user?.surname}{" "}
                                </span>
                            )) || (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {t(
                                        "userLicenseList.transfer.withoutUser"
                                    )}{" "}
                                </span>
                            )}
                            <LicensesProgressBar
                                remainingNum={u.numberOfRemainingCases}
                                maxNum={u.maxNumOfCases}
                                isSelect={true}
                            />
                        </span>
                    ),
                }))
            );
        } else {
            if (!filteredLicenses) return;
            setSelectList(filteredLicenses);
            setUserLicensesOriginListOptions(
                filteredLicenses.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {"LIC-"} {u.creationOrder}
                            {(!!u.user && (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {u.user?.name} {u.user?.surname}{" "}
                                </span>
                            )) || (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {t(
                                        "userLicenseList.transfer.withoutUser"
                                    )}{" "}
                                </span>
                            )}
                            <LicensesProgressBar
                                remainingNum={u.numberOfRemainingCases}
                                maxNum={u.maxNumOfCases}
                                isSelect={true}
                            />
                        </span>
                    ),
                }))
            );
        }
    };

    const refreshDestinySelectOptions = () => {
        if (!textSearchDestLicenses) {
            if (!unfilteredLicenses) return;
            setSelectList(unfilteredLicenses);
            setUserLicensesDestinyListOptions(
                unfilteredLicenses.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {"LIC-"} {u.creationOrder}
                            {(!!u.user && (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {u.user?.name} {u.user?.surname}{" "}
                                </span>
                            )) || (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {t(
                                        "userLicenseList.transfer.withoutUser"
                                    )}{" "}
                                </span>
                            )}
                            <LicensesProgressBar
                                remainingNum={u.numberOfRemainingCases}
                                maxNum={u.maxNumOfCases}
                                isSelect={true}
                            />
                        </span>
                    ),
                }))
            );
        } else {
            if (!filteredLicenses) return;
            setSelectList(filteredLicenses);
            setUserLicensesDestinyListOptions(
                filteredLicenses.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {"LIC-"} {u.creationOrder}
                            {(!!u.user && (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {u.user?.name} {u.user?.surname}{" "}
                                </span>
                            )) || (
                                <span className={styles.remainingCases}>
                                    {" "}
                                    {t(
                                        "userLicenseList.transfer.withoutUser"
                                    )}{" "}
                                </span>
                            )}
                            <LicensesProgressBar
                                remainingNum={u.numberOfRemainingCases}
                                maxNum={u.maxNumOfCases}
                                isSelect={true}
                            />
                        </span>
                    ),
                }))
            );
        }
    };

    const onChange = (
        changedValues: IMoveNumCasesBetweenUserLicensesModel,
        values: IMoveNumCasesBetweenUserLicensesModel
    ) => {
        updateMoveCasesInput(values);
        if (!values || !selectList) {
            return;
        }
        let userLicense = selectList.filter(
            (element) => element.id === values.userLicenseIdOrigin
        );
        setRemainingCasesOrigin(userLicense[0].numberOfRemainingCases);
        setIsFormValid(checkIsFormValid(values));
        setUserLicensesDestinyListOptions(
            userLicensesOriginListOptions.filter((element) => {
                return element.value !== values.userLicenseIdOrigin;
            })
        );
        if (values.userLicenseIdOrigin === values.userLicenseIdDestiny) {
            setIsFormValid(false);
        }
    };

    const checkIsFormValid = (
        input: IMoveNumCasesBetweenUserLicensesModel | undefined
    ) => {
        if (!input) {
            return false;
        }
        const isValidOrigin =
            input.userLicenseIdOrigin !== undefined &&
            input.userLicenseIdOrigin !== "" &&
            input.numberOfCases !== null &&
            input.numberOfCases !== undefined &&
            input.numberOfCases > 0;
        if (selectedOption === 1) {
            return (
                isValidOrigin &&
                input.userLicenseIdDestiny !== undefined &&
                input.userLicenseIdDestiny !== ""
            );
        } else if (selectedOption === 2) {
            return isValidOrigin;
        }
        return false;
    };

    const onShowUserLicenses = () => {
        form.resetFields();
        updateMoveCasesInput({
            numberOfCases: 0,
            userLicenseIdDestiny: "",
            userLicenseIdOrigin: "",
        });
        setRemainingCasesOrigin(0);
        updateIsResultModalVisible(false);
        updateFiltersText();
        if (unfilteredPage <= 0) {
            onRefetchSelectUserLicenses();
        }
    };

    return (
        <>
            <Modal
                title={modalTitle}
                visible={isModalVisible}
                width={550}
                closable={false}
                footer={[
                    <Button
                        key={"cancel"}
                        onClick={() => {
                            updateIsModalVisible(false);
                            onShowUserLicenses();
                        }}
                        type="default"
                    >
                        {t("userLicenseList.transfer.back")}
                    </Button>,
                    <Button
                        key={"ok"}
                        onClick={onTransferCases}
                        type="primary"
                        disabled={!isFormValid}
                    >
                        {t("userLicenseList.transfer.select")}
                    </Button>,
                ]}
            >
                <Form layout="vertical" onValuesChange={onChange} form={form}>
                    <Form.Item
                        label={t("userLicenseList.transfer.licenseOrigin")}
                        name="userLicenseIdOrigin"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <FilteredSelect
                            options={userLicensesOriginListOptions}
                            refreshSelectOptions={refreshOriginSelectOptions}
                            textSearchUser={textSearchOriginLicenses}
                            unfilteredUsers={unfilteredLicenses}
                            filteredUsers={filteredLicenses}
                            onLoadMoreUsers={onLoadMoreUserLicenses}
                            onFilterUser={(textSearchOriginLicenses: string) =>
                                onFilterUserLicense(
                                    textSearchOriginLicenses,
                                    true
                                )
                            }
                            dataList={userLicenseList}
                            setComboPage={setUnfilteredPage}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("userLicenseList.transfer.numCases")}
                        name="numberOfCases"
                        help={
                            t("userLicenseList.transfer.message") +
                            (remainingCasesOrigin -
                                (!moveCasesBetweenUserLicensesInput ||
                                !moveCasesBetweenUserLicensesInput.numberOfCases
                                    ? 0
                                    : moveCasesBetweenUserLicensesInput.numberOfCases))
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    t(
                                        "userLicenseList.transfer.requiredMessage1"
                                    ) +
                                    remainingCasesOrigin +
                                    t(
                                        "userLicenseList.transfer.requiredMessage2"
                                    ),
                            },
                        ]}
                    >
                        <InputNumber
                            name="inputNumber"
                            min={1}
                            max={remainingCasesOrigin}
                            className={styles.inputNumber}
                            placeholder={t(
                                "userLicenseList.transfer.numCasesPropmt"
                            )}
                        />
                    </Form.Item>
                    {selectedOption === 1 && (
                        <>
                            <Divider />
                            <Form.Item
                                label={t(
                                    "userLicenseList.transfer.licenseDestiny"
                                )}
                                name="userLicenseIdDestiny"
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.required"),
                                    },
                                ]}
                            >
                                <FilteredSelect
                                    options={userLicensesDestinyListOptions}
                                    refreshSelectOptions={
                                        refreshDestinySelectOptions
                                    }
                                    textSearchUser={textSearchDestLicenses}
                                    unfilteredUsers={unfilteredLicenses}
                                    filteredUsers={filteredLicenses}
                                    onLoadMoreUsers={onLoadMoreUserLicenses}
                                    onFilterUser={(
                                        textSearchDestLicenses: string
                                    ) =>
                                        onFilterUserLicense(
                                            textSearchDestLicenses,
                                            false
                                        )
                                    }
                                    dataList={userLicenseList}
                                    setComboPage={setUnfilteredPage}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowUserLicenses}
                localisedTexts={{
                    title:
                        selectedOption === 2
                            ? t("userLicenseList.transfer.succesModal1.title")
                            : t("userLicenseList.transfer.succesModal2.title"),
                    subtitle: "",
                    primaryButton: t("userLicenseList.transfer.buttonModal"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
        </>
    );
};
