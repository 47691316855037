import { Configuration } from "@azure/msal-browser/dist/config/Configuration";

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
        authority: process.env.REACT_APP_AUTH_AUTHORITY!,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL!,
        knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES!],
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [process.env.REACT_APP_LOGIN_REQUEST_SCOPES!],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
