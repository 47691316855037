import { Button, Col, Collapse, Row } from "antd";
import styles from "./biomarkerTypeContent.module.scss";
import { DarkTag } from "../darkTag/darkTag";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ISequence } from "../../../domain/entities/sequence";
import { Spacer } from "../../../../../components/appCore/spacer/spacer";
import { IVisualizerWindow } from "../../../domain/entities/modelImages";


const { Panel } = Collapse;

interface IProps {
    panelHeight: string;
    sequence: ISequence;
    setActiveType: Dispatch<SetStateAction<string>>;
    setTagActiveAction: (type: string, model: string, view: string, biomarker: string) => void;
    visualizerWindows: IVisualizerWindow[];
    singleImages: Set<string> | undefined;
    activeView: string;
    setActiveView: Dispatch<SetStateAction<string>>;
    isMultiplanar: boolean;
}

export const BiomarkerTypeContent = ({
    panelHeight,
    setTagActiveAction,
    setActiveType,
    sequence,
    visualizerWindows,
    singleImages,
    activeView,
    setActiveView,
    isMultiplanar
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Row style={{ maxHeight: panelHeight }}>
          {sequence.biomarkers && (
            <Collapse
              ghost
              className={styles.SecondaryCollapse}
              accordion
              expandIconPosition="end"
              defaultActiveKey={0}
              style={{ maxHeight: panelHeight }}
            >
              {sequence.biomarkers.map((biomarkers, i) => (
                <Panel
                  header={biomarkers.key}
                  key={i}
                  className={styles.Panel}
                  style={{ maxHeight: panelHeight }}
                >
                  <Col span={24} key={biomarkers.key}>
                    {biomarkers.value.map((biomarker) => {
                      let view = activeView;
                      if (biomarker === "BVir") {
                        return (
                          <div key={biomarker}>
                            <Button
                              className={styles.DarkButton}
                              onClick={() => {
                                if (activeView === "singleImages") {
                                  view = "axial";
                                  setActiveView(view);
                                }
                                setActiveType(sequence.type);
                                setTagActiveAction(
                                  sequence.type,
                                  biomarkers.key,
                                  view,
                                  "BVir"
                                );
                              }}
                            >
                              {t(
                                "biomarkerMapping." +
                                  sequence.type.toLowerCase() +
                                  "." +
                                  biomarker.toLowerCase()
                              )}
                              <span className="kenko-icon-open button-icon" />
                            </Button>{" "}
                            <Spacer size={"xs"} />
                          </div>
                        );
                      } else if (singleImages?.has(biomarker)) {
                        return (
                            <div key={biomarker}>
                                <Button
                                    className={styles.DarkButton}
                                    disabled={isMultiplanar}
                                    onClick={() => {
                                        view = "singleImages";
                                        setActiveType(sequence.type);
                                        setTagActiveAction(sequence.type, biomarkers.key, view, biomarker);
                                        setActiveView(view);
                                }}
                                >
                                {t("biomarkerMapping.singleImages." + biomarker.toLowerCase())}
                                <span className="kenko-icon-curves button-icon" />
                                </Button>{" "}
                                <Spacer size={"xs"} />
                            </div>
                        );
                      }
      
                      let isActive =
                        visualizerWindows
                          .filter((e) => e.activeModel === biomarkers.key)
                          .filter((e) => e.activeBiomarker === biomarker).length > 0;
      
                      return (
                        <DarkTag
                          key={biomarker}
                          biomarker={t(
                            "biomarkerMapping." +
                              sequence.type.toLowerCase() +
                              "." +
                              biomarker.toLowerCase(),
                            {
                              escapeInterpolation: false,
                            }
                          )}
                          isActive={isActive}
                          setActiveAction={() => {
                            if (activeView === "singleImages") {
                              view = "axial";
                              setActiveView(view);
                            }
                            setActiveType(sequence.type);
                            setTagActiveAction(sequence.type, biomarkers.key, view, biomarker);
                          }}
                        />
                      );
                    })}
                  </Col>
                </Panel>
              ))}
            </Collapse>
          )}
        </Row>
      );
};
