import { Button, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    isModalVisible: boolean;
    setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const IncompleteSectionModal = ({
    isModalVisible,
    setIsModalVisible,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            title={t("createSequences.modal.title")}
            visible={isModalVisible}
            width={550}
            closable={false}
            footer={[
                <Button
                    key={"edit"}
                    onClick={() => setIsModalVisible(false)}
                    type="primary"
                >
                    {t("createSequences.modal.stay")}
                </Button>,
            ]}
        >
            <p>{t("createSequences.modal.subtitle")}</p>
        </Modal>
    );
};
