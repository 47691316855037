import { Modal } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResultModal } from "../../../../components/appCore/resultModal/resultModal";
import { ILicenseFeature } from "../../domain/entities/licenseFeature";
import { useDeleteLicenseFeature } from "../../domain/hooks/useDeleteLicenseFeature";

interface IProps {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    isVisible: boolean;
    licenseFeatureDetails: ILicenseFeature | undefined;
}
export const DeleteLicenseFeatureModal = ({
    setIsVisible,
    isVisible,
    licenseFeatureDetails,
}: IProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);

    const { deleteLicenseFeature } = useDeleteLicenseFeature();

    const onDelete = () => {
        setIsResultModalVisible(true);
        deleteLicenseFeature(licenseFeatureDetails?.id! ).then((res) => {
            setIsResultModalLoading(false);
        });
    };

    const onShowSequences = () => {
        setIsVisible(false);
        setIsResultModalVisible(false);
        navigate("../features");
    };

    return (
        <>
            <Modal
                title={t("licenseFeature.delete.modal.title")}
                visible={isVisible && !isResultModalVisible}
                cancelText={t("cancel")}
                onCancel={() => setIsVisible(false)}
                okText={t("licenseFeature.delete.modal.delete")}
                onOk={onDelete}
            >
                <p>
                    {t("licenseFeature.delete.modal.firstDescrip")}
                    <b>{licenseFeatureDetails?.name}</b>
                    {t("licenseFeature.delete.modal.secondDescrip")}
                </p>
            </Modal>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                key={licenseFeatureDetails?.id}
                onPrimary={onShowSequences}
                localisedTexts={{
                    title: t("licenseFeature.delete.resultModal.title"),
                    subtitle: t(
                        "licenseFeature.delete.resultModal.description"
                    ),
                    primaryButton: t(
                        "licenseFeature.delete.resultModal.close"
                    ),
                }}
                hasSecondary={false}
            />
        </>
    );
};