import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import Sider from "antd/es/layout/Sider";
import styles from "./visualizerSider.module.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BiomarkerTypeContent } from "./BiomarkerTypeContent/biomarkerTypeContent";
import { ISequenceImages, IVisualizerWindow } from "../../domain/entities/modelImages";
import { ISequenceSliceReference, ISequence, ISequenceSliceController } from "../../domain/entities/sequence";

const { Panel } = Collapse;

interface IProps {
    currentSequencesData: ISequence[]  | undefined;
    setImageInScreen: (currentSequenceImages: ISequenceImages, index: number, activeType: string, activeModel: string, activeView: string, activeBiomarker: string, activeSlice:number) => void;
    visualizerWindows: IVisualizerWindow[];
    sequenceImages: ISequenceImages;
    activeWindow: number;
    singleImages: Set<string> | undefined;
    isMultiplanar: boolean;
    sequenceSliceController: ISequenceSliceController;
    setSequenceSliceReference: Dispatch<SetStateAction<ISequenceSliceReference>>;
    activeSliceByWindow: (isAlgo: boolean) => void;
}

export const VisualizerSider = ({
    currentSequencesData,
    visualizerWindows,
    sequenceImages,
    activeWindow,
    singleImages,
    isMultiplanar,
    setImageInScreen,
    sequenceSliceController,
    setSequenceSliceReference,
    activeSliceByWindow,
}: IProps) => {
    const { t } = useTranslation();
    const [extraHeight, setExtraHeight] = useState<number>(0);
    const [lastActiveType, setLastActiveType] = useState<string>("");
    const [activeView, setActiveView] = useState<string>("axial");
    const panelHeight = "calc(100vh - " + extraHeight.toString() + "px)";

    const setTagActiveAction = (type: string, model: string, view: string, biomarker: string) => {
        
        if(!sequenceImages) return;
        if(isMultiplanar){
            let activeSlice = 0

            if(visualizerWindows[activeWindow].activeView === "coronal") activeSlice = sequenceSliceController[type]["coronal"].activeSlice;
            else if(visualizerWindows[activeWindow].activeView === "sagital") activeSlice = sequenceSliceController[type]["sagital"].activeSlice;
            else activeSlice = sequenceSliceController[type]["axial"].activeSlice;
            if (visualizerWindows[activeWindow].activeType !== lastActiveType){
                setImageInScreen(sequenceImages, 0, type, model, "axial", biomarker, sequenceSliceController[type]["axial"].activeSlice)
                setImageInScreen(sequenceImages, 2, type, model, "coronal", biomarker, sequenceSliceController[type]["coronal"].activeSlice)
                setImageInScreen(sequenceImages, 3, type, model, "sagital", biomarker, sequenceSliceController[type]["sagital"].activeSlice)
                setSequenceSliceReference({type, activeSlice: 1, sliceCount: sequenceSliceController[type]["axial"].sliceCount})
                return
            }
            setImageInScreen(sequenceImages, activeWindow, visualizerWindows[activeWindow].activeType, visualizerWindows[activeWindow].activeModel, visualizerWindows[activeWindow].activeView, 
                biomarker, activeSlice)
            setLastActiveType(visualizerWindows[activeWindow].activeType);
            return
        }

        if(singleImages?.has(biomarker)){
            setImageInScreen(sequenceImages, activeWindow, type, model, view, biomarker, sequenceSliceController[type]["singleImages"].activeSlice)
            setLastActiveType(type);
        }
        else{
            setImageInScreen(sequenceImages, activeWindow, type, model, view, biomarker, sequenceSliceController[type]["axial"].activeSlice);
            if (visualizerWindows[activeWindow].activeType !== lastActiveType) activeSliceByWindow(true)
            setLastActiveType(type);
        }
    };

    const onChange = (key: string | string[]) => {
        if(key) setLastActiveType(key.toString());
        else setLastActiveType("");
    };

    const checkSlicesOfActiveView = (activeView: string, sequence: ISequence) => {
        if (activeView === "axial") return sequence.views.axial! > 0;
        else if (activeView === "coronal") return sequence.views.coronal! > 0;
        else if (activeView === "sagital") return sequence.views.sagital! > 0;
        else if (activeView === "singleimages") return true;
    }


    //===============USEEFFECTS==================

    useEffect(() => {
        if(visualizerWindows[activeWindow].activeType === "")
            setLastActiveType(lastActiveType);
        else setLastActiveType(visualizerWindows[activeWindow].activeType);
    }, [visualizerWindows])

    // When data is fetched, set height accordingly
    useEffect(() => {
        if (!currentSequencesData) return;
        setExtraHeight(currentSequencesData?.length * 49 + 120);
    }, [currentSequencesData]);

    useEffect(() => {
        if(visualizerWindows[activeWindow].activeType === "") return;
        setLastActiveType(visualizerWindows[activeWindow].activeType);
    },[activeWindow])

    return (
        <Sider className={styles.Sider} width={230}>
            <Collapse
                ghost
                activeKey={lastActiveType}
                onChange={onChange}
                className={styles.MainCollapse}
                accordion
                expandIcon={() => <></>}
            >
                {currentSequencesData &&
                    currentSequencesData.map(
                        (sequence) =>
                            checkSlicesOfActiveView(activeView.toLowerCase(), sequence ) && (
                                <Panel
                                    showArrow={true}
                                    key={sequence.type}
                                    className={styles.MainPanel}
                                    header={
                                        <>
                                            <span
                                                className="kenko-icon-folder"
                                                style={{ marginRight: "8px" }}
                                            />
                                            <b>
                                                {t(
                                                    "biomarkerTypes." +
                                                        sequence.type.toLowerCase()
                                                ).toUpperCase()}
                                            </b>
                                        </>
                                    }
                                >
                                    <BiomarkerTypeContent
                                        panelHeight={panelHeight}
                                        sequence={sequence}
                                        setActiveType={setLastActiveType}
                                        setTagActiveAction={setTagActiveAction}
                                        visualizerWindows={visualizerWindows}
                                        singleImages={singleImages}
                                        activeView={activeView}
                                        setActiveView={setActiveView}
                                        isMultiplanar={isMultiplanar}
                                    />
                                </Panel>
                            )
                    )}
            </Collapse>
        </Sider>
    );
};
