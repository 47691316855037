import { Divider, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

interface IProps {
    caseDetails: any;
}

export const ViewFinalDiagnosis = ({ caseDetails }: IProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Title level={5}>{t("caseDetails.finalDiagnosis.diagnosis")}</Title>
            {caseDetails && (
                <Tag>
                    {caseDetails.isPositive ? t("positive") : t("negative")}
                </Tag>
            )}
            <Divider />
            <Title level={5}>
                {t("caseDetails.finalDiagnosis.diagnosisDescription")}
            </Title>
            {caseDetails && <Text>{caseDetails.finalNotes}</Text>}
        </>
    );
};
