import { Button, Card, Form } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./userLicenseInfo.module.scss";
import { CreateUserLicenseForm } from "../createUserLicenseForm/createUserLicenseForm";
import {
    IUserLicense,
    IUserLicenseCreate,
} from "../../domain/entities/userLicenseModel";

interface IProps {
    remainingCases: number | undefined;
    updateUserLicenseNumCases: (value: number | undefined) => void;
    updateCurrentStep: (value: number) => void;
    updateIsIconVisible: (value: boolean) => void;
    userLicenseDetails: IUserLicenseCreate | undefined;
    userLicenseModel: IUserLicense | undefined;
    buttonText: string;
    onRealoadUserLicense?: () => void;
    subtitle: string;
}

export const UserLicenseInfo = ({
    remainingCases,
    updateUserLicenseNumCases,
    updateCurrentStep,
    updateIsIconVisible,
    userLicenseDetails,
    userLicenseModel,
    buttonText,
    onRealoadUserLicense,
    subtitle,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const onClick = () => {
        if (!!onRealoadUserLicense) {
            onRealoadUserLicense();
        } else {
            updateCurrentStep(1);
            updateIsIconVisible(true);
        }
    };

    const updateIsFormValid = (value: boolean) => {
        setIsFormValid(value);
    };

    return (
        <>
            <Card>
                <CreateUserLicenseForm
                    remainingCases={remainingCases}
                    updateIsFormValid={updateIsFormValid}
                    updateUserLicenseNumCases={updateUserLicenseNumCases}
                    title={t("createUserLicense.userLicenseInfo.title")}
                    subtitle={subtitle}
                    userLicenseDetails={userLicenseDetails}
                    userLicenseModel={userLicenseModel}
                />
                <Form.Item className={styles.stepNavigation}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={onClick}
                        disabled={!isFormValid}
                    >
                        {buttonText}
                    </Button>
                </Form.Item>
            </Card>
        </>
    );
};
