import { useTranslation } from "react-i18next";
import styles from "./visualizerWindow.module.scss";
import { VisualizerSlider } from "../visualizerSlider/visualizerSlider";
import { Dispatch, SetStateAction } from "react";
import { download } from "../../../../../components/appCore/fileDownloader/fileDownloader";
import HtmlPrinter from "../../../../../components/helpers/htmlPrinter/htmlPrinter";
import { PixelAnalizeImage } from "../visualizerCanvas/pixelAnalizeImage";
import { ICoordinates } from "../visualizer";
import { ISequenceSliceController, ISequenceSliceReference } from "../../../domain/entities/sequence";
import { IVisualizerWindow } from "../../../domain/entities/modelImages";


export interface WindowStyle{
    width: string;
    height: string;
}

interface IProps {
    sliceCount: number;
    visualizerLayout: number;
    windowIndex: number;
    activeSlice: number;
    visualizerWindow: IVisualizerWindow;
    setActiveWindow: Dispatch<SetStateAction<number>>;
    activeWindow: number;
    isSingleImage: boolean;
    isPixelAnalizeActive: boolean;
    relativeZAxis: keyof ICoordinates;
    setCoordinates: Dispatch<SetStateAction<ICoordinates>>;
    view: string;
    sequenceSliceController: ISequenceSliceController;
    setSequenceSliceController: Dispatch<SetStateAction<ISequenceSliceController>>;
    sequenceSliceReference: ISequenceSliceReference;
    setSequenceSliceReference: Dispatch<SetStateAction<ISequenceSliceReference>>;
    isMultiplanar: boolean;
}

function forbiddenCharacterFilter(inputText: string): string {
    return inputText
        .replace("<sub>", "-")
        .replace("</sub>", "")
        .replace("λ", "L")
        .replace("/", "-");
}

export const VisualizerWindow = ({
    sliceCount,
    visualizerLayout,
    windowIndex,
    activeSlice,
    visualizerWindow,
    setActiveWindow,
    activeWindow,
    isSingleImage,
    isPixelAnalizeActive,
    relativeZAxis,
    setCoordinates,
    view,
    sequenceSliceController,
    setSequenceSliceController,
    sequenceSliceReference,
    setSequenceSliceReference,
    isMultiplanar
}: IProps) => {
    const { t } = useTranslation();
    
    const onWheel = (delta: number) => {
        let scaledDelta = delta * 0.01;
        if (scaledDelta < 0) scaledDelta = +1;
        else scaledDelta = -1;

        if(sequenceSliceController[visualizerWindow.activeType] !== undefined && sliceCount > 1){
            if(isMultiplanar){
                let newIndex = sequenceSliceController[visualizerWindow.activeType][view].activeSlice + scaledDelta;
                sequenceSliceController[visualizerWindow.activeType][view].activeSlice = Math.min(Math.max(newIndex, 1), sequenceSliceController[visualizerWindow.activeType][view].sliceCount);
                setSequenceSliceController({...sequenceSliceController});
                return;
            }
            let newIndex = sequenceSliceReference.activeSlice + scaledDelta;
            let trimmedSlice = Math.min(Math.max(newIndex, 1), sequenceSliceReference.sliceCount);
            setSequenceSliceReference({...sequenceSliceReference, activeSlice:trimmedSlice});
        }
        
    }

    const cutIndicator = () => {
        return activeSlice > sliceCount ? `MIP` : `${sequenceSliceController[visualizerWindow.activeType][view].activeSlice}/${sequenceSliceController[visualizerWindow.activeType][view].sliceCount}` 
    }

    const viewIndicator = () => {
        return isSingleImage ? t(`biomarkerMapping.singleImages.${visualizerWindow.activeBiomarker.toLowerCase()}`) 
        : t(`biomarkerMapping.views.${visualizerWindow.activeView}`)
    }

    const setStyleByView = (view: string) => {
        if(view === "axial")
            return {
                width: "fit-content",
                height: "calc(100% - 1px)"
            } as WindowStyle
        
        else
            return {
                width: "100%",
                height: "auto"
            } as WindowStyle    
    } 

    const onClickInPixel = (
        event: any,
        origin: keyof ICoordinates,
        imageScreenWidth: number,
        imageScreenHeight: number,
        originalImageWidth: number,
        originalImageHeight: number
    ) => {
        
        if (!imageScreenWidth || !imageScreenHeight || !originalImageWidth || !originalImageHeight) return

        const { offsetX, offsetY } = event.nativeEvent;
        const values = { x: offsetX, y: offsetY };
        values.x = normalizeCoordinates(values.x, imageScreenWidth, originalImageWidth);
        values.y = normalizeCoordinates(values.y, imageScreenHeight, originalImageHeight);
        
        if (origin === "z") {
            setCoordinatesByOriginAxis(values.x, values.y, activeSlice)
            return;
        }else if(origin === "x"){
            setCoordinatesByOriginAxis(activeSlice, sliceCount-values.x, values.y)
            return;
        }
        setCoordinatesByOriginAxis(values.x, activeSlice, values.y)
    };

    const setCoordinatesByOriginAxis = (xValue: number, yValue:number, zValue:number) => {
        let coordinates: ICoordinates = { x: 0, y: 0, z: 0 };
        coordinates["x"] = xValue;
        coordinates["y"] = yValue;
        coordinates["z"] = zValue;
        setCoordinates(coordinates);
    }

    const normalizeCoordinates = (value: number, actualDimension: number, naturalDimension: number) => {
        return Math.round((value * naturalDimension)/actualDimension)
    }

    return (
        <div
            onWheel={(e) => onWheel(e.deltaY)}
            className={`${styles.VisualizerWindow} ${
                visualizerLayout === 4 && styles.HalfHeight
            }
            ${activeWindow === windowIndex && styles.Active}
            `}
            key={windowIndex}
            onClick={() => setActiveWindow(windowIndex)}
        >
            {visualizerWindow.activeImageSrc !== "" ? (
                <>
                    <div className={styles.VisualizerWindowHeader}>
                        <span
                            className="kenko-icon-download"
                            onClick={() => {isSingleImage ? (
                                download(
                                    visualizerWindow.activeImageSrc,
                                    `${forbiddenCharacterFilter(
                                        t(
                                            `biomarkerMapping.singleImages.${visualizerWindow.activeBiomarker.toLowerCase()}`
                                        )
                                    )}_${activeSlice}.png`,
                                    "image/png"
                                )
                            ) : (
                                download(
                                    visualizerWindow.activeImageSrc,
                                    `${forbiddenCharacterFilter(
                                        t(
                                            "biomarkerMapping." +
                                                visualizerWindow.activeType.toLowerCase() +
                                                "." +
                                                visualizerWindow.activeBiomarker.toLowerCase()
                                        )
                                    )}_${activeSlice}.png`,
                                    "image/png"
                                )

                            )}
                                
                            }
                        />
                    </div>
                    <VisualizerSlider
                        activeSlice={activeSlice}
                        sliceCount={sliceCount}
                        isSingleImage={isSingleImage}
                        sequenceSliceReference={sequenceSliceReference}
                        setSequenceSliceReference={setSequenceSliceReference}

                        isMultiplanar={isMultiplanar}
                        sequenceSliceController={sequenceSliceController}
                        setSequenceSliceController={setSequenceSliceController}
                        visualizerWindow={visualizerWindow}
                    />
                    {isPixelAnalizeActive ? (
                            <>
                                <div className={styles.ImageContainer}>
                                    <PixelAnalizeImage 
                                            onClick={(event: any, currentWidth: number, currentHeight:number,
                                                originalImageWidth: number, originalImageHeight:number) =>
                                                onClickInPixel(event, relativeZAxis, currentWidth, currentHeight,
                                                    originalImageWidth, originalImageHeight)
                                            }
                                            visualizerWindow={visualizerWindow}
                                            style={setStyleByView(view)}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.ImageContainer}>
                                    <img
                                        alt="biomarkerImage"
                                        src={visualizerWindow.activeImageSrc}
                                        className={styles.Image}
                                    />
                                </div>
                            </>
                        )
                    }
                    
                    
                    <div className={`${styles.FloatingIndicator} 
                        ${visualizerLayout === 2 && styles.FloatingIndicatorMedium}
                        ${visualizerLayout === 4 && styles.FloatingIndicatorSmall}
                    `}>
                        <div>
                            {t("visualizer.model") +
                                visualizerWindow.activeModel}
                        </div>
                        <div hidden={isSingleImage}>
                            {t("visualizer.biomarker")}
                            <HtmlPrinter
                                content={isSingleImage ? ( t(
                                    `biomarkerMapping.singleImages.${visualizerWindow.activeBiomarker.toLowerCase()}`
                                )) : (t(
                                    "biomarkerMapping." +
                                    visualizerWindow.activeType.toLowerCase() +
                                    "." +
                                    visualizerWindow.activeBiomarker.toLowerCase()
                                ))}
                            />
                        </div>
                        <div>{`${t("visualizer.view")} ${viewIndicator()}`}</div>
                        <div hidden={isSingleImage}>{`${t("visualizer.cut")} ${cutIndicator()}`}</div>
                    </div>
                </>
            ) : (
                <div>{t("visualizer.openABiomarker")}</div>
            )}
        </div>
    );
};
