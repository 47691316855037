import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { CaseList } from "../../../components/appCore/caseList/caseList";
import { useAllCases } from "../../../features/case/domain/hooks/useAllCases";
import {
    CaseSortableFields,
    FilterInputOfCaseFilteredFieldsInput,
    SortInputOfCaseSortableFieldsInput,
} from "../../../generated/graphql";

export const OrganisationCaseListPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const [sortInputs, setSortInputs] = useState<
        SortInputOfCaseSortableFieldsInput[]
    >([{ fieldName: CaseSortableFields.CreationDate, isAsc: false }]);
    const [filterInput, setFilterInput] =
        useState<FilterInputOfCaseFilteredFieldsInput[]>();
    const [textSearchInput, setTextSeachInput] = useState<string>();
    const ELEMENTS_PAGE = 10;
    const PAGE_NUMBER = 0;

    //===========HOOKS==============
    const {
        data: caseList,
        isLoading,
        errorMessage,
        getAllCases,
    } = useAllCases();

    const onLoadMore = (
        pageNumber: number,
        sortFields: SortInputOfCaseSortableFieldsInput[],
        filterInput: FilterInputOfCaseFilteredFieldsInput[]
    ) => {
        updateSortInput(sortFields);
        updateFilterInput(filterInput);

        getAllCases(
            ELEMENTS_PAGE,
            pageNumber,
            sortFields,
            textSearchInput,
            filterInput
        );
    };

    useEffect(() => {
        getAllCases(ELEMENTS_PAGE, PAGE_NUMBER, sortInputs);
    }, []);

    const onRefetch = () => {
        getAllCases(ELEMENTS_PAGE, PAGE_NUMBER, sortInputs);
    };

    const onFilter = (textInput: string) => {
        setTextSeachInput(textInput);
        getAllCases(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputs,
            textInput,
            filterInput
        );
    };

    const updateSortInput = (input: SortInputOfCaseSortableFieldsInput[]) => {
        setSortInputs(input);
    };
    const updateFilterInput = (
        input: FilterInputOfCaseFilteredFieldsInput[]
    ) => {
        setFilterInput(input);
    };

    useEffect(() => {
        setHeaderContent({
            title: t("caseList.title"),
            breadCrumb: [],
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t]);

    return (
        <Content>
            <CaseList
                isOrganisation
                caseListData={caseList}
                onLoadMore={onLoadMore}
                errorMessage={errorMessage}
                isLoading={isLoading}
                onRefetch={onRefetch}
                onFilter={onFilter}
                sortInputs={sortInputs}
                filterInput={filterInput}
            />
        </Content>
    );
};
