import {
    throwExceptionFromGqlMutationIfExists,
    throwExceptionFromGqlQueryIfExists,
} from "../../../../core/data/dataSources/kenkoDataSource";
import {
    SequencePresentation,
    CasePresentation,
    ModelImagesPresentation,
    useImagesLazyQuery,
    useCaseLazyQuery,
    useSequencesByCaseLazyQuery,
    useUpdateCaseMutation,
    CaseListPresentation,
    useAllCasesLazyQuery,
    SortInputOfCaseSortableFieldsInput,
    FilterInputOfCaseFilteredFieldsInput,
} from "../../../../generated/graphql";

export interface ICaseDataSource {
    // TODO remove "any" of Promise type
    getAllCases: (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfCaseSortableFieldsInput[],
        textSearchInput?: string,
        filterInputs?: FilterInputOfCaseFilteredFieldsInput[]
    ) => Promise<CaseListPresentation | undefined | null>;
    getCaseById: (
        caseId: string
    ) => Promise<Partial<CasePresentation> | undefined>;
    updateFinalDiagnosisCase: (
        caseId: string,
        finalNotes: string,
        isPositive: boolean
    ) => Promise<Partial<CasePresentation> | undefined>;
    getSequencesByCase: (
        caseId: string
    ) => Promise<SequencePresentation[] | undefined>;
    getImagesByCase: (
        caseId: string
    ) => Promise<ModelImagesPresentation[] | undefined>;
}

export const CaseDataSource = (): ICaseDataSource => {
    const [filteredCasesQuery] = useAllCasesLazyQuery();
    const [caseQuery] = useCaseLazyQuery();
    const [updateCase] = useUpdateCaseMutation();
    const [sequencesByCaseQuery] = useSequencesByCaseLazyQuery();
    const [imagesByCaseQuery] = useImagesLazyQuery();

    const getAllCases = async (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfCaseSortableFieldsInput[],
        textSearchInput?: string,
        filterInputs?: FilterInputOfCaseFilteredFieldsInput[]
    ) => {
        const result = await filteredCasesQuery({
            variables: {
                input: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortInputs: sortInputs,
                    textSearchInput: textSearchInput,
                    filterInputs: filterInputs,
                },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.cases;
    };

    const getCaseById = async (caseId: string) => {
        const result = await caseQuery({ variables: { id: caseId } });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.case;
    };

    const updateFinalDiagnosisCase = async (
        caseId: string,
        finalNotes: string,
        isPositive: boolean
    ) => {
        const result = await updateCase({
            variables: { id: caseId, finalNotes, isPositive },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.updateCase;
    };

    const getSequencesByCase = async (caseId: string) => {
        const result = await sequencesByCaseQuery({ variables: { caseId } });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.sequencesByCase;
    };

    const getImagesByCase = async (caseId: string) => {
        const result = await imagesByCaseQuery({ variables: { caseId } });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.images;
    };

    return {
        getAllCases,
        getCaseById,
        updateFinalDiagnosisCase,
        getSequencesByCase,
        getImagesByCase,
    };
};
