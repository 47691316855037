import { Key } from "react";
import {
    UserPresentation,
    UserSortableFields,
} from "../../../../generated/graphql";

export interface IUserReducedDto {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
}

export interface IUserProfileDetails {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export interface IUserBasicInfo {
    id: string | undefined;
    name: string | undefined;
    surname: string | undefined;
    email?: string | undefined | null;
    orgId?: string | undefined | null;
}

export interface IUserWithLic {
    id: string;
    name: string;
    surname: string;
    totalCases?: number;
    userLicenseId?: string | null | undefined;
    creationOrder?: number | null | undefined;
    maxNumberOfCases?: any;
    numberOfRemainingCases?: any;
    email?: string | null;
}

export interface IUserListPresentation {
    userPresentations: IUserWithLic[];
    totalResults: number;
}

export enum EUserSortableFields {
    name = "name",
    surname = "surname",
}

export const UserBasicInfoFromUserPresentation = (
    userPresentation: Partial<UserPresentation>
): Partial<IUserBasicInfo> => ({
    id: userPresentation.id,
    name: userPresentation.name,
    surname: userPresentation.surname,
    email: userPresentation.email,
    orgId: userPresentation.orgId,
});

export const IUserWithLicFromUserPresentation = (
    userPresentation: Partial<UserPresentation>
): Partial<IUserWithLic> => ({
    id: userPresentation.id,
    name: userPresentation.name,
    surname: userPresentation.surname,
    totalCases: userPresentation.totalCases,
    userLicenseId: userPresentation.userLicenseId,
    creationOrder: userPresentation.creationOrder,
    maxNumberOfCases: userPresentation.maxNumberOfCases,
    numberOfRemainingCases: userPresentation.numberOfRemainingCases,
    email: userPresentation.email,
});

export const UserSortableFieldFromKey = (
    fieldName: Key | undefined
): UserSortableFields => {
    switch (fieldName) {
        case EUserSortableFields.name:
            return UserSortableFields.Name;
        default:
            return UserSortableFields.Surname;
    }
};
