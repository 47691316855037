import { useTranslation } from "react-i18next";
import { Button, Card, Divider, Skeleton, Tag, Typography } from "antd";
import styles from "./caseDetailsDescription.module.scss";
import { OrganTag } from "../../appCore/organTag/organTag";
import { Spacer } from "../../appCore/spacer/spacer";
import { useState } from "react";
import { DeleteCaseModal } from "../../appCore/deleteCaseModal/deleteCaseModal";
import { ICaseDetails, StatusType } from "../../../features/case/domain/entities/case";

const { Title, Text } = Typography;

interface IProps {
    caseDetails: ICaseDetails | undefined;
    caseId: string | undefined;
    isOrganisation?: boolean;
}

export const CaseDetailsDescription = ({
    caseDetails,
    caseId,
    isOrganisation = false,
}: IProps) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    let space = " ";

    return (
        <>
            <Title level={4}>
                {caseDetails ? (
                    <>{t("case") + space + caseDetails.reference}</>
                ) : (
                    <Skeleton
                        paragraph={false}
                        active
                        className={styles.TitleSkeleton}
                    />
                )}
            </Title>
            <Card>
                <Text>
                    {caseDetails ? (
                        <>
                            {t("caseDetails.description.creationDate") + space}
                            <b>{caseDetails.creationDate.split("T")[0]}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {caseDetails ? (
                        <>
                            {t("caseDetails.description.organ") + space}
                            <OrganTag organ={caseDetails.organ} />
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {caseDetails ? (
                        <>
                            {t("caseDetails.description.initialDiagnosis") +
                                space}
                            <Tag
                                className={
                                    !caseDetails.isPositivePrediction
                                        ? styles.Disabled
                                        : ""
                                }
                            >
                                {caseDetails.isPositivePrediction
                                    ? t("positive")
                                    : t("negative")}
                            </Tag>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {caseDetails ? (
                        <>
                            {t("caseDetails.description.caseObservations") +
                                space}
                            <p>{caseDetails.initialNotes}</p>
                        </>
                    ) : (
                        <>
                            <Skeleton
                                paragraph={false}
                                active
                                className={styles.Skeleton}
                            />
                            <Skeleton
                                paragraph={false}
                                active
                                className={styles.Skeleton}
                            />
                            <Skeleton
                                paragraph={false}
                                active
                                className={styles.Skeleton}
                            />
                        </>
                    )}
                </Text>
                <Spacer size="xs" />
                {caseDetails &&
                    caseDetails.state === StatusType.Created &&
                    !isOrganisation && (
                        <Button
                            onClick={() => {
                                setIsModalVisible(true);
                            }}
                            className={styles.DeleteButton}
                        >
                            {t("deleteCaseModal.delete")}
                            <span className={`kenko-icon-bin`} />
                        </Button>
                    )}
            </Card>
            <DeleteCaseModal
                caseId={caseId}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                reference={caseDetails?.reference}
            />
        </>
    );
};
