import { Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import fragments from "../../../../gql/fragments";
import { DeleteUserMutation } from "../../../../generated/graphql";
import { ResultModal } from "../../../../components/appCore/resultModal/resultModal";

const { Text } = Typography;

interface IProps {
    refetch: (pageNumber: number) => void;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    isVisible: boolean;
    userName: string;
    userId: string;
    currentPage: number;
}

export const DeleteUserModal = ({
    refetch,
    setIsVisible,
    isVisible,
    userName,
    userId,
    currentPage,
}: IProps) => {
    const { t } = useTranslation();

    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);

    const [DeleteUserMutation] = useMutation<DeleteUserMutation>(
        fragments.DELETE_USER
    );

    const onDelete = () => {
        setIsResultModalVisible(true);
        DeleteUserMutation({ variables: { input: { id: userId } } }).then(
            (res) => {
                setIsResultModalLoading(false);
                refetch(currentPage);
            }
        );
    };

    return (
        <>
            <Modal
                title={t("userList.deletionModal.title")}
                visible={isVisible && !isResultModalVisible}
                cancelText={t("userList.deletionModal.back")}
                onCancel={() => setIsVisible(false)}
                okText={t("userList.deletionModal.delete")}
                onOk={onDelete}
            >
                <Text>
                    {t("userList.deletionModal.textStart")}
                    <Text strong>{userName}</Text>
                    {t("userList.deletionModal.textEnd")}
                </Text>
            </Modal>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                onPrimary={() => {
                    setIsVisible(false);
                    setIsResultModalVisible(false);
                }}
                localisedTexts={{
                    title: t("userList.deletionSuccess.title"),
                    subtitle: t("userList.deletionSuccess.subtitle"),
                    primaryButton: t("userList.deletionSuccess.close"),
                }}
                hasSecondary={false}
            />
        </>
    );
};
