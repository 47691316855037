import { Card, Divider, Skeleton, Typography } from "antd";
import { Spacer } from "../../appCore/spacer/spacer";
import styles from "./organisationCard.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { IOrganisationDetailsProfile } from "../../../pages/myProfilePage/myProfilePage";

const { Title, Text } = Typography;

interface IProps {
    organisationDetails: IOrganisationDetailsProfile | undefined;
}

export const OrganisationCard = ({ organisationDetails }: IProps) => {
    const { t } = useTranslation();

    return (
        <Card>
            <div>
                <Title level={4}>{t("myProfile.organisation.title")}</Title>
                <Text type="secondary">
                    {t("myProfile.organisation.subtitle")}
                </Text>
            </div>
            <Spacer size="s" />
            {organisationDetails ? (
                <div className={styles.Data}>
                    <div>
                        <Text>{t("myProfile.organisation.organisation")}</Text>
                    </div>
                    <div>
                        <Text strong>{organisationDetails.name}</Text>
                    </div>
                </div>
            ) : (
                <Skeleton
                    paragraph={false}
                    active
                    className={styles.Skeleton}
                />
            )}
            <Divider />
            {organisationDetails ? (
                <div className={styles.Data}>
                    <div>
                        <Text>{t("myProfile.organisation.admin")}</Text>
                    </div>
                    <div>
                        <Text strong>
                            {organisationDetails &&
                                organisationDetails.adminName}
                        </Text>
                    </div>
                </div>
            ) : (
                <Skeleton
                    paragraph={false}
                    active
                    className={styles.Skeleton}
                />
            )}
        </Card>
    );
};
