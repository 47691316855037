import { Col, Row } from "antd";
import styles from "./editOrgnisationPage.module.scss";
import { StepsComponent } from "../../../components/appCore/stepsComponent/stepsComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { IOrganisationCreationDto } from "../../../features/organisation/domain/entities/organisationModel";
import { useMutation, useQuery } from "@apollo/client";
import {
    OrganisationQuery,
    UpdateOrganisationMutation,
    UpdateUserMutation,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { OrganisationInfoStep } from "../../../components/adminComponents/createOrganisationComponents/organisationInfoStep/organisationInfoStep";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useNavigate, useParams } from "react-router-dom";

export const EditOrganisationPage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [organisationDetails, setOrganisationDetails] =
        useState<IOrganisationCreationDto>();
    const { setHeaderContent } = useHeader();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);

    let { organisationId } = useParams<any>();

    useEffect(() => {
        setHeaderContent({
            title: t("editOrganisation.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/",
        });
    }, []);

    const [UpdateOrganisationMutation] =
        useMutation<UpdateOrganisationMutation>(fragments.UPDATE_ORGANISATION);
    const [UpdateUserMutation] = useMutation<UpdateUserMutation>(
        fragments.UPDATE_USER
    );

    const { data: getOrganisationData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION,
        { variables: { orgId: organisationId } }
    );

    useEffect(() => {
        if (!getOrganisationData) {
            return;
        }
        let { admin, cifAndOthers, organisationLicense, name } =
            getOrganisationData.organisation;
        if (!admin || !admin.email) {
            return;
        }
        if (!organisationLicense) {
            return;
        }

        setOrganisationDetails({
            name: name,
            firstName: admin.name,
            lastName: admin.surname,
            email: admin.email,
            cifAndOthers: cifAndOthers,
            licenseId: organisationLicense.id,
        });
    }, [getOrganisationData]);

    const onFinish = () => {
        if (
            !organisationDetails ||
            !getOrganisationData ||
            !getOrganisationData.organisation.admin
        ) {
            return;
        }

        setIsResultModalVisible(true);
        UpdateUserMutation({
            variables: {
                id: getOrganisationData.organisation.admin.id,
                email: organisationDetails.email,
                name: organisationDetails.firstName,
                surname: organisationDetails.lastName,
                orgId: organisationId,
                isOrgAdmin: true,
            },
        })
            .then((resUser) => {
                if (!resUser.data) {
                    setIsResultModalError(true);
                    return;
                }
                UpdateOrganisationMutation({
                    variables: {
                        orgId: organisationId,
                        name: organisationDetails.name,
                        cifAndOthers: organisationDetails.cifAndOthers,
                    },
                })
                    .then((resOrg) => {
                        if (!resOrg.data) {
                            setIsResultModalError(true);
                            return;
                        }
                        setIsResultModalLoading(false);
                    })
                    .catch(() => {
                        setIsResultModalError(true);
                    });
            })
            .catch(() => {
                setIsResultModalError(true);
            });
    };

    const onShowOrganisation = () => {
        navigate("../organisation/" + organisationId);
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepsComponent
                        currentStep={currentStep}
                        title={t("editOrganisation.steps.title")}
                        steps={[
                            {
                                title: t("createOrganisation.steps.1.title"),
                                description: t(
                                    "createOrganisation.steps.1.description"
                                ),
                            },
                            {
                                title: t("createOrganisation.steps.2.title"),
                                description: t(
                                    "createOrganisation.steps.2.description"
                                ),
                            },
                        ]}
                        isComplete={true}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <OrganisationInfoStep
                        setCurrentStep={setCurrentStep}
                        organisationDetails={organisationDetails}
                        setOrganisationDetails={setOrganisationDetails}
                        contextDependentTexts={{
                            firstSubtitle:
                                "editOrganisation.infoStep.informationSubtitle",
                            secondSubtitle:
                                "editOrganisation.infoStep.adminInformationSubtitle",
                            buttonLabel: "editOrganisation.next",
                        }}
                        onButtonClick={onFinish}
                    />
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowOrganisation}
                onSecondary={() => {
                    navigate("/createOrganisation");
                }}
                localisedTexts={{
                    title: t("editOrganisation.successModal.title"),
                    subtitle: t("editOrganisation.successModal.subtitle"),
                    secondaryButton: t(
                        "editOrganisation.successModal.createAnother"
                    ),
                    primaryButton: t(
                        "editOrganisation.successModal.showOrganisation"
                    ),
                    errorTitle: t("editOrganisation.successModal.errorTitle"),
                    errorRetry: t("editOrganisation.successModal.errorRetry"),
                }}
            />
        </>
    );
};
