import { Card, Progress, Typography } from "antd";
import styles from "./counterComponent.module.scss";
import { GetProgressColor } from "../../../helpers/getProgressColor/getProgressColor";
import { tuple } from "antd/lib/_util/type";

const { Text, Title } = Typography;

interface IProps {
    title: string;
    description: string;
    count: number;
    outOf: number;
    isCard: boolean;
}

export const CounterComponent = ({
    title,
    description,
    count,
    outOf,
    isCard
}: IProps) => {
    const percent = (count / outOf) * 100;

    return (
        <>
        {isCard ? (<Card className={styles.Card}>
            <div className={styles.Container}>
                <Title level={4}>{title}</Title>
                <div className={styles.Description}>
                    <Text>{description}</Text>
                </div>
                <div className={styles.FractionContainer}>
                    <span>
                        {count}/{outOf}
                    </span>
                    <Progress
                        percent={percent}
                        showInfo={false}
                        strokeColor={GetProgressColor(percent)}
                        trailColor="#EAEAEC"
                    />
                </div>
            </div>
        </Card>) :
        (
            <div className={styles.Container}>
                <Text strong={true}>{title}</Text>
                <div className={styles.FractionContainer}>
                    <span>
                        {count}/{outOf}
                    </span>
                    <Progress
                        percent={percent}
                        showInfo={false}
                        strokeColor={GetProgressColor(percent)}
                        trailColor="#EAEAEC"
                    />
                </div>
            </div>
        )}
        
        </>
        
    );
};
