import { Card, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { IOrganisationReducedTableDto } from "../../../../features/organisation/domain/entities/organisationModel";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

interface IProps {
    organisationsWithLicense: IOrganisationReducedTableDto[] | undefined;
}

export const LicenseDetailsOrganisations = ({
    organisationsWithLicense,
}: IProps) => {
    const { t } = useTranslation();

    const columns: ColumnsType<IOrganisationReducedTableDto> = [
        {
            title: t("licenseDetails.organisationsWithLicense.table.name"),
            dataIndex: "name",
            key: "reference",
        },
        {
            title: t("caseList.table.action"),
            key: "action",
            dataIndex: ["id", "email"],
            fixed: "right",
            width: 150,
            render: (value, record: IOrganisationReducedTableDto) => (
                <>
                    <Link to={"/organisation/" + record.id}>
                        <span className="kenko-icon-eye"></span>
                    </Link>
                    <a
                        href={"mailto: " + record.email}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="kenko-icon-mail button-icon"></span>
                    </a>
                </>
            ),
        },
    ];

    return (
        <>
            <Title level={4}>
                {t("licenseDetails.organisationsWithLicense.title")}
            </Title>
            <Card>
                <Table
                    dataSource={organisationsWithLicense}
                    columns={columns}
                    rowKey="id"
                />
            </Card>
        </>
    );
};
