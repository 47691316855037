import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ILicenseFeatureSimpleData } from "../../../features/licenseFeature/domain/entities/licenseFeature";
import { useAllLicenseFeature } from "../../../features/licenseFeature/domain/hooks/useAllLicenseFeatures";

export const LicenseFeatureListPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();
    const [licenseFeaturesList, setLicenseFeaturesList] = useState<
        ILicenseFeatureSimpleData[]
    >([]);
    const ELEMENTS_PAGE = 10;

    const { data: licenseFeaturesListData, getAllLicenseFeatures } =
        useAllLicenseFeature();

    const onPaginate = (pageNumber: number) => {
        getAllLicenseFeatures(ELEMENTS_PAGE, pageNumber);
    };

    useEffect(() => {
        getAllLicenseFeatures(ELEMENTS_PAGE, 0);
    }, []);

    useEffect(() => {
        if (!licenseFeaturesListData) return;
        setLicenseFeaturesList(licenseFeaturesListData?.licenseFeatures);
    }, [licenseFeaturesListData]);

    useEffect(() => {
        setHeaderContent({
            title: t("licenseFeature.title"),
            breadCrumb: [],
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="createLicense"
                    onClick={() => {
                        navigate("/createFeature");
                    }}
                >
                    {t("licenseFeature.create.initialCreateButton")}
                    <PlusOutlined />
                </Button>
            ),
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t]);

    const columns: ColumnsType<ILicenseFeatureSimpleData> = [
        {
            title: t("licenseFeature.feature.name"),
            dataIndex: "name",
            key: "name",
            width: "20%",
        },
        {
            title: t("licenseFeature.feature.description"),
            dataIndex: "description",
            key: "description",
            width: "60%",
        },
        {
            title: t("licenseFeature.feature.price"),
            dataIndex: "price",
            key: "price",
            width: "10%",
        },
        {
            title: t("licenseList.table.actions"),
            key: "actions",
            dataIndex: "id",
            width: "10%",
            fixed: "right",
            render: (id) => (
                <Link to={"/feature/" + id}>
                    <span className="kenko-icon-eye"></span>
                </Link>
            ),
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={licenseFeaturesList}
                rowKey="id"
                scroll={{ x: 800 }}
                pagination={{
                    total: licenseFeaturesListData?.totalResults,
                    onChange: (page) => {
                        onPaginate(page);
                    },
                }}
            />
        </>
    );
};
