import { useTranslation } from "react-i18next";
import { Alert, Progress, Typography } from "antd";
import { Spacer } from "../../../appCore/spacer/spacer";

const { Text } = Typography;

interface IProps {
    percentage: number;
}

export const CaseProcessing = ({ percentage }: IProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Alert
                type="info"
                message={t("caseDetails.resultFolders.analyzingCase.title")}
                description={t(
                    "caseDetails.resultFolders.analyzingCase.description"
                )}
                showIcon
            />
            <Spacer size="m" />
            <Text>{t("caseDetails.resultFolders.analyzingCase.state")}</Text>
            <Spacer size="s" />
            <Progress percent={percentage} />
        </>
    );
};
