import { gql } from "@apollo/client";

const CREATE_SEQUENCES = gql`
    mutation AddSequencesTemplate($input: CreateSequencesTemplateInput!) {
        addSequencesTemplate(sequencesInput: $input) {
            id
            name
            stepSequenceTemplates {
                sequenceType
                stepTemplates {
                    id
                    type
                    unversionedEcsTaskName
                    version
                }
            }
        }
    }
`;

const GET_ALL_SEQUENCES = gql`
    query allSequences($input: GetSequencesTemplateInput!) {
        sequencesTemplates(input: $input) {
            sequencesTemplatePresentations {
                id
                name
                stepSequenceTemplates {
                    sequenceType
                    stepTemplates {
                        type
                        unversionedEcsTaskName
                        version
                    }
                }
            }
            totalResults
        }
    }
`;

const GET_SEQUENCES_BY_ID = gql`
    query sequencesTemplate($sequencesTemplateId: String!) {
        sequencesTemplate(sequencesTemplateId: $sequencesTemplateId) {
            id
            name
            stepSequenceTemplates {
                sequenceType
                stepTemplates {
                    id
                    type
                    unversionedEcsTaskName
                    version
                }
            }
        }
        licensesForASequencesTemplate(seqTemplateId: $sequencesTemplateId) {
            id
            name
            organ
            totalPrice
            maxNumberOfCases
            maxNumberOfUsers
            sequencesTemplate {
                id
                name
            }
        }
    }
`;

const UPDATE_SEQUENCES = gql`
    mutation updateSequencesTemplate(
        $templateInput: EditSequencesTemplateInput!
    ) {
        updateSequencesTemplate(templateInput: $templateInput) {
            id
            name
            stepSequenceTemplates {
                sequenceType
                stepTemplates {
                    id
                    type
                    unversionedEcsTaskName
                    version
                }
            }
        }
    }
`;

const DELETE_SEQUENCES = gql`
    mutation deleteSequencesTemplate($id: String!) {
        deleteSequencesTemplate(id: $id) {
            id
            name
        }
    }
`;

const GET_TASKS_BY_SEQUENCE = gql`
    query tasks($input: String!) {
        tasks(sequenceType: $input) {
            tasks {
                key
                value {
                    name
                    version
                    description
                }
            }
        }
    }
`;

export const sequencesFragments = {
    CREATE_SEQUENCES,
    GET_ALL_SEQUENCES,
    GET_SEQUENCES_BY_ID,
    UPDATE_SEQUENCES,
    DELETE_SEQUENCES,
    GET_TASKS_BY_SEQUENCE,
};
