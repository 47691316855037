import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./passwordCard.module.scss";
import React, { useState } from "react";
import { Spacer } from "../../appCore/spacer/spacer";
import { ChangePasswordModal } from "../../appCore/changePasswordModal/changePasswordModal";

const { Title, Text } = Typography;

export const PasswordCard = () => {
    const { t } = useTranslation();
    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
        useState<boolean>(false);

    return (
        <>
            <Card className={styles.Card}>
                <div>
                    <Title level={4}>{t("myProfile.password.title")}</Title>
                    <Text type="secondary">
                        {t("myProfile.password.subtitle")}
                    </Text>
                </div>
                <Spacer size="s" />
                <div
                    className={styles.Password}
                    onClick={() => {
                        setIsChangePasswordModalVisible(true);
                    }}
                >
                    <div>
                        <Text>{t("myProfile.password.password")}</Text>
                        <Spacer size="xs" />
                        <Text>{t("myProfile.password.thePassword")}</Text>
                    </div>
                    <span className="kenko-icon-simple-right-arrow" />
                </div>
            </Card>
            {isChangePasswordModalVisible && (
                <ChangePasswordModal
                    setIsChangePasswordModalVisible={
                        setIsChangePasswordModalVisible
                    }
                />
            )}
        </>
    );
};
