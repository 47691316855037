import { useTranslation } from "react-i18next";
import { Button, Card, Divider, Form, Input, Typography } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./organisationInfoStep.module.scss";
import { Spacer } from "../../../appCore/spacer/spacer";
import { IOrganisationCreationDto } from "../../../../features/organisation/domain/entities/organisationModel";
import { useForm } from "antd/es/form/Form";

const { Title, Text } = Typography;

interface IProps {
    setCurrentStep: Dispatch<SetStateAction<number>>;
    organisationDetails?: IOrganisationCreationDto | undefined;
    setOrganisationDetails: Dispatch<
        SetStateAction<IOrganisationCreationDto | undefined>
    >;
    contextDependentTexts?: IContextDependentTexts;
    onButtonClick?: () => void;
}

interface IFormDto {
    organisationName: string;
    cifAndOthers: string;
    firstName: string;
    lastName: string;
    adminEmail: string;
}

interface IContextDependentTexts {
    firstSubtitle: string;
    secondSubtitle: string;
    buttonLabel: string;
}

export const OrganisationInfoStep = ({
    setCurrentStep,
    organisationDetails,
    setOrganisationDetails,
    contextDependentTexts,
    onButtonClick,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [form] = useForm();

    const validateMessages = {
        required: t("fieldValidation.required"),
    };

    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    // ? Validation logic
    const checkIsFormValid = (input: IFormDto) => {
        return (
            input &&
            input.organisationName !== undefined &&
            input.organisationName !== "" &&
            input.cifAndOthers !== undefined &&
            input.cifAndOthers !== "" &&
            input.firstName !== undefined &&
            input.firstName !== "" &&
            input.lastName !== undefined &&
            input.lastName !== "" &&
            input.adminEmail !== undefined &&
            input.adminEmail !== "" &&
            pattern.test(input.adminEmail)
        );
    };

    const onChange = (
        changedValues: IFormDto | undefined,
        values: IFormDto | undefined
    ) => {
        if (!values) return;
        setIsFormValid(checkIsFormValid(values));
        if (!checkIsFormValid(values)) return;

        setOrganisationDetails({
            name: values?.organisationName!,
            firstName: values?.firstName!,
            lastName: values?.lastName!,
            email: values?.adminEmail!,
            cifAndOthers: values?.cifAndOthers!,
            licenseId: "",
        });
    };

    useEffect(() => {
        if (!organisationDetails) return;
        form.setFieldsValue({
            organisationName: organisationDetails.name,
            cifAndOthers: organisationDetails.cifAndOthers,
            firstName: organisationDetails.firstName,
            lastName: organisationDetails.lastName,
            adminEmail: organisationDetails.email,
        });
    }, [organisationDetails]);

    return (
        <>
            <Card>
                <Form
                    layout="vertical"
                    onValuesChange={onChange}
                    validateMessages={validateMessages}
                    form={form}
                >
                    <div>
                        <Title level={4}>
                            {t("createOrganisation.infoStep.information")}
                        </Title>
                        <Text type="secondary">
                            {t(
                                contextDependentTexts
                                    ? contextDependentTexts.firstSubtitle
                                    : "createOrganisation.infoStep.informationSubtitle"
                            )}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createOrganisation.infoStep.name")}
                                name="organisationName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        "createOrganisation.infoStep.namePrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createOrganisation.infoStep.cif")}
                                name="cifAndOthers"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        "createOrganisation.infoStep.cifPrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Divider />

                    <div>
                        <Title level={4}>
                            {t("createOrganisation.infoStep.adminInformation")}
                        </Title>
                        <Text type="secondary">
                            {t(
                                contextDependentTexts
                                    ? contextDependentTexts.secondSubtitle
                                    : "createOrganisation.infoStep.adminInformationSubtitle"
                            )}
                        </Text>
                    </div>

                    <Spacer size={"m"} />

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t(
                                    "createOrganisation.infoStep.adminName"
                                )}
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        "createOrganisation.infoStep.adminNamePrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>

                        <div className={styles.Col}>
                            <Form.Item
                                label={t(
                                    "createOrganisation.infoStep.adminLastName"
                                )}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        "createOrganisation.infoStep.adminLastNamePrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className={styles.Row}>
                        <div className={styles.Col}>
                            <Form.Item
                                label={t("createOrganisation.infoStep.email")}
                                name="adminEmail"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: pattern,
                                        message: t(
                                            "formValidation.invalidEmail"
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        "createOrganisation.infoStep.emailPrompt"
                                    )}
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.Col}></div>
                    </div>

                    <Spacer size={"s"} />

                    <Form.Item className={styles.StepNavigation}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!isFormValid}
                            onClick={
                                onButtonClick
                                    ? onButtonClick
                                    : () => {
                                          setCurrentStep(1);
                                      }
                            }
                        >
                            {t(
                                contextDependentTexts
                                    ? contextDependentTexts.buttonLabel
                                    : "createOrganisation.next"
                            )}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};
