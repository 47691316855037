import { throwExceptionFromGqlQueryIfExists } from "../../../../core/data/dataSources/kenkoDataSource";
import {
    SequencesListPresentation,
    useAllSequencesLazyQuery,
} from "../../../../generated/graphql";

export interface ISequencesTemplateDataSource {
    getAllSequencesTemplates: (
        pageSize: number,
        pageNumber: number
    ) => Promise<SequencesListPresentation | undefined | null>;
}

export const SequencesTemplateDataSource = (): ISequencesTemplateDataSource => {
    const [allSequencesTemplatesQuery] = useAllSequencesLazyQuery();

    const getAllSequencesTemplates = async (
        pageSize: number,
        pageNumber: number
    ) => {
        const result = await allSequencesTemplatesQuery({
            variables: {
                input: { pageSize: pageSize, pageNumber: pageNumber },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.sequencesTemplates;
    };

    return {
        getAllSequencesTemplates,
    };
};
