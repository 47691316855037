import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { IUserLicenseModel } from "../../domain/entities/userLicenseModel";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
    FilterValue,
    SorterResult,
    TablePaginationConfig,
} from "antd/lib/table/interface";
import {
    IUserBasicInfo,
    IUserListPresentation,
} from "../../../user/domain/entities/userModel";
import styles from "./userLicenseList.module.scss";
import { DefaultOptionType } from "antd/lib/select";
import { CloseOutlined } from "@ant-design/icons";
import { LicensesProgressBar } from "../../../../components/organisationAdminComponents/common/licensesProgressBar/licensesProgressBar";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
    SortFields,
    TableParams,
} from "../../../common/domain/entities/sortInterfaces";
import { SearchBar } from "../../../common/presentation/searchBar/searchBar";
import { FilteredSelect } from "../../../user/presentation/filteredSelect/filteredSelect";

export interface DataType {
    id: string | null;
    creationOrder: number;
    organ: string | null;
    maxNumOfCases: any;
    totalCasesHistory: any;
    numberOfRemainingCases: any;
    active: boolean;
    user?: IUserBasicInfo | null | undefined;
}

export interface IProps {
    userLicenseList: IUserLicenseModel[];
    onAssignUserToUserLicense: (
        userLicense?: IUserLicenseModel,
        currentUserId?: string
    ) => void;
    updateIsAssignModalVisible: (value: boolean) => void;
    isAssignModalVisible: boolean;
    updateIsUnassignModalVisible: (value: boolean) => void;
    isUnassignModalVisible: boolean;
    onUnassignUser: (userLicenseId: string) => void;
    onLoadMoreUserLicenses: (
        pageNumber: number,
        sortFields: SortFields[]
    ) => void;
    onLoadMoreUsers: (pageNumber: number) => void;
    totalResults: number | undefined;
    userList: IUserListPresentation | undefined;
    onRefetchUserLicenses: () => void;
    onFilterUserLicenses: (textInput: string) => void;
    onFilterUser: (textInput: string) => void;
    textSearchUser: string;
    unfilteredUsers: IUserBasicInfo[] | undefined;
    filteredUsers: IUserBasicInfo[] | undefined;
    isLoading: boolean;
}

export const UserLicenseList = ({
    userLicenseList,
    onAssignUserToUserLicense,
    updateIsAssignModalVisible,
    isAssignModalVisible,
    updateIsUnassignModalVisible,
    isUnassignModalVisible,
    onUnassignUser,
    onLoadMoreUserLicenses,
    onLoadMoreUsers,
    totalResults,
    userList,
    onRefetchUserLicenses,
    onFilterUserLicenses,
    onFilterUser,
    textSearchUser,
    unfilteredUsers,
    filteredUsers,
    isLoading,
}: IProps) => {
    const [usersWithoutUserLicenseOption, setUsersWithoutUserLicenseOption] =
        useState<DefaultOptionType[]>([]);
    const { t } = useTranslation();
    const [unassignedUser, setUnassignedUser] = useState<
        IUserBasicInfo | undefined
    >();
    const [unassignedUserLicenseId, setUnassignedUserLicenseId] =
        useState<string>();
    const [currentUserId, setCurrentUserId] = useState<
        string | undefined | null
    >();
    const [userLicense, setUserLicense] = useState<IUserLicenseModel>();
    const [tableParams, setTableParams] = useState<TableParams>({});

    //===============USEEFFECTS==================
    useEffect(() => {
        refreshSelectOptions();
    }, [unfilteredUsers, filteredUsers]);

    useEffect(() => {
        setTableParams({
            pagination: {
                current: 1,
                pageSize: 10,
                total: totalResults,
                showSizeChanger: false,
            },
        });
    }, [totalResults]);

    //===============FUNCTIONS==================
    const refreshSelectOptions = () => {
        if (!textSearchUser) {
            if (!unfilteredUsers) return;
            setUsersWithoutUserLicenseOption(
                unfilteredUsers.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {u.name} {u.surname}
                            <span className={styles.email}> {u.email}</span>
                        </span>
                    ),
                }))
            );
        } else {
            if (!filteredUsers) return;
            setUsersWithoutUserLicenseOption(
                filteredUsers.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {u.name} {u.surname}
                            <span className={styles.email}> {u.email}</span>
                        </span>
                    ),
                }))
            );
        }
    };

    const onChange = (value: any) => {
        updateIsAssignModalVisible(true);
        setCurrentUserId(value);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<IUserLicenseModel>
            | SorterResult<IUserLicenseModel>[]
    ) => {
        let individualSorter = sorter as SorterResult<IUserLicenseModel>;
        let sorterList = sorter as SorterResult<IUserLicenseModel>[];

        let sortFields: SortFields[] = [];
        if (sorterList.length > 1) {
            sortFields = sorterList.map((o) => ({
                fieldName: o.columnKey,
                order: o.order,
            }));
        } else {
            if (!!individualSorter.order) {
                sortFields.push({
                    fieldName: individualSorter.columnKey,
                    order: individualSorter.order,
                });
            }
        }
        if (!pagination.current) return;
        onLoadMoreUserLicenses(pagination.current - 1, sortFields);

        setTableParams({
            pagination: {
                current: pagination.current,
                pageSize: tableParams.pagination?.pageSize,
                total: pagination.total,
                showSizeChanger: false,
            },
            ...sorter,
        });
    };

    const columns: ColumnsType<IUserLicenseModel> = [
        {
            title: t("userLicenseList.table.license"),
            dataIndex: "creationOrder",
            key: "creationOrder",
            width: "12%",
            sorter: {
                multiple: 1,
            },
            defaultSortOrder: "ascend",
            render: (creationOrder) => <>{"LIC-" + creationOrder}</>,
        },
        {
            title: t("userLicenseList.table.assignUser"),
            dataIndex: "user",
            key: "user",
            width: "35%",
            sorter: {
                multiple: 2,
            },
            render: (user, userLicense) => {
                if (!!user) {
                    const updatedOptions = [
                        {
                            value: user.id,
                            label: (
                                <span>
                                    {user.name} {user.surname}
                                    <span className={styles.email}>
                                        {" "}
                                        {user.email}
                                    </span>
                                </span>
                            ),
                        },
                        ...usersWithoutUserLicenseOption,
                    ];

                    return (
                        <>
                            <FilteredSelect
                                customKey={"select1" + userLicense.id}
                                className={styles.progress}
                                onChange={(a) => {
                                    onChange(a);
                                    setUserLicense(userLicense);
                                }}
                                options={updatedOptions}
                                refreshSelectOptions={refreshSelectOptions}
                                textSearchUser={textSearchUser}
                                unfilteredUsers={unfilteredUsers}
                                filteredUsers={filteredUsers}
                                onLoadMoreUsers={onLoadMoreUsers}
                                onFilterUser={onFilterUser}
                                dataList={userList}
                                value={user.id}
                            />
                            <Button
                                type="link"
                                onClick={() => {
                                    setUnassignedUser(userLicense.user!);
                                    setUnassignedUserLicenseId(userLicense.id!);
                                    updateIsUnassignModalVisible(true);
                                }}
                            >
                                <CloseOutlined />
                            </Button>
                        </>
                    );
                } else {
                    return (
                        <FilteredSelect
                            customKey={"select2" + userLicense.id}
                            className={styles.progress}
                            onChange={(a) => {
                                onAssignUserToUserLicense(userLicense, a!);
                            }}
                            options={usersWithoutUserLicenseOption}
                            refreshSelectOptions={refreshSelectOptions}
                            textSearchUser={textSearchUser}
                            unfilteredUsers={unfilteredUsers}
                            filteredUsers={filteredUsers}
                            onLoadMoreUsers={onLoadMoreUsers}
                            onFilterUser={onFilterUser}
                            dataList={userList}
                            value={null}
                        />
                    );
                }
            },
        },
        {
            title: t("userLicenseList.table.remainingCases"),
            dataIndex: "numberOfRemainingCases",
            key: "numberOfRemainingCases",
            width: "35%",
            sorter: {
                multiple: 3,
            },
            render: (numberOfRemainingCases, userLicense) => {
                return (
                    <LicensesProgressBar
                        remainingNum={numberOfRemainingCases}
                        maxNum={userLicense.maxNumOfCases}
                        isSelect={false}
                    />
                );
            },
        },
        {
            title: t("userLicenseList.table.assignCases"),
            dataIndex: "totalCasesHistory",
            key: "totalCasesHistory",
            sorter: {
                multiple: 4,
            },
            width: "10%",
        },
        {
            title: t("userLicenseList.table.actions"),
            key: "actions",
            dataIndex: "id",
            width: "8%",
            fixed: "right",
            render: (id) => (
                <>
                    <Link to={"/reloadUserLicense/" + id}>
                        <PlusOutlined className={styles.margin} />
                    </Link>
                    <Link to={"/assignUser/" + id}>
                        <UserOutlined />
                    </Link>
                </>
            ),
        },
    ];

    return (
        <>
            <SearchBar
                onFilter={onFilterUserLicenses}
                placeholder={t("tableTools.userLicensesPrompt")}
            />
            {userLicenseList ? (
                <Table
                    columns={columns}
                    dataSource={userLicenseList}
                    rowKey="id"
                    scroll={{ x: 1000 }}
                    onChange={(paginate, filter, sorter) =>
                        handleTableChange(paginate, filter, sorter)
                    }
                    pagination={tableParams.pagination}
                    loading={isLoading}
                />
            ) : (
                <div className={styles.TextContainer}>
                    <p>{t("userLicenseList.table.noUserLicenses")}</p>
                </div>
            )}
            <Modal
                title={t("userLicenseList.modal.1.title")}
                visible={isAssignModalVisible}
                width={550}
                closable={false}
                footer={[
                    <Button
                        key={"cancel"}
                        onClick={() => {
                            updateIsAssignModalVisible(false);
                            onRefetchUserLicenses();
                        }}
                        type="primary"
                    >
                        {t("userLicenseList.modal.1.cancel")}
                    </Button>,
                    <Button
                        key={"ok"}
                        onClick={() => {
                            if (!!userLicense && !!currentUserId) {
                                onAssignUserToUserLicense(
                                    userLicense,
                                    currentUserId
                                );
                            }
                        }}
                        type="primary"
                    >
                        {t("userLicenseList.modal.1.ok")}
                    </Button>,
                ]}
            >
                <p>
                    {t("userLicenseList.modal.1.subtitle1") +
                        "LIC-" +
                        userLicense?.creationOrder +
                        t("userLicenseList.modal.1.subtitle2")}
                </p>
            </Modal>
            <Modal
                title={
                    t("userLicenseList.modal.2.title1") +
                    unassignedUser?.name +
                    " " +
                    unassignedUser?.surname +
                    t("userLicenseList.modal.2.title2")
                }
                visible={isUnassignModalVisible}
                width={550}
                closable={false}
                footer={[
                    <Button
                        key={"cancel"}
                        onClick={() => {
                            updateIsUnassignModalVisible(false);
                        }}
                        type="primary"
                    >
                        {t("userLicenseList.modal.2.cancel")}
                    </Button>,
                    <Button
                        key={"ok"}
                        onClick={() => onUnassignUser(unassignedUserLicenseId!)}
                        type="primary"
                    >
                        {t("userLicenseList.modal.2.ok")}
                    </Button>,
                ]}
            >
                <p>{t("userLicenseList.modal.2.subtitle1")}</p>
            </Modal>
        </>
    );
};
