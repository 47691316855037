import { IOrganisationDetailsDto } from "../../../../features/organisation/domain/entities/organisationModel";
import { Button, Card, Col, Row, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import styles from "./organisationLicenseFeatures.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ILicenseFeature } from "../../../../features/licenseFeature/domain/entities/licenseFeature";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { OrganisationQuery } from "../../../../generated/graphql";
import { useState } from "react";
import { UnassignLicenseFeatureModal } from "./unassignLicenseFeatureModalComponent/unassignLicenseFeatureModal";

const { Title } = Typography;

interface IProps {
    organisationLicenseFeatures: ILicenseFeature[] | undefined;
    organisationDetailsCombined: IOrganisationDetailsDto | undefined;
    refetchOrganisationData?: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<OrganisationQuery>>;
}

export const OrganisationLicenseFeatures = ({
    organisationLicenseFeatures,
    organisationDetailsCombined,
    refetchOrganisationData,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [featureIdToDelete, setFeatureIdToDelete] = useState<string>();

    const onDelete = (id: string) => {
        setFeatureIdToDelete(id);
        setIsModalVisible(true);
    };

    const columns: ColumnsType<ILicenseFeature> = [
        {
            title: t("licenseFeature.feature.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("licenseFeature.feature.description"),
            dataIndex: "description",
            key: "description",
        },
        {
            title: t("licenseFeature.feature.price"),
            dataIndex: "price",
            key: "price",
        },
        {
            title: t("licenseFeature.feature.actions"),
            dataIndex: "id",
            key: "id",
            render: (id) => {
                return (
                    <div className={styles.ActionElements}>
                        <Button
                            onClick={() => {
                                onDelete(id);
                            }}
                            type="link"
                        >
                            <span className={`kenko-icon-bin`} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card>
                <Row>
                    <Col xs={12} md={12}>
                        <Title level={4}>{t("licenseFeature.title")}</Title>
                    </Col>
                    <Col xs={12} md={12}>
                        <div className={styles.DivButton}>
                            <Button
                                type="primary"
                                key="buton"
                                onClick={() => {
                                    navigate(
                                        "/assignLicenseFeatures/" +
                                            organisationDetailsCombined?.id!,
                                        {
                                            state: {
                                                organisationLicenseFeatures,
                                            },
                                        }
                                    );
                                }}
                            >
                                {t("licenseFeature.assignFeatureButton")}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Table
                            columns={columns}
                            dataSource={organisationLicenseFeatures}
                            rowKey="id"
                            scroll={{ x: 800 }}
                            pagination={{ pageSize: 3 }}
                        />
                    </Col>
                </Row>
            </Card>

            <UnassignLicenseFeatureModal
                orgId={organisationDetailsCombined?.id}
                licenseFeatureId={featureIdToDelete}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                refetchOrganisationData={refetchOrganisationData}
            />
        </>
    );
};
