import { useTranslation } from "react-i18next";
import styles from "./organisationSupportPage.module.scss";
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../hooks/headerHook";
import { HelpCard } from "../../../components/supportComponents/helpCard/helpCard";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import { HowItWorksCard } from "../../../components/supportComponents/howItWorksCard/howItWorksCard";
import { UserManualCard } from "../../../components/supportComponents/userManualCard/userManualCard";
import { AboutCard } from "../../../components/supportComponents/aboutCard/aboutCard";
import { SendCommentModal } from "../../../components/supportComponents/sendCommentModal/sendCommentModal";

const OrganisationSupportPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();

    const [isSendCommentModalVisible, setIsSendCommentModalVisible] =
        useState<boolean>(false);

    useEffect(() => {
        setHeaderContent({
            title: t("support.title"),
            breadCrumb: [],
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t]);

    return (
        <>
            <Tabs size="large" className={styles.Tabs}>
                <Tabs.TabPane tab={t("support.tabs.help")} key="1">
                    <div className={styles.Container}>
                        <div className={styles.Col}>
                            <div className={styles.Row}>
                                <HelpCard
                                    setIsSendCommentModalVisible={
                                        setIsSendCommentModalVisible
                                    }
                                />
                            </div>
                            <Spacer size="s" />
                            <div className={styles.Row}>
                                <HowItWorksCard />
                            </div>
                        </div>
                        <div className={styles.Col}>
                            <div className={styles.Row}>
                                <UserManualCard />
                            </div>
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("support.tabs.info")} key="2">
                    <div>
                        <AboutCard />
                    </div>
                </Tabs.TabPane>
            </Tabs>
            {isSendCommentModalVisible && (
                <SendCommentModal
                    setIsSendCommentModalVisible={setIsSendCommentModalVisible}
                />
            )}
        </>
    );
};

export default OrganisationSupportPage;
