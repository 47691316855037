import styles from "./organTag.module.scss";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";

interface IProps {
    organ: string;
}

export const OrganTag = ({ organ }: IProps) => {
    const { t } = useTranslation();

    return (
        <Tag className={styles.RoundedTag} color="purple">
            {t(organ.toLowerCase())}
        </Tag>
    );
};
