import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { CaseDataSource } from "../../data/dataSources/caseDataSource";
import {
    ISequenceImages,
    sequenceImagesFromListOfIModelImages,
} from "../entities/modelImages";
import { LogLevel, faro } from "@grafana/faro-web-sdk";

interface IAdditionalMethods {
    getImagesByCase: (caseId: string) => Promise<void>;
}

export const useImagesByCase: IUseDomainHook<
    ISequenceImages | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = CaseDataSource();
    const [images, setImages] = useState<ISequenceImages | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getImagesByCase = async (caseId: string) => {
        setIsLoading(true);

        try {
            const result = await dataSource.getImagesByCase(caseId);
            if (!result) {
                setErrorMessage("Not found");
                faro.api.pushLog(
                    [`Not found images of a case with id ${caseId}`],
                    { level: LogLevel.WARN }
                );
            } else {
                setImages(sequenceImagesFromListOfIModelImages(result));
            }
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }

        setIsLoading(false);
    };

    return {
        data: images,
        isLoading,
        errorMessage,
        getImagesByCase,
    };
};
