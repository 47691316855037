import { Button, Card, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../appCore/spacer/spacer";
import React, { useState } from "react";
import styles from "./howItWorksCard.module.scss";

const { Title, Text } = Typography;

export const HowItWorksCard = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const closeVideo = () => {
        setIsModalOpen(false);
        const videoPlayer = document.getElementById(
            "videoPlayer"
        ) as HTMLVideoElement;
        videoPlayer.pause();
    };

    return (
        <Card>
            <div>
                <Title level={4}>{t("support.howItWorks.title")}</Title>
                <Text type="secondary">{t("support.howItWorks.subtitle")}</Text>
            </div>
            <Spacer size="s" />
            <div className={styles.VideoBox}>
                <img src="/media/support/VideoCover.png" alt="" />
                <Button onClick={() => setIsModalOpen(true)}>
                    {t("support.howItWorks.watch")}
                </Button>
            </div>
            <Modal
                title={t("support.howItWorks.title")}
                visible={isModalOpen}
                width={800}
                cancelText={t("support.howItWorks.exit")}
                onCancel={closeVideo}
                okButtonProps={{ hidden: true }}
            >
                <div className={styles.VideoContainer}>
                    <video id="videoPlayer" height="400" width="800" controls>
                        <source src="/media/support/kenko--ingles.mp4" />
                    </video>
                </div>
            </Modal>
        </Card>
    );
};
