import { Key } from "react";
import {
    CaseListPresentation,
    CasePresentation,
    CaseSortableFields,
} from "../../../../generated/graphql";
import { IUserBasicInfo } from "../../../user/domain/entities/userModel";

export interface ICase {
    creationDate: Date;
    finalNotes?: string | null;
    id: string;
    initialNotes: string;
    isPositive?: boolean | null;
    isPositivePrediction: boolean;
    organ: string;
    pdfState?: string | null;
    percentage?: number | null;
    reference: string;
    state: string;
    user: IUserBasicInfo;
    zipLink?: string | null;
    zipState?: string | null;
}

export interface IFinalDiagnosis {
    caseId: string;
    finalNotes: string;
    isPositive: boolean;
    state: string;
}

export interface ICreationCaseDetails {
    reference: string;
    initialNotes: string;
    isPositivePrediction: boolean | undefined;
}

export interface ICaseDetails extends ICreationCaseDetails {
    state: StatusType;
    finalNotes?: string | null;
    isPositive?: boolean | null;
    creationDate: string;
    organ: string;
    percentage: number;
    zipState: ZipStatusType;
    zipLink: string;
}

export interface ICaseListPresentation {
    casePresentations: ICase[];
    totalResults: number;
}

export enum StatusType {
    Finished = "Finished",
    Closed = "Closed",
    Incomplete = "Incomplete",
    Processing = "Processing",
    Error = "Error",
    Ready = "Ready",
    Created = "Created",
}

export enum ZipStatusType {
    NotRequested = "NotRequested",
    Finished = "Finished",
    Processing = "Processing",
    Fail = "Fail",
}

export enum ECaseSortableFields {
    reference = "reference",
    creationDate = "creationDate",
    isPositivePrediction = "isPositivePrediction",
    isPositive = "isPositive",
}

export enum ECaseFilteredFields {
    creationDate = "creationDate",
    state = "state",
    isPositive = "isPositive",
    isPositivePrediction = "isPositivePrediction",
}

export enum CaseStatusType {
    Created = "Created",
    Ready = "Ready",
    Processing = "Processing",
    Finished = "Finished",
    Incomplete = "Incomplete",
    Error = "Error",
    Archived = "Archived",
    Closed = "Closed",
    SequenceUpdated = "SequenceUpdated",
}

export enum DiagnosisType {
    Positive = "true",
    Negative = "false",
    NoDiagnosis = "NoDiagnosis",
}

export const partialCaseFromPresentation = (
    casePresentation: Partial<CasePresentation>
): Partial<ICase> => ({
    creationDate: casePresentation.creationDate,
    finalNotes: casePresentation.finalNotes ?? null,
    id: casePresentation.id,
    initialNotes: casePresentation.initialNotes,
    isPositive: !!casePresentation.isPositive,
    isPositivePrediction: casePresentation.isPositivePrediction,
    organ: casePresentation.organ,
    pdfState: casePresentation.pdfState,
    percentage: casePresentation.percentage,
    reference: casePresentation.reference,
    state: casePresentation.state,
    zipLink: casePresentation.zipLink,
    zipState: casePresentation.zipState,
});

export const caseListFromCaseListPresentation = (
    presentation: Partial<CaseListPresentation>
): Partial<ICaseListPresentation> => ({
    casePresentations: presentation.casePresentations,
    totalResults: presentation.totalResults,
});

export const caseDetailsFromPartialCase = (
    currentCase: Partial<ICase>
): ICaseDetails => ({
    state: currentCase.state as StatusType,
    reference: currentCase.reference ?? "",
    initialNotes: currentCase.initialNotes ?? "",
    isPositivePrediction: currentCase.isPositivePrediction,
    isPositive: currentCase.isPositive,
    creationDate: currentCase.creationDate?.toString() ?? "",
    organ: currentCase.organ ?? "",
    percentage: currentCase.percentage ?? 0,
    zipState: currentCase.zipState as ZipStatusType,
    zipLink: currentCase.zipLink ?? "",
    finalNotes: currentCase.finalNotes ?? null,
});

export const finalDiagnosisFromPresentation = (
    currentCase: Partial<CasePresentation>
): IFinalDiagnosis => ({
    caseId: currentCase.id!,
    isPositive: currentCase.isPositive!,
    finalNotes: currentCase.finalNotes!,
    state: currentCase.state!,
});

export const CaseSortableFieldFromKey = (
    fieldName: Key | undefined
): CaseSortableFields => {
    switch (fieldName) {
        case ECaseSortableFields.reference:
            return CaseSortableFields.DoctorReference;
        case ECaseSortableFields.creationDate:
            return CaseSortableFields.CreationDate;
        case ECaseSortableFields.isPositive:
            return CaseSortableFields.FinalDiagnosis;
        default:
            return CaseSortableFields.InitialDiagnosis;
    }
};
