import { Progress } from "antd";
import styles from "./licensesProgressBar.module.scss";
import { GetProgressColor } from "../../../helpers/getProgressColor/getProgressColor";

export interface IProps {
    maxNum: number;
    remainingNum: number;
    isSelect: boolean;
}

export const LicensesProgressBar = ({
    maxNum,
    remainingNum,
    isSelect,
}: IProps) => {
    let usedCases = maxNum - remainingNum;
    let percent = (usedCases * 100) / maxNum;

    return (
        <>
            {!isSelect ? (
                <div className={styles.FractionContainer}>
                    <span>{usedCases + "/" + maxNum}</span>
                    <Progress
                        status="normal"
                        percent={percent}
                        size="small"
                        showInfo={false}
                        strokeColor={GetProgressColor(percent)}
                        trailColor="#EAEAEC"
                    />
                </div>
            ) : (
                <Progress
                    className={styles.selectProgressBar}
                    status="normal"
                    percent={percent}
                    size="small"
                    format={() => `${usedCases}/${maxNum}`}
                    strokeColor={GetProgressColor(percent)}
                    trailColor="#EAEAEC"
                />
            )}
        </>
    );
};
