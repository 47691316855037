import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useTranslation } from "react-i18next";
import { loadMore } from "../../../../components/helpers/selectUtils/selectUtils";
import {
    IUserBasicInfo,
    IUserListPresentation,
} from "../../domain/entities/userModel";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    IUserLicenseListPresentation,
    IUserLicenseModel,
} from "../../../userLicense/domain/entities/userLicenseModel";

interface IProps {
    onChange?: (value: string) => void;
    options: DefaultOptionType[];
    refreshSelectOptions: () => void;
    textSearchUser: string;
    unfilteredUsers: IUserBasicInfo[] | IUserLicenseModel[] | undefined;
    filteredUsers: IUserBasicInfo[] | IUserLicenseModel[] | undefined;
    onLoadMoreUsers: (pageNumber: number) => void;
    onFilterUser: (textInput: string) => void;
    dataList: IUserListPresentation | IUserLicenseListPresentation | undefined;
    customKey?: string;
    className?: string;
    value?: string | null;
    setComboPage?: Dispatch<SetStateAction<number>>;
}

export const FilteredSelect = ({
    onChange,
    options,
    refreshSelectOptions,
    textSearchUser,
    unfilteredUsers,
    filteredUsers,
    onLoadMoreUsers,
    onFilterUser,
    dataList,
    customKey,
    className,
    value,
    setComboPage,
}: IProps) => {
    const { t } = useTranslation();
    const [unfilteredPage, setUnfilteredPage] = useState(0);
    const [filteredPage, setFilteredPage] = useState(0);

    useEffect(() => {
        if (!setComboPage) return;
        setComboPage(unfilteredPage);
    }, [unfilteredPage]);

    return (
        <Select
            key={customKey}
            className={className}
            options={options}
            onChange={onChange}
            value={value}
            placeholder={t("createSequences.select")}
            onDropdownVisibleChange={() => refreshSelectOptions()}
            onPopupScroll={(e) => {
                if (!textSearchUser) {
                    loadMore(
                        e,
                        unfilteredUsers,
                        dataList,
                        unfilteredPage,
                        setUnfilteredPage,
                        onLoadMoreUsers
                    );
                } else {
                    loadMore(
                        e,
                        filteredUsers,
                        dataList,
                        filteredPage,
                        setFilteredPage,
                        onLoadMoreUsers
                    );
                }
            }}
            showSearch
            onSearch={(value) => {
                onFilterUser(value);
                setFilteredPage(0);
            }}
            filterOption={false}
        />
    );
};
