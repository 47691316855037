import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../hooks/headerHook";
import { CreateCaseUploadStep } from "../../components/createCaseComponents/createCaseUploadStep/createCaseUploadStep";
import { CreateCaseInfoStep } from "../../components/createCaseComponents/createCaseInfoStep/createCaseInfoStep";
import styles from "./createCasePage.module.scss";
import { StepsComponent } from "../../components/appCore/stepsComponent/stepsComponent";
import { ICreationCaseDetails } from "../../features/case/domain/entities/case";

export const CreateCasePage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [caseDetails, setCaseDetails] = useState<ICreationCaseDetails>({
        reference: "",
        isPositivePrediction: undefined,
        initialNotes: "",
    });

    const showCloseAlert = (ev: BeforeUnloadEvent) => {
        ev.preventDefault();
        return (ev.returnValue = "Are you sure you want to close?");
    };

    useEffect(() => {
        setHeaderContent({
            title: t("createCase.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/",
        });
        window.addEventListener("beforeunload", showCloseAlert);
        return () => {
            window.removeEventListener("beforeunload", showCloseAlert);
        };
    }, []);

    return (
        <Row wrap={true} gutter={16} className={styles.Container}>
            <Col span={24} md={8} xl={6}>
                <StepsComponent
                    currentStep={currentStep}
                    title={t("createCase.steps.title")}
                    steps={[
                        {
                            title: t("createCase.steps.1.title"),
                            description: t("createCase.steps.1.description"),
                        },
                        {
                            title: t("createCase.steps.2.title"),
                            description: t("createCase.steps.2.description"),
                        },
                    ]}
                />
            </Col>
            <Col span={24} md={16} xl={18}>
                {currentStep === 0 && (
                    <CreateCaseInfoStep
                        setCurrent={setCurrentStep}
                        setCaseDetails={setCaseDetails}
                        caseDetails={caseDetails}
                    />
                )}
                {currentStep === 1 && (
                    <CreateCaseUploadStep
                        setCurrent={setCurrentStep}
                        caseDetails={caseDetails}
                    />
                )}
            </Col>
        </Row>
    );
};
