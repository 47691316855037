import { Button, Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../appCore/spacer/spacer";
import styles from "./aboutCard.module.scss";
import React from "react";
import label from "../../../images/etiqueta-kenko.svg"

const { Title, Text } = Typography;

export const AboutCard = () => {
    const { t } = useTranslation();

    return (
        <Card>
            <div>
                <Title level={4}>{t("support.about.title")}</Title>
                <Text type="secondary">{t("support.about.subtitle")}</Text>
            </div>
            <Spacer size="s" />
            <Text type="secondary">{t("support.about.moreInfo")}</Text>
            <Spacer size="s" />
            <div className={styles.Buttons}>
                <a href="https://biopsiavirtual.com" target="_blank">
                    <Button>{t("support.about.linkKenko")}</Button>
                </a>
                <a
                    href="https://www.linkedin.com/company/kenko-imalytics"
                    target="_blank"
                >
                    <Button>{t("support.about.linkedIn")}</Button>
                </a>
            </div>
            <Spacer size="l" />
            <div className={styles.label}>
                <img src={label} alt={t("support.labelAlt")}/>
            </div>
            <Spacer size="s" />
        </Card>
    );
};
