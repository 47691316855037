import { gql } from "@apollo/client";

const GET_LICENSE_FEATURE_BY_ID = gql`
    query licenseFeature($licenseFeatureId: String!) {
        licenseFeature(licenseFeatureId: $licenseFeatureId) {
            id
            name
            description
            environKey
            environValue
            price
        }
        organisationsForAFeature(licenseFeatureId: $licenseFeatureId) {
            id
            name
            email
        }
    }
`;

const GET_LICENSE_FEATURES = gql`
    query licenseFeatures($input: GetLicenseFeatureInput!) {
        licenseFeatures(input: $input) {
            licenseFeatures {
                id
                name
                description
                price
                environKey
                environValue
            }
            totalResults
        }
    }
`;

const GET_UNASSIGNED_LICENSE_FEATURES = gql`
    query unassignedLicenseFeatures($orgId: String!) {
        unAssignedLicenseFeatures(orgId: $orgId) {
            id
            name
            description
            price
        }
    }
`;

const CREATE_LICENSE_FEATURE = gql`
    mutation addLicenseFeature($featureInput: AddLicenseFeatureInput!) {
        addLicenseFeature(featureInput: $featureInput) {
            id
            name
            description
            environKey
            environValue
            price
        }
    }
`;

const UPDATE_LICENSE_FEATURE = gql`
    mutation updateLicenseFeature($featureInput: EditLicenseFeatureInput!) {
        updateLicenseFeature(featureInput: $featureInput) {
            id
            name
            description
            environKey
            environValue
            price
        }
    }
`;

const DELETE_LICENSE_FEATURE = gql`
    mutation deleteLicenseFeature($licenseFeatureid: String!) {
        deleteLicenseFeature(licenseFeatureid: $licenseFeatureid) {
            id
            name
            description
            environKey
            environValue
            price
        }
    }
`;

const UNASSIGN_LICENSE_FEATURE_TO_ORG = gql`
    mutation unassignLicenseFeatureToOrg(
        $licenseFeatureId: String!
        $orgId: String!
    ) {
        unassignLicenseFeatureToOrg(
            orgId: $orgId
            licenseFeatureId: $licenseFeatureId
        ) {
            id
            name
            cifAndOthers
            admin {
                id
                name
                surname
                email
            }
        }
    }
`;

const ASSIGN_LICENSE_FEATURES_TO_ORG = gql`
    mutation assignLicenseFeatureToOrg(
        $licenseFeatureIds: [String!]!
        $orgId: String!
    ) {
        assignLicenseFeatureToOrg(
            orgId: $orgId
            licenseFeatureIds: $licenseFeatureIds
        ) {
            id
            name
            cifAndOthers
            admin {
                id
                name
                surname
                email
            }
        }
    }
`;

export const licenseFeatureFragments = {
    GET_LICENSE_FEATURE_BY_ID,
    GET_LICENSE_FEATURES,
    GET_UNASSIGNED_LICENSE_FEATURES,
    CREATE_LICENSE_FEATURE,
    UPDATE_LICENSE_FEATURE,
    DELETE_LICENSE_FEATURE,
    UNASSIGN_LICENSE_FEATURE_TO_ORG,
    ASSIGN_LICENSE_FEATURES_TO_ORG,
};
