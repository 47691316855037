import { Key, SortOrder } from "antd/lib/table/interface";
import {
    UserLicensePresentation,
    UserLicenseSortableFields,
    UserPresentation,
} from "../../../../generated/graphql";
import { IUserBasicInfo } from "../../../user/domain/entities/userModel";

export interface IUserLicenseModel {
    id: string | null;
    creationOrder: number;
    organ: string | null;
    maxNumOfCases: any;
    totalCasesHistory: any;
    numberOfRemainingCases: any;
    active: boolean;
    user?: IUserBasicInfo | null | undefined;
}

export interface IUserLicense {
    id?: string | null | undefined;
    creationOrder?: number | undefined;
    organ?: string | null | undefined;
    maxNumOfCases?: any;
    totalCasesHistory?: any;
    numberOfRemainingCases?: any;
    active?: boolean | undefined;
    user?: IUserBasicInfo | null | undefined;
}

export interface IUserLicenseCreate {
    numberOfCases: number | undefined;
    id?: string | undefined | null;
}

export interface IAssignUserLicenseToUserInput {
    userId: string;
    userLicenseId: string;
}

export interface IMoveNumCasesBetweenUserLicensesModel {
    userLicenseIdOrigin: string;
    numberOfCases: number;
    userLicenseIdDestiny: string;
}

export interface IUserLicenseListPresentation {
    userLicensePresentations: IUserLicenseModel[];
    totalResults: number;
}

export enum EUserLicensesSortableFields {
    creationOrder = "creationOrder",
    totalCasesHistory = "totalCasesHistory",
    numberOfRemainingCases = "numberOfRemainingCases",
    user = "user",
}

export const UserLicenseModelFromUserLicensePresentation = (
    userLicensePresentation: Partial<UserLicensePresentation>
): Partial<IUserLicenseModel> => ({
    id: userLicensePresentation.id,
    creationOrder: userLicensePresentation.creationOrder,
    organ: userLicensePresentation.organ,
    maxNumOfCases: userLicensePresentation.maxNumOfCases,
    totalCasesHistory: userLicensePresentation.totalCasesHistory,
    numberOfRemainingCases: userLicensePresentation.numberOfRemainingCases,
    active: userLicensePresentation.active,
    user: userLicensePresentation.user,
});

export const IUserBasicInfoFromconvertUserPresentation = (
    userPresentation: UserPresentation
): IUserBasicInfo => {
    return {
        id: userPresentation.id,
        email: userPresentation.email,
        name: userPresentation.name,
        surname: userPresentation.surname,
        orgId: userPresentation.orgId,
    };
};

export const SortableFieldFromKey = (
    fieldName: Key | undefined
): UserLicenseSortableFields => {
    switch (fieldName) {
        case EUserLicensesSortableFields.creationOrder:
            return UserLicenseSortableFields.CreationOrder;
        case EUserLicensesSortableFields.numberOfRemainingCases:
            return UserLicenseSortableFields.MaxNumberOfCases;
        case EUserLicensesSortableFields.user:
            return UserLicenseSortableFields.User;
        default:
            return UserLicenseSortableFields.TotalCasesHistory;
    }
};
