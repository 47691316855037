import {
    ApolloQueryResult,
    OperationVariables,
    useMutation,
} from "@apollo/client";
import { Modal } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    DeleteCaseMutation,
    RemainingItemsQuery,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { ResultModal } from "../resultModal/resultModal";
interface IProps {
    caseId: string | undefined;
    onRefetch?: () => void;
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    reference: string | undefined;
    refetchRemainingItems?: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<RemainingItemsQuery>>;
}

export const DeleteCaseModal = ({
    caseId,
    onRefetch,
    isVisible,
    setIsVisible,
    reference,
    refetchRemainingItems,
}: IProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);

    const [DeleteCaseMutation] = useMutation<DeleteCaseMutation>(
        fragments.DELETE_CASE
    );

    const onDeleteCase = (caseId: string | undefined) => {
        setIsResultModalVisible(true);
        DeleteCaseMutation({
            variables: {
                caseId: caseId,
            },
        }).then((res) => {
            setIsResultModalLoading(false);
            if (!!onRefetch) onRefetch();
            if (!!refetchRemainingItems) refetchRemainingItems();
        });
    };

    return (
        <>
            <Modal
                title={t("deleteCaseModal.title")}
                visible={isVisible && !isResultModalVisible}
                cancelText={t("cancel")}
                onCancel={() => setIsVisible(false)}
                okText={t("deleteCaseModal.delete")}
                onOk={() => {
                    onDeleteCase(caseId);
                }}
            >
                <p>
                    {t("deleteCaseModal.subtitle1")}
                    <b>{reference}</b>
                    {t("deleteCaseModal.subtitle2")}
                </p>
            </Modal>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                onPrimary={() => {
                    setIsVisible(false);
                    setIsResultModalVisible(false);
                    navigate("/");
                }}
                localisedTexts={{
                    title: t("deleteCaseModal.deleteSuccess.title"),
                    subtitle: t("deleteCaseModal.deleteSuccess.subtitle"),
                    primaryButton: t("deleteCaseModal.deleteSuccess.close"),
                }}
                hasSecondary={false}
            />
        </>
    );
};
