import {
    throwExceptionFromGqlMutationIfExists,
    throwExceptionFromGqlQueryIfExists,
} from "../../../../core/data/dataSources/kenkoDataSource";
import {
    SortInputOfUserSortableFieldsInput,
    UserListPresentation,
    UserPresentation,
    useAddUserMutation,
    useAllUsersLazyQuery,
    useDeleteUserMutation,
    useIsUserDeletedLazyQuery,
    useMeLazyQuery,
    useResetPasswordMutation,
    useUpdateUserMutation,
    useUserLazyQuery,
} from "../../../../generated/graphql";

export interface IUserDataSource {
    addUser: (
        firstName: string,
        lastName: string,
        email: string
    ) => Promise<UserPresentation>;
    resetPassword: () => Promise<UserPresentation>;
    getAllUsers: (
        isUsersWithoutLicense: boolean,
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserSortableFieldsInput[],
        textSearchInput?: string
    ) => Promise<UserListPresentation | undefined | null>;
    getMe: () => Promise<UserPresentation>;
    updateUser: (
        id: string,
        email: string,
        name: string,
        surname: string,
        orgId: string,
        isOrgAdmin: boolean
    ) => Promise<any>;
    deleteUser: (userId: string) => Promise<UserPresentation>;
    getUser: (userId: string) => Promise<UserPresentation>;
    isUserDeleted: (email: string) => Promise<boolean>;
}

export const UserDataSource = (): IUserDataSource => {
    const [addUserMutation] = useAddUserMutation();
    const [resetPasswordMutation] = useResetPasswordMutation();
    const [allUsersQuery] = useAllUsersLazyQuery();
    const [meQuery] = useMeLazyQuery();
    const [updateUserMutation] = useUpdateUserMutation();
    const [deleteUserMutation] = useDeleteUserMutation();
    const [userQuery] = useUserLazyQuery();
    const [isUserDeletedQuery] = useIsUserDeletedLazyQuery();

    const addUser = async (
        firstName: string,
        lastName: string,
        email: string
    ) => {
        const result = await addUserMutation({
            variables: {
                firstName: firstName,
                lastName: lastName,
                email: email,
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.addUser;
    };

    const resetPassword = async () => {
        const result = await resetPasswordMutation();
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.resetPassword;
    };

    const updateUser = async (
        id: string,
        email: string,
        name: string,
        surname: string,
        orgId: string,
        isOrgAdmin: boolean
    ) => {
        const result = await updateUserMutation({
            variables: {
                id: id,
                email: email,
                name: name,
                surname: surname,
                orgId: orgId,
                isOrgAdmin: isOrgAdmin,
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.updateUser;
    };

    const deleteUser = async (userId: string) => {
        const result = await deleteUserMutation({
            variables: {
                input: {
                    id: userId,
                },
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.deleteUser;
    };

    const getAllUsers = async (
        isUsersWithoutLicense: boolean,
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserSortableFieldsInput[],
        textSearchInput?: string
    ) => {
        const result = await allUsersQuery({
            variables: {
                input: {
                    isUsersWithoutLicense: isUsersWithoutLicense,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortInputs: sortInputs,
                    textSearchInput: textSearchInput,
                },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.users;
    };

    const getMe = async () => {
        const result = await meQuery();
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.me;
    };

    const getUser = async (userId: string) => {
        const result = await userQuery({
            variables: {
                input: userId,
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.user;
    };

    const isUserDeleted = async (email: string) => {
        const result = await isUserDeletedQuery({
            variables: {
                email: email,
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.isUserDeleted;
    };

    return {
        addUser,
        resetPassword,
        updateUser,
        deleteUser,
        getAllUsers,
        getMe,
        getUser,
        isUserDeleted,
    };
};
