import { Form, Input, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Spacer } from "../../../appCore/spacer/spacer";
import TextArea from "antd/es/input/TextArea";
import { ResultModal } from "../../../appCore/resultModal/resultModal";
import { useMutation } from "@apollo/client";
import { CreateSupportMessageMutation } from "../../../../generated/graphql";
import fragments from "../../../../gql/fragments";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface IProps {
    setIsExtendLicenseRequestModalVisible: Dispatch<SetStateAction<boolean>>;
}

interface IExtendLicenseRequestForm {
    numberOfCases: number;
    numberOfLicenses: number;
    extraInfo: string;
}

export const ExtendLicenseRequestModal = ({
    setIsExtendLicenseRequestModalVisible,
}: IProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [detailsToSend, setDetailsToSend] = useState<
        IExtendLicenseRequestForm | undefined
    >(undefined);
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(true);
    const [isSuccessModalError, setIsSuccessModalError] =
        useState<boolean>(false);

    const [CreateSupportMessage] = useMutation<CreateSupportMessageMutation>(
        fragments.CREATE_SUPPORT_MESSAGE
    );

    const checkIsFormValid = (input: IExtendLicenseRequestForm | undefined) => {
        return (
            input &&
            input.numberOfCases !== undefined &&
            input.numberOfCases !== 0 &&
            input.numberOfLicenses !== undefined &&
            input.numberOfLicenses !== 0 &&
            input.extraInfo !== undefined &&
            input.extraInfo !== ""
        );
    };

    const onChange = (
        changedValues: IExtendLicenseRequestForm | undefined,
        values: IExtendLicenseRequestForm | undefined
    ) => {
        if (!values) {
            setIsFormValid(false);
            return;
        }
        if (!checkIsFormValid(values)) {
            setIsFormValid(false);
            return;
        }

        setDetailsToSend(values);

        setIsFormValid(true);
    };

    const onSend = (values: IExtendLicenseRequestForm | undefined) => {
        if (!values) return;
        if (!checkIsFormValid(values)) return;

        setIsSuccessModalVisible(true);

        const line1 = "Number of Cases: " + values.numberOfCases;
        const line2 = "Number of User Licenses: " + values.numberOfLicenses;
        const line3 = "Extra Info: " + values.extraInfo;

        const content = line1 + "\n" + line2 + "\n" + line3;

        CreateSupportMessage({
            variables: { title: "Extend License Request", content: content },
        })
            .then((res) => {
                if (res === undefined || res === "")
                    setIsSuccessModalError(true);
                setIsSuccessModalLoading(false);
            })
            .catch(() => {
                setIsSuccessModalError(true);
            });
    };

    const closeModal = () => {
        setIsSuccessModalVisible(false);
        setIsSuccessModalLoading(true);
        setIsSuccessModalError(false);
        setIsExtendLicenseRequestModalVisible(false);
    };

    return (
        <>
            <Modal
                title={t("myAdminProfile.extendLicenseRequest.title")}
                visible={!isSuccessModalVisible}
                cancelText={t("cancel")}
                onCancel={() => setIsExtendLicenseRequestModalVisible(false)}
                okText={t("myAdminProfile.extendLicenseRequest.send")}
                onOk={() => onSend(detailsToSend)}
                okButtonProps={{ disabled: !isFormValid }}
            >
                <Text>{t("myAdminProfile.extendLicenseRequest.subtitle")}</Text>

                <Spacer size="s" />

                <Form layout="vertical" onValuesChange={onChange}>
                    <Form.Item
                        label={t(
                            "myAdminProfile.extendLicenseRequest.extraCasesTitle"
                        )}
                        name="numberOfCases"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "myAdminProfile.extendLicenseRequest.extraCasesPrompt"
                            )}
                            type="number"
                        />
                        {/*TODO: Add subtitle here*/}
                    </Form.Item>

                    <Form.Item
                        label={t(
                            "myAdminProfile.extendLicenseRequest.extraUserLicensesTitle"
                        )}
                        name="numberOfLicenses"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "myAdminProfile.extendLicenseRequest.extraUserLicensesPrompt"
                            )}
                            type="number"
                        />
                        {/*TODO: Add subtitle here*/}
                    </Form.Item>

                    <Form.Item
                        label={t(
                            "myAdminProfile.extendLicenseRequest.extraInfoTitle"
                        )}
                        name="extraInfo"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <TextArea
                            placeholder={t(
                                "myAdminProfile.extendLicenseRequest.extraInfoPrompt"
                            )}
                            showCount
                            maxLength={200}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                isError={isSuccessModalError}
                onPrimary={closeModal}
                hasSecondary={false}
                onErrorPrimary={() => {
                    closeModal();
                    navigate("/support");
                }}
                onErrorSecondary={closeModal}
                localisedTexts={{
                    title: t(
                        "myAdminProfile.extendLicenseRequest.success.title"
                    ),
                    subtitle: t(
                        "myAdminProfile.extendLicenseRequest.success.subtitle"
                    ),
                    primaryButton: t(
                        "myAdminProfile.extendLicenseRequest.success.back"
                    ),
                    errorTitle: t(
                        "myAdminProfile.extendLicenseRequest.error.title"
                    ),
                    errorSubtitle: t(
                        "myAdminProfile.extendLicenseRequest.error.subtitle"
                    ),
                    errorRetry: t(
                        "myAdminProfile.extendLicenseRequest.error.back"
                    ),
                    errorPrimary: t(
                        "myAdminProfile.extendLicenseRequest.error.support"
                    ),
                }}
            />
        </>
    );
};
