import { Button, Modal, Result, Spin } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./resultModal.module.scss";

interface ISuccessModalTexts {
    title: string;
    subtitle: string;
    secondaryButton?: string;
    primaryButton: string;
    errorTitle?: string;
    errorSubtitle?: string;
    errorRetry?: string;
    errorPrimary?: string;
}

interface IProps {
    isVisible: boolean;
    isLoading?: boolean;
    isError?: boolean;
    hasSecondary?: boolean;
    onPrimary: () => void;
    onSecondary?: () => void;
    onErrorPrimary?: () => void;
    onErrorSecondary?: () => void;
    localisedTexts: ISuccessModalTexts;
    isSecondaryButtonDisabled?: boolean;
}

export const ResultModal = ({
    isVisible = false,
    isLoading = false,
    isError = false,
    hasSecondary = true,
    isSecondaryButtonDisabled = false,
    onPrimary,
    onSecondary,
    localisedTexts,
    onErrorPrimary,
    onErrorSecondary,
}: IProps) => {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const onCreateNew = () => {
        navigate(0);
    };

    return (
        <Modal visible={isVisible} closable={false} footer={null} width={600}>
            {isError ? (
                <Result
                    key="resultModal"
                    status="error"
                    title={localisedTexts.errorTitle}
                    subTitle={localisedTexts.errorSubtitle}
                    extra={[
                        <Button
                            onClick={
                                onErrorSecondary
                                    ? onErrorSecondary
                                    : onCreateNew
                            }
                            key="secondary"
                        >
                            {localisedTexts.errorRetry}
                        </Button>,
                        <Button
                            onClick={onErrorPrimary}
                            key="primary"
                            style={
                                localisedTexts.errorPrimary
                                    ? {}
                                    : { display: "none" }
                            }
                            type="primary"
                        >
                            {localisedTexts.errorPrimary}
                        </Button>,
                    ]}
                />
            ) : isLoading ? (
                <div className={styles.LoadingModal} key="spin">
                    <Spin size="large" />
                </div>
            ) : (
                <Result
                    key="resultModalSuccess"
                    status="success"
                    title={localisedTexts.title}
                    subTitle={localisedTexts.subtitle}
                    extra={[
                        <Fragment key="secondary" >
                            {hasSecondary && (
                                <Button
                                hidden={isSecondaryButtonDisabled}
                                    onClick={
                                        onSecondary ? onSecondary : onCreateNew
                                    }
                                >
                                    {localisedTexts.secondaryButton}
                                </Button>
                            )}
                        </Fragment>,
                        <Button
                            type="primary"
                            key="primary"
                            onClick={onPrimary}
                        >
                            {localisedTexts.primaryButton}
                        </Button>,
                    ]}
                />
            )}
        </Modal>
    );
};
