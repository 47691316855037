import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../../appCore/spacer/spacer";
import { Dispatch, SetStateAction } from "react";

const { Text } = Typography;

interface IProps {
    setIsModalVisible: Dispatch<SetStateAction<boolean>>;
    isOrgAdmin: boolean;
}

export const AddFinalDiagnosis = ({
    setIsModalVisible,
    isOrgAdmin,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Text>{t("caseDetails.finalDiagnosis.description")}</Text>
            <Spacer size="s" />
            <Button
                onClick={() => setIsModalVisible(true)}
                disabled={isOrgAdmin}
            >
                {t("caseDetails.finalDiagnosis.addDiagnosis")}
                <span
                    className="kenko-icon-plus"
                    style={{ paddingLeft: "6px" }}
                ></span>
            </Button>
        </>
    );
};
