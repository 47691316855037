import { AppSider } from "../appSider/appSider";
import { Layout } from "antd";
import { AppHeader } from "../appHeader/appHeader";
import styles from "./appLayout.module.scss";
import { useLocation } from "react-router-dom";
import { useHeader } from "../../hooks/headerHook";

const { Content } = Layout;

interface IProps {
    children: JSX.Element;
}

export const AppLayout = ({ children }: IProps) => {
    const location = useLocation();
    const { headerContent } = useHeader();
    const { isHeaderHidden } = headerContent;
    let isLayoutHidden = location.pathname.toLowerCase().includes("/visualizer/")
    return (
        <>
            {isLayoutHidden ? (
                <>{children}</>
            ) : (
                <Layout>
                    {location.pathname.toLowerCase() !== "/createcase" &&
                        location.pathname.toLowerCase() !== "/invite" &&
                        location.pathname.toLowerCase() !== "/createlicense" &&
                        location.pathname.toLowerCase() !==
                            "/createsequences" &&
                        location.pathname !== "/createuserlicense" &&
                        location.pathname.toLowerCase() !==
                            "/createorganisation" && <AppSider />}
                    <Layout>
                        {!isHeaderHidden && <AppHeader></AppHeader>}
                        <Content
                            className={`${
                                isHeaderHidden
                                    ? styles.ErrorPageContent
                                    : styles.Content
                            }`}
                        >
                            {children}
                        </Content>
                    </Layout>
                </Layout>
            )}
        </>
    );
};
