import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ILicensePresentation } from "../../../features/licenseTemplate/domain/entities/licenseModel";
import { OrganTag } from "../../../components/appCore/organTag/organTag";
import { useAllLicenses } from "../../../features/licenseTemplate/domain/hooks/useAllLicenses";

export const LicenseListPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();
    const location = useLocation();

    const [licenseList, setLicenseList] = useState<ILicensePresentation[]>([]);
    const ELEMENTS_PAGE = 10;

    //===============HOOKS==================
    const {
        data: licenseListData,
        isLoading,
        errorMessage,
        getAllLicenses,
    } = useAllLicenses();

    //===============FUNCTIONS==================
    const onPaginate = (pageNumber: number) => {
        getAllLicenses(ELEMENTS_PAGE, pageNumber);
    };

    const columns: ColumnsType<ILicensePresentation> = [
        {
            title: t("licenseList.table.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("licenseList.table.organ"),
            dataIndex: "organ",
            key: "organ",
            render: (organ) => <OrganTag organ={organ.toLowerCase()} />,
        },
        {
            title: t("licenseList.table.distributor"),
            dataIndex: "distributor",
            key: "distributor",
            render: (distributor) => <>{distributor.name}</>,
        },
        {
            title: t("licenseList.table.sequence"),
            dataIndex: "sequencesTemplate",
            key: "sequencesTemplate",
            render: (sequencesTemplate) => (
                <>{sequencesTemplate && sequencesTemplate.name}</>
            ),
        },
        {
            title: t("licenseList.table.price"),
            dataIndex: "totalPrice",
            key: "totalPrice",
        },
        {
            title: t("licenseList.table.maxCases"),
            dataIndex: "maxNumberOfCases",
            key: "maxNumberOfCases",
        },
        {
            title: t("licenseList.table.maxUsers"),
            dataIndex: "maxNumberOfUsers",
            key: "maxNumberOfUsers",
        },
        {
            title: t("licenseList.table.actions"),
            key: "actions",
            dataIndex: "id",
            fixed: "right",
            render: (id) => (
                <Link to={"/license/" + id}>
                    <span className="kenko-icon-eye"></span>
                </Link>
            ),
        },
    ];

    //===============USEEFFECTS==================
    useEffect(() => {
        getAllLicenses(ELEMENTS_PAGE, 0);
    }, []);

    useEffect(() => {
        if (!licenseListData) return;
        setLicenseList(licenseListData.licensePresentations);
    }, [licenseListData]);

    useEffect(() => {
        getAllLicenses(ELEMENTS_PAGE, 0);
    }, [location]);

    useEffect(() => {
        setHeaderContent({
            title: t("licenseList.title"),
            breadCrumb: [],
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="createLicense"
                    onClick={() => {
                        navigate("/createLicense");
                    }}
                >
                    {t("licenseList.createLicense")}
                    <PlusOutlined />
                </Button>
            ),
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t]);

    return (
        <>
            <Table
                columns={columns}
                dataSource={licenseList}
                rowKey="id"
                scroll={{ x: 800 }}
                pagination={{
                    pageSize: ELEMENTS_PAGE,
                    total: licenseListData?.totalResults,
                    onChange: (page) => {
                        onPaginate(page - 1);
                    },
                }}
            />
        </>
    );
};
