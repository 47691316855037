import { Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Dispatch, Key, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ISequencesTemplateTable } from "../../../features/sequencesTemplate/domain/entities/sequencesModel";
import styles from "./sequencesExpandedTable.module.scss";
import { ILicenseTemplateModel } from "../../../features/licenseTemplate/domain/entities/licenseModel";

interface IProps {
    sequencesTemplateList: ISequencesTemplateTable[];
    isSequenceListPage: boolean;
    setLicenseDetails?: Dispatch<SetStateAction<ILicenseTemplateModel>>;
    sequencesIdKey?: Key;
    setSequencesIdKey?: Dispatch<SetStateAction<Key | undefined>>;
    onPaginate: (pageNumber: number) => void;
    totalResults: number | undefined;
    elementsPage: number;
}

export const SequencesExpandedTable = ({
    sequencesTemplateList,
    isSequenceListPage,
    setLicenseDetails,
    sequencesIdKey,
    setSequencesIdKey,
    onPaginate,
    totalResults,
    elementsPage,
}: IProps) => {
    const { t } = useTranslation();

    const expandedRowRender = (record: ISequencesTemplateTable) => {
        const { stepSequenceTemplates } = record;
        const subColumns = [
            {
                dataIndex: "sequenceType",
                key: "sequenceType",
                width: "25%",
                onCell: (_: any, index: number | undefined) => {
                    let counter = 0;
                    let result = 0;
                    for (
                        let i = 0;
                        i < record.stepSequenceTemplates.length;
                        i++
                    ) {
                        const stepSequenceTemplate =
                            record.stepSequenceTemplates[i];
                        if (index === counter) {
                            result = stepSequenceTemplate.stepTemplates.length;
                            break;
                        } else if (
                            index! <
                            counter + stepSequenceTemplate.stepTemplates.length
                        ) {
                            result = 0;
                            break;
                        }
                        counter += stepSequenceTemplate.stepTemplates.length;
                    }
                    return { rowSpan: result };
                },
            },
            {
                dataIndex: "type",
                key: "type",
                width: "25%",
            },
            {
                dataIndex: "unversionedEcsTaskName",
                key: "unversionedEcsTaskName",
                width: "25%",
            },

            {
                dataIndex: "version",
                key: "version",
                width: "25%",
            },
        ];

        const subData = stepSequenceTemplates
            .map((stepSequenceTemplate, index) => {
                const { sequenceType, stepTemplates } = stepSequenceTemplate;
                const data = stepTemplates.map((stepTemplate, i: number) => ({
                    key: `${index}_${i}`,
                    sequenceType,
                    type: stepTemplate.type,
                    unversionedEcsTaskName: stepTemplate.unversionedEcsTaskName,
                    version: stepTemplate.version,
                }));
                return data;
            })
            .flat();

        return (
            <Table
                rowClassName={styles.SubTable}
                columns={subColumns}
                dataSource={subData}
                pagination={false}
                showHeader={false}
                rowKey="key"
            />
        );
    };

    const seqListColumns: ColumnsType<ISequencesTemplateTable> = [
        {
            title: t("sequencesList.table.name"),
            dataIndex: "name",
            key: "nameseqListColumns",
            width: "25%",
            render: (_, sequencesModel) => {
                if (!sequencesModel.stepSequenceTemplates) return;
                return (
                    <div>
                        {sequencesModel.name}
                        <Tag className={styles.Tag}>
                            {sequencesModel.stepSequenceTemplates.length}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: t("sequencesList.table.type"),
            width: "25%",
        },
        {
            title: t("sequencesList.table.task"),
            width: "25%",
        },
        {
            title: t("sequencesList.table.version"),
            width: "10%",
        },
        {
            title: t("organisationList.table.actions"),
            dataIndex: "id",
            key: "idseqListColumns",
            width: "15%",
            align: "center",
            render: (id) => (
                <Link className={styles.Icon} to={"/sequence/" + id}>
                    <span className="kenko-icon-eye"></span>
                </Link>
            ),
        },
    ];

    const createLicensePageColumns: ColumnsType<ISequencesTemplateTable> = [
        {
            title: t("sequencesList.table.name"),
            dataIndex: "name",
            key: "namecreateLicensePageColumns",
            render: (_, sequencesModel) => {
                if (!sequencesModel.stepSequenceTemplates) return;
                return (
                    <div>
                        {sequencesModel.name}
                        <Tag className={styles.Tag}>
                            {sequencesModel.stepSequenceTemplates.length}
                        </Tag>
                    </div>
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ISequencesTemplateTable[]
        ) => {
            if (!!setSequencesIdKey && !!setLicenseDetails) {
                setLicenseDetails((prevState) => ({
                    ...prevState,
                    sequencesTemplateId: String(selectedRowKeys[0]),
                }));
                setSequencesIdKey(selectedRowKeys[0]);
            }
        },
    };

    return (
        <>
            {!isSequenceListPage ? (
                <Table
                    columns={createLicensePageColumns}
                    dataSource={sequencesTemplateList}
                    expandedRowRender={expandedRowRender}
                    rowKey="id"
                    rowSelection={{
                        type: "radio",
                        ...rowSelection,
                        selectedRowKeys: [sequencesIdKey!],
                    }}
                    pagination={{
                        pageSize: elementsPage,
                        total: totalResults,
                        onChange: (page) => {
                            onPaginate(page - 1);
                        },
                    }}
                />
            ) : (
                <Table
                    columns={seqListColumns}
                    dataSource={sequencesTemplateList}
                    expandedRowRender={expandedRowRender}
                    rowKey="id"
                    pagination={{
                        pageSize: elementsPage,
                        total: totalResults,
                        onChange: (page) => {
                            onPaginate(page - 1);
                        },
                    }}
                />
            )}
        </>
    );
};
