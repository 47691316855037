import {kenkoDataSource} from "../core/data/dataSources/kenkoDataSource";
import axios from "axios";
import { RcFile } from "antd/lib/upload";
import { ICaseDetails } from "../features/case/domain/entities/case";

const prostateCase = () => {
    return kenkoDataSource({
        method: "POST",
        url: "/case",
        data: { Reference: Date.now().toString(), Organ: "Bladder" },
    });
};

const breastCase = (caseDetails: ICaseDetails) => {
    return kenkoDataSource({
        method: "POST",
        url: "/case",
        data: {
            CaseId: "",
            OwnerId: "",
            OrgId: "",
            InitialNotes: caseDetails.initialNotes,
            InitialDiagnosis: caseDetails.isPositivePrediction,
            UserLicenseId: "",
            Reference: caseDetails.reference,
        },
    });
};

const getCaseUrl = (caseId: any, fileName: any) => {
    return kenkoDataSource({
        method: "GET",
        url: "/Upload/" + caseId,
        params: { fileName: caseId + "-origin.zip" },
    });
};

const postCaseFile = (data: RcFile, url: string, setPercent: any) => {
    return axios.put(url, data, {
        headers: {
            "content-type": "application/zip",
            "x-amz-meta-kenko-organ": "Breast",
            "x-amz-storage-class": "INTELLIGENT_TIERING",
            "Access-Control-Allow-Origin": "*",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentCompleted);
        },
    });
};

const CaseService = {
    prostateCase,
    breastCase,
    getCaseUrl,
    postCaseFile,
};

export default CaseService;
