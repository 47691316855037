import {
    throwExceptionFromGqlMutationIfExists,
    throwExceptionFromGqlQueryIfExists,
} from "../../../../core/data/dataSources/kenkoDataSource";
import {
    SortInputOfUserLicenseSortableFieldsInput,
    UserLicenseListPresentation,
    UserLicenseMovedCases,
    UserLicensePresentation,
    useAssignUserLicenseToUserMutation,
    useCreateUserLicenseMutation,
    useMoveNumCasesBetweenUserLicensesMutation,
    useReloadUserLicenseMutation,
    useReturnCasesToOrgLicenseMutation,
    useUnassignUserLicenseToUserMutation,
    useUserLicenseLazyQuery,
    useUserLicensesLazyQuery,
    useUserLicensesWithoutUserLazyQuery,
} from "../../../../generated/graphql";
import { IMoveNumCasesBetweenUserLicensesModel } from "../../domain/entities/userLicenseModel";

export interface IUserLicenseDataSource {
    createUserLicense: (
        numberOfCases: number,
        userId: string | null
    ) => Promise<UserLicensePresentation | undefined>;
    reloadUserLicense: (
        userLicenseId: string,
        numberOfCases: number
    ) => Promise<UserLicensePresentation | undefined>;
    assignUserToUserLicense: (
        userLicenseId: string,
        userId: string
    ) => Promise<UserLicensePresentation | undefined>;
    unassignUserToUserLicense: (
        userLicenseId: string
    ) => Promise<UserLicensePresentation | undefined>;
    moveCasesBetweenUserLicenses: (
        moveCasesInput: IMoveNumCasesBetweenUserLicensesModel
    ) => Promise<UserLicenseMovedCases | undefined>;
    returnCasesToOrg: (
        userLicenseId: string,
        numberOfCases: number
    ) => Promise<UserLicensePresentation | undefined>;
    getUserLicensesWithoutUser: (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserLicenseSortableFieldsInput[],
        textSearchInput?: string
    ) => Promise<UserLicenseListPresentation | undefined | null>;
    getUserLicenses: (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserLicenseSortableFieldsInput[],
        textSearchInput?: string
    ) => Promise<UserLicenseListPresentation | undefined | null>;
    getUserLicense: (
        userLicenseId: string
    ) => Promise<Partial<UserLicensePresentation> | undefined>;
}

export const UserLicenseDataSource = (): IUserLicenseDataSource => {
    const [userLicensesQuery] = useUserLicensesLazyQuery();
    const [userLicensesWithoutUserQuery] =
        useUserLicensesWithoutUserLazyQuery();
    const [userLicenseQuery] = useUserLicenseLazyQuery();
    const [createUserLicenseMutation] = useCreateUserLicenseMutation();
    const [reloadUserLicenseMutation] = useReloadUserLicenseMutation();
    const [assignUserLicenseMutation] = useAssignUserLicenseToUserMutation();
    const [unassignUserLicenseMutation] =
        useUnassignUserLicenseToUserMutation();
    const [moveCasesBetweenUserLicensesMutation] =
        useMoveNumCasesBetweenUserLicensesMutation();
    const [returnCasesToOrgMutation] = useReturnCasesToOrgLicenseMutation();

    const getUserLicenses = async (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserLicenseSortableFieldsInput[],
        textSearchInput?: string
    ) => {
        const result = await userLicensesQuery({
            variables: {
                input: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortInputs: sortInputs,
                    textSearchInput: textSearchInput,
                },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.userLicenses;
    };

    const getUserLicensesWithoutUser = async (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfUserLicenseSortableFieldsInput[],
        textSearchInput?: string
    ) => {
        const result = await userLicensesWithoutUserQuery({
            variables: {
                input: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    sortInputs: sortInputs,
                    textSearchInput: textSearchInput,
                },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.userLicensesWithoutUser;
    };

    const getUserLicense = async (userLicenseId: string) => {
        const result = await userLicenseQuery({
            variables: { userLicenseId: userLicenseId },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.userLicense;
    };

    const createUserLicense = async (
        numberOfCases: number,
        userId: string | null
    ) => {
        const result = await createUserLicenseMutation({
            variables: {
                input: {
                    numberOfCases: numberOfCases,
                    userId: userId,
                },
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.createUserLicense;
    };

    const reloadUserLicense = async (
        userLicenseId: string,
        numberOfCases: number
    ) => {
        const result = await reloadUserLicenseMutation({
            variables: {
                input: {
                    userLicenseId: userLicenseId,
                    numberOfCases: numberOfCases,
                },
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.reloadUserLicense;
    };

    const assignUserToUserLicense = async (
        userLicenseId: string,
        userId: string
    ) => {
        const result = await assignUserLicenseMutation({
            variables: {
                input: {
                    userLicenseId: userLicenseId,
                    userId: userId,
                },
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.assignUserLicenseToUser;
    };

    const unassignUserToUserLicense = async (userLicenseId: string) => {
        const result = await unassignUserLicenseMutation({
            variables: {
                input: {
                    userLicenseId: userLicenseId,
                },
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.unassignUserLicenseToUser;
    };

    const moveCasesBetweenUserLicenses = async (
        moveCasesInput: IMoveNumCasesBetweenUserLicensesModel
    ) => {
        const result = await moveCasesBetweenUserLicensesMutation({
            variables: { input: moveCasesInput },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.moveNumCasesBetweenUserLicenses;
    };

    const returnCasesToOrg = async (
        userLicenseId: string,
        numberOfCases: number
    ) => {
        const result = await returnCasesToOrgMutation({
            variables: {
                input: {
                    userLicenseId: userLicenseId,
                    numberOfCases: numberOfCases,
                },
            },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.returnCasesToOrgLicense;
    };

    return {
        getUserLicenses,
        getUserLicensesWithoutUser,
        getUserLicense,
        createUserLicense,
        reloadUserLicense,
        unassignUserToUserLicense,
        assignUserToUserLicense,
        moveCasesBetweenUserLicenses,
        returnCasesToOrg,
    };
};
