import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { IUserLicenseModel } from "../entities/userLicenseModel";
import { UserLicenseDataSource } from "../../data/dataSource/userLicenseDataSource";
import { faro } from '@grafana/faro-web-sdk';

interface IAdditionalMethods {
    getUserLicense: (userLicenseId: string) => Promise<void>;
}

export const useUserLicense: IUseDomainHook<
    Partial<IUserLicenseModel> | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = UserLicenseDataSource();
    const [userLicense, setUserLicense] = useState<
        Partial<IUserLicenseModel> | undefined
    >();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getUserLicense = async (userLicenseId: string) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getUserLicense(userLicenseId);
            if (!result) {
                setErrorMessage("Not found");
            } else {
                setUserLicense(result);
            }
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: userLicense,
        isLoading,
        errorMessage,
        getUserLicense,
    };
};
