import { licenseFragments } from "./fragments/licenses";
import { caseFragments } from "./fragments/cases";
import { organisationFragments } from "./fragments/organisations";
import { sequenceFragments } from "./fragments/sequence";
import { userFragments } from "./fragments/users";
import { supportFragments } from "./fragments/support";
import { notificationFragments } from "./fragments/notifications";
import { sequencesFragments } from "./fragments/sequences";
import { userLicensesFragments } from "./fragments/userLicenses";
import { licenseFeatureFragments } from "./fragments/licenseFeatures";

const fragments = {
    ...licenseFragments,
    ...caseFragments,
    ...organisationFragments,
    ...sequenceFragments,
    ...supportFragments,
    ...userFragments,
    ...notificationFragments,
    ...sequencesFragments,
    ...userLicensesFragments,
    ...licenseFeatureFragments,
};

export default fragments;
