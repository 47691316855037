import { Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    onOk: () => void;
    setShowBlockLeaveAlert: Dispatch<SetStateAction<boolean>>;
}

export const LeaveAlert = ({ onOk, setShowBlockLeaveAlert }: IProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            visible
            onOk={onOk}
            onCancel={() => {
                setShowBlockLeaveAlert(false);
            }}
            title={t("createCase.blockLeaveAlert.title")}
            okText={t("createCase.blockLeaveAlert.exit")}
            cancelText={t("createCase.blockLeaveAlert.cancel")}
            width={400}
        >
            {t("createCase.blockLeaveAlert.description")}
        </Modal>
    );
};
