import { Col, Row } from "antd";
import styles from "./createOrganisationPage.module.scss";
import { StepsComponent } from "../../../components/appCore/stepsComponent/stepsComponent";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { OrganisationAddLicenseStep } from "../../../components/adminComponents/createOrganisationComponents/organisationAddLicenseStep/organisationAddLicenseStep";
import { IOrganisationCreationDto } from "../../../features/organisation/domain/entities/organisationModel";
import { useMutation } from "@apollo/client";
import { AddOrganisationMutation } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { OrganisationInfoStep } from "../../../components/adminComponents/createOrganisationComponents/organisationInfoStep/organisationInfoStep";
import { ILicenseListPresentation } from "../../../features/licenseTemplate/domain/entities/licenseModel";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useNavigate } from "react-router-dom";
import { useAllLicenses } from "../../../features/licenseTemplate/domain/hooks/useAllLicenses";

export const CreateOrganisationPage = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [organisationDetails, setOrganisationDetails] =
        useState<IOrganisationCreationDto>();
    const { setHeaderContent } = useHeader();
    const [licenseList, setLicenseList] = useState<ILicenseListPresentation>();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [organisationId, setOrganisationId] = useState<string>("");
    const ELEMENTS_PAGE = 6;

    //===============HOOKS==================
    const {
        data: licenseListData,
        isLoading,
        errorMessage,
        getAllLicenses,
    } = useAllLicenses();

    useEffect(() => {
        getAllLicenses(ELEMENTS_PAGE, 0);
    }, []);

    const onPaginate = (pageNumber: number) => {
        getAllLicenses(ELEMENTS_PAGE, pageNumber);
    };

    useEffect(() => {
        setHeaderContent({
            title: t("createOrganisation.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/",
        });
    }, []);

    const [AddOrganisationMutation] = useMutation<AddOrganisationMutation>(
        fragments.CREATE_ORGANISATION
    );

    const onFinish = () => {
        setIsResultModalVisible(true);
        AddOrganisationMutation({ variables: organisationDetails })
            .then((org) => {
                if (!org.data) {
                    setIsResultModalError(true);
                }
                setIsResultModalLoading(false);
                setOrganisationId(org.data?.addOrganisation.id!);
            })
            .catch(() => {
                setIsResultModalLoading(false);
                setIsResultModalError(true);
            });
    };

    const onShowOrganisation = () => {
        navigate("../organisation/" + organisationId);
    };

    // ? Set License Data on Update
    useEffect(() => {
        if (!licenseListData) return;
        setLicenseList(licenseListData);
    }, [licenseListData]);

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepsComponent
                        currentStep={currentStep}
                        title={t("createOrganisation.steps.title")}
                        steps={[
                            {
                                title: t("createOrganisation.steps.1.title"),
                                description: t(
                                    "createOrganisation.steps.1.description"
                                ),
                            },
                            {
                                title: t("createOrganisation.steps.2.title"),
                                description: t(
                                    "createOrganisation.steps.2.description"
                                ),
                            },
                        ]}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    {(currentStep === 0 && (
                        <OrganisationInfoStep
                            setCurrentStep={setCurrentStep}
                            setOrganisationDetails={setOrganisationDetails}
                        />
                    )) ||
                        (currentStep === 1 && (
                            <OrganisationAddLicenseStep
                                onFinish={onFinish}
                                organisationDetails={organisationDetails}
                                setOrganisationDetails={setOrganisationDetails}
                                licenseList={licenseList}
                                onPaginate={onPaginate}
                                elementsPage={ELEMENTS_PAGE}
                            />
                        ))}
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowOrganisation}
                localisedTexts={{
                    title: t("createOrganisation.successModal.title"),
                    subtitle: t("createOrganisation.successModal.subtitle"),
                    secondaryButton: t(
                        "createOrganisation.successModal.createAnother"
                    ),
                    primaryButton: t(
                        "createOrganisation.successModal.showCase"
                    ),
                    errorTitle: t("createOrganisation.successModal.errorTitle"),
                    errorRetry: t("createOrganisation.successModal.errorRetry"),
                }}
            />
        </>
    );
};
