import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import styles from "./userListPage.module.scss";
import { DeleteUserModal } from "../../../features/user/presentation/deleteUserModal/deleteUserModal";
import {
    IUserWithLic,
    UserSortableFieldFromKey,
} from "../../../features/user/domain/entities/userModel";
import { Link, useNavigate } from "react-router-dom";
import { useAllUsers } from "../../../features/user/domain/hooks/useAllUsers";
import {
    SortInputOfUserSortableFieldsInput,
    UserSortableFields,
} from "../../../generated/graphql";
import {
    BooleanFromOrder,
    SortFields,
    TableParams,
} from "../../../features/common/domain/entities/sortInterfaces";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { SearchBar } from "../../../features/common/presentation/searchBar/searchBar";

export const UserListPage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { setHeaderContent } = useHeader();

    const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
    const [userToDeleteName, setUserToDeleteName] = useState<string>("");
    const [userToDeleteId, setUserToDeleteId] = useState<string>("");
    const [userWithLicDetails, setUserWithLicDetails] = useState<
        IUserWithLic[] | undefined
    >();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [sortInputs, setSortInputs] = useState<
        SortInputOfUserSortableFieldsInput[]
    >([{ fieldName: UserSortableFields.Name, isAsc: true }]);
    const [tableParams, setTableParams] = useState<TableParams>({});
    const [textSearchInput, setTextSeachInput] = useState<string>();
    const ELEMENTS_PAGE = 10;

    //===============HOOKS==================
    const {
        data: userList,
        isLoading,
        errorMessage,
        getAllUsers,
    } = useAllUsers();

    //===============FUNCTIONS==================
    const updateSortInput = (sortFields: SortFields[]) => {
        setSortInputs(
            sortFields.map((o) => ({
                fieldName: UserSortableFieldFromKey(o.fieldName),
                isAsc: BooleanFromOrder(o.order),
            }))
        );
    };

    const updateCurrentPage = (value: number) => {
        setCurrentPage(value);
    };

    const onLoadMore = (pageNumber: number, sortFields: SortFields[]) => {
        updateSortInput(sortFields);
        updateCurrentPage(pageNumber);
        let input: SortInputOfUserSortableFieldsInput[] = sortFields.map(
            (o) => ({
                fieldName: UserSortableFieldFromKey(o.fieldName),
                isAsc: BooleanFromOrder(o.order),
            })
        );
        getAllUsers(false, ELEMENTS_PAGE, pageNumber, input, textSearchInput);
    };

    const onFilter = (textInput: string) => {
        setTextSeachInput(textInput);
        getAllUsers(false, ELEMENTS_PAGE, 0, sortInputs, textInput);
    };

    const onRefetch = () => {
        getAllUsers(false, ELEMENTS_PAGE, currentPage, sortInputs);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<IUserWithLic> | SorterResult<IUserWithLic>[]
    ) => {
        let individualSorter = sorter as SorterResult<IUserWithLic>;
        let sorterList = sorter as SorterResult<IUserWithLic>[];

        let sortFields: SortFields[] = [];
        if (sorterList.length > 1) {
            sortFields = sorterList.map((o) => ({
                fieldName: o.columnKey,
                order: o.order,
            }));
        } else {
            if (!!individualSorter.order) {
                sortFields.push({
                    fieldName: individualSorter.columnKey,
                    order: individualSorter.order,
                });
            }
        }
        if (!pagination.current) return;
        onLoadMore(pagination.current - 1, sortFields);

        setTableParams({
            pagination: {
                current: pagination.current,
                pageSize: tableParams.pagination?.pageSize,
                total: pagination.total,
                showSizeChanger: false,
            },
            ...sorter,
        });
    };

    const columns: ColumnsType<IUserWithLic> = [
        {
            title: t("userList.table.name"),
            dataIndex: ["name", "surname"],
            key: "name",
            sorter: {
                multiple: 1,
            },
            defaultSortOrder: "ascend",
            render: (text, data) => (
                <span>{data.name + " " + data.surname}</span>
            ),
        },
        {
            title: t("userList.table.license"),
            dataIndex: "creationOrder",
            key: "creationOrder",
            render: (text, data) => {
                if (!data.creationOrder) {
                    return (
                        <span className={styles.noLicense}>
                            {t("userList.table.noLicense")}
                        </span>
                    );
                } else {
                    return <span>{"LIC-" + data.creationOrder}</span>;
                }
            },
        },
        {
            title: t("userList.table.remainingCases"),
            dataIndex: "numberOfRemainingCases",
            key: "numberOfRemainingCases",
        },
        {
            title: t("userList.table.totalCases"),
            dataIndex: "totalCases",
            key: "totalCases",
        },
        {
            title: t("userList.table.actions"),
            key: "action",
            dataIndex: ["id", "name", "surname"],
            fixed: "right",
            render: (text, data) => (
                <>
                    <Link to={"/editUser/" + data.id} className={styles.space}>
                        <span className="kenko-icon-edit"></span>
                    </Link>
                    <Button
                        type="link"
                        onClick={() => {
                            setUserToDeleteName(data.name + " " + data.surname);
                            setUserToDeleteId(data.id);
                            setIsDeletionModalVisible(true);
                        }}
                    >
                        <span className="kenko-icon-bin"></span>
                    </Button>
                </>
            ),
        },
    ];

    //===============USEEFFECTS==================
    useEffect(() => {
        setTableParams({
            pagination: {
                current: 1,
                pageSize: 10,
                total: userList?.totalResults,
                showSizeChanger: false,
            },
        });
    }, [userList?.totalResults]);

    useEffect(() => {
        getAllUsers(false, ELEMENTS_PAGE, currentPage, sortInputs);
    }, []);

    useEffect(() => {
        if (!userList) return;
        setUserWithLicDetails(userList.userPresentations);
    }, [userList]);

    useEffect(() => {
        setHeaderContent({
            title: t("userList.title"),
            breadCrumb: [],
            goBackLinkOverride: undefined,
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="inviteUser"
                    onClick={() => {
                        navigate("/invite");
                    }}
                >
                    {t("userList.invite")}
                    <span className={`kenko-icon-plus ${styles.buttonIcon}`} />
                </Button>
            ),
        });
    }, []);

    return (
        <>
            <SearchBar
                onFilter={onFilter}
                placeholder={t("tableTools.usersPrompt")}
            />
            {errorMessage ? (
                <div className={styles.textContainer}>
                    <p>{t("userList.table.errorLoading")}</p>
                </div>
            ) : isLoading ? (
                <Table columns={columns} loading />
            ) : userWithLicDetails !== undefined &&
              userWithLicDetails.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={userWithLicDetails as []}
                    rowKey="id"
                    onChange={(paginate, filter, sorter) =>
                        handleTableChange(paginate, filter, sorter)
                    }
                    pagination={tableParams.pagination}
                    loading={isLoading}
                />
            ) : (
                <div className={styles.textContainer}>
                    <p>{t("userList.table.noUsers")}</p>
                </div>
            )}
            <DeleteUserModal
                refetch={onRefetch}
                isVisible={isDeletionModalVisible}
                userName={userToDeleteName}
                userId={userToDeleteId}
                setIsVisible={setIsDeletionModalVisible}
                currentPage={currentPage}
            />
        </>
    );
};
