import styles from "./spacer.module.scss";
import React from "react";

declare const Sizes: ["xs", "s", "m", "l"];
export declare type Size = typeof Sizes[number];

interface IProps {
    size: Size;
}

export const Spacer = ({ size }: IProps) => {
    if (size === "xs") {
        return <div className={styles.SizeExtraSmall}></div>;
    }
    if (size === "s") {
        return <div className={styles.SizeSmall}></div>;
    }
    if (size === "m") {
        return <div className={styles.SizeMedium}></div>;
    } else {
        return <div className={styles.SizeBig}></div>;
    }
};
