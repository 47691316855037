import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { ILicenseFeature } from "../entities/licenseFeature";
import { LicenseFeatureDataSource } from "../../data/dataSources/featureDataSource";
import { faro } from '@grafana/faro-web-sdk';

interface IAdditionalMethods {
    deleteLicenseFeature: (id:string) => Promise<void>;
}

export const useDeleteLicenseFeature: IUseDomainHook<
    ILicenseFeature | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = LicenseFeatureDataSource();
    const [feature, setFeature] = useState<ILicenseFeature | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const deleteLicenseFeature = async (id:string) => {
        setIsLoading(true);

        try {
            const result = await dataSource.deleteLicenseFeature(id);
            setFeature(result);
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }

        setIsLoading(false);
    };

    return {
        data:feature,
        isLoading,
        errorMessage,
        deleteLicenseFeature,
    };
};