import { useTranslation } from "react-i18next";
import { Badge, Breadcrumb, Button, Divider, PageHeader } from "antd";
import { BellOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useHeader } from "../../hooks/headerHook";
import styles from "./appHeader.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import fragments from "../../gql/fragments";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useAsync } from "react-async";
import { AsyncTokenLookup } from "../../App";
import { RoleType } from "../../models/core/profileModel";
import {
    GlobalSubscription,
    RemainingItemsQuery,
} from "../../generated/graphql";
import { PushNotificationType } from "../../models/core/notificationModel";
import { NotificationBox } from "../notificationBox/notificationBox";
import { LeaveAlert } from "../createCaseComponents/leaveAlert/leaveAlert";
import { getCookie, setCookie } from "../helpers/cookieUtils/cookieUtils";
import i18next from "i18next";

interface JwtPayloadWithExtension extends JwtPayload {
    sub: string;
    extension_kenkousertype: string;
    extension_kenkoorgadmin: boolean;
    extension_kenkouserid: string;
}

export interface INotification {
    notificationType: PushNotificationType;
    numberIfRelevant?: number;
    textIfRelevant?: string;
    relevantId: string;
    read: boolean;
    id: string;
    doctorReference?: string;
}

export const AppHeader = () => {
    const { t, i18n } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    const { headerContent } = useHeader();
    const {
        breadCrumb,
        title,
        subtitle,
        buttonElement,
        hasCreateCaseButton,
        hasInviteUserButton,
        goBackLinkOverride,
        enableBlockOnLeave,
    } = headerContent;
    const navigate = useNavigate();
    const breadCrumbToFocus = breadCrumb[headerContent.breadCrumb.length - 2];
    const [userRole, setUserRole] = useState<string | null>();
    const [hasCases, setHasCases] = useState<boolean>(false);
    const [hasUsers, setHasUsers] = useState<boolean>(false);
    const [userId, setUserId] = useState<string | null>();
    const [isNotificationListOpen, setIsNotificationListOpen] =
        useState<boolean>(false);
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [notificationList, setNotificationList] = useState<INotification[]>(
        []
    );
    const [showBlockLeaveAlert, setShowBlockLeaveAlert] =
        useState<boolean>(false);

    const { data: remainingItemsData, subscribeToMore } =
        useQuery<RemainingItemsQuery>(fragments.GET_REMAINING_ITEMS);

    const token = useAsync(AsyncTokenLookup);

    useEffect(() => {
        setNotificationCount(notificationList.filter((x) => !x.read).length);
    }, [notificationList]);

    useEffect(() => {
        let cookie = getCookie("profileLanguage");
        if (cookie === undefined) {
            i18n.changeLanguage(navigator.language);
        } else {
            i18n.changeLanguage(cookie);
        }
    }, []);

    useEffect(() => {
        if (!token.data) return;

        let role = jwtDecode<JwtPayloadWithExtension>(
            token.data
        ).extension_kenkousertype;
        if (
            role === RoleType.User &&
            jwtDecode<JwtPayloadWithExtension>(token.data)
                .extension_kenkoorgadmin
        ) {
            role = RoleType.OrganisationAdmin;
        }

        let id = jwtDecode<JwtPayloadWithExtension>(
            token.data
        ).extension_kenkouserid;

        setUserRole(role);
        setUserId(id);
    }, [token.data]);

    useEffect(() => {
        let localStorageNotifications: INotification[] = [];
        Object.keys(localStorage).map((key) => {
            let localstorageItem = localStorage.getItem(key);
            if (!!localstorageItem) {
                localStorageNotifications.push(
                    JSON.parse(localstorageItem) as INotification
                );
            }
        });

        setNotificationList(() => [...localStorageNotifications]);
        return () => {};
    }, []);

    const getNotificationType = (
        notificationTypeString: string
    ): PushNotificationType => {
        let result = PushNotificationType.UserCreated;
        switch (notificationTypeString) {
            case PushNotificationType.UserCreated.toString():
                result = PushNotificationType.UserCreated;
                break;
            case PushNotificationType.AdminCreated.toString():
                result = PushNotificationType.AdminCreated;
                break;
            case PushNotificationType.CaseProgressed.toString():
                result = PushNotificationType.CaseProgressed;
                break;
            case PushNotificationType.PasswordReset.toString():
                result = PushNotificationType.PasswordReset;
                break;
            case PushNotificationType.SupportMessage.toString():
                result = PushNotificationType.SupportMessage;
                break;
            case PushNotificationType.CaseCompleted.toString():
                result = PushNotificationType.CaseCompleted;
                break;
            case PushNotificationType.FewCasesLeft.toString():
                result = PushNotificationType.FewCasesLeft;
                break;
            case PushNotificationType.FewUsersLeft.toString():
                result = PushNotificationType.FewUsersLeft;
                break;
            case PushNotificationType.ZipStateChanged.toString():
                result = PushNotificationType.ZipStateChanged;
                break;
        }

        return result;
    };
    useEffect(() => {
        if (!userId) {
            return;
        }

        subscribeToMore<GlobalSubscription>({
            document: fragments.GLOBAL_NOTIFICATIONS_SUBSCRIPTION,
            variables: { userId: userId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data.global) return prev;

                const {
                    notificationType,
                    numberIfRelevant,
                    relevantId,
                    textIfRelevant,
                    doctorReference,
                } = subscriptionData.data.global;

                let notificationResult: INotification = {
                    notificationType: getNotificationType(notificationType),
                    numberIfRelevant: numberIfRelevant,
                    textIfRelevant: textIfRelevant,
                    relevantId: relevantId,
                    doctorReference: doctorReference,
                    read: false,
                    id: `${relevantId}_${getNotificationType(
                        notificationType
                    )}_${numberIfRelevant}_${textIfRelevant}`,
                };

                // ? Check if there are cases left (numberIfRelevant is cases left)
                if (
                    notificationResult.notificationType ===
                    PushNotificationType.FewCasesLeft
                ) {
                    if (subscriptionData.data.global.numberIfRelevant === 0)
                        setHasCases(false);
                    else if (subscriptionData.data.global.numberIfRelevant > 0)
                        setHasCases(true);
                }

                // ? Check if there are users left (numberIfRelevant is users left)
                if (
                    notificationResult.notificationType ===
                        PushNotificationType.FewUsersLeft &&
                    userRole === RoleType.OrganisationAdmin
                ) {
                    if (subscriptionData.data.global.numberIfRelevant === 0)
                        setHasUsers(false);
                    else if (subscriptionData.data.global.numberIfRelevant > 0)
                        setHasUsers(true);
                }

                // ! Excluded notifications:
                if (
                    notificationType === PushNotificationType.ZipStateChanged &&
                    (textIfRelevant.toLowerCase() === "fail" ||
                        textIfRelevant.toLowerCase() === "notrequested")
                ) {
                    return prev;
                }

                setNotificationList((current) => {
                    let isInNotifications =
                        current.filter(
                            (element) => element.id === notificationResult.id
                        ).length === 1;

                    if (isInNotifications) {
                        return current;
                    } else {
                        return [...current, notificationResult];
                    }
                });

                return prev;
            },
        });
        return () => {};
    }, [userId]);

    useEffect(() => {
        if (!remainingItemsData) return;
        if (!remainingItemsData.remainingItems) return;

        if (remainingItemsData.remainingItems.remainingCases === 0)
            setHasCases(false);
        else if (remainingItemsData.remainingItems.remainingCases > 0) {
            setHasCases(true);
        }

        if (remainingItemsData.remainingItems.remainingUsers === 0)
            setHasUsers(false);
        else if (remainingItemsData.remainingItems.remainingUsers > 0) {
            setHasUsers(true);
        }
    }, [remainingItemsData]);

    const createCaseButton = (
        <Button
            type="primary"
            size="large"
            key="createCase"
            onClick={() => {
                navigate("/createCase");
            }}
            disabled={!hasCases}
        >
            {t("caseList.createCase")}
            <PlusOutlined />
        </Button>
    );

    const inviteUserButton = (
        <Button
            type="primary"
            size="large"
            key="createLicense"
            onClick={() => {
                navigate("/invite");
            }}
            disabled={!hasUsers}
        >
            {t("userList.invite")}
            <PlusOutlined />
        </Button>
    );

    const setAsRead = (notification: INotification) => {
        notification.read = true;

        setNotificationList((current) => {
            let restOfNotifications = current.filter(
                (element) => element.id !== notification.id
            );

            return [...restOfNotifications, notification];
        });
    };

    const deleteNotification = (notification: INotification) => {
        setNotificationList((current) => {
            let restOfNotifications = current.filter(
                (element) => element.id !== notification.id
            );

            return [...restOfNotifications];
        });
        localStorage.removeItem(notification.id);
    };

    const setAllRead = () => {
        setIsNotificationListOpen(false);
        setNotificationList((current) =>
            current.map((e) => {
                e.read = true;
                return e;
            })
        );
    };

    useEffect(() => {
        notificationList.map((element) => {
            localStorage.setItem(element.id, JSON.stringify(element));
        });
    }, [notificationList]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsNotificationListOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <PageHeader
            ghost={false}
            onBack={
                goBackLinkOverride
                    ? () => {
                          if (enableBlockOnLeave) {
                              setShowBlockLeaveAlert(true);
                              return;
                          }
                          if (!!goBackLinkOverride) {
                              navigate(goBackLinkOverride);
                          } else if (
                              !!breadCrumbToFocus &&
                              breadCrumbToFocus.link
                          ) {
                              navigate(breadCrumbToFocus.link!);
                          } else {
                              return;
                          }
                      }
                    : undefined
            }
            title={title}
            subTitle={subtitle}
            extra={[
                <div key="rightSide">
                    <span
                        onClick={() =>
                            setIsNotificationListOpen((current) => !current)
                        }
                    >
                        <Badge count={notificationCount}>
                            {/*TODO: Show count of unread*/}
                            <BellOutlined className={styles.bell} />
                        </Badge>
                    </span>

                    {(buttonElement ||
                        hasCreateCaseButton ||
                        hasInviteUserButton) && <Divider type="vertical" />}
                    {hasCreateCaseButton && createCaseButton}
                    {hasInviteUserButton && inviteUserButton}
                    {buttonElement}
                </div>,
            ]}
        >
            {headerContent.breadCrumb.length > 0 ? (
                <Breadcrumb>
                    {headerContent.breadCrumb.map((crumb, i) => {
                        return (
                            <Breadcrumb.Item key={i}>
                                {crumb.link ? (
                                    <Link to={crumb.link}>{crumb.label}</Link>
                                ) : (
                                    crumb.label
                                )}
                            </Breadcrumb.Item>
                        );
                    })}
                </Breadcrumb>
            ) : null}
            {isNotificationListOpen && (
                <NotificationBox
                    reference={ref}
                    notificationList={notificationList}
                    setAsRead={setAsRead}
                    deleteNotification={deleteNotification}
                    setAllRead={setAllRead}
                />
            )}
            {showBlockLeaveAlert && (
                <LeaveAlert
                    onOk={() => {
                        setShowBlockLeaveAlert(false);
                        if (!!goBackLinkOverride) {
                            navigate(goBackLinkOverride);
                        } else if (
                            !!breadCrumbToFocus &&
                            breadCrumbToFocus.link
                        ) {
                            navigate(breadCrumbToFocus.link!);
                        }
                    }}
                    setShowBlockLeaveAlert={setShowBlockLeaveAlert}
                />
            )}
        </PageHeader>
    );
};
