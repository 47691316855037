import { Alert, Button, Modal, Progress } from "antd";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../../appCore/spacer/spacer";
import { useNavigate } from "react-router-dom";

interface IProps {
    isVisible: boolean;
    isError: boolean;
    percent: number;
    onRetryCallback: () => void;
}

export const UploadModal = ({
    isVisible = false,
    isError,
    percent = 0,
    onRetryCallback,
}: IProps) => {
    let navigate = useNavigate();
    const { t } = useTranslation();

    // !! TODO: Block page leave
    // TODO: Set correct background color for alert

    return (
        <Modal
            title={t("createCase.uploadStep.uploadingModalTitle")}
            visible={isVisible}
            closable={false}
            footer={
                isError ? (
                    <Button type="primary" onClick={onRetryCallback}>
                        {t("createCase.uploadStep.tryAgain")}
                    </Button>
                ) : null
            }
        >
            <Progress
                percent={percent}
                status={isError ? "exception" : "active"}
            />
            <Spacer size="s" />
            {isError ? (
                <Alert
                    type="error"
                    message={t("createCase.uploadStep.modalErrorTitle")}
                    description={t(
                        "createCase.uploadStep.modalErrorDescription"
                    )}
                    showIcon
                    closable
                />
            ) : (
                <Alert
                    type="warning"
                    message={t("createCase.uploadStep.modalAlertTitle")}
                    description={t(
                        "createCase.uploadStep.modalAlertDescription"
                    )}
                    showIcon
                    closable
                />
            )}
        </Modal>
    );
};
