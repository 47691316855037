import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { CaseDataSource } from "../../data/dataSources/caseDataSource";
import { ISequence, sequenceFromSequencePresentation } from "../entities/sequence";
import { LogLevel, faro } from '@grafana/faro-web-sdk';

interface IAdditionalMethods {
    getSequencesByCase: (caseId:string) => Promise<void>;
}

export const useSequencesByCase: IUseDomainHook<
    ISequence[] | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = CaseDataSource();
    const [sequences, setSequences] = useState<ISequence[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getSequencesByCase = async (caseId:string) => {
        setIsLoading(true);

        try {
            const result = await dataSource.getSequencesByCase(caseId);
            if (!result) {
                setErrorMessage("Not found");   
                faro.api.pushLog([`Not found sequences of a case with id ${caseId}`], { level: LogLevel.WARN }); 
            }
            else{
                const sequenceResult = result.map((biomarker) => 
                sequenceFromSequencePresentation(biomarker)
                )
                setSequences(sequenceResult);
            }
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }

        setIsLoading(false);
    };

    return {
        data: sequences,
        isLoading,
        errorMessage,
        getSequencesByCase,
    };
};