import { useTranslation } from "react-i18next";
import { Layout, Menu } from "antd";
import logo from "../../images/logo.svg";
import { useMsal } from "@azure/msal-react";
import { LogoutOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./appSider.module.scss";
import { useProfile } from "../../hooks/profileHook";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { RoleType } from "../../models/core/profileModel";
import { useEffect, useState } from "react";

const { Sider } = Layout;

export const AppSider = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    let navigate = useNavigate();
    const { role } = useProfile();
    const location = useLocation();

    const [currentKey, setCurrentKey] = useState<string>("home");

    const userItems: ItemType[] | undefined = [
        {
            label: t("sider.cases"),
            key: "home",
            onClick: () => navigate("/"),
            icon: <span className="kenko-icon-document" />,
        },
        {
            label: t("sider.myProfile"),
            key: "myProfile",
            onClick: () => navigate("/myProfile"),
            icon: <span className="kenko-icon-person" />,
        },
        {
            label: t("sider.support"),
            key: "support",
            onClick: () => navigate("/support"),
            icon: <span className="kenko-icon-tool" />,
        },
    ];

    const adminItems: ItemType[] | undefined = [
        {
            label: t("sider.organisations"),
            key: "home",
            onClick: () => navigate("/"),
            icon: <span className="kenko-icon-document" />,
        },
        {
            label: t("sider.licenses"),
            key: "license",
            onClick: () => navigate("/licenses"),
            icon: <span className="kenko-icon-archive-filled" />,
        },
        {
            label: t("sider.sequences"),
            key: "sequence",
            onClick: () => navigate("/sequences"),
            icon: <span className="kenko-icon-sequences" />,
        },
        {
            label: t("licenseFeature.title"),
            key: "feature",
            onClick: () => navigate("/features"),
            //Change the icon when designed
            icon: <span className="kenko-icon-archive-filled" />,
        },
    ];

    const organisationAdminItems: ItemType[] | undefined = [
        {
            label: t("sider.cases"),
            key: "home",
            onClick: () => navigate("/"),
            icon: <span className="kenko-icon-document" />,
        },
        {
            label: t("sider.stats"),
            key: "statistics",
            onClick: () => navigate("/statistics"),
            icon: <span className="kenko-icon-stats" />,
        },
        {
            label: t("sider.myProfile"),
            key: "myProfile",
            onClick: () => navigate("/myProfile"),
            icon: <span className="kenko-icon-person" />,
        },
        {
            label: t("sider.users"),
            key: "user",
            icon: <span className="kenko-icon-people" />,
            children: [
                {
                    label: t("sider.userLicenses"),
                    key: "userLicensesSubmenu",
                    onClick: () => navigate("/userLicenses"),
                },
                {
                    label: t("sider.users"),
                    key: "usersSubmenu",
                    onClick: () => navigate("/users"),
                },
            ],
        },
        {
            label: t("sider.support"),
            key: "support",
            onClick: () => navigate("/support"),
            icon: <span className="kenko-icon-tool" />,
        },
    ];

    const logoutMenuItem = [
        {
            label: t("sider.logout"),
            key: "logout",
            onClick: () => {
                const currentAccount = instance.getAccountByHomeId(
                    instance.getAllAccounts()[0].localAccountId
                );
                instance.logoutRedirect({
                    account: currentAccount,
                });
            },
            icon: <LogoutOutlined />,
        },
    ];

    let currentItems =
        role === RoleType.User
            ? userItems
            : role === RoleType.Admin
            ? adminItems
            : organisationAdminItems;

    useEffect(() => {
        currentItems.forEach((item) => {
            if (!item) return;
            if (
                item.key === location.pathname.replace("/", "") ||
                location.pathname.includes(item.key!.toString())
            ) {
                setCurrentKey(item.key!.toString());
                return;
            } else if (location.pathname === "/") {
                setCurrentKey("home");
                return;
            }
        });
    }, [location]);

    return (
        <Sider
            width={200}
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={(broken) => {}}
            onCollapse={(collapsed, type) => {}}
            className={styles.Sider}
        >
            <Link to={"/"}>
                <img src={logo} className="logo" alt="logo" />
            </Link>

            <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                selectedKeys={[currentKey]}
                items={currentItems}
            />
            <Menu
                mode="inline"
                items={logoutMenuItem}
                className={styles.Logout}
            />
        </Sider>
    );
};
