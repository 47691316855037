import { Button, Card, Divider, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISequenceTemplateModel } from "../../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { DeleteSequencesModal } from "../deleteSequencesModel/deleteSequencesModal";
import styles from "./sequencesDetailsDescription.module.scss";

const { Title, Text } = Typography;

interface IProps {
    sequencesDetails: ISequenceTemplateModel | undefined;
    numLicenses: number | undefined;
}

export const SequencesDetailsDescription = ({
    sequencesDetails,
    numLicenses,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);
    let space = " ";

    return (
        <>
            <Title level={4}>{sequencesDetails?.name}</Title>
            <Card>
                <Text>
                    <>
                        {t("sequencesDetails.description.numTecniques") + space}
                        <b>{sequencesDetails?.stepSequenceTemplates.length}</b>
                    </>
                </Text>
                <Divider />
                {!numLicenses ? (
                    <Text>
                        <>
                            {t("sequencesDetails.description.numLicenses") +
                                space}
                            <b>0</b>
                        </>
                    </Text>
                ) : (
                    <Text>
                        <>
                            {t("sequencesDetails.description.numLicenses") +
                                space}
                            <b>{numLicenses}</b>
                        </>
                    </Text>
                )}
                <Divider />

                <Row className={styles.ButtonsRow}>
                    {!numLicenses ? (
                        <>
                            <Button
                                onClick={() => {
                                    setIsDeleteModalVisible(true);
                                }}
                                className={styles.DeleteButton}
                            >
                                {t("sequencesDetails.delete")}
                                <span
                                    className={`kenko-icon-bin ${styles.DeleteIcon}`}
                                />
                            </Button>
                            <Button
                                className={styles.EditButton}
                                onClick={() =>
                                    navigate(
                                        "/editSequence/" + sequencesDetails?.id
                                    )
                                }
                            >
                                {t("edit")}
                                <span
                                    className={`kenko-icon-edit ${styles.EditIcon}`}
                                />
                            </Button>
                        </>
                    ) : (
                        <Button
                            className={styles.AloneEditButton}
                            onClick={() =>
                                navigate(
                                    "/editSequence/" + sequencesDetails?.id
                                )
                            }
                        >
                            {t("edit")}
                            <span
                                className={`kenko-icon-edit ${styles.EditIcon}`}
                            />
                        </Button>
                    )}
                </Row>
            </Card>
            <DeleteSequencesModal
                isVisible={isDeleteModalVisible}
                setIsVisible={setIsDeleteModalVisible}
                sequencesDetails={sequencesDetails}
            />
        </>
    );
};
