import { Button, Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../appCore/spacer/spacer";
import React from "react";
import styles from "./userManualCard.module.scss";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

export const UserManualCard = () => {
    const { t } = useTranslation();

    return (
        <Card>
            <div>
                <Title level={4}>{t("support.userManual.title")}</Title>
                <Text type="secondary">{t("support.userManual.subtitle")}</Text>
            </div>
            <Spacer size="s" />
            <Link
                to="/media/support/[V.2] Manual de Usuario.pdf"
                target="_blank"
            >
                <img
                    className={styles.ManualBox}
                    src="/media/support/Manual Cover.png"
                />
            </Link>
            <Spacer size="s" />
            <a href="/media/support/[V.2] Manual de Usuario.pdf" download>
                <Button>
                    {t("support.userManual.download")}
                    <span className="kenko-icon-download button-icon" />
                </Button>
            </a>
        </Card>
    );
};
