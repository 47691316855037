import styles from "./statisticsPage.module.scss";
import { Col, Row } from "antd";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import { CounterComponent } from "../../../components/organisationAdminComponents/statisticsComponents/counterComponent/counterComponent";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useHeader } from "../../../hooks/headerHook";
import { RingStatComponent } from "../../../components/organisationAdminComponents/statisticsComponents/ringStatComponent/ringStatComponent";
import { useQuery } from "@apollo/client";
import { StatsQuery } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";

export const StatisticsPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();

    const {
        loading: getStatsLoading,
        error: getStatsError,
        data: getStatsData,
    } = useQuery<StatsQuery>(fragments.GET_STATS);

    useEffect(() => {
        setHeaderContent({
            title: t("sider.stats"),
            breadCrumb: [],
        });
    }, []);

    let { stats } = getStatsData || {};

    return (
        <>
            <Row wrap={true} gutter={16}>
                <Col xs={24} md={12} className={styles.FirstCard}>
                    {stats && (
                        <CounterComponent
                            title={t("stats.cases.title")}
                            description={t("stats.cases.description")}
                            count={stats.totalCreatedCases}
                            outOf={stats.maxNumberOfCases}
                            isCard={true}
                        />
                    )}
                </Col>

                <Col xs={24} md={12}>
                    {stats && (
                        <CounterComponent
                            title={t("stats.licenses.title")}
                            description={t("stats.licenses.description")}
                            count={
                                stats.maxNumberOfUsers -
                                stats.numberOfRemainingUsers
                            }
                            outOf={stats.maxNumberOfUsers}
                            isCard={true}
                        />
                    )}
                </Col>
            </Row>

            <Spacer size="s" />

            <Row wrap={true} gutter={16}>
                <Col span={24}>
                    {stats && (
                        <RingStatComponent
                            localisedTexts={{
                                title: t("stats.falsePositives.title"),
                                description: t(
                                    "stats.falsePositives.description"
                                ),
                                detailOne: t(
                                    "stats.falsePositives.totalPositive"
                                ),
                                detailTwo: t(
                                    "stats.falsePositives.totalFalsePositive"
                                ),
                                ringDataName: t(
                                    "stats.falsePositives.falsePositives"
                                ),
                                underRingText: t(
                                    "stats.falsePositives.savings"
                                ),
                                cases: t("stats.falsePositives.cases"),
                            }}
                            dataOne={stats.totalPositive}
                            dataTwo={stats.positiveFalse}
                        />
                    )}
                </Col>
            </Row>

            <Spacer size="s" />

            <Row wrap={true} gutter={16}>
                <Col span={24}>
                    {stats && (
                        <RingStatComponent
                            localisedTexts={{
                                title: t("stats.falseNegatives.title"),
                                description: t(
                                    "stats.falseNegatives.description"
                                ),
                                detailOne: t(
                                    "stats.falseNegatives.totalNegative"
                                ),
                                detailTwo: t(
                                    "stats.falseNegatives.totalFalseNegative"
                                ),
                                ringDataName: t(
                                    "stats.falseNegatives.falsePositives"
                                ),
                                underRingText: t(
                                    "stats.falseNegatives.savings"
                                ),
                                cases: t("stats.falseNegatives.cases"),
                            }}
                            dataOne={stats.totalNegative}
                            dataTwo={stats.negativeFalse}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};
