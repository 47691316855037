import { Select, Form, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Spacer } from "../../appCore/spacer/spacer";
import TextArea from "antd/es/input/TextArea";
import { ResultModal } from "../../appCore/resultModal/resultModal";
import { useMutation } from "@apollo/client";
import { CreateSupportMessageMutation } from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { useForm } from "antd/es/form/Form";

const { Text } = Typography;

interface IProps {
    setIsSendCommentModalVisible: Dispatch<SetStateAction<boolean>>;
}

interface IFormInput {
    requestType: string;
    details: string;
}

export const SendCommentModal = ({ setIsSendCommentModalVisible }: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [detailsToSend, setDetailsToSend] = useState<IFormInput | undefined>(
        undefined
    );
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(true);
    const [isSuccessModalError, setIsSuccessModalError] =
        useState<boolean>(false);

    const [CreateSupportMessage] = useMutation<CreateSupportMessageMutation>(
        fragments.CREATE_SUPPORT_MESSAGE
    );

    const onChange = (
        changedValues: IFormInput | undefined,
        values: IFormInput | undefined
    ) => {
        if (!values) {
            setIsFormValid(false);
            return;
        }

        if (!checkIsFormValid(values)) {
            setIsFormValid(false);
            return;
        }

        setDetailsToSend(values);

        setIsFormValid(true);
    };

    const onSend = (values: IFormInput | undefined) => {
        if (!values) return;
        if (!checkIsFormValid(values)) return;

        setIsSuccessModalVisible(true);

        const line1 = "Request Type: " + values.requestType;
        const line2 = "Request Details: " + values.details;

        const content = line1 + "\n" + line2;

        CreateSupportMessage({
            variables: { title: "Support Message", content: content },
        })
            .then((res) => {
                if (res === undefined || res === "")
                    setIsSuccessModalError(true);
                setIsSuccessModalLoading(false);
            })
            .catch(() => {
                setIsSuccessModalError(true);
            });
    };

    // ? Validation logic
    const checkIsFormValid = (input: IFormInput | undefined) => {
        return (
            input !== undefined &&
            input.requestType !== undefined &&
            input.requestType !== "" &&
            input.details !== undefined &&
            input.details !== ""
        );
    };

    const closeModal = () => {
        setIsSendCommentModalVisible(false);
        setIsSuccessModalError(false);
        setIsSuccessModalLoading(true);
        setIsSuccessModalVisible(false);
    };

    const sendAnother = () => {
        form.resetFields();
        setIsSuccessModalError(false);
        setIsSuccessModalLoading(true);
        setIsSuccessModalVisible(false);
    };

    return (
        <>
            <Modal
                title={t("support.sendComment.title")}
                visible={!isSuccessModalVisible}
                okText={t("support.sendComment.send")}
                cancelText={t("cancel")}
                okButtonProps={{ disabled: !isFormValid }}
                onOk={() => {
                    onSend(detailsToSend);
                }}
                onCancel={closeModal}
            >
                <Text>{t("support.sendComment.subtitle")}</Text>
                <Spacer size="s" />
                <Form layout="vertical" onValuesChange={onChange} form={form}>
                    <Form.Item
                        label={t("support.sendComment.type")}
                        name="requestType"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("support.sendComment.typePrompt")}
                            options={[
                                {
                                    label: t("support.sendComment.types.issue"),
                                    value: "issue",
                                },
                                {
                                    label: t(
                                        "support.sendComment.types.suggestion"
                                    ),
                                    value: "suggestion",
                                },
                                {
                                    label: t(
                                        "support.sendComment.types.question"
                                    ),
                                    value: "question",
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("support.sendComment.details")}
                        name="details"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <TextArea
                            placeholder={t("support.sendComment.detailsPrompt")}
                            rows={4}
                            maxLength={500}
                            showCount
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                isError={isSuccessModalError}
                onPrimary={closeModal}
                onSecondary={sendAnother}
                onErrorPrimary={closeModal}
                onErrorSecondary={sendAnother}
                localisedTexts={{
                    title: t("support.sendComment.success.title"),
                    subtitle: t("support.sendComment.success.subtitle"),
                    secondaryButton: t("support.sendComment.success.secondary"),
                    primaryButton: t("support.sendComment.success.primary"),
                    errorTitle: t("support.sendComment.error.title"),
                    errorPrimary: t("support.sendComment.error.primary"),
                    errorRetry: t("support.sendComment.error.retry"),
                }}
            />
        </>
    );
};
