import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AddSequencesStep } from "../../../components/adminComponents/createSequencesComponents/addSequencesStep/addSequencesStep";
import { SequencesInfoStep } from "../../../components/adminComponents/createSequencesComponents/sequencesInfoStep/sequencesInfoStep";
import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import {
    SequencesTemplateQuery,
    TasksQuery,
    UpdateSequencesTemplateMutation,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { useHeader } from "../../../hooks/headerHook";
import { ISequencesCreationDto } from "../../../features/sequencesTemplate/domain/entities/sequencesModel";
import styles from "./editSequencesPage.module.scss";
import { areSequencesEqual } from "../../../components/helpers/areSequencesEqual/areSequencesEqual";
import { IncompleteSectionModal } from "../../../components/adminComponents/createSequencesComponents/IncompleteSectionModal/IncompleteSectionModal";

interface IStep {
    title: string;
    description: string;
}

export const EditSequencesPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();
    let { sequencesTemplateId } = useParams<any>();
    const [tasks, setTasks] = useState<TasksQuery>();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [sequencesDetails, setSequencesDetails] = useState<
        Partial<ISequencesCreationDto>
    >({
        name: "",
        stepSequencesTemplate: [],
    });
    const [sequencesTemplate, setSequencesTemplate] = useState<
        Partial<ISequencesCreationDto>
    >({
        name: "",
        stepSequencesTemplate: [
            { sequenceType: undefined, stepTemplates: [{}] },
        ],
    });
    const [isFormValid, setisFormValid] = useState<boolean>(true);
    const [isSequenceDelete, setIsSequenceDelete] = useState<boolean>(false);
    const [isStepDelete, setIsStepDelete] = useState<boolean>(false);
    const [sequenceType, setSequenceType] = useState<string>("");
    const [isConfirmModalVisible, setIsConfirmModalVisible] =
        useState<boolean>(false);
    const [numLicenses, setNumLicenses] = useState<number>(0);
    const [canBeCreated, setCanBeCreated] = useState<boolean>(false);
    const [
        isIncompleteSectionModalVisible,
        setIsIncompleteSectionModalVisible,
    ] = useState<boolean>(false);

    const initialSteps = [
        {
            title: t("createSequences.steps.1.title"),
            description: t("createSequences.steps.1.description"),
        },
    ];
    const [steps, setSteps] = useState<IStep[]>(initialSteps);

    useEffect(() => {
        setHeaderContent({
            title: t("editOrganisation.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/sequences",
        });
    }, []);

    const [UpdateSequencesMutation] =
        useMutation<UpdateSequencesTemplateMutation>(
            fragments.UPDATE_SEQUENCES,
            {
                variables: {
                    templateInput: {
                        id: sequencesTemplateId,
                        name: sequencesTemplate.name,
                        stepSequenceTemplates:
                            sequencesTemplate.stepSequencesTemplate?.map(
                                (seq) => ({
                                    sequenceType: seq.sequenceType,
                                    stepTemplates: seq.stepTemplates?.map(
                                        (step) => ({
                                            id: step.id ?? null, 
                                            type: step.type,
                                            unversionedEcsTaskName:
                                                step.unversionedEcsTaskName,
                                            version: step.version,
                                        })
                                    ),
                                })
                            ),
                    },
                },
            }
        );

    const { data: sequencesTemplateData } = useQuery<SequencesTemplateQuery>(
        fragments.GET_SEQUENCES_BY_ID,
        {
            variables: { sequencesTemplateId: sequencesTemplateId },
        }
    );

    const { data: TaskTemplateData } = useQuery<TasksQuery>(
        fragments.GET_TASKS_BY_SEQUENCE,
        {
            variables: {
                input: sequenceType,
            },
        }
    );

    useEffect(() => {
        if (!TaskTemplateData) return;
        setTasks(TaskTemplateData);
    }, [TaskTemplateData]);

    useEffect(() => {
        if (!sequencesTemplateData) return;
        setSequencesDetails({
            name: sequencesTemplateData.sequencesTemplate.name,
            stepSequencesTemplate:
                sequencesTemplateData.sequencesTemplate.stepSequenceTemplates,
        });
        setSequencesTemplate({
            name: sequencesTemplateData.sequencesTemplate.name,
            stepSequencesTemplate:
                sequencesTemplateData.sequencesTemplate.stepSequenceTemplates,
        });
        setNumLicenses(
            sequencesTemplateData.licensesForASequencesTemplate.length
        );
    }, [sequencesTemplateData]);

    useEffect(() => {
        setCanBeCreated(
            isFormValid &&
                areSequencesEqual(sequencesDetails, sequencesTemplate)
        );
    }, [sequencesTemplate, sequencesDetails]);

    const onShowSequences = () => {
        navigate("../sequences");
    };

    const onEditClick = (buttonIndex: number) => {
        if (!areSequencesEqual(sequencesDetails, sequencesTemplate)) {
            setIsIncompleteSectionModalVisible(true);
        } else {
            setCurrentStep(buttonIndex);
        }
    };

    const checkIsFormValid = (
        sequencesDetails: Partial<ISequencesCreationDto>
    ): boolean => {
        let result = true;
        if (!sequencesDetails.name) result = false;
        sequencesDetails.stepSequencesTemplate?.forEach((sequence) => {
            if (sequence.sequenceType === undefined) {
                result = false;
            }
            sequence.stepTemplates?.forEach((step) => {
                if (!step.unversionedEcsTaskName || !step.version) {
                    result = false;
                }
            });
        });
        return result;
    };

    useEffect(() => {
        setisFormValid(checkIsFormValid(sequencesDetails));
        setIsSequenceDelete(checkIfCanDeleteSequence(sequencesDetails));
    }, [sequencesDetails]);

    const checkIfCanDeleteSequence = (
        sequencesDetails: Partial<ISequencesCreationDto>
    ): boolean => {
        return !(sequencesDetails.stepSequencesTemplate?.length! <= 1);
    };

    useEffect(() => {
        const step = {
            title: t("createSequences.steps.2.title"),
            description: t("createSequences.steps.2.description"),
        };
        const newSteps = initialSteps;
        sequencesDetails.stepSequencesTemplate?.forEach(() => {
            newSteps.push(step);
            setSteps(newSteps);
        });
    }, [sequencesDetails.stepSequencesTemplate]);

    const onModalTecnique = () => {
        if (!areSequencesEqual(sequencesDetails, sequencesTemplate)) {
            setIsIncompleteSectionModalVisible(true);
        } else {
            onAddTecnique();
        }
    };

    const onFinish = () => {
        setIsConfirmModalVisible(false);

        if (!sequencesTemplate) return;
        if (!sequencesTemplateData) return;

        setIsResultModalVisible(true);
        setIsResultModalVisible(true);
        UpdateSequencesMutation({ variables: sequencesTemplate })
            .then((result) => {
                if (!result.data) {
                    setIsResultModalError(true);
                }
                setIsResultModalLoading(false);
            })
            .catch((error) => {
                setIsResultModalLoading(false);
                setIsResultModalError(true);
            });
    };

    const onAddTecnique = () => {
        setIsIncompleteSectionModalVisible(false);
        if (!sequencesDetails || !sequencesDetails.stepSequencesTemplate)
            return;
        const newSequence = [
            ...sequencesDetails?.stepSequencesTemplate,
            { sequenceType: undefined, stepTemplates: [{}] },
        ];
        setSequencesDetails((prevState) => ({
            ...prevState,
            stepSequencesTemplate: newSequence,
        }));
        setCurrentStep(steps.length);
    };

    useEffect(() => {
        setHeaderContent({
            title: t("createLicense.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/sequences",
            buttonElement: (
                <Button
                    key="createSequencesButton"
                    type="primary"
                    size="large"
                    disabled={!canBeCreated}
                    onClick={onConfirm}
                >
                    {t("editSequences.edit")}
                </Button>
            ),
        });
    }, [isFormValid, canBeCreated]);

    const onConfirm = () => {
        if (!numLicenses) {
            onFinish();
        } else {
            setIsConfirmModalVisible(true);
        }
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isEditStep={true}
                        isIconVisible={true}
                        currentStep={currentStep}
                        title={t("editSequences.title")}
                        steps={steps}
                        onItemClick={onEditClick}
                        icon={
                            <span
                                className={`kenko-icon-edit ${styles.Edit}`}
                            />
                        }
                    />

                    <Spacer size={"s"} />

                    {currentStep >= 1 && (
                        <div className={styles.ButtonDiv}>
                            <Button
                                className={styles.Button}
                                disabled={
                                    !(
                                        isFormValid ||
                                        areSequencesEqual(
                                            sequencesDetails,
                                            sequencesTemplate
                                        )
                                    )
                                }
                                onClick={onModalTecnique}
                            >
                                {t("createSequences.addTecnique")}
                                <span
                                    className={`kenko-icon-plus ${styles.ButtonIcon}`}
                                />
                            </Button>
                        </div>
                    )}
                </Col>

                <Col span={24} md={16} xl={18}>
                    {(currentStep === 0 && (
                        <SequencesInfoStep
                            setSequencesDetails={setSequencesDetails}
                            sequencesDetails={sequencesDetails}
                            sequencesTemplate={sequencesTemplate}
                            setSequencesTemplate={setSequencesTemplate}
                            onAddTecnique={() => {
                                setCurrentStep((prevStep) => prevStep + 1);
                            }}
                        />
                    )) ||
                        (currentStep >= 1 && (
                            <AddSequencesStep
                                setCurrentStep={setCurrentStep}
                                currentStep={currentStep}
                                setSequencesDetails={setSequencesDetails}
                                sequencesDetails={sequencesDetails}
                                sequenceIndex={0}
                                isFormValid={isFormValid}
                                isSequenceDelete={isSequenceDelete}
                                isStepDelete={isStepDelete}
                                setIsStepDelete={setIsStepDelete}
                                setSteps={setSteps}
                                steps={steps}
                                sequencesTemplate={sequencesTemplate}
                                setSequencesTemplate={setSequencesTemplate}
                                sequenceType={sequenceType}
                                setSequenceType={setSequenceType}
                                tasks={tasks}
                            />
                        ))}
                </Col>
            </Row>
            <ResultModal
                isVisible={isResultModalVisible}
                isLoading={isResultModalLoading}
                isError={isResultModalError}
                onPrimary={onShowSequences}
                localisedTexts={{
                    title: t("createSequences.successModal.title"),
                    subtitle: t("createSequences.successModal.subtitle"),
                    secondaryButton: t(
                        "createSequences.successModal.createAnother"
                    ),
                    primaryButton: t("createSequences.successModal.showCase"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
            <Modal
                title={t("editSequences.modal.title")}
                visible={isConfirmModalVisible}
                width={550}
                closable={false}
                footer={[
                    <Button
                        key={"cancel" + sequencesTemplateId}
                        onClick={() => setIsConfirmModalVisible(false)}
                    >
                        {t("cancel")}
                    </Button>,
                    <Button
                        key={"edit" + sequencesTemplateId}
                        onClick={onFinish}
                        type="primary"
                    >
                        {t("editSequences.edit")}
                    </Button>,
                ]}
            >
                <p>
                    {t("editSequences.modal.firstDescripPart")}
                    <b>{sequencesTemplate.name}</b>
                    {t("editSequences.modal.secondDescripPart") +
                        numLicenses +
                        t("editSequences.modal.thirdDescripPart")}
                </p>
            </Modal>
            <IncompleteSectionModal
                isModalVisible={isIncompleteSectionModalVisible}
                setIsModalVisible={setIsIncompleteSectionModalVisible}
            />
        </>
    );
};
