import { Content } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { LicenseDetailsDescription } from "../../../components/adminComponents/licenseDetailsComponents/licenseDetailsDescription/licenseDetailsDescription";
import { LicenseDetailsOrganisations } from "../../../components/adminComponents/licenseDetailsComponents/licenseDetailsOrganisations/licenseDetailsOrganisations";
import { useQuery } from "@apollo/client";
import fragments from "../../../gql/fragments";
import { LicenseQuery } from "../../../generated/graphql";
import {
    ILicensePresentation,
    ILicenseTemplateModel,
} from "../../../features/licenseTemplate/domain/entities/licenseModel";
import { IOrganisationReducedTableDto } from "../../../features/organisation/domain/entities/organisationModel";

export const LicenseDetailsPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const [licenseDetails, setLicenseDetails] =
        useState<ILicensePresentation>();
    const [organisationsWithLicense, setOrganisationsWithLicense] =
        useState<IOrganisationReducedTableDto[]>();
    const [numOrganisations, setNumOrganisations] = useState<number>(0);
    const [isFromOrg] = useState<boolean>(false);

    let { licenseId } = useParams<any>();

    const { data: licenseData } = useQuery<LicenseQuery>(
        fragments.GET_LICENSE_BY_ID,
        {
            variables: { licenseId: licenseId },
        }
    );

    useEffect(() => {
        setHeaderContent({
            title: t("licenseDetails.exitHeader"),
            breadCrumb: [
                { label: t("licenseDetails.exitHeader"), link: "/licenses" },
            ],
            goBackLinkOverride: "/licenses",
        });
    }, []);

    useEffect(() => {
        if (!licenseData) return;
        setLicenseDetails({
            ...licenseData.licenseTemplate,
            sequencesTemplate: licenseData.licenseTemplate.sequencesTemplate!,
            distributor: licenseData.licenseTemplate.distributor!,
        });
        setOrganisationsWithLicense(licenseData.organisationsForALicense);
        setNumOrganisations(licenseData.organisationsForALicense.length);
    }, [licenseData]);

    return (
        <Content>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                    <LicenseDetailsDescription
                        licenseDetails={licenseDetails}
                        numOrganisations={numOrganisations}
                        areButtonsVisible={true}
                        organisationDetails={undefined}
                        isFromOrg={isFromOrg}
                        isVisible={false}
                    />
                </Col>
                <Col xs={24} md={16}>
                    <LicenseDetailsOrganisations
                        organisationsWithLicense={organisationsWithLicense}
                    />
                </Col>
            </Row>
        </Content>
    );
};
