import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { SequencesTemplateDataSource } from "../../data/dataSource/sequencesTemplateDataSource";
import {
    ISequencesListPresentation,
} from "../entities/sequencesModel";
import { faro } from "@grafana/faro-web-sdk";

interface IAdditionalMethods {
    getAllSequencesTemplates: (
        pageSize: number,
        pageNumber: number
    ) => Promise<void>;
}

export const useAllSequencesTemplates: IUseDomainHook<
    ISequencesListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = SequencesTemplateDataSource();
    const [sequencesList, setSequencesList] = useState<
        ISequencesListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllSequencesTemplates = async (
        pageSize: number,
        pageNumber: number
    ) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getAllSequencesTemplates(
                pageSize,
                pageNumber
            );
            setSequencesList(result);
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: sequencesList,
        isLoading,
        errorMessage,
        getAllSequencesTemplates,
    };
};
