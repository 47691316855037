import { Button, Card, Divider, Row, Skeleton, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./licenseFeatureDetails.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ILicenseFeature } from "../../domain/entities/licenseFeature";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import { DeleteLicenseFeatureModal } from "../deleteLicenseFeatureModal/deleteLicenseFeatureModal";

const { Title, Text } = Typography;

interface IProps {
    licenseFeatureDetails: ILicenseFeature | undefined;
    isDeleteActionAllowed: boolean;
}

export const LicenseFeatureDetails = ({
    licenseFeatureDetails, 
    isDeleteActionAllowed,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let space = ": ";
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);

    const editPageNavigation = () => {
        navigate(
            "/editFeature/" + licenseFeatureDetails?.id
        )
    }

    return (
        <>
            <Card>
                <Title level={4}>{t("licenseFeature.feature.name")}</Title>
                <Spacer size={"m"}/>
                <Text>
                    {licenseFeatureDetails ? (
                        <b>{licenseFeatureDetails.name}</b>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseFeatureDetails ? (
                        <>
                            {t("licenseFeature.feature.description") + space}
                            <p><b>{licenseFeatureDetails.description}</b></p>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseFeatureDetails ? (
                        <>
                            {t("licenseFeature.feature.environKey") + space}
                            <b>{licenseFeatureDetails.environKey}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseFeatureDetails ? (
                        <>
                            {t("licenseFeature.feature.environValue") + space}
                            <b>{licenseFeatureDetails.environValue}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Text>
                    {licenseFeatureDetails ? (
                        <>
                            {t("licenseFeature.feature.price") + space}
                            <b>{licenseFeatureDetails.price + "€"}</b>
                        </>
                    ) : (
                        <Skeleton
                            paragraph={false}
                            active
                            className={styles.Skeleton}
                        />
                    )}
                </Text>
                <Divider />
                <Spacer size="xs"/>
               
                    <Row className={styles.ButtonsRow}>
                        {isDeleteActionAllowed ? (
                            <>
                                <Button
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                    }}
                                    className={styles.DeleteButton}
                                >
                                    {t("sequencesDetails.delete")}
                                    <span
                                        className={`kenko-icon-bin ${styles.DeleteIcon}`}
                                    />
                                </Button>
                                <Button
                                    className={styles.EditButton}
                                    onClick={() =>
                                        editPageNavigation()
                                    }
                                >
                                    {t("edit")}
                                    <span
                                        className={`kenko-icon-edit ${styles.EditIcon}`}
                                    />
                                </Button>
                            </>
                        ) : (
                            <Button
                                className={styles.AloneEditButton}
                                onClick={() =>
                                    editPageNavigation()
                                }
                            >
                                {t("edit")}
                                <span
                                    className={`kenko-icon-edit ${styles.EditIcon}`}
                                />
                            </Button>
                        )}
                    </Row>
                
            </Card>
            <DeleteLicenseFeatureModal
                isVisible={isDeleteModalVisible}
                setIsVisible={setIsDeleteModalVisible}
                licenseFeatureDetails={licenseFeatureDetails}
            />
        </>
    );
};
