import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../hooks/headerHook";
import { CaseList } from "../../components/appCore/caseList/caseList";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./caseListPage.module.scss";
import fragments from "../../gql/fragments";
import { useQuery } from "@apollo/client";
import {
    CaseSortableFields,
    FilterInputOfCaseFilteredFieldsInput,
    RemainingItemsQuery,
    SortInputOfCaseSortableFieldsInput,
} from "../../generated/graphql";
import { useAllCases } from "../../features/case/domain/hooks/useAllCases";

const CaseListPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();
    const [sortInputs, setSortInputs] = useState<
        SortInputOfCaseSortableFieldsInput[]
    >([{ fieldName: CaseSortableFields.CreationDate, isAsc: false }]);
    const [textSearchInput, setTextSeachInput] = useState<string>();
    const [filterInput, setFilterInput] =
        useState<FilterInputOfCaseFilteredFieldsInput[]>();
    const ELEMENTS_PAGE = 10;
    const PAGE_NUMBER = 0;

    const { data: remainingItemsData, refetch: refetchRemainingItems } =
        useQuery<RemainingItemsQuery>(fragments.GET_REMAINING_ITEMS);

    //===========HOOKS==============
    const {
        data: caseList,
        isLoading,
        errorMessage,
        getAllCases,
    } = useAllCases();

    const onLoadMore = (
        pageNumber: number,
        sortFields: SortInputOfCaseSortableFieldsInput[],
        filterInput: FilterInputOfCaseFilteredFieldsInput[]
    ) => {
        updateSortInput(sortFields);
        updateFilterInput(filterInput);

        getAllCases(
            ELEMENTS_PAGE,
            pageNumber,
            sortFields,
            textSearchInput,
            filterInput
        );
    };

    useEffect(() => {
        getAllCases(ELEMENTS_PAGE, PAGE_NUMBER, sortInputs);
    }, []);

    const onRefetch = () => {
        getAllCases(ELEMENTS_PAGE, PAGE_NUMBER, sortInputs);
    };

    const onFilter = (textInput: string) => {
        setTextSeachInput(textInput);
        getAllCases(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputs,
            textInput,
            filterInput
        );
    };

    const updateSortInput = (input: SortInputOfCaseSortableFieldsInput[]) => {
        setSortInputs(input);
    };

    const updateFilterInput = (
        input: FilterInputOfCaseFilteredFieldsInput[]
    ) => {
        setFilterInput(input);
    };

    useEffect(() => {
        setHeaderContent({
            title: t("caseList.title"),
            breadCrumb: [],
            goBackLinkOverride: undefined,
            buttonElement: (
                <Button
                    className={styles.buttonIcon}
                    type="primary"
                    size="large"
                    key="caseListBasicUser"
                    onClick={() => {
                        navigate("/createCase");
                    }}
                    disabled={
                        remainingItemsData?.remainingItems.remainingCases === 0
                    }
                >
                    {t("caseList.createCase")}
                    <span className={`kenko-icon-plus ${styles.buttonIcon}`} />
                </Button>
            ),
        });
    }, [setHeaderContent, t, remainingItemsData]);

    return (
        <CaseList
            refetchRemainingItems={refetchRemainingItems}
            caseListData={caseList}
            onLoadMore={onLoadMore}
            errorMessage={errorMessage}
            isLoading={isLoading}
            onRefetch={onRefetch}
            onFilter={onFilter}
            sortInputs={sortInputs}
            filterInput={filterInput}
        />
    );
};

export default CaseListPage;
