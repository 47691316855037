import { Form, Typography } from "antd";
import styles from "./chooseUserLicense.module.scss";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import {
    IUserLicenseListPresentation,
    IUserLicenseModel,
} from "../../../userLicense/domain/entities/userLicenseModel";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/lib/select";
import { LicensesProgressBar } from "../../../../components/organisationAdminComponents/common/licensesProgressBar/licensesProgressBar";
import { FilteredSelect } from "../filteredSelect/filteredSelect";

const { Title, Text } = Typography;

interface IProps {
    updateUserLicenseUserId: (value: string | undefined) => void;
    updateIsFormValid: (value: boolean) => void;
    userLicensesWithoutUser: IUserLicenseListPresentation | undefined;
    currentUserLicense: IUserLicenseModel | undefined;
    onLoadMoreUserLicenses: (pageNumber: number) => void;
    onFilterUserLicense: (textInput: string) => void;
    textSearchLicenses: string;
    unfilteredLicenses: IUserLicenseModel[] | undefined;
    filteredLicenses: IUserLicenseModel[] | undefined;
}

export const ChooseUserLicense = ({
    updateUserLicenseUserId,
    updateIsFormValid,
    userLicensesWithoutUser,
    currentUserLicense,
    onLoadMoreUserLicenses,
    onFilterUserLicense,
    textSearchLicenses,
    unfilteredLicenses,
    filteredLicenses,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [userLicensesOptions, setUserLicensesOptions] = useState<
        DefaultOptionType[]
    >([]);

    useEffect(() => {
        refreshSelectOptions();
    }, [unfilteredLicenses, filteredLicenses]);

    const onChange = (value: string) => {
        if (!value) {
            return;
        }
        updateIsFormValid(true);
        updateUserLicenseUserId(value);
    };

    useEffect(() => {
        if (!currentUserLicense) {
            return;
        }
        setUserLicensesOptions((prev) => {
            let result = prev;
            result.push({
                value: currentUserLicense.id,
                label: (
                    <span>
                        {"LIC-"}
                        {currentUserLicense.creationOrder}{" "}
                        <LicensesProgressBar
                            remainingNum={
                                currentUserLicense.numberOfRemainingCases
                            }
                            maxNum={currentUserLicense.maxNumOfCases}
                            isSelect={true}
                        />
                    </span>
                ),
            });
            return result;
        });

        form.setFieldsValue({
            userLicense: currentUserLicense.id,
        });
    }, [currentUserLicense]);

    const refreshSelectOptions = () => {
        if (!textSearchLicenses) {
            if (!unfilteredLicenses) return;
            setUserLicensesOptions(
                unfilteredLicenses.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {"LIC-"}
                            {u.creationOrder}{" "}
                            <LicensesProgressBar
                                remainingNum={u.numberOfRemainingCases}
                                maxNum={u.maxNumOfCases}
                                isSelect={true}
                            />
                        </span>
                    ),
                }))
            );
        } else {
            if (!filteredLicenses) return;
            setUserLicensesOptions(
                filteredLicenses.map((u) => ({
                    value: u.id,
                    label: (
                        <span>
                            {"LIC-"}
                            {u.creationOrder}{" "}
                            <LicensesProgressBar
                                remainingNum={u.numberOfRemainingCases}
                                maxNum={u.maxNumOfCases}
                                isSelect={true}
                            />
                        </span>
                    ),
                }))
            );
        }
    };

    return (
        <>
            <Form layout="vertical" form={form}>
                <div>
                    <Title level={4}>
                        {t("invite.license.chooseLicense.title")}
                    </Title>
                    <Text type="secondary">
                        {t("invite.license.chooseLicense.subtitle")}
                    </Text>
                </div>
                <Spacer size="m" />
                <Form.Item
                    className={styles.select}
                    label={t("invite.license.chooseLicense.select")}
                    name="userLicense"
                    rules={[
                        {
                            required: true,
                            message: t("form.required"),
                        },
                    ]}
                >
                    <FilteredSelect
                        onChange={onChange}
                        options={userLicensesOptions}
                        refreshSelectOptions={refreshSelectOptions}
                        textSearchUser={textSearchLicenses}
                        unfilteredUsers={unfilteredLicenses}
                        filteredUsers={filteredLicenses}
                        onLoadMoreUsers={onLoadMoreUserLicenses}
                        onFilterUser={onFilterUserLicense}
                        dataList={userLicensesWithoutUser}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
