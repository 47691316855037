import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import fragments from "../../../gql/fragments";
import { UpdateUserMutation, UserQuery } from "../../../generated/graphql";
import { useEffect, useState } from "react";
import { IUserBasicInfo } from "../../../features/user/domain/entities/userModel";
import { useHeader } from "../../../hooks/headerHook";
import { Col, Row } from "antd";
import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { InviteInfoStep } from "../../../features/user/presentation/inviteInfoStep/inviteInfoStep";
import styles from "./editUserPage.module.scss";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";

export const EditUserPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    const navigate = useNavigate();
    let { userId } = useParams<any>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isModalLoading, setIsModalLoading] = useState<boolean>(true);
    const [isModalError, setIsModalError] = useState<boolean>(false);
    const [updatedUserDetails, setUpdatedUserDetails] = useState<
        IUserBasicInfo | undefined
    >();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isIconVisible, setIsIconVisible] = useState<boolean>(true);

    useEffect(() => {
        setHeaderContent({
            title: t("exit"),
            breadCrumb: [],
            goBackLinkOverride: "/users",
        });
    }, [setHeaderContent, t]);

    const [UpdateUserMutation] = useMutation<UpdateUserMutation>(
        fragments.UPDATE_USER,
        {
            variables: {
                id: userId,
                email: updatedUserDetails?.email,
                name: updatedUserDetails?.name,
                surname: updatedUserDetails?.surname,
                orgId: updatedUserDetails?.orgId,
                isOrgAdmin: false,
            },
        }
    );
    const { data: userData } = useQuery<UserQuery>(fragments.GET_USER, {
        variables: {
            input: userId,
        },
    });

    useEffect(() => {
        if (userData) {
            setUpdatedUserDetails(userData.user);
        }
    }, [userData]);

    const updateUserName = (value: string | undefined) => {
        setUpdatedUserDetails((prevState) => ({
            id: prevState?.id,
            name: value,
            surname: prevState?.surname,
            email: prevState?.email,
            orgId: prevState?.orgId,
        }));
    };
    const updateUserSurname = (value: string | undefined) => {
        setUpdatedUserDetails((prevState) => ({
            id: prevState?.id,
            name: prevState?.name,
            surname: value,
            email: prevState?.email,
            orgId: prevState?.orgId,
        }));
    };
    const updateUserEmail = (value: string | undefined) => {
        setUpdatedUserDetails((prevState) => ({
            id: prevState?.id,
            name: prevState?.name,
            surname: prevState?.surname,
            email: value,
            orgId: prevState?.orgId,
        }));
    };
    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };
    const updateCurrentStep = (value: number) => {
        setCurrentStep((prevStep) => prevStep + value);
    };
    const updateIsIconVisible = (value: boolean) => {
        setIsIconVisible(value);
    };
    const onShowUsers = () => {
        navigate("/users");
    };

    const onUpdateUser = () => {
        setIsModalVisible(true);
        UpdateUserMutation({
            variables: updatedUserDetails,
        }).then((res) => {
            if (!res.data) {
                setIsModalError(true);
            }
            setIsModalLoading(false);
        });
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        title={t("editUser.title")}
                        isComplete={true}
                        steps={[
                            {
                                title: t("invite.steps.1.title"),
                                description: t("invite.steps.1.description"),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                        isEditStep={true}
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    <InviteInfoStep
                        updateCurrentStep={updateCurrentStep}
                        updateIsIconVisible={updateIsIconVisible}
                        updateUserName={updateUserName}
                        updateUserSurname={updateUserSurname}
                        updateUserEmail={updateUserEmail}
                        updatedUserDetails={updatedUserDetails}
                        isEditPage={true}
                        email={updatedUserDetails?.email}
                        onUpdateUser={onUpdateUser}
                    />
                </Col>
            </Row>
            <ResultModal
                isVisible={isModalVisible}
                isLoading={isModalLoading}
                isError={isModalError}
                onPrimary={onShowUsers}
                hasSecondary={false}
                localisedTexts={{
                    title: t("editUser.successModal.title"),
                    subtitle: "",
                    secondaryButton: t("invite.successModal.another"),
                    primaryButton: t("invite.successModal.viewList"),
                    errorTitle: t("createSequences.successModal.errorTitle"),
                    errorRetry: t("createSequences.successModal.errorRetry"),
                }}
            />
        </>
    );
};
