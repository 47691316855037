import { Form, InputNumber, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import styles from "./createUserLicenseForm.module.scss";
import { useEffect } from "react";
import {
    IUserLicense,
    IUserLicenseCreate,
} from "../../domain/entities/userLicenseModel";

const { Title, Text } = Typography;

interface IProps {
    remainingCases: number | undefined;
    updateIsFormValid: (value: boolean) => void;
    updateUserLicenseNumCases: (value: number | undefined) => void;
    title: string;
    subtitle: string;
    userLicenseDetails: IUserLicenseCreate | undefined;
    userLicenseModel?: IUserLicense | undefined;
}
interface IFormDto {
    numberOfCases: number;
}

export const CreateUserLicenseForm = ({
    remainingCases,
    updateIsFormValid,
    updateUserLicenseNumCases,
    title,
    subtitle,
    userLicenseDetails,
    userLicenseModel,
}: IProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const checkIsFormValid = (input: IFormDto) => {
        return input.numberOfCases !== null;
    };

    const onChange = (
        changedValues: IFormDto | undefined,
        values: IFormDto | undefined
    ) => {
        if (!values) return;
        updateIsFormValid(checkIsFormValid(values));
        updateUserLicenseNumCases(values.numberOfCases);
    };

    useEffect(() => {
        if (!userLicenseDetails) return;
        form.setFieldsValue({
            numberOfCases: userLicenseDetails.numberOfCases,
        });
    }, [userLicenseDetails]);

    return (
        <>
            <Form layout="vertical" form={form} onValuesChange={onChange}>
                <div>
                    <Title level={4}>{title}</Title>
                    <Text type="secondary">{subtitle}</Text>
                </div>
                <Spacer size="xs" />
                {!!userLicenseModel && (
                    <div>
                        <Text>{t("editUserLicense.creationOrderTitle")}</Text>
                        <Text strong>
                            LIC-{userLicenseModel?.creationOrder}{" "}
                        </Text>
                        <br />
                        <Text>{t("editUserLicense.assignedTo")}</Text>
                        <Text strong>
                            {!!userLicenseModel.user
                                ? userLicenseModel.user?.name +
                                  " " +
                                  userLicenseModel.user?.surname
                                : t("userLicenseList.transfer.withoutUser")}
                        </Text>
                        <br />
                        <Text>{t("editUserLicense.remainingCasesTitle")}</Text>
                        <Text strong>
                            {userLicenseModel.numberOfRemainingCases}
                        </Text>
                        <br />
                        <Text>{t("editUserLicense.totalCasesTitle")}</Text>
                        <Text>
                            {userLicenseModel.numberOfRemainingCases +
                                (!userLicenseDetails ||
                                !userLicenseDetails.numberOfCases
                                    ? 0
                                    : userLicenseDetails.numberOfCases)}
                        </Text>
                    </div>
                )}

                <Spacer size="m" />
                <Form.Item
                    label={
                        !!userLicenseModel
                            ? t("editUserLicense.inputCases")
                            : t("createUserLicense.userLicenseInfo.numCases")
                    }
                    name="numberOfCases"
                    help={
                        t("userLicenseList.transfer.message") +
                        (remainingCases! -
                            (!userLicenseDetails ||
                            !userLicenseDetails.numberOfCases
                                ? 0
                                : userLicenseDetails.numberOfCases))
                    }
                    rules={[
                        {
                            required: true,
                            message:
                                t("userLicenseList.transfer.requiredMessage1") +
                                remainingCases +
                                t("userLicenseList.transfer.requiredMessage2"),
                        },
                    ]}
                >
                    <InputNumber
                        name="inputNumber"
                        className={styles.input}
                        min={0}
                        max={remainingCases}
                        placeholder={t(
                            "createUserLicense.userLicenseInfo.numCasesPrompt"
                        )}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
