import { useQuery } from "@apollo/client";
import {
    Button,
    Modal,
    Radio,
    RadioChangeEvent,
    Space,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    OrganisationQuery,
    SortInputOfUserLicenseSortableFieldsInput,
    UserLicenseSortableFields,
} from "../../../generated/graphql";
import fragments from "../../../gql/fragments";
import { useHeader } from "../../../hooks/headerHook";
import {
    IMoveNumCasesBetweenUserLicensesModel,
    IUserLicenseListPresentation,
    IUserLicenseModel,
    SortableFieldFromKey,
} from "../../../features/userLicense/domain/entities/userLicenseModel";
import styles from "./userLicenseListPage.module.scss";
import { UserLicenseList } from "../../../features/userLicense/presentation/userLicenseList/userLicenseList";
import { TransferCases } from "../../../features/userLicense/presentation/transferCases/transferCases";
import {
    IUserBasicInfo,
    IUserListPresentation,
} from "../../../features/user/domain/entities/userModel";
import { useAllUserLicenses } from "../../../features/userLicense/domain/hooks/useAllUserLicenses";
import { useAssignUserToUserLicense } from "../../../features/userLicense/domain/hooks/useAssignUserToUserLicense";
import { useUnassignUserToUserLicense } from "../../../features/userLicense/domain/hooks/useUnassignUserToUserLicense";
import { useMoveCasesBetweenUserLicenses } from "../../../features/userLicense/domain/hooks/useMoveCasesBetweenUserLicenses";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import { useReturnCasesToOrg } from "../../../features/userLicense/domain/hooks/useReturnCasesToOrg";
import { useAllUsers } from "../../../features/user/domain/hooks/useAllUsers";
import {
    BooleanFromOrder,
    SortFields,
} from "../../../features/common/domain/entities/sortInterfaces";
const { Text } = Typography;

export const UserLicenseListPage = () => {
    const [userLicenseList, setUserLicenseList] = useState<IUserLicenseModel[]>(
        []
    );

    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();

    const [isAssignModalVisible, setIsAssignModalVisible] =
        useState<boolean>(false);
    const [isUnassignModalVisible, setIsUnassignModalVisible] =
        useState<boolean>(false);
    const [isTransferCasesModalVisible, setIsTransferCasesModalVisible] =
        useState<boolean>(false);
    const [isQuestionModalVisible, setIsQuestionModalVisible] =
        useState<boolean>(false);
    const [
        moveCasesBetweenUserLicensesInput,
        setMoveCasesBetweenUserLicensesInput,
    ] = useState<IMoveNumCasesBetweenUserLicensesModel>();
    const [isResultModalVisible, setIsResultModalVisible] =
        useState<boolean>(false);
    const [isResultModalLoading, setIsResultModalLoading] =
        useState<boolean>(true);
    const [isResultModalError, setIsResultModalError] = useState(false);
    const [remainingUsers, setRemainingUsers] = useState<number>();
    const [checkValue, setCheckValue] = useState<number>(0);
    const [usersList, setUsersList] = useState<IUserListPresentation>();
    const [unfilteredUsers, setUnfilteredUsers] = useState<IUserBasicInfo[]>();
    const [filteredUsers, setFilteredUsers] = useState<IUserBasicInfo[]>();
    const [sortInputsUserLicense, setSortInputsUserLicense] = useState<
        SortInputOfUserLicenseSortableFieldsInput[]
    >([{ fieldName: UserLicenseSortableFields.CreationOrder, isAsc: true }]);
    const [sortInputsSelectUserLicense, setSortInputsSelectUserLicense] =
        useState<SortInputOfUserLicenseSortableFieldsInput[]>([
            { fieldName: UserLicenseSortableFields.CreationOrder, isAsc: true },
        ]);
    const [textSearchUserLicense, setTextSeachUserLicense] = useState<string>();
    const [textSearchUser, setTextSeachUser] = useState<string>("");
    const [unfilteredLicenses, setUnfilteredLicensess] =
        useState<IUserLicenseModel[]>();
    const [filteredLicenses, setFilteredLicenses] =
        useState<IUserLicenseModel[]>();
    const [
        textSearchDestinySelectLicenses,
        setTextSearchDestinySelectLicenses,
    ] = useState<string>("");
    const [textSearchOriginSelectLicenses, setTextSearchOriginSelectLicenses] =
        useState<string>("");
    const [userLicenseSelectList, setUserLicenseSelectList] =
        useState<IUserLicenseListPresentation>();
    const ELEMENTS_PAGE = 10;
    const PAGE_NUMBER = 0;

    const { data: organisationLicenseData } = useQuery<OrganisationQuery>(
        fragments.GET_ORGANISATION
    );

    //===============HOOKS==================
    const {
        data: userLicenseListData,
        getAllUserLicenses,
        isLoading,
    } = useAllUserLicenses();
    const {
        data: userLicenseSelectListData,
        getAllUserLicenses: getSelectUserLicenses,
    } = useAllUserLicenses();
    const {
        data: userLicenseAssign,
        errorMessage: errorMessageAssign,
        assignUserToUserLicense,
    } = useAssignUserToUserLicense();
    const {
        data: userLicenseUnassign,
        errorMessage: errorMessageUnassign,
        unassignUserToUserLicense,
    } = useUnassignUserToUserLicense();
    const {
        data: userLicensesMoved,
        isLoading: isLoadingMoved,
        errorMessage: errorMessageMoved,
        moveCasesBetweenUserLicenses,
    } = useMoveCasesBetweenUserLicenses();
    const {
        data: userLicenseToReturn,
        isLoading: isLoadingToReturn,
        errorMessage: errorMessageToReturn,
        returnCasesToOrg,
    } = useReturnCasesToOrg();
    const { data: userListData, getAllUsers } = useAllUsers();

    //===============UPDATES==================
    const updateIsAssignModalVisible = (value: boolean) => {
        setIsAssignModalVisible(value);
    };
    const updateIsUnassignModalVisible = (value: boolean) => {
        setIsUnassignModalVisible(value);
    };
    const updateIsModalTransferCasesVisible = (value: boolean) => {
        setIsTransferCasesModalVisible(value);
    };
    const updateMoveCasesInput = (
        value: IMoveNumCasesBetweenUserLicensesModel | undefined
    ) => {
        setMoveCasesBetweenUserLicensesInput(value);
    };
    const updateIsResultModalVisible = (value: boolean) => {
        setIsResultModalVisible(value);
    };
    const updateCheckValue = (value: number) => {
        setCheckValue(value);
    };
    const updateSortInputUserLicense = (sortFields: SortFields[]) => {
        setSortInputsUserLicense(
            sortFields.map((o) => ({
                fieldName: SortableFieldFromKey(o.fieldName),
                isAsc: BooleanFromOrder(o.order),
            }))
        );
    };
    const updateFiltersText = () => {
        setTextSearchOriginSelectLicenses("");
        setTextSearchDestinySelectLicenses("");
    };

    //===============FUNCTIONS==================
    const onAssignUserToUserLicense = (
        userLicense?: IUserLicenseModel,
        currentUserId?: string
    ) => {
        if (!userLicense?.id || !currentUserId) return;
        assignUserToUserLicense(userLicense?.id, currentUserId);
    };

    const onTransferCases = () => {
        if (!moveCasesBetweenUserLicensesInput) return;
        if (checkValue === 1) {
            moveCasesBetweenUserLicenses(moveCasesBetweenUserLicensesInput);
        } else if (checkValue === 2) {
            returnCasesToOrg(
                moveCasesBetweenUserLicensesInput?.userLicenseIdOrigin,
                moveCasesBetweenUserLicensesInput?.numberOfCases
            );
        }
    };

    const onUnassignUser = (userLicenseId: string) => {
        if (!userLicenseId) return;
        unassignUserToUserLicense(userLicenseId);
    };

    const onChange = (e: RadioChangeEvent) => {
        if (!e) {
            return;
        }
        updateCheckValue(e.target.value);
    };

    const onLoadMoreUserLicenses = (
        pageNumber: number,
        sortFields: SortFields[]
    ) => {
        updateSortInputUserLicense(sortFields);
        let input: SortInputOfUserLicenseSortableFieldsInput[] = sortFields.map(
            (o) => ({
                fieldName: SortableFieldFromKey(o.fieldName),
                isAsc: BooleanFromOrder(o.order),
            })
        );
        getAllUserLicenses(
            ELEMENTS_PAGE,
            pageNumber,
            input,
            textSearchUserLicense
        );
    };

    const onRefetchUserLicenses = () => {
        getAllUserLicenses(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputsUserLicense,
            textSearchUserLicense
        );
    };

    const onLoadMoreSelectUserLicenses = (pageNumber: number) => {
        getSelectUserLicenses(
            ELEMENTS_PAGE,
            pageNumber,
            sortInputsSelectUserLicense,
            textSearchUser
        ).then((res: IUserLicenseListPresentation | null | undefined) => {
            if (!res) {
                return;
            }
            if (
                !textSearchOriginSelectLicenses ||
                !textSearchDestinySelectLicenses
            ) {
                setUnfilteredLicensess(
                    unfilteredLicenses?.concat(res.userLicensePresentations)
                );
            } else {
                setFilteredLicenses(
                    filteredLicenses?.concat(res.userLicensePresentations)
                );
            }
        });
    };

    const onFilterUserLicenses = (textInput: string) => {
        setTextSeachUserLicense(textInput);
        getAllUserLicenses(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputsUserLicense,
            textInput
        );
    };

    const onFilterSelectUserLicense = (
        textInput: string,
        isOrginList: boolean
    ) => {
        isOrginList
            ? setTextSearchOriginSelectLicenses(textInput)
            : setTextSearchDestinySelectLicenses(textInput);
        getSelectUserLicenses(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputsSelectUserLicense,
            textInput
        ).then((res) => {
            if (!res) return;
            setFilteredLicenses(res?.userLicensePresentations);
        });
    };

    const onLoadMoreUsers = (pageNumber: number) => {
        getAllUsers(
            true,
            ELEMENTS_PAGE,
            pageNumber,
            undefined,
            textSearchUser
        ).then((res: IUserListPresentation | null | undefined) => {
            if (!res) {
                return;
            }
            if (!textSearchUser) {
                setUnfilteredUsers(
                    unfilteredUsers?.concat(res.userPresentations)
                );
            } else {
                setFilteredUsers(filteredUsers?.concat(res.userPresentations));
            }
        });
    };

    const onFilterUser = (textInput: string) => {
        setTextSeachUser(textInput);
        getAllUsers(
            true,
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            undefined,
            textInput
        ).then((res) => {
            if (!res) return;
            setFilteredUsers(res?.userPresentations);
        });
    };

    const onRefetchUsers = () => {
        getAllUsers(true, ELEMENTS_PAGE, PAGE_NUMBER).then((res) => {
            if (!res) return;
            setUnfilteredUsers(res?.userPresentations);
        });
    };

    const onRefetchSelectUserLicenses = () => {
        getSelectUserLicenses(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputsSelectUserLicense
        ).then((res) => {
            if (!res) return;
            setUnfilteredLicensess(res?.userLicensePresentations);
        });
    };

    //===============USEEFFECTS==================
    useEffect(() => {
        setHeaderContent({
            title: t("userLicenseList.title"),
            breadCrumb: [],
            buttonElement: (
                <>
                    <Button
                        className={styles.spaceBetweenButtons}
                        type="default"
                        size="large"
                        key="transferCases"
                        onClick={() => {
                            setIsQuestionModalVisible(true);
                        }}
                    >
                        {t("userLicenseList.transfer.button")}
                        <span
                            className={`kenko-icon-transfer-cases ${styles.ButtonIcon}`}
                        />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        key="createUserLicense"
                        disabled={!remainingUsers}
                        onClick={() => {
                            navigate("/createuserlicense");
                        }}
                    >
                        {t("userLicenseList.create")}
                        <span
                            className={`kenko-icon-plus ${styles.ButtonIcon}`}
                        />
                    </Button>
                </>
            ),
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t, remainingUsers]);

    useEffect(() => {
        if (userLicenseListData) {
            setUserLicenseList(userLicenseListData.userLicensePresentations);
        }
        if (userLicenseSelectListData) {
            setUserLicenseSelectList(userLicenseSelectListData);
        }
        if (organisationLicenseData) {
            setRemainingUsers(
                organisationLicenseData.organisation.organisationLicense
                    .remainingUsers
            );
        }
    }, [
        userLicenseListData,
        organisationLicenseData,
        userLicenseSelectListData,
    ]);

    useEffect(() => {
        getAllUserLicenses(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputsUserLicense,
            textSearchUserLicense
        );
    }, []);

    useEffect(() => {
        getSelectUserLicenses(
            ELEMENTS_PAGE,
            PAGE_NUMBER,
            sortInputsSelectUserLicense
        ).then((res) => {
            if (!res) return;
            setUnfilteredLicensess(res?.userLicensePresentations);
        });
    }, []);

    useEffect(() => {
        getAllUsers(true, ELEMENTS_PAGE, PAGE_NUMBER).then((res) => {
            if (!res) return;
            setUnfilteredUsers(res?.userPresentations);
        });
    }, []);

    useEffect(() => {
        if (!userListData) return;
        setUsersList(userListData);
    }, [userListData]);

    useEffect(() => {
        if (errorMessageAssign) setIsResultModalError(true);
        if (!userLicenseAssign) return;
        updateIsAssignModalVisible(false);
        onRefetchUsers();
        onRefetchUserLicenses();
    }, [userLicenseAssign, errorMessageAssign]);

    useEffect(() => {
        if (errorMessageUnassign) setIsResultModalError(true);
        if (!userLicenseUnassign) return;
        updateIsUnassignModalVisible(false);
        onRefetchUsers();
        onRefetchUserLicenses();
    }, [userLicenseUnassign, errorMessageUnassign]);

    useEffect(() => {
        if (errorMessageMoved) setIsResultModalError(true);
        if (!userLicensesMoved) return;
        updateIsResultModalVisible(true);
        setIsTransferCasesModalVisible(false);
        setIsResultModalLoading(isLoadingMoved);
        onRefetchUserLicenses();
    }, [userLicensesMoved, errorMessageMoved]);

    useEffect(() => {
        if (errorMessageToReturn) setIsResultModalError(true);
        if (!userLicenseToReturn) return;
        updateIsResultModalVisible(true);
        setIsTransferCasesModalVisible(false);
        setIsResultModalLoading(isLoadingToReturn);
        onRefetchUserLicenses();
    }, [userLicenseToReturn, errorMessageToReturn]);

    return (
        <>
            <UserLicenseList
                userLicenseList={userLicenseList}
                onAssignUserToUserLicense={onAssignUserToUserLicense}
                updateIsAssignModalVisible={updateIsAssignModalVisible}
                isAssignModalVisible={isAssignModalVisible}
                updateIsUnassignModalVisible={updateIsUnassignModalVisible}
                isUnassignModalVisible={isUnassignModalVisible}
                onUnassignUser={onUnassignUser}
                onLoadMoreUserLicenses={onLoadMoreUserLicenses}
                onRefetchUserLicenses={onRefetchUserLicenses}
                onLoadMoreUsers={onLoadMoreUsers}
                totalResults={userLicenseListData?.totalResults}
                userList={usersList}
                onFilterUserLicenses={onFilterUserLicenses}
                onFilterUser={onFilterUser}
                textSearchUser={textSearchUser}
                unfilteredUsers={unfilteredUsers}
                filteredUsers={filteredUsers}
                isLoading={isLoading}
            />
            <Modal
                title={t("userLicenseList.transfer.title")}
                visible={isQuestionModalVisible}
                width={550}
                closable={false}
                footer={[
                    <Button
                        key={"cancel"}
                        type="default"
                        onClick={() => {
                            setIsQuestionModalVisible(false);
                        }}
                    >
                        {t("cancel")}
                    </Button>,
                    <Button
                        key={"ok"}
                        type="primary"
                        onClick={() => {
                            setIsQuestionModalVisible(false);
                            updateIsModalTransferCasesVisible(true);
                        }}
                    >
                        {t("acept")}
                    </Button>,
                ]}
            >
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Text>
                            {t("userLicenseList.transfer.question.title")}
                        </Text>
                    </div>
                </div>
                <Spacer size="xs" />
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Radio.Group onChange={onChange}>
                            <Space direction="vertical">
                                <Radio value={1}>
                                    {t(
                                        "userLicenseList.transfer.question.option1"
                                    )}
                                </Radio>
                                <Radio value={2}>
                                    {t(
                                        "userLicenseList.transfer.question.option2"
                                    )}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>
            </Modal>
            <TransferCases
                userLicenseList={userLicenseSelectList}
                isModalVisible={isTransferCasesModalVisible}
                updateIsModalVisible={updateIsModalTransferCasesVisible}
                onTransferCases={onTransferCases}
                updateMoveCasesInput={updateMoveCasesInput}
                moveCasesBetweenUserLicensesInput={
                    moveCasesBetweenUserLicensesInput
                }
                isResultModalVisible={isResultModalVisible}
                isResultModalLoading={isResultModalLoading}
                isResultModalError={isResultModalError}
                updateIsResultModalVisible={updateIsResultModalVisible}
                selectedOption={checkValue}
                modalTitle={
                    checkValue === 1
                        ? t("userLicenseList.transfer.titleMoveCases")
                        : t("userLicenseList.transfer.returnCasesTitle")
                }
                onLoadMoreUserLicenses={onLoadMoreSelectUserLicenses}
                onFilterUserLicense={onFilterSelectUserLicense}
                textSearchOriginLicenses={textSearchOriginSelectLicenses}
                textSearchDestLicenses={textSearchDestinySelectLicenses}
                unfilteredLicenses={unfilteredLicenses}
                filteredLicenses={filteredLicenses}
                updateFiltersText={updateFiltersText}
                onRefetchSelectUserLicenses={onRefetchSelectUserLicenses}
            />
        </>
    );
};
