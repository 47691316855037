import { useTranslation } from "react-i18next";
import { Button, Row, Table, Tag } from "antd";
import { Spacer } from "../../../appCore/spacer/spacer";
import { ColumnsType } from "antd/es/table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import fragments from "../../../../gql/fragments";
import styles from "./folderDisplay.module.scss";
import { RequestExportMutation } from "../../../../generated/graphql";
import { LoadingOutlined } from "@ant-design/icons";
import { download } from "../../../appCore/fileDownloader/fileDownloader";
import { useNavigate } from "react-router-dom";
import visualizerService from "../../../../services/visualizerService";
import {
    ICaseDetails,
    ZipStatusType,
} from "../../../../features/case/domain/entities/case";
import { useSequencesByCase } from "../../../../features/case/domain/hooks/useSequenceByCase";
import { useImagesByCase } from "../../../../features/case/domain/hooks/useImagesByCase";
import {
    IBiomarkerModelTag,
    ISequence,
} from "../../../../features/case/domain/entities/sequence";
import { ISequenceImages } from "../../../../features/case/domain/entities/modelImages";

interface IProps {
    caseId: string | undefined;
    caseDetails: ICaseDetails | undefined;
    currentSequencesData: ISequence[] | undefined;
    currentSequenceImages: ISequenceImages | undefined;
    isLoading: boolean;
    isOrgAdmin: boolean;
}

export const FolderDisplay = ({
    caseId,
    caseDetails,
    currentSequencesData,
    currentSequenceImages,

    isOrgAdmin,
    isLoading,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState<object[]>();
    const [downloadButtonText, setDownloadButtonText] = useState<string>(
        t("caseDetails.resultFolders.process")
    );
    const [isDownloadProcessing, setIsDownloadProcessing] =
        useState<boolean>(false);
    const [isLoadingMipImages, setLoadingMipImages] = useState<boolean>(false);
    const [isLoadingTagData, setLoadingTagData] = useState<boolean>(false);

    let iterationDataSource: Object[] = [];

    const [requestExportMutation] = useMutation<RequestExportMutation>(
        fragments.REQUEST_EXPORT
    );

    useEffect(() => {
        if (!currentSequenceImages) return;
        setLoadingMipImages(true);
        Object.keys(currentSequenceImages).forEach((model) => {
            Object.keys(currentSequenceImages[model]["axial"]).forEach(
                (bio) => {
                    if (
                        bio === "CT1" ||
                        bio === "L1" ||
                        bio === "BVir" ||
                        bio === "C3_C2"
                    ) {
                        new Image().src =
                            currentSequenceImages[model]["axial"][bio][
                                currentSequenceImages[model]["axial"][bio]
                                    .length - 1
                            ];
                    }
                }
            );
        });
        setLoadingMipImages(false);
        return () => {}
    }, [currentSequenceImages]);

    useEffect(() => {
        if (!caseDetails) return;

        switch (caseDetails.zipState) {
            case ZipStatusType.Fail || ZipStatusType.NotRequested:
                setDownloadButtonText(t("caseDetails.resultFolders.process"));
                break;
            case ZipStatusType.Processing:
                setDownloadButtonText(
                    t("caseDetails.resultFolders.processing")
                );
                break;
            case ZipStatusType.Finished:
                setDownloadButtonText(t("caseDetails.resultFolders.finished"));
                break;
        }

        setIsDownloadProcessing(
            caseDetails?.zipState === ZipStatusType.Processing
        );
    }, [caseDetails]);

    useEffect(() => {
        if (!currentSequencesData) return;
        setLoadingTagData(true);
        currentSequencesData.forEach((sequence) => {
            let biomarkerModelTag: IBiomarkerModelTag[] = [];
            if (sequence.type.toLowerCase() !== "dti") {
                sequence.biomarkers.forEach((biomarker) => {
                    if (biomarker.value.includes("C3_C2")) {
                        biomarkerModelTag.push({
                            name: biomarker.key,
                            sequenceModel: biomarker.key,
                            sequenceType: sequence.type.toLowerCase(),
                            biomarker: "C3_C2",
                            views: sequence.views,
                        });
                    }
                });
            } else {
                sequence.biomarkers.forEach((biomarker) => {
                    if (biomarker.key === "DTI-PLS") {
                        biomarkerModelTag.push({
                            name: biomarker.key,
                            sequenceModel: biomarker.key,
                            sequenceType: sequence.type.toLowerCase(),
                            biomarker: "L1",
                            views: sequence.views,
                        });
                    } else {
                        biomarkerModelTag.push({
                            name: biomarker.key,
                            sequenceModel: biomarker.key,
                            sequenceType: sequence.type.toLowerCase(),
                            biomarker: "CT1",
                            views: sequence.views,
                        });
                    }
                });
            }

            if (sequence.biomarkers[0].value.includes("BVir"))
                biomarkerModelTag.push({
                    name: t("biomarkerModels.biopsy"),
                    sequenceModel: sequence.biomarkers[0].key,
                    sequenceType: sequence.type.toLowerCase(),
                    biomarker: "BVir",
                    color: "blue",
                    views: sequence.views,
                });

            iterationDataSource.push({
                techniques: sequence.type,
                slices: sequence.views.axial!,
                biomarkers: biomarkerModelTag,
                key: sequence.type,
            });
        });

        setDataSource(iterationDataSource);
        setLoadingTagData(false);
    }, [currentSequencesData]);

    const columns: ColumnsType<any> = [
        {
            title: t("caseDetails.resultFolders.techniques"),
            dataIndex: "techniques",
            key: "techniques",
            fixed: "left",
        },
        {
            title: t("caseDetails.resultFolders.slices"),
            dataIndex: "slices",
            key: "slices",
            render: (slices: number) => <>{slices && slices.toString()}</>,
        },
        {
            title: t("caseDetails.resultFolders.biomarkerModels"),
            dataIndex: "biomarkers",
            key: "biomarkers",
            render: (biomarkers: IBiomarkerModelTag[]) =>
                biomarkers.map((biomarker) => (
                    <Tag
                        className={styles.TagButton}
                        key={biomarker.name}
                        color={biomarker.color}
                        onClick={() => {
                            navigate("/visualizer/" + caseId, {
                                state: {
                                    sequences: currentSequencesData,
                                    images: currentSequenceImages,
                                    biomarkerTag: biomarker,
                                },
                            });
                        }}
                    >
                        {biomarker.name}
                    </Tag>
                )),
        },
    ];

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={isLoading || isLoadingMipImages || isLoadingTagData}
            />
            <Spacer size="m" />
            {!isOrgAdmin && (
                <Row justify="end">
                    <Button
                        onClick={() => {
                            if (caseDetails?.zipState === "Finished") {
                                download(
                                    caseDetails.zipLink,
                                    `${caseDetails?.reference}.zip`,
                                    "application/zip"
                                );
                            } else {
                                requestExportMutation({
                                    variables: {
                                        id: caseId,
                                        exportType: "zip",
                                    },
                                });
                                setIsDownloadProcessing(true);
                            }
                        }}
                        disabled={isDownloadProcessing}
                    >
                        {downloadButtonText}
                        {isDownloadProcessing ? (
                            <LoadingOutlined />
                        ) : (
                            <span className="kenko-icon-download button-icon" />
                        )}
                    </Button>
                </Row>
            )}
        </>
    );
};
