import { FilterValue, Key, SortOrder, TablePaginationConfig } from "antd/lib/table/interface";

export interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export interface SortFields {
    fieldName: Key | undefined;
    order: SortOrder | undefined;
}

export interface FilterFields {
    fieldName: Key | undefined;
    values: string[];
}

export const BooleanFromOrder = (order: SortOrder | undefined): boolean => {
    if (order === "ascend") {
        return true;
    } else {
        return false;
    }
};
