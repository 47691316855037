import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { CaseDataSource } from "../../data/dataSources/caseDataSource";
import { ICaseListPresentation } from "../entities/case";
import { faro } from "@grafana/faro-web-sdk";
import {
    FilterInputOfCaseFilteredFieldsInput,
    SortInputOfCaseSortableFieldsInput,
} from "../../../../generated/graphql";

interface IAdditionalMethods {
    getAllCases: (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfCaseSortableFieldsInput[],
        textSearchInput?: string,
        filterInputs?: FilterInputOfCaseFilteredFieldsInput[]
    ) => Promise<void>;
}

export const useAllCases: IUseDomainHook<
    ICaseListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = CaseDataSource();
    const [casesList, setCasesList] = useState<
        ICaseListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllCases = async (
        pageSize: number,
        pageNumber: number,
        sortInputs?: SortInputOfCaseSortableFieldsInput[],
        textSearchInput?: string,
        filterInputs?: FilterInputOfCaseFilteredFieldsInput[]
    ) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getAllCases(
                pageSize,
                pageNumber,
                sortInputs,
                textSearchInput,
                filterInputs
            );
            setCasesList(result);
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    };

    return {
        data: casesList,
        isLoading,
        errorMessage,
        getAllCases,
    };
};
