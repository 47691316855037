import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { CaseDataSource } from "../../data/dataSources/caseDataSource";

import { LogLevel, faro } from '@grafana/faro-web-sdk';
import { ICase, partialCaseFromPresentation } from "../entities/case";


interface IAdditionalMethods {
    getCaseById: (caseId:string) => Promise<void>;
}

export const useCaseById: IUseDomainHook<
    Partial<ICase> | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = CaseDataSource();
    const [currentCase, setCurrentCase] = useState<Partial<ICase> | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getCaseById = async (caseId:string) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getCaseById(caseId);
            if (!result) {
                setErrorMessage("Not found");
                faro.api.pushLog([`Not found case with id ${caseId}`], { level: LogLevel.WARN });   
            }
            else{
                const parseResult = partialCaseFromPresentation(result);
                setCurrentCase(parseResult);
                faro.api.pushLog([`Get case with id ${caseId}`], { level: LogLevel.TRACE });
            }
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }

        setIsLoading(false);
    };

    return {
        data: currentCase,
        isLoading,
        errorMessage,
        getCaseById,
    };
};