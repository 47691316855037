import { LicenseFeatureListPresentation } from "../../../../generated/graphql";
import { IOrganisationReducedTableDto } from "../../../organisation/domain/entities/organisationModel";

export interface IOrgsAndLicenseFeature {
    licenseFeature: ILicenseFeature;
    organisationsWithFeature: IOrganisationReducedTableDto[];
}

export interface ILicenseFeature {
    description: string;
    environKey: string;
    environValue: string;
    id: string;
    name: string;
    price: number;
}

export interface ILicenseFeatureSimpleData {
    description: string;
    id: string;
    name: string;
    price: number;
}

export interface ILicenseFeatureInput {
    name: string;
    description: string;
    environKey: string;
    environValue: string;
    price: number;
}

export const licenseFeatureFromPartialFeature = (
    currentFeature: Partial<ILicenseFeature>
): ILicenseFeatureSimpleData => ({
    description: currentFeature.description!,
    id: currentFeature.id!,
    name: currentFeature.name!,
    price: currentFeature.price!,
});

export interface ILicenseFeatureListPresentation {
    licenseFeatures: ILicenseFeatureSimpleData[];
    totalResults: number;
}

export const licenseFeatureListFromLicenseFeatureListPresentation = (
    presentation: Partial<LicenseFeatureListPresentation>
): Partial<ILicenseFeatureListPresentation> => ({
    licenseFeatures: presentation.licenseFeatures,
    totalResults: presentation.totalResults,
});
