import {
    Alert,
    Button,
    Card,
    Divider,
    Radio,
    RadioChangeEvent,
    Space,
    Typography,
} from "antd";
import { Spacer } from "../../../../components/appCore/spacer/spacer";
import { useTranslation } from "react-i18next";
import { ChooseUserLicense } from "../chooseUserLicense/chooseUserLicense";
import styles from "./assignUserLicense.module.scss";
import { useState } from "react";
import {
    IUserLicenseCreate,
    IUserLicenseListPresentation,
    IUserLicenseModel,
} from "../../../userLicense/domain/entities/userLicenseModel";
import { CreateUserLicenseForm } from "../../../userLicense/presentation/createUserLicenseForm/createUserLicenseForm";

const { Title, Text } = Typography;

interface IProps {
    updateCheckValue: (value: number | undefined) => void;
    checkValue: number | undefined;
    updateUserLicenseUserId: (value: string | undefined | null) => void;
    userLicensesWithoutUser: IUserLicenseListPresentation | undefined;
    remainingCases: number | undefined;
    remainingUsers: number | undefined;
    updateUserLicenseNumCases: (value: number | undefined) => void;
    onCreateUser: () => void;
    onCreateComplete: () => void;
    userLicenseDetails: IUserLicenseCreate | undefined;
    currentUserLicense?: IUserLicenseModel | undefined;
    buttonText: string;
    onLoadMoreUserLicenses: (pageNumber: number) => void;
    onFilterUserLicense: (textInput: string) => void;
    textSearchLicenses: string;
    unfilteredLicenses: IUserLicenseModel[] | undefined;
    filteredLicenses: IUserLicenseModel[] | undefined;
}

export const AssignUserLicense = ({
    updateCheckValue,
    checkValue,
    updateUserLicenseUserId,
    userLicensesWithoutUser,
    remainingCases,
    remainingUsers,
    updateUserLicenseNumCases,
    onCreateUser,
    onCreateComplete,
    userLicenseDetails,
    currentUserLicense,
    buttonText,
    onLoadMoreUserLicenses,
    onFilterUserLicense,
    textSearchLicenses,
    unfilteredLicenses,
    filteredLicenses,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const updateIsFormValid = (value: boolean) => {
        setIsFormValid(value);
    };

    const onChange = (e: RadioChangeEvent) => {
        if (!e) return;
        updateCheckValue(e.target.value);
        updateIsFormValid(false);
        updateUserLicenseUserId(undefined);
        updateUserLicenseNumCases(undefined);
    };

    return (
        <>
            <Card>
                <div>
                    <Title level={4}>{t("invite.license.title")}</Title>
                    <Text type="secondary">{t("invite.license.subtitle")}</Text>
                </div>
                <Spacer size={"m"} />
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Text>{t("invite.license.question.title")}</Text>
                    </div>
                </div>
                <Spacer size="xs" />
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Radio.Group onChange={onChange}>
                            <Space direction="vertical">
                                <Radio value={1}>
                                    {t("invite.license.question.yes")}
                                </Radio>
                                <Radio value={2} disabled={!remainingUsers}>
                                    {t("invite.license.question.no")}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>

                <Divider />

                {(checkValue === 1 && (
                    <ChooseUserLicense
                        updateUserLicenseUserId={updateUserLicenseUserId}
                        updateIsFormValid={updateIsFormValid}
                        userLicensesWithoutUser={userLicensesWithoutUser}
                        currentUserLicense={currentUserLicense}
                        onLoadMoreUserLicenses={onLoadMoreUserLicenses}
                        textSearchLicenses={textSearchLicenses}
                        unfilteredLicenses={unfilteredLicenses}
                        filteredLicenses={filteredLicenses}
                        onFilterUserLicense={onFilterUserLicense}
                    />
                )) ||
                    (checkValue === 2 && (
                        <CreateUserLicenseForm
                            remainingCases={remainingCases}
                            updateIsFormValid={updateIsFormValid}
                            updateUserLicenseNumCases={
                                updateUserLicenseNumCases
                            }
                            title={t("invite.license.newLicense.title")}
                            subtitle={t("invite.license.newLicense.subtitle")}
                            userLicenseDetails={userLicenseDetails}
                        />
                    ))}

                {!remainingUsers && checkValue === 0 && (
                    <Alert
                        className={styles.alert}
                        message={t("invite.license.complete.title")}
                        description={t("invite.license.complete.description")}
                        type="warning"
                        showIcon
                    />
                )}
                {remainingUsers === 1 && checkValue === 0 && (
                    <Alert
                        className={styles.alert}
                        message={t("invite.license.almostComplete.title")}
                        description={t(
                            "invite.license.almostComplete.description"
                        )}
                        type="info"
                        showIcon
                    />
                )}

                <div className={styles.stepNavigation}>
                    <Button
                        className={styles.buttonLater}
                        type="default"
                        htmlType="submit"
                        onClick={onCreateUser}
                    >
                        {t("invite.license.asignLater")}
                    </Button>
                    <Button
                        className={styles.buttonCreate}
                        type="primary"
                        htmlType="submit"
                        disabled={!isFormValid}
                        onClick={onCreateComplete}
                    >
                        {buttonText}
                    </Button>
                </div>
            </Card>
        </>
    );
};
