import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { IUserLicenseListPresentation } from "../entities/userLicenseModel";
import { UserLicenseDataSource } from "../../data/dataSource/userLicenseDataSource";
import { faro } from "@grafana/faro-web-sdk";
import { SortInputOfUserLicenseSortableFieldsInput } from "../../../../generated/graphql";

interface IAdditionalMethods {
    getAllUserLicenses: (
        pageSize: number,
        pageNumber: number,
        sortInput?: SortInputOfUserLicenseSortableFieldsInput[],
        textSearchInput?: string
    ) => Promise<IUserLicenseListPresentation | undefined | null>;
}

export const useAllUserLicenses: IUseDomainHook<
    IUserLicenseListPresentation | undefined | null,
    IAdditionalMethods
> = () => {
    const dataSource = UserLicenseDataSource();
    const [userLicensesList, setUserLicensesList] = useState<
        IUserLicenseListPresentation | undefined | null
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getAllUserLicenses = async (
        pageSize: number,
        pageNumber: number,
        sortInput?: SortInputOfUserLicenseSortableFieldsInput[],
        textSearchInput?: string
    ) => {
        setIsLoading(true);
        try {
            const result = await dataSource.getUserLicenses(
                pageSize,
                pageNumber,
                sortInput,
                textSearchInput
            );
            setUserLicensesList(result);
            setIsLoading(false);
            return result;
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
            setIsLoading(false);
            return undefined;
        }
    };

    return {
        data: userLicensesList,
        isLoading,
        errorMessage,
        getAllUserLicenses,
    };
};
