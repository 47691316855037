import { gql } from "@apollo/client";

const CREATE_USER_LICENSE = gql`
    mutation createUserLicense($input: UserLicenseInput!) {
        createUserLicense(input: $input) {
            id
            organ
            creationOrder
            maxNumOfCases
            totalCasesHistory
            numberOfRemainingCases
            active
        }
    }
`;

const RELOAD_USER_LICENSE = gql`
    mutation reloadUserLicense($input: ReloadLicenseInput!) {
        reloadUserLicense(input: $input) {
            id
            organ
            maxNumOfCases
            creationOrder
            totalCasesHistory
            numberOfRemainingCases
            user {
                id
                name
                surname
                email
                isOrgAdmin
                orgId
            }
            active
        }
    }
`;

const ASSIGN_USER_TO_USER_LICENSE = gql`
    mutation assignUserLicenseToUser($input: AssignUserLicenseToUserInput!) {
        assignUserLicenseToUser(input: $input) {
            id
            organ
            maxNumOfCases
            creationOrder
            totalCasesHistory
            numberOfRemainingCases
            user {
                id
                name
                surname
                email
                isOrgAdmin
                orgId
            }
            active
        }
    }
`;

const UNASSIGN_USER_TO_USER_LICENSE = gql`
    mutation unassignUserLicenseToUser(
        $input: UnassignUserLicenseToUserInput!
    ) {
        unassignUserLicenseToUser(input: $input) {
            id
            organ
            maxNumOfCases
            creationOrder
            totalCasesHistory
            numberOfRemainingCases
            user {
                id
                name
                surname
                email
                isOrgAdmin
                orgId
            }
            active
        }
    }
`;

const MOVE_NUM_CASES_BETWEEN_USER_LICENSE = gql`
    mutation moveNumCasesBetweenUserLicenses(
        $input: MoveNumCasesBetweenUserLicensesInput!
    ) {
        moveNumCasesBetweenUserLicenses(input: $input) {
            userLicenseOrigin {
                id
                organ
                creationOrder
                maxNumOfCases
                totalCasesHistory
                numberOfRemainingCases
                active
            }
            userLicenseDestiny {
                id
                organ
                creationOrder
                maxNumOfCases
                totalCasesHistory
                numberOfRemainingCases
                active
            }
        }
    }
`;

const GET_USER_LICENSES_WITHOUT_USER = gql`
    query userLicensesWithoutUser($input: GetUserLicensesInput!) {
        userLicensesWithoutUser(input: $input) {
            userLicensePresentations {
                id
                creationOrder
                organ
                maxNumOfCases
                totalCasesHistory
                numberOfRemainingCases
                user {
                    id
                    name
                    surname
                    email
                    isOrgAdmin
                    orgId
                }
                active
            }
            totalResults
        }
    }
`;

const GET_USER_LICENSES = gql`
    query userLicenses($input: GetUserLicensesInput!) {
        userLicenses(input: $input) {
            userLicensePresentations {
                id
                creationOrder
                organ
                maxNumOfCases
                totalCasesHistory
                numberOfRemainingCases
                user {
                    id
                    name
                    surname
                    email
                    isOrgAdmin
                    orgId
                }
                active
            }
            totalResults
        }
    }
`;

const GET_USER_LICENSE = gql`
    query userLicense($userLicenseId: String!) {
        userLicense(userLicenseId: $userLicenseId) {
            id
            creationOrder
            organ
            maxNumOfCases
            totalCasesHistory
            numberOfRemainingCases
            user {
                id
                name
                surname
                email
                isOrgAdmin
                orgId
            }
            active
        }
    }
`;

const RETURN_CASES_TO_ORG = gql`
    mutation returnCasesToOrgLicense($input: ReloadLicenseInput!) {
        returnCasesToOrgLicense(input: $input) {
            id
            organ
            creationOrder
            maxNumOfCases
            totalCasesHistory
            numberOfRemainingCases
            active
        }
    }
`;

export const userLicensesFragments = {
    CREATE_USER_LICENSE,
    RELOAD_USER_LICENSE,
    ASSIGN_USER_TO_USER_LICENSE,
    UNASSIGN_USER_TO_USER_LICENSE,
    MOVE_NUM_CASES_BETWEEN_USER_LICENSE,
    GET_USER_LICENSES_WITHOUT_USER,
    GET_USER_LICENSES,
    GET_USER_LICENSE,
    RETURN_CASES_TO_ORG,
};
