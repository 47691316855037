import {
    throwExceptionFromGqlMutationIfExists,
    throwExceptionFromGqlQueryIfExists,
} from "../../../../core/data/dataSources/kenkoDataSource";
import {
    LicenseFeature,
    useAddLicenseFeatureMutation,
    useDeleteLicenseFeatureMutation,
    useLicenseFeatureLazyQuery,
    useLicenseFeaturesLazyQuery,
    useUnassignedLicenseFeaturesLazyQuery,
    useUpdateLicenseFeatureMutation,
} from "../../../../generated/graphql";
import {
    ILicenseFeature,
    ILicenseFeatureInput,
    ILicenseFeatureListPresentation,
    IOrgsAndLicenseFeature,
} from "../../domain/entities/licenseFeature";

export interface ILicenseFeatureDataSource {
    getAllLicenseFeatures: (
        pageSize: number,
        pageNumber: number
    ) => Promise<ILicenseFeatureListPresentation | undefined | null>;
    getLicenseFeatureById: (
        id: string
    ) => Promise<IOrgsAndLicenseFeature | undefined>;
    addLicenseFeature: (
        licenseFeatureInput: ILicenseFeatureInput
    ) => Promise<LicenseFeature | undefined>;
    editLicenseFeature: (
        licenseFeature: ILicenseFeature
    ) => Promise<LicenseFeature | undefined>;
    deleteLicenseFeature: (id: string) => Promise<LicenseFeature | undefined>;
    getUnassignedLicenseFeature: (
        orgId: string
    ) => Promise<Partial<ILicenseFeature>[]>;
}

export const LicenseFeatureDataSource = (): ILicenseFeatureDataSource => {
    const [allLicenseFeaturesQuery] = useLicenseFeaturesLazyQuery();
    const [licenseFeatureQuery] = useLicenseFeatureLazyQuery();
    const [addLicenseFeatureMutation] = useAddLicenseFeatureMutation();
    const [editLicenseFeatureMutation] = useUpdateLicenseFeatureMutation();
    const [deleteLicenseFeatureMutation] = useDeleteLicenseFeatureMutation();
    const [unassignedLicenseFeatureQuery] =
        useUnassignedLicenseFeaturesLazyQuery();

    const getAllLicenseFeatures = async (
        pageSize: number,
        pageNumber: number
    ) => {
        const result = await allLicenseFeaturesQuery({
            variables: {
                input: {
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.licenseFeatures;
    };

    const getLicenseFeatureById = async (id: string) => {
        const result = await licenseFeatureQuery({
            variables: { licenseFeatureId: id },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return {
            licenseFeature: result.data!.licenseFeature,
            organisationsWithFeature: result.data!.organisationsForAFeature,
        } as IOrgsAndLicenseFeature;
    };

    const addLicenseFeature = async (
        licenseFeatureInput: ILicenseFeatureInput
    ) => {
        const result = await addLicenseFeatureMutation({
            variables: { featureInput: licenseFeatureInput },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.addLicenseFeature;
    };

    const editLicenseFeature = async (licenseFeature: ILicenseFeature) => {
        const result = await editLicenseFeatureMutation({
            variables: { featureInput: licenseFeature },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.updateLicenseFeature;
    };

    const deleteLicenseFeature = async (id: string) => {
        const result = await deleteLicenseFeatureMutation({
            variables: { licenseFeatureid: id },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.deleteLicenseFeature;
    };

    const getUnassignedLicenseFeature = async (orgId: string) => {
        const result = await unassignedLicenseFeatureQuery({
            variables: { orgId: orgId },
        });
        await throwExceptionFromGqlMutationIfExists(result);
        return result.data!.unAssignedLicenseFeatures;
    };

    return {
        getAllLicenseFeatures,
        getLicenseFeatureById,
        addLicenseFeature,
        editLicenseFeature,
        deleteLicenseFeature,
        getUnassignedLicenseFeature,
    };
};
