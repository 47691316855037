import { useTranslation } from "react-i18next";
import { BasicInfoCard } from "../../components/myProfileComponents/basicInfoCard/basicInfoCard";
import { Button, Col, Row } from "antd";
import { Spacer } from "../../components/appCore/spacer/spacer";
import { PasswordCard } from "../../components/myProfileComponents/passwordCard/passwordCard";
import { useEffect, useState } from "react";
import { useHeader } from "../../hooks/headerHook";
import { useNavigate } from "react-router-dom";
import { PreferencesCard } from "../../components/myProfileComponents/preferencesCard/preferencesCard";
import { OrganisationCard } from "../../components/myProfileComponents/organisationCard/organisationCard";
import { useQuery } from "@apollo/client";
import fragments from "../../gql/fragments";
import {
    MeQuery,
    OrganisationForUserProfileQuery,
    RemainingItemsQuery,
} from "../../generated/graphql";
import styles from "./myProfilePage.module.scss";
import { IUserReducedDto } from "../../features/user/domain/entities/userModel";

export interface IOrganisationDetailsProfile {
    name: string;
    adminName: string;
}

const MyProfilePage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();

    const [organisationDetails, setOrganisationDetails] =
        useState<IOrganisationDetailsProfile>();
    const [userDetails, setUserDetails] = useState<IUserReducedDto>();

    // ! TODO: Fetch User
    const { data: getMeData } = useQuery<MeQuery>(fragments.GET_ME);

    // ! TODO: Fetch Organisation (Currently not working, giving a 500 error)
    const { data: getOrganisationData } =
        useQuery<OrganisationForUserProfileQuery>(
            fragments.GET_ORGANISATION_FOR_USER_PROFILE
        );
    const { data: remainingItemsData } = useQuery<RemainingItemsQuery>(
        fragments.GET_REMAINING_ITEMS
    );

    useEffect(() => {
        setHeaderContent({
            title: t("myProfile.title"),
            breadCrumb: [],
            goBackLinkOverride: undefined,
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="caseListBasicUser"
                    onClick={() => {
                        navigate("/createCase");
                    }}
                    disabled={
                        remainingItemsData?.remainingItems.remainingCases === 0
                    }
                >
                    {t("caseList.createCase")}
                    <span className={`kenko-icon-plus ${styles.buttonIcon}`} />
                </Button>
            ),
        });
    }, [setHeaderContent, t, remainingItemsData]);

    useEffect(() => {
        if (
            !getOrganisationData ||
            !getOrganisationData.organisation ||
            !getOrganisationData.organisation.admin
        )
            return;

        setOrganisationDetails({
            name: getOrganisationData.organisation.name,
            adminName: getOrganisationData.organisation.admin.name,
        });
    }, [getOrganisationData]);

    useEffect(() => {
        if (!getMeData || !getMeData.me.email) return;
        setUserDetails({
            email: getMeData.me.email,
            firstName: getMeData.me.name,
            lastName: getMeData.me.surname,
        });
    }, [getMeData]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <BasicInfoCard userDetails={userDetails} />
                </Col>
            </Row>
            <Spacer size="s" />
            <Row wrap={true} gutter={16}>
                <Col span={24} md={12}>
                    <PasswordCard />
                </Col>
                <Col span={24} md={12}>
                    <PreferencesCard />
                </Col>
            </Row>
            <Spacer size="s" />
            <Row>
                <Col span={24}>
                    <OrganisationCard
                        organisationDetails={organisationDetails}
                    />
                </Col>
            </Row>
            <Spacer size="xs" />
            <div className={styles.Footer}>
                <a>{t("myProfile.footer.privacy")}</a>
                <a>{t("myProfile.footer.terms")}</a>
                <a
                    onClick={() => {
                        navigate("/support");
                    }}
                >
                    {t("myProfile.footer.help")}
                </a>
            </div>
            <Spacer size="s" />
        </>
    );
};

export default MyProfilePage;
