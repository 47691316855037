import { Card, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { ILicensePresentation } from "../../../../features/licenseTemplate/domain/entities/licenseModel";
import { OrganTag } from "../../../appCore/organTag/organTag";

const { Title } = Typography;

interface IProps {
    licenseWithSequences: ILicensePresentation[] | undefined;
}

export const SequencesDetailsLicenses = ({ licenseWithSequences }: IProps) => {
    const { t } = useTranslation();

    const columns: ColumnsType<ILicensePresentation> = [
        {
            title: t("sequencesDetails.licensesWithSequences.table.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("sequencesDetails.licensesWithSequences.table.organ"),
            dataIndex: "organ",
            key: "organ",
            render: (organ) => <OrganTag organ={organ.toLowerCase()} />,
        },
        {
            title: t("sequencesDetails.licensesWithSequences.table.price"),
            dataIndex: "totalPrice",
            key: "totalPrice",
        },
        {
            title: t("sequencesDetails.licensesWithSequences.table.maxCases"),
            dataIndex: "maxNumberOfCases",
            key: "maxNumberOfCases",
        },
    ];

    return (
        <>
            <Title level={4}>
                {t("sequencesDetails.licensesWithSequences.title")}
            </Title>
            <Card>
                <Table
                    dataSource={licenseWithSequences}
                    columns={columns}
                    rowKey="id"
                />
            </Card>
        </>
    );
};
