import { message } from "antd";
import i18n from "i18next";

const key = "loadingMessage";

export const download = (
    link: string,
    filename: string,
    contentType: string
) => {
    fetch(link, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": contentType,
        },
    })
        .then((response) => {
            message.loading({
                content: i18n.t("caseDetails.download.downloading"),
                key: key,
                duration: 60,
            });
            return response.blob();
        })
        .then((blob) => {
            message.success({
                content: i18n.t("caseDetails.download.success"),
                key: key,
            });
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        })
        .catch(() => {
            message.error({
                content: i18n.t("caseDetails.download.fail"),
                key: key,
                duration: 10,
            });
        });
};
