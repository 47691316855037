import {
    InteractionRequiredAuthError,
    PublicClientApplication,
} from "@azure/msal-browser";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { msalConfig } from "../../../authConfig";
import { FetchResult, QueryResult } from "@apollo/client";
import { Exception } from "../interfaces";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    maxRedirects: 0,
});

const instance = new PublicClientApplication(msalConfig);

export const kenkoDataSource = async (options: AxiosRequestConfig) => {
    var request = {
        scopes: [process.env.REACT_APP_LOGIN_REQUEST_SCOPES!],
        account: instance.getAllAccounts()[0],
    };

    async function getToken() {
        return await instance
            .acquireTokenSilent(request)
            .then((tokenResponse) => {
                return "Bearer " + tokenResponse.accessToken;
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails

                    return instance.acquireTokenRedirect(request);
                }
                console.log(error);
            });
    }

    const token = await getToken();

    options.headers = {
        ...options.headers,
        Authorization: token || "",
        "Access-Control-Allow-Origin": "*",
    };

    const onSuccess = (response: AxiosResponse) => {
        return response.data;
    };

    const onError = (error: any) => {
        if (!!error && error.response) {
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.replace("/");
            }
            // Request was made but server responded with something
            // other than 2xx
        } else {
            // Something else happened while setting up the request
            // triggered the error
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options).then(onSuccess).catch(onError);
};

export const throwExceptionFromGqlQueryIfExists = async (
    result: QueryResult<any, any>
) => {
    // @ts-ignore
    

    if (result.error) {
        const exception: Exception = {
            message: result.error.message,
        };

        throw exception;
    }
    if (!result.data) {
        const exception: Exception = {
            message: "no data",
        };
        throw exception;
    }
};

export const throwExceptionFromGqlMutationIfExists = (
    result: FetchResult<any, any>
) => {
    //TODO JOSE AND IVAN REVIEW ALWAYS 500
    // @ts-ignore

    if (result.errors) {
        const exception: Exception = {
            message: result.errors[0].message,
        };

        throw exception;
    }
    if (!result.data) {
        const exception: Exception = {
            message: "no data",
        };
        throw exception;
    }
};

