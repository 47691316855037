import { useEffect, useState } from "react";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Button, Col, Row, Table, Tag } from "antd";
import styles from "../../../pages/caseListPage/caseListPage.module.scss";
import { useTranslation } from "react-i18next";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import {
    CaseFilteredFields,
    FilterInputOfCaseFilteredFieldsInput,
    RemainingItemsQuery,
    SortInputOfCaseSortableFieldsInput,
} from "../../../generated/graphql";
import { OrganTag } from "../organTag/organTag";
import { StatusBadge } from "../statusBadge/statusBadge";
import { Link } from "react-router-dom";
import { DeleteCaseModal } from "../deleteCaseModal/deleteCaseModal";
import {
    CaseSortableFieldFromKey,
    CaseStatusType,
    DiagnosisType,
    ECaseFilteredFields,
    ICase,
    ICaseListPresentation,
    StatusType,
} from "../../../features/case/domain/entities/case";
import {
    ColumnFilterItem,
    FilterValue,
    SorterResult,
} from "antd/lib/table/interface";
import {
    BooleanFromOrder,
    SortFields,
    TableParams,
} from "../../../features/common/domain/entities/sortInterfaces";
import { SearchBar } from "../../../features/common/presentation/searchBar/searchBar";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import { Spacer } from "../spacer/spacer";
import { FilterFilled } from "@ant-design/icons";
import moment from "moment";
import { RangeValue } from "rc-picker/lib/interface";

const { RangePicker } = DatePicker;

interface IProps {
    isOrganisation?: boolean;
    refetchRemainingItems?: (
        variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<RemainingItemsQuery>>;
    caseListData: ICaseListPresentation | undefined | null;
    onLoadMore: (
        pageNumber: number,
        sortFields: SortInputOfCaseSortableFieldsInput[],
        filterFields: FilterInputOfCaseFilteredFieldsInput[]
    ) => void;
    errorMessage: string | undefined;
    isLoading: boolean;
    onRefetch: () => void;
    onFilter: (textInput: string) => void;
    sortInputs: SortInputOfCaseSortableFieldsInput[];
    filterInput: FilterInputOfCaseFilteredFieldsInput[] | undefined;
}

export const CaseList = ({
    isOrganisation = false,
    refetchRemainingItems,
    caseListData,
    onLoadMore,
    errorMessage,
    isLoading,
    onFilter,
    onRefetch,
    sortInputs,
    filterInput,
}: IProps) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [caseIdToDelete, setCaseIdToDelete] = useState<string>();
    const [caseReference, setCaseReference] = useState<string>();
    const [dateMoment, setDateMoment] = useState<
        RangeValue<moment.Moment> | undefined
    >(undefined);
    const [filterDate, setFilterDate] =
        useState<FilterInputOfCaseFilteredFieldsInput[]>();
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [tableParams, setTableParams] = useState<TableParams>({});
    const [filteredInfo, setFilteredInfo] = useState<
        Record<string, FilterValue | null>
    >({});

    useEffect(() => {
        if (!caseListData || !caseListData.totalResults) return;
        setTableParams({
            pagination: {
                current: 1,
                pageSize: 10,
                total: caseListData.totalResults,
                showSizeChanger: false,
            },
        });
    }, [caseListData?.totalResults]);

    const onDelete = (id: string) => {
        if (!caseListData) return;
        setIsModalVisible(true);
        const caseToDelete = caseListData.casePresentations.filter(
            (c) => c.id === id
        );
        setCaseReference(caseToDelete![0].reference);
        setCaseIdToDelete(id);
    };

    const onChangeDate = (
        date: RangeValue<moment.Moment>,
        dateString: [string, string]
    ) => {
        if (!date) return;
        setDateMoment(date);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ICase> | SorterResult<ICase>[]
    ) => {
        let individualSorter = sorter as SorterResult<ICase>;
        let sorterList = sorter as SorterResult<ICase>[];

        let sortFields: SortFields[] = [];
        if (sorterList.length > 1) {
            sortFields = sorterList.map((o) => ({
                fieldName: o.columnKey,
                order: o.order,
            }));
        } else {
            if (!!individualSorter.order) {
                sortFields.push({
                    fieldName: individualSorter.columnKey,
                    order: individualSorter.order,
                });
            }
        }

        let sortInput: SortInputOfCaseSortableFieldsInput[] = sortFields.map(
            (o) => ({
                fieldName: CaseSortableFieldFromKey(o.fieldName),
                isAsc: BooleanFromOrder(o.order),
            })
        );

        setFilteredInfo(filters);
        let filterInput: FilterInputOfCaseFilteredFieldsInput[] = [];
        const filtersKeys = Object.keys(filters);
        filtersKeys.forEach((key) => {
            switch (key) {
                case ECaseFilteredFields.isPositive:
                    if (filters.isPositive !== null) {
                        filterInput.push({
                            fieldName: CaseFilteredFields.FinalDiagnosis,
                            values: filters.isPositive?.map((value) =>
                                value.toString()
                            ),
                        });
                    }
                    break;
                case ECaseFilteredFields.isPositivePrediction:
                    if (filters.isPositivePrediction !== null) {
                        filterInput.push({
                            fieldName: CaseFilteredFields.InitialDiagnosis,
                            values: filters.isPositivePrediction?.map((value) =>
                                value.toString()
                            ),
                        });
                    }
                    break;
                case ECaseFilteredFields.state:
                    if (filters.state !== null) {
                        filterInput.push({
                            fieldName: CaseFilteredFields.State,
                            values: filters.state?.map((value) =>
                                value.toString()
                            ),
                        });
                    }
                    break;
            }
        });

        let concatFilterInput = filterInput;
        if (filterDate) {
            concatFilterInput = filterInput.concat(filterDate);
        }

        if (!pagination.current || !pagination.pageSize) return;
        setCurrentPage(pagination.current - 1);
        onLoadMore(pagination.current - 1, sortInput, concatFilterInput);

        setTableParams({
            pagination: {
                current: pagination.current,
                pageSize: tableParams.pagination?.pageSize,
                total: pagination.total,
                showSizeChanger: false,
            },
            ...sorter,
        });
    };

    const statusFilters = (): ColumnFilterItem[] => {
        let statusList = Object.values(CaseStatusType).filter(
            (status) =>
                status !== CaseStatusType.SequenceUpdated &&
                status !== CaseStatusType.Archived
        );
        return statusList.map((status) => ({
            text: <StatusBadge status={status} popover={false} />,
            value: status,
        }));
    };

    const diagnosisFilters = (isInitial: boolean): ColumnFilterItem[] => {
        if (isInitial) {
            let valuesList = Object.values(DiagnosisType).filter(
                (value) => value !== DiagnosisType.NoDiagnosis
            );
            return valuesList.map((type) => ({
                text: t("diagnosisType." + type.toLowerCase()),
                value: type,
            }));
        }
        return Object.values(DiagnosisType).map((type) => ({
            text: t("diagnosisType." + type.toLowerCase()),
            value: type,
        }));
    };

    const onClearDate = () => {
        setDateMoment(undefined);
        setFilterDate(undefined);
    };

    const clearAllFilters = () => {
        setFilteredInfo({});
        setDateMoment(undefined);
        setFilterDate(undefined);
        onLoadMore(0, sortInputs, []);
    };

    const onSetDate = () => {
        let newFilterInput: FilterInputOfCaseFilteredFieldsInput[] = [];
        if (filterInput) {
            newFilterInput = filterInput.filter(
                (filter) =>
                    filter.fieldName !== CaseFilteredFields.CreationDate1 &&
                    filter.fieldName !== CaseFilteredFields.CreationDate2
            );
        }

        let input: FilterInputOfCaseFilteredFieldsInput[] = [];
        if (!!dateMoment && dateMoment[0] !== null && dateMoment[1] !== null) {
            let dateInput: FilterInputOfCaseFilteredFieldsInput[] = [];
            dateInput.push(
                {
                    fieldName: CaseFilteredFields.CreationDate1,
                    values: [dateMoment[0].format("YYYY-MM-DD")],
                },
                {
                    fieldName: CaseFilteredFields.CreationDate2,
                    values: [dateMoment[1].format("YYYY-MM-DD")],
                }
            );
            setFilterDate(dateInput);

            input = dateInput;
            if (newFilterInput) {
                input = newFilterInput.concat(dateInput);
            }
        }

        onLoadMore(!dateMoment ? 0 : currentPage, sortInputs, input);
        setDropdownOpen(false);
    };

    const columns: ColumnsType<ICase> = isOrganisation
        ? [
              {
                  title: t("caseList.table.id"),
                  dataIndex: "reference",
                  key: "reference",
                  width: 60,
                  sorter: { multiple: 2 },
                  filteredValue: filteredInfo.reference || null,
              },
              {
                  title: t("caseList.table.date"),
                  dataIndex: "creationDate",
                  key: "creationDate",
                  width: 70,
                  render: (datasource: any) => (
                      <>{datasource.toString().split("T")[0]}</>
                  ),
                  filteredValue: filteredInfo.creationDate || null,
                  filterDropdownVisible: dropdownOpen,
                  filterDropdown: (props) => {
                      return (
                          <Col>
                              <Row>
                                  <RangePicker
                                      value={dateMoment}
                                      className={styles.rangePicker}
                                      format={"YYYY-MM-DD"}
                                      locale={locale}
                                      onChange={(date, dateString) =>
                                          onChangeDate(date, dateString)
                                      }
                                  />
                              </Row>
                              <Row className={styles.rangePickerRow}>
                                  <Button
                                      type="text"
                                      className={styles.resetButton}
                                      disabled={!dateMoment}
                                      onClick={onClearDate}
                                  >
                                      {t("caseList.filters.reset")}
                                  </Button>
                                  <Button
                                      type="primary"
                                      className={styles.okButton}
                                      onClick={onSetDate}
                                  >
                                      {t("caseList.filters.ok")}
                                  </Button>
                              </Row>
                              <Spacer size={"xs"} />
                          </Col>
                      );
                  },
                  defaultSortOrder: "descend",
                  filterIcon: (
                      <FilterFilled
                          onClick={() =>
                              dropdownOpen
                                  ? setDropdownOpen(false)
                                  : setDropdownOpen(true)
                          }
                          className={
                              !!dateMoment
                                  ? styles.filterIcon
                                  : styles.noFilterIcon
                          }
                      />
                  ),
                  sorter: { multiple: 1 },
              },
              {
                  title: t("caseList.table.organ"),
                  dataIndex: "organ",
                  key: "organ",
                  width: 35,
                  filteredValue: filteredInfo.organ || null,
                  render: (organ) => <OrganTag organ={organ.toLowerCase()} />,
              },
              {
                  title: t("caseList.table.initialDiagnosis"),
                  dataIndex: "isPositivePrediction",
                  key: "isPositivePrediction",
                  width: 60,
                  sorter: { multiple: 3 },
                  filters: diagnosisFilters(true),
                  filteredValue: filteredInfo.isPositivePrediction || null,
                  render: (isPositive: boolean) => (
                      <Tag
                          color="default"
                          className={!isPositive ? styles.Disabled : ""}
                      >
                          {isPositive ? t("positive") : t("negative")}
                      </Tag>
                  ),
              },
              {
                  title: t("caseList.table.analysis"),
                  dataIndex: "state",
                  key: "state",
                  width: 60,
                  filters: statusFilters(),
                  filteredValue: filteredInfo.state || null,
                  render: (status: Partial<CaseStatusType>) => (
                      <StatusBadge status={CaseStatusType[status]} />
                  ),
              },
              {
                  title: t("caseList.table.finalDiagnosis"),
                  dataIndex: ["isPositive", "state"],
                  key: "isPositive",
                  width: 60,
                  sorter: { multiple: 4 },
                  filteredValue: filteredInfo.isPositive || null,
                  filters: diagnosisFilters(false),
                  render: (text: any, row: any) =>
                      row["state"] === StatusType.Closed ? (
                          <Tag
                              color="default"
                              className={
                                  !row["isPositive"] ? styles.Disabled : ""
                              }
                          >
                              {row["isPositive"]
                                  ? t("positive")
                                  : t("negative")}
                          </Tag>
                      ) : (
                          <></>
                      ),
              },
              {
                  title: t("caseList.table.user"),
                  dataIndex: "user",
                  key: "user",
                  width: 60,
                  className: !isOrganisation ? styles.Hidden : "",
                  filteredValue: filteredInfo.user || null,
                  render: (user: any) => (
                      <span>{`${user.name} ${user.surname}`}</span>
                  ),
              },
              {
                  title: t("caseList.table.action"),
                  key: "action",
                  dataIndex: "id",
                  fixed: "right",
                  width: 45,
                  render: (id, row: any) => {
                      if (row["state"] === StatusType.Created) {
                          return;
                      } else {
                          return (
                              <Link to={`/case/${id}`}>
                                  <span className="kenko-icon-eye"></span>
                              </Link>
                          );
                      }
                  },
              },
          ]
        : [
              {
                  title: t("caseList.table.id"),
                  dataIndex: "reference",
                  key: "reference",
                  width: 60,
                  sorter: { multiple: 2 },
                  filteredValue: filteredInfo.reference || null,
              },
              {
                  title: t("caseList.table.date"),
                  dataIndex: "creationDate",
                  key: "creationDate",
                  width: 70,
                  render: (datasource: any) => (
                      <>{datasource.toString().split("T")[0]}</>
                  ),
                  filteredValue: filteredInfo.creationDate || null,
                  filterDropdownVisible: dropdownOpen,
                  filterDropdown: (props) => {
                      return (
                          <Col>
                              <Row>
                                  <RangePicker
                                      value={dateMoment}
                                      className={styles.rangePicker}
                                      format={"YYYY-MM-DD"}
                                      locale={locale}
                                      onChange={(date, dateString) =>
                                          onChangeDate(date, dateString)
                                      }
                                  />
                              </Row>
                              <Row className={styles.rangePickerRow}>
                                  <Button
                                      type="text"
                                      className={styles.resetButton}
                                      disabled={!dateMoment}
                                      onClick={onClearDate}
                                  >
                                      {t("caseList.filters.reset")}
                                  </Button>
                                  <Button
                                      type="primary"
                                      className={styles.okButton}
                                      onClick={onSetDate}
                                  >
                                      {t("caseList.filters.ok")}
                                  </Button>
                              </Row>
                              <Spacer size={"xs"} />
                          </Col>
                      );
                  },
                  filterIcon: (
                      <FilterFilled
                          onClick={() =>
                              dropdownOpen
                                  ? setDropdownOpen(false)
                                  : setDropdownOpen(true)
                          }
                          className={
                              !!dateMoment
                                  ? styles.filterIcon
                                  : styles.noFilterIcon
                          }
                      />
                  ),
                  sorter: { multiple: 1 },
                  defaultSortOrder: "descend",
              },
              {
                  title: t("caseList.table.organ"),
                  dataIndex: "organ",
                  key: "organ",
                  width: 35,
                  filteredValue: filteredInfo.organ || null,
                  render: (organ) => <OrganTag organ={organ.toLowerCase()} />,
              },
              {
                  title: t("caseList.table.initialDiagnosis"),
                  dataIndex: "isPositivePrediction",
                  key: "isPositivePrediction",
                  width: 60,
                  sorter: { multiple: 3 },
                  filters: diagnosisFilters(true),
                  filteredValue: filteredInfo.isPositivePrediction || null,
                  render: (isPositive: boolean) => (
                      <Tag
                          color="default"
                          className={!isPositive ? styles.Disabled : ""}
                      >
                          {isPositive ? t("positive") : t("negative")}
                      </Tag>
                  ),
              },
              {
                  title: t("caseList.table.analysis"),
                  dataIndex: "state",
                  key: "state",
                  width: 60,
                  filteredValue: filteredInfo.state || null,
                  filters: statusFilters(),
                  render: (status: Partial<CaseStatusType>) => (
                      <StatusBadge status={CaseStatusType[status]} />
                  ),
              },
              {
                  title: t("caseList.table.finalDiagnosis"),
                  dataIndex: ["isPositive", "state"],
                  key: "isPositive",
                  width: 60,
                  sorter: { multiple: 4 },
                  filteredValue: filteredInfo.isPositive || null,
                  filters: diagnosisFilters(false),
                  render: (text: any, row: any) =>
                      row["state"] === StatusType.Closed ? (
                          <Tag
                              color="default"
                              className={
                                  !row["isPositive"] ? styles.Disabled : ""
                              }
                          >
                              {row["isPositive"]
                                  ? t("positive")
                                  : t("negative")}
                          </Tag>
                      ) : (
                          <></>
                      ),
              },
              {
                  title: t("caseList.table.action"),
                  key: "action",
                  dataIndex: "id",
                  fixed: "right",
                  width: 45,
                  render: (id: any, row: any) => (
                      <>
                          <Link to={`/case/${id}`}>
                              <span className="kenko-icon-eye"></span>
                          </Link>
                          {row["state"] === StatusType.Created && (
                              <Button
                                  onClick={() => {
                                      onDelete(id);
                                  }}
                                  type="link"
                              >
                                  <span className={`kenko-icon-bin`} />
                              </Button>
                          )}
                      </>
                  ),
              },
          ];

    return (
        <>
            <Row className={styles.headerRow}>
                <SearchBar
                    onFilter={onFilter}
                    placeholder={
                        isOrganisation
                            ? t("tableTools.casesOrgPropmpt")
                            : t("tableTools.casesPropmpt")
                    }
                />
                <Button type="primary" onClick={clearAllFilters}>
                    {t("tableTools.clearFilters")}
                </Button>
            </Row>
            {errorMessage !== "" ? (
                <div className={styles.TextContainer}>
                    <p>{t("caseList.table.errorLoading")}</p>
                </div>
            ) : isLoading ? (
                <Table columns={columns} loading />
            ) : (
                caseListData && (
                    <Table
                        dataSource={caseListData.casePresentations}
                        columns={columns}
                        scroll={{ x: 1000 }}
                        rowKey="id"
                        onChange={(paginate, filter, sorter) =>
                            handleTableChange(paginate, filter, sorter)
                        }
                        pagination={tableParams.pagination}
                        loading={isLoading}
                    ></Table>
                )
            )}
            <DeleteCaseModal
                caseId={caseIdToDelete}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                onRefetch={onRefetch}
                reference={caseReference}
                refetchRemainingItems={refetchRemainingItems}
            />
        </>
    );
};
