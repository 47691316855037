import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHeader } from "../../../hooks/headerHook";
import { ISequencesTemplateTable } from "../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./sequencesListPage.module.scss";
import { SequencesExpandedTable } from "../../../components/adminComponents/sequencesListComponents/sequencesExpandedTable";
import { useAllSequencesTemplates } from "../../../features/sequencesTemplate/domain/hooks/useAllSequencesTemplates";

export const SequencesListPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();

    const [sequencesTemplateList, setSequencesTemplateList] = useState<
        ISequencesTemplateTable[]
    >([]);
    const ELEMENTS_PAGE = 10;

    //===============HOOKS==================
    const {
        data: sequencesList,
        isLoading,
        errorMessage,
        getAllSequencesTemplates,
    } = useAllSequencesTemplates();

    //===============FUNCTIONS==================
    const onPaginate = (pageNumber: number) => {
        getAllSequencesTemplates(ELEMENTS_PAGE, pageNumber);
    };

    //===============USEEFFECTS==================
    useEffect(() => {
        getAllSequencesTemplates(ELEMENTS_PAGE, 0);
    }, []);

    useEffect(() => {
        if (!sequencesList) return;
        setSequencesTemplateList(
            sequencesList.sequencesTemplatePresentations as ISequencesTemplateTable[]
        );
    }, [sequencesList]);

    useEffect(() => {
        setHeaderContent({
            title: t("sequencesList.title"),
            breadCrumb: [],
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="createSequencesTemplate"
                    onClick={() => {
                        navigate("/createSequences");
                    }}
                >
                    {t("sequencesList.createSequences")}
                    <PlusOutlined />
                </Button>
            ),
            goBackLinkOverride: undefined,
        });
    }, [setHeaderContent, t]);

    return (
        <SequencesExpandedTable
            sequencesTemplateList={sequencesTemplateList}
            isSequenceListPage={true}
            onPaginate={onPaginate}
            totalResults={sequencesList?.totalResults}
            elementsPage={ELEMENTS_PAGE}
        />
    );
};
