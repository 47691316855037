import { useTranslation } from "react-i18next";
import { useHeader } from "../../../hooks/headerHook";
import { Key, useEffect, useState } from "react";
import styles from "./createLicensePage.module.scss";
import { Col, Row } from "antd";
import { LicenseInfoStep } from "../../../components/adminComponents/createLicenseComponents/licenseInfoStep/licenseInfoStep";
import { LicenseAddSequenceStep } from "../../../components/adminComponents/createLicenseComponents/licenseAddSequenceStep/licenseAddSequenceStep";
import { LicenseAddDistributorStep } from "../../../components/adminComponents/createLicenseComponents/licenseAddDistributorStep/licenseAddDistributorStep";
import {
    AddLicenseTemplateMutation,
    TasksQuery,
} from "../../../generated/graphql";
import { useMutation, useQuery } from "@apollo/client";
import fragments from "../../../gql/fragments";
import {
    ISequenceTemplateTable,
    ISequencesTemplateTable,
} from "../../../features/sequencesTemplate/domain/entities/sequencesModel";
import { ResultModal } from "../../../components/appCore/resultModal/resultModal";
import { useNavigate } from "react-router-dom";

import { StepWithEditIconComponent } from "../../../components/appCore/stepWithEditIconComponent/stepWithEditIconComponent";
import { useAllSequencesTemplates } from "../../../features/sequencesTemplate/domain/hooks/useAllSequencesTemplates";
import {
    IDistributor,
    ILicenseTemplateModel,
    IMemoryLimits,
} from "../../../features/licenseTemplate/domain/entities/licenseModel";

export const CreateLicensePage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { setHeaderContent } = useHeader();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [sequencesTemplateList, setSequencesTemplateList] = useState<
        ISequencesTemplateTable[]
    >([]);

    const [distributorDetails, setDistributorDetails] = useState<IDistributor>({
        name: "",
        unversionedDistributorName: "",
        version: 0,
    });

    const [memoryLimitsDetails, setMemoryLimitsDetails] =
        useState<IMemoryLimits>({
            currentEcsMemorySize: 8192, //8 GB is the current for ECS task with 4 CPU's
            maxEcsMemorySize: 8192,
        });

    const [licenseDetails, setLicenseDetails] = useState<ILicenseTemplateModel>(
        {
            id: "",
            name: "",
            totalPrice: 0,
            organ: "",
            maxNumberOfCases: 0,
            maxNumberOfUsers: 0,
            sequencesTemplateId: "",
            distributor: distributorDetails,
            memoryLimits: memoryLimitsDetails,
        }
    );

    const [tasks, setTasks] = useState<ISequenceTemplateTable[]>([]);

    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(false);
    const [licenseId, setLicenseId] = useState<string>("");
    const [sequenceDistributorType] = useState<string>("dst");
    const [isIconVisible, setIsIconVisible] = useState<boolean>(false);
    const [sequencesIdKey, setSequencesIdKey] = useState<Key>();
    const [distributorIdKey, setDistributorIdKey] = useState<Key>();
    const [isLicensInfoValid, setIsLicenseInfoValid] = useState<boolean>(false);
    const [isDistributorChosen, setIsDistributorChosen] =
        useState<boolean>(false);
    const ELEMENTS_PAGE = 6;

    //===============HOOKS==================
    const {
        data: sequencesListData,
        isLoading,
        errorMessage,
        getAllSequencesTemplates,
    } = useAllSequencesTemplates();

    const [AddLicenseMutation] = useMutation<AddLicenseTemplateMutation>(
        fragments.CREATE_LICENSE_TEMPLATE
    );

    const { data: TaskTemplateData } = useQuery<TasksQuery>(
        fragments.GET_TASKS_BY_SEQUENCE,
        {
            variables: {
                input: sequenceDistributorType,
            },
        }
    );

    useEffect(() => {
        getAllSequencesTemplates(ELEMENTS_PAGE, 0);
    }, []);

    const onPaginate = (pageNumber: number) => {
        getAllSequencesTemplates(ELEMENTS_PAGE, pageNumber);
    };

    useEffect(() => {
        if (!TaskTemplateData) return;
        setTasks(TaskTemplateData.tasks.tasks as ISequenceTemplateTable[]);
    }, [TaskTemplateData]);

    useEffect(() => {
        setHeaderContent({
            title: t("createLicense.exitHeader"),
            breadCrumb: [],
            goBackLinkOverride: "/licenses",
        });
    }, []);

    useEffect(() => {
        if (!sequencesListData) return;
        setSequencesTemplateList(
            sequencesListData.sequencesTemplatePresentations as ISequencesTemplateTable[]
        );
    }, [sequencesListData]);

    const onFinish = () => {
        setIsSuccessModalVisible(true);
        setIsSuccessModalLoading(true);
        AddLicenseMutation({ variables: licenseDetails }).then((result) => {
            setIsSuccessModalLoading(false);
            setLicenseId(result!.data!.addLicenseTemplate!.id);
        });
    };

    const onShowLicense = () => {
        navigate("../license/" + licenseId);
    };

    const onEditClick = (buttonIndex: number) => {
        setCurrentStep(buttonIndex);
    };

    return (
        <>
            <Row wrap={true} gutter={16} className={styles.Container}>
                <Col span={24} md={8} xl={6}>
                    <StepWithEditIconComponent
                        isEditStep={false}
                        isIconVisible={isIconVisible}
                        currentStep={currentStep}
                        title={t("createLicense.steps.title")}
                        steps={[
                            {
                                title: t("createLicense.steps.1.title"),
                                description: t(
                                    "createLicense.steps.1.description"
                                ),
                            },
                            {
                                title: t("createLicense.steps.2.title"),
                                description: t(
                                    "createLicense.steps.2.description"
                                ),
                            },
                            {
                                title: t("createLicense.steps.3.title"),
                                description: t(
                                    "createLicense.steps.3.description"
                                ),
                            },
                        ]}
                        onItemClick={onEditClick}
                        icon={
                            isIconVisible && (
                                <span
                                    className={`kenko-icon-edit ${styles.Edit}`}
                                />
                            )
                        }
                    />
                </Col>
                <Col span={24} md={16} xl={18}>
                    {(currentStep === 0 && (
                        <LicenseInfoStep
                            licenseDetails={licenseDetails}
                            setLicenseDetails={setLicenseDetails}
                            setCurrentStep={setCurrentStep}
                            setIsStepIconVisible={setIsIconVisible}
                            setIsLicenseInfoValid={setIsLicenseInfoValid}
                            isLicenseInfoValid={isLicensInfoValid}
                        />
                    )) ||
                        (currentStep === 1 && (
                            <LicenseAddDistributorStep
                                tasks={tasks}
                                setLicenseDetails={setLicenseDetails}
                                setCurrentStep={setCurrentStep}
                                setDistributorDetails={setDistributorDetails}
                                distributorDetails={distributorDetails}
                                setIsDistributorChosen={setIsDistributorChosen}
                                isDistributorChosen={isDistributorChosen}
                                distributorIdKey={distributorIdKey}
                                setDistributorIdKey={setDistributorIdKey}
                            />
                        )) ||
                        (currentStep === 2 && (
                            <LicenseAddSequenceStep
                                sequencesTemplateList={sequencesTemplateList}
                                setLicenseDetails={setLicenseDetails}
                                onFinish={onFinish}
                                licenseDetails={licenseDetails}
                                textButton={t("createLicense.create")}
                                sequencesIdKey={sequencesIdKey}
                                setSequencesIdKey={setSequencesIdKey}
                                isFormValid={!!sequencesIdKey}
                                memoryLimitsDetails={memoryLimitsDetails}
                                setMemoryLimitsDetails={setMemoryLimitsDetails}
                                totalResults={sequencesListData?.totalResults}
                                onPaginate={onPaginate}
                                elementsPage={ELEMENTS_PAGE}
                            />
                        ))}
                </Col>
            </Row>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                onPrimary={onShowLicense}
                localisedTexts={{
                    title: t("createLicense.successModal.title"),
                    subtitle: t("createLicense.successModal.subtitle"),
                    secondaryButton: t(
                        "createLicense.successModal.createAnother"
                    ),
                    primaryButton: t("createLicense.successModal.showCase"),
                }}
            />
        </>
    );
};
