import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import styles from "./visualizerLayoutSelector.module.scss";
import { Dispatch, SetStateAction } from "react";

const { Text } = Typography;

interface IProps {
    setVisualizerLayout: Dispatch<SetStateAction<number>>;
    emptyVisualizerWindowByIndex: (index: number) => void;
    activeWindow: number;
    setActiveWindow: Dispatch<SetStateAction<number>>;
    isMultiplanar: boolean;
}

export const VisualizerLayoutSelector = ({
    setVisualizerLayout,
    emptyVisualizerWindowByIndex,
    activeWindow,
    setActiveWindow,
    isMultiplanar
}: IProps) => {
    const { t } = useTranslation();

    const onChange = (e: any) => {
        setVisualizerLayout(parseInt(e.target.value));

        if (parseInt(e.target.value) < 4) {
            emptyVisualizerWindowByIndex(2);
            emptyVisualizerWindowByIndex(3);

            if (activeWindow > 1) {
                setActiveWindow(0);
            }
        }
        if (parseInt(e.target.value) < 2) {
            emptyVisualizerWindowByIndex(1);

            setActiveWindow(0);
        }
    };

    return (
        <>
        { !isMultiplanar ? (<div className={styles.Container} >
            <Text className={styles.Text}>{t("visualizer.header.window")}</Text>
            <div className={styles.SelectBox}>
                <select
                    defaultValue="one"
                    onChange={onChange}
                    className={styles.Select}
                    disabled={isMultiplanar}
                >
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={4}>Four</option>
                </select>
            </div>
        </div>) : (<></>)}
        </>
    );
};
