import { useTranslation } from "react-i18next";
import { Form, Modal, Radio, Typography } from "antd";
import styles from "./finalDiagnosisModal.module.scss"
import TextArea from "antd/es/input/TextArea";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Spacer } from "../../../components/appCore/spacer/spacer";
import { useUpdateCase } from "../domain/hooks/useUpdateCase";
import { ICase, ICaseDetails, StatusType } from "../domain/entities/case";

const { Text } = Typography;

interface IProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    caseId?: string;
    caseDetails: ICaseDetails;
    setCaseDetails: Dispatch<SetStateAction<ICaseDetails| undefined>>;
}

interface IFormContent {
    id?: string;
    isPositive?: boolean;
    finalNotes?: string;
}

export const FinalDiagnosisModal = ({
    isVisible,
    setIsVisible,
    caseId,
    caseDetails,
    setCaseDetails,
}: IProps) => {
    const { t } = useTranslation();
    const [formContent, setFormContent] = useState<IFormContent | undefined>();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const [form] = Form.useForm();

    const {data: updatedCase, updateFinalDiagnosisCase} =  useUpdateCase();

    const validateMessages = {
        required: t("fieldValidation.required"),
    };

    const onChange = () => {
        let isValid =
            form.getFieldsValue().isPositivePrediction !== undefined &&
            form.getFieldsValue().finalNotes !== "" && form.getFieldsValue().finalNotes !== undefined;

        setFormContent({
            id: caseId,
            isPositive: form.getFieldsValue().isPositivePrediction,
            finalNotes: form.getFieldsValue().finalNotes,
        });
        setIsFormValid(isValid);
    };

    const onAccept = () => {
        if(!formContent?.id || !formContent?.finalNotes) {
            setIsVisible(false);
            return
        }
        updateFinalDiagnosisCase(formContent.id, formContent.finalNotes, formContent.isPositive!)
        setIsVisible(false);
    };

    const options = [
        { label: t("positive"), value: true },
        { label: t("negative"), value: false },
    ];

    useEffect(() => {
        if(!updatedCase ||
            !updatedCase.state) return;
        setCaseDetails({...caseDetails, 
            state:updatedCase.state as StatusType, 
            isPositive:updatedCase.isPositive, 
            finalNotes: updatedCase.finalNotes
        })
    }, [updatedCase])

    return (
        <Modal
            title={t("caseDetails.finalDiagnosis.title")}
            visible={isVisible}
            cancelText={t("cancel")}
            okText={t("caseDetails.finalDiagnosis.addDiagnosis")}
            okButtonProps={{ disabled: !isFormValid }}
            onOk={onAccept}
            onCancel={() => setIsVisible(false)}
        >
            <Text>{t("caseDetails.finalDiagnosis.description")}</Text>
            <Form
                layout="vertical"
                form={form}
                onChange={onChange}
                validateMessages={validateMessages}
            >
                <Spacer size={"m"} />

                <Form.Item
                    label={t("caseDetails.finalDiagnosis.selectDiagnosis")}
                    name="isPositivePrediction"
                    className={styles.HorizontalFormItem}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group
                        options={options}
                        optionType="button"
                        buttonStyle="solid"
                        className={styles.RadioSelect}
                    />
                </Form.Item>

                <Form.Item
                    label={t("caseDetails.finalDiagnosis.diagnosisDescription")}
                    name="finalNotes"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea
                        placeholder={t(
                            "createCase.infoStep.caseObservationsPrompt"
                        )}
                        maxLength={200}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};