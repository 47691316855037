import { throwExceptionFromGqlQueryIfExists } from "../../../../core/data/dataSources/kenkoDataSource";
import { useGetLicensesLazyQuery } from "../../../../generated/graphql";
import { ILicenseListPresentation } from "../../domain/entities/licenseModel";

export interface ILicenseDataSource {
    getAllLicenseTemplates: (
        pageSize: number,
        pageNumber: number
    ) => Promise<ILicenseListPresentation | undefined | null>;
}

export const LicenseDataSource = (): ILicenseDataSource => {
    const [licensesQuery] = useGetLicensesLazyQuery();

    const getAllLicenseTemplates = async (
        pageSize: number,
        pageNumber: number
    ) => {
        const result = await licensesQuery({
            variables: {
                input: { pageSize: pageSize, pageNumber: pageNumber },
            },
        });
        await throwExceptionFromGqlQueryIfExists(result);
        return result.data!.licenses;
    };

    return {
        getAllLicenseTemplates,
    };
};
