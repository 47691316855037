import { Content } from "antd/es/layout/layout";
import styles from "./visualizer.module.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { VisualizerWindow } from "./visualizerWindow/visualizerWindow";
import { VisualizerEmptyWindow } from "./visualizerEmptyWindow/visualizerEmptyWindow";
import React from "react";
import { ISequenceSliceController, ISequenceSliceReference } from "../../domain/entities/sequence";
import { IVisualizerWindow } from "../../domain/entities/modelImages";

export interface ICoordinates {
    x: number;
    y: number;
    z: number;
}

interface IProps {
    visualizerLayout: number;
    visualizerWindows: IVisualizerWindow[];
    setActiveWindow: Dispatch<SetStateAction<number>>;
    activeWindow: number;
    singleImages: Set<string> | undefined;
    isMultiplanar: boolean;
    isPixelAnalizeActive: boolean;
    sequenceSliceController: ISequenceSliceController;
    setSequenceSliceController: Dispatch<SetStateAction<ISequenceSliceController>>;
    sequenceSliceReference: ISequenceSliceReference;
    setSequenceSliceReference: Dispatch<SetStateAction<ISequenceSliceReference>>;
}

export const Visualizer = ({
    visualizerLayout,
    visualizerWindows,
    setActiveWindow,
    activeWindow,
    singleImages,
    isMultiplanar,
    isPixelAnalizeActive,
    sequenceSliceController,
    setSequenceSliceController,
    sequenceSliceReference,
    setSequenceSliceReference
}: IProps) => {

    const [coordinates, setCoordinates] = useState<ICoordinates>({
        x: 0,
        y: 0,
        z: 0,
    });

    const createVisualizerImage = (visualizerLayout:number, index:number, visualizerWindow:IVisualizerWindow, activeSlice:number, sliceCount:number, 
        setActiveWindow: Dispatch<SetStateAction<number>>, activeWindow: number, isSingleImage:boolean, isPixelAnalizeActive:boolean, 
        relativeZAxis: keyof ICoordinates, view: string) =>{

            return <VisualizerWindow
                sliceCount={sliceCount}
                visualizerLayout={visualizerLayout}
                windowIndex={index}
                key={index}
                activeSlice={activeSlice}
                visualizerWindow={visualizerWindow}
                setActiveWindow={setActiveWindow}
                activeWindow={activeWindow}
                isSingleImage={isSingleImage}
                isPixelAnalizeActive={isPixelAnalizeActive}
                relativeZAxis={relativeZAxis}
                setCoordinates={setCoordinates}
                view={view}
                sequenceSliceController={sequenceSliceController}
                setSequenceSliceController={setSequenceSliceController}
                sequenceSliceReference={sequenceSliceReference}
                setSequenceSliceReference={setSequenceSliceReference}
                isMultiplanar={isMultiplanar}
            />
    }

    const generateLayout = () => {
        if(isMultiplanar){
            return generateMultiplanarViews()
        }
        let content = [];
        for (let i = 0; i < visualizerLayout; i++) {
            let activeSlice = 1;
            let sliceCount = 1;
            if(sequenceSliceController[visualizerWindows[i].activeType] !== undefined){
                activeSlice = sequenceSliceController[visualizerWindows[i].activeType]["axial"].activeSlice;
                sliceCount = sequenceSliceController[visualizerWindows[i].activeType]["axial"].sliceCount;
            }
            if(singleImages?.has(visualizerWindows[i].activeBiomarker)){
                content.push(
                    //Axial data is only for the slider logic
                    createVisualizerImage(visualizerLayout, i, visualizerWindows[i], 1,
                        1, setActiveWindow, activeWindow, true, false, "z", "axial")
                )
            }else{
                content.push(
                    createVisualizerImage(visualizerLayout, i, visualizerWindows[i], 
                        activeSlice, 
                        sliceCount,
                        setActiveWindow, activeWindow, false, false, "z", "axial")
                )
            }
        }
        return content;
    };

    const checkCoordinatesLimits = (coordinates: ICoordinates) => {
        if(coordinates.x <= 0 || coordinates.x > sequenceSliceController[visualizerWindows[activeWindow].activeType]["sagital"].sliceCount){
            coordinates.x = checkLowerLimit(coordinates.x)
            coordinates.x = checkUpperLimit(coordinates.x, sequenceSliceController[visualizerWindows[activeWindow].activeType]["sagital"].sliceCount, 
                sequenceSliceController[visualizerWindows[activeWindow].activeType]["sagital"].activeSlice)
        } 
        if(coordinates.y <= 0 || coordinates.y > sequenceSliceController[visualizerWindows[activeWindow].activeType]["coronal"].sliceCount){
            coordinates.y = checkLowerLimit(coordinates.y)
            coordinates.y = checkUpperLimit(coordinates.y, sequenceSliceController[visualizerWindows[activeWindow].activeType]["coronal"].sliceCount, 
                sequenceSliceController[visualizerWindows[activeWindow].activeType]["coronal"].activeSlice)
        } 
        if(coordinates.z <= 0 || coordinates.z > sequenceSliceController[visualizerWindows[activeWindow].activeType]["axial"].sliceCount){
            coordinates.z = checkLowerLimit(coordinates.z)
            coordinates.z = checkUpperLimit(coordinates.z, sequenceSliceController[visualizerWindows[activeWindow].activeType]["axial"].sliceCount, 
                sequenceSliceController[visualizerWindows[activeWindow].activeType]["axial"].activeSlice)
        } 
        return coordinates;
    }

    const checkLowerLimit = (axis: number) => {
        return axis <= 0 ? 1 : axis;
    }

    const checkUpperLimit = (axis: number, sliceCount: number, activeSlice: number) => {
        return axis > sliceCount && activeSlice !== sliceCount+1 ? sliceCount : axis;
    }

    const generateMultiplanarViews = () => {
        let content = [];

        //Window 0: set axial view; Window 1 empty; Window 2: set coronal view; Window 3: set sagital view;
        content.push(
            createVisualizerImage(4, 0, visualizerWindows[0], 
                sequenceSliceController[visualizerWindows[0].activeType]["axial"].activeSlice, 
                sequenceSliceController[visualizerWindows[0].activeType]["axial"].sliceCount,
                setActiveWindow, activeWindow, false, isPixelAnalizeActive, "z", "axial"),
            <VisualizerEmptyWindow />,
            createVisualizerImage(4, 2, visualizerWindows[2], 
                sequenceSliceController[visualizerWindows[0].activeType]["coronal"].activeSlice, 
                sequenceSliceController[visualizerWindows[0].activeType]["coronal"].sliceCount,
                setActiveWindow, activeWindow, false, isPixelAnalizeActive, "y", "coronal"),
            createVisualizerImage(4, 3, visualizerWindows[3],  
                sequenceSliceController[visualizerWindows[0].activeType]["sagital"].activeSlice, 
                sequenceSliceController[visualizerWindows[0].activeType]["sagital"].sliceCount,
                setActiveWindow, activeWindow, false, isPixelAnalizeActive, "x", "sagital")
        )
 
        return content;
    }

    useEffect(() => {
        if(!isPixelAnalizeActive || !coordinates) return;
        let checkedCoordinates = checkCoordinatesLimits(coordinates)
        sequenceSliceController[visualizerWindows[activeWindow].activeType]["axial"].activeSlice = checkedCoordinates.z;
        sequenceSliceController[visualizerWindows[activeWindow].activeType]["coronal"].activeSlice = checkedCoordinates.y;
        sequenceSliceController[visualizerWindows[activeWindow].activeType]["sagital"].activeSlice = checkedCoordinates.x;
        setSequenceSliceController({...sequenceSliceController});
    }, [coordinates])

    return (  
        <Content className={styles.Container}>
            {generateLayout().map((component, index) => (
            <React.Fragment key={index}>
                {component}
            </React.Fragment>
            ))}
        </Content>
        
    );
};
