import { SequencePresentation } from "../../../../generated/graphql";
import { ISequenceImages } from "./modelImages";

export interface ISequenceSliceController {
    [activeType: string]:{
        [view: string]:{
            activeSlice: number;
            sliceCount: number;
        };
    }
}

export interface ISequenceSliceReference {
    type: string;
    activeSlice: number;
    sliceCount: number;
}

export interface ISequence {
    biomarkers: IBiomarkers[];
    singleImages: string[];
    type: string;
    views: IView;
}

export interface IBiomarkers {
    key: string;
    value: string[];
}

export interface IView{
    axial?: number;
    coronal?: number;
    sagital?: number;
}

export interface ISequencesData{
    sequences: ISequence[],
    images: ISequenceImages,
    biomarkerTag: IBiomarkerModelTag | undefined,
}

export interface IBiomarkerModelTag {
    name: string;
    sequenceModel: string;
    sequenceType: string;
    biomarker: string;
    color?: string;
    views: IView
}

export const sequenceFromSequencePresentation = (biomarkerPresentation:SequencePresentation):ISequence => {
    let biomarkerResult: ISequence = {...biomarkerPresentation, biomarkers: [] ,views:{}};
    biomarkerPresentation.views.forEach((view) => {
        let key: keyof IView = view.key as keyof IView;
        biomarkerResult.views[key] = view.value;
    })

    biomarkerPresentation.biomarkers.forEach((biomarkers) => {
        let bio: IBiomarkers = {
            key : biomarkers.key, 
            value: biomarkers.value.concat(biomarkerPresentation.singleImages)
        } as IBiomarkers;
        biomarkerResult.biomarkers.push(bio)
    })
    
    return biomarkerResult
}