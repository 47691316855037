import { useState } from "react";
import { IUseDomainHook } from "../../../../core/domain/interfaces";
import { ILicenseFeatureSimpleData, licenseFeatureFromPartialFeature } from "../entities/licenseFeature";
import { LicenseFeatureDataSource } from "../../data/dataSources/featureDataSource";
import { faro } from '@grafana/faro-web-sdk';

interface IAdditionalMethods {
    getUnassignedLicenseFeatures: (orgId: string) => Promise<void>;
}

export const useUnassignedLicenseFeaturea: IUseDomainHook<
    ILicenseFeatureSimpleData[] | undefined,
    IAdditionalMethods
> = () => {
    const dataSource = LicenseFeatureDataSource();
    const [features, setFeatures] = useState<ILicenseFeatureSimpleData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const getUnassignedLicenseFeatures = async (orgId: string) => {
        setIsLoading(true);

        try {
            const result = await dataSource.getUnassignedLicenseFeature(orgId);
            let simplifiedResults: ILicenseFeatureSimpleData[] = []
            result.forEach((feature) => {
                simplifiedResults.push(licenseFeatureFromPartialFeature(feature));
            })
            setFeatures(simplifiedResults);
            
        } catch (e: any) {
            faro.api.pushError(new Error(e.message));
            setErrorMessage(e.message);
        }

        setIsLoading(false);
    };

    return {
        data:features,
        isLoading,
        errorMessage,
        getUnassignedLicenseFeatures,
    };
};