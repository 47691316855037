import { gql } from "@apollo/client";

const GET_CASE_BY_ID = gql`
    query case($id: String!) {
        case(id: $id) {
            id
            reference
            creationDate
            organ
            isPositivePrediction
            state
            isPositive
            initialNotes
            finalNotes
            percentage
            zipState
            zipLink
        }
    }
`;

const CREATE_CASE = gql`
    mutation addCase(
        $initialNotes: String!
        $isPositivePrediction: Boolean!
        $reference: String!
    ) {
        addCase(
            input: {
                initialNotes: $initialNotes
                isPositivePrediction: $isPositivePrediction
                reference: $reference
            }
        ) {
            id
        }
    }
`;

const UPDATE_CASE = gql`
    mutation updateCase(
        $id: String!
        $finalNotes: String!
        $isPositive: Boolean!
    ) {
        updateCase(
            input: { id: $id, finalNotes: $finalNotes, isPositive: $isPositive }
        ) {
            id
            finalNotes
            isPositive
            state
        }
    }
`;

const CASE_CHANGED = gql`
    subscription caseChanged($id: String!) {
        case(id: $id) {
            relevantId
            newState
            percentage
            zipState
            zipLink
        }
    }
`;

const GET_ALL_CASES = gql`
    query allCases($input: GetCaseInput!) {
        cases(input: $input) {
            casePresentations {
                id
                reference
                creationDate
                organ
                isPositivePrediction
                state
                isPositive
                initialNotes
                user {
                    id
                    name
                    surname
                }
            }
            totalResults
        }
    }
`;

const REQUEST_EXPORT = gql`
    mutation requestExport($id: String!, $exportType: String!) {
        requestExport(input: { id: $id, exportType: $exportType }) {
            zipState
            zipLink
        }
    }
`;

const DELETE_CASE = gql`
    mutation deleteCase($caseId: String!) {
        deleteCase(caseId: $caseId) {
            id
            user {
                id
                name
                surname
            }
            initialNotes
            state
            creationDate
        }
    }
`;

export const caseFragments = {
    GET_ALL_CASES,
    GET_CASE_BY_ID,
    CREATE_CASE,
    UPDATE_CASE,
    CASE_CHANGED,
    REQUEST_EXPORT,
    DELETE_CASE,
};
