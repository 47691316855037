import HtmlPrinter from "../../../../../components/helpers/htmlPrinter/htmlPrinter";
import styles from "./darkTag.module.scss";


interface IProps {
    biomarker: string;
    isActive: boolean;
    setActiveAction: () => any;
}

export const DarkTag = ({ biomarker, isActive, setActiveAction }: IProps) => {
    return (
        <div
            className={`${styles.Tag} ${isActive ? styles.Active : ""}`}
            onClick={setActiveAction}
        >
            <HtmlPrinter content={biomarker} />
        </div>
    );
};
