import { useEffect, useRef, useState } from "react";
import styles from "./pixelAnalizeImage.module.scss"
import { WindowStyle } from "../visualizerWindow/visualizerWindow";
import { IVisualizerWindow } from "../../../domain/entities/modelImages";


interface IPixelAnalizeImageProps {
    onClick: (arg0: any, arg1: number, arg2: number, arg3: number, arg4: number) => void;
    visualizerWindow: IVisualizerWindow;
    style: WindowStyle;
}

export const PixelAnalizeImage = ({
    onClick,
    visualizerWindow,
    style
}: IPixelAnalizeImageProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

    const handleResize = () => {
        if (!imgRef.current) return;
        const img = imgRef.current;
        setCanvasSize({ width: img.width, height: img.height });
    };

    useEffect(() => {
        if (!imgRef.current) return;
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!canvasRef.current || !imgRef.current) return;

        const canvas = canvasRef.current;
        const img = imgRef.current;

        canvas.width = canvasSize.width;
        canvas.height = canvasSize.height;
        canvas.style.position = 'absolute';
        canvas.style.top = img.offsetTop + 'px';
        canvas.style.left = img.offsetLeft + 'px';
    }, [canvasSize]);

    useEffect(() => {
        if (!imgRef.current) return;
        const img = imgRef.current;
        setCanvasSize({ width: img.width, height: img.height });
    }, [visualizerWindow]);

    return (
        <>
            <div 
            className={styles.Container} 
            style={{
                width: style.width
            }}
            >
                <img
                    className={styles.MainImage}
                    src={visualizerWindow.activeImageSrc}
                    alt="biomarkerImage"
                    ref={imgRef}
                    style={{
                        height: style.height,
                        width: style.width
                    }}
                />
            </div>
            
            <div>
                <canvas
                    ref={canvasRef}
                    className={styles.MainCanvas}
                    onClick={(event: any) => 
                        onClick(event, imgRef?.current?.getBoundingClientRect().width ?? 0, 
                        imgRef?.current?.getBoundingClientRect().height ?? 0,
                        imgRef?.current?.naturalWidth!, imgRef?.current?.naturalHeight!)
                    }
                />
            </div>
        </>

    );
};