import { gql } from "@apollo/client";

const GLOBAL_NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription global($userId: String!) {
        global(userId: $userId) {
            notificationType
            relevantId
            numberIfRelevant
            textIfRelevant
            doctorReference
        }
    }
`;

const GET_REMAINING_ITEMS = gql`
    query remainingItems {
        remainingItems {
            remainingCases
            remainingUsers
        }
    }
`;

export const notificationFragments = {
    GLOBAL_NOTIFICATIONS_SUBSCRIPTION,
    GET_REMAINING_ITEMS,
};
