import { Form, Input, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Spacer } from "../../../appCore/spacer/spacer";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { ResultModal } from "../../../appCore/resultModal/resultModal";
import { useMutation } from "@apollo/client";
import fragments from "../../../../gql/fragments";
import { CreateSupportMessageMutation } from "../../../../generated/graphql";

const { Text } = Typography;

interface IProps {
    setIsChangeLicenseRequestModalVisible: Dispatch<SetStateAction<boolean>>;
}

interface IChangeLicenseRequestForm {
    numberOfCases: number;
    numberOfLicenses: number;
    extraInfo: string;
}

export const ChangeLicenseRequestModal = ({
    setIsChangeLicenseRequestModalVisible,
}: IProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [detailsToSend, setDetailsToSend] = useState<
        IChangeLicenseRequestForm | undefined
    >(undefined);
    const [isSuccessModalVisible, setIsSuccessModalVisible] =
        useState<boolean>(false);
    const [isSuccessModalLoading, setIsSuccessModalLoading] =
        useState<boolean>(true);
    const [isSuccessModalError, setIsSuccessModalError] =
        useState<boolean>(false);

    const [CreateSupportMessage] = useMutation<CreateSupportMessageMutation>(
        fragments.CREATE_SUPPORT_MESSAGE
    );

    const checkIsFormValid = (input: IChangeLicenseRequestForm | undefined) => {
        return (
            input &&
            input.numberOfCases !== undefined &&
            input.numberOfCases !== 0 &&
            input.numberOfLicenses !== undefined &&
            input.numberOfLicenses !== 0 &&
            input.extraInfo !== undefined &&
            input.extraInfo !== ""
        );
    };

    const onChange = (
        changedValues: IChangeLicenseRequestForm | undefined,
        values: IChangeLicenseRequestForm | undefined
    ) => {
        if (!values) {
            setIsFormValid(false);
            return;
        }
        if (!checkIsFormValid(values)) {
            setIsFormValid(false);
            return;
        }

        setDetailsToSend(values);

        setIsFormValid(true);
    };

    const onSend = (values: IChangeLicenseRequestForm | undefined) => {
        if (!values) return;
        if (!checkIsFormValid(values)) return;

        setIsSuccessModalVisible(true);

        const line1 = "New number of cases: " + values.numberOfCases;
        const line2 = "New number of user licenses: " + values.numberOfLicenses;
        const line3 = "Extra info: " + values.extraInfo;

        const content = line1 + "\n" + line2 + "\n" + line3;

        CreateSupportMessage({
            variables: { title: "Change License Request", content: content },
        })
            .then((res) => {
                if (res === undefined || res === "")
                    setIsSuccessModalError(true);
                setIsSuccessModalLoading(false);
            })
            .catch(() => {
                setIsSuccessModalError(true);
            });
    };

    const closeModal = () => {
        setIsSuccessModalVisible(false);
        setIsSuccessModalLoading(true);
        setIsSuccessModalError(false);
        setIsChangeLicenseRequestModalVisible(false);
    };

    return (
        <>
            <Modal
                title={t(
                    "myAdminProfile.orgAndLicenseDetails.licenseExtensionRequest"
                )}
                visible={!isSuccessModalVisible}
                cancelText={t("cancel")}
                onCancel={() => setIsChangeLicenseRequestModalVisible(false)}
                okText={t("myAdminProfile.changeLicenseRequest.send")}
                onOk={() => onSend(detailsToSend)}
                okButtonProps={{ disabled: !isFormValid }}
            >
                <Text>{t("myAdminProfile.changeLicenseRequest.subtitle")}</Text>

                <Spacer size="s" />

                <Form layout="vertical" onValuesChange={onChange}>
                    <Form.Item
                        label={t(
                            "myAdminProfile.changeLicenseRequest.newCasesTitle"
                        )}
                        name="numberOfCases"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "myAdminProfile.changeLicenseRequest.newCasesPrompt"
                            )}
                            type="number"
                        />
                        {/*TODO: Add subtitle here with the correct number*/}
                    </Form.Item>

                    <Form.Item
                        label={t(
                            "myAdminProfile.changeLicenseRequest.newUserLicensesTitle"
                        )}
                        name="numberOfLicenses"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "myAdminProfile.changeLicenseRequest.newUserLicensesPrompt"
                            )}
                            type="number"
                        />
                        {/*TODO: Add subtitle here with the correct number*/}
                    </Form.Item>

                    <Form.Item
                        label={t(
                            "myAdminProfile.changeLicenseRequest.extraInfoTitle"
                        )}
                        name="extraInfo"
                        rules={[
                            {
                                required: true,
                                message: t("form.required"),
                            },
                        ]}
                    >
                        <TextArea
                            placeholder={t(
                                "myAdminProfile.changeLicenseRequest.extraInfoPrompt"
                            )}
                            showCount
                            maxLength={200}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <ResultModal
                isVisible={isSuccessModalVisible}
                isLoading={isSuccessModalLoading}
                isError={isSuccessModalError}
                onPrimary={() => {
                    setIsSuccessModalVisible(false);
                    setIsChangeLicenseRequestModalVisible(false);
                }}
                hasSecondary={false}
                onErrorPrimary={() => {
                    closeModal();
                    navigate("/support");
                }}
                onErrorSecondary={closeModal}
                localisedTexts={{
                    title: t(
                        "myAdminProfile.changeLicenseRequest.success.title"
                    ),
                    subtitle: t(
                        "myAdminProfile.changeLicenseRequest.success.subtitle"
                    ),
                    primaryButton: t(
                        "myAdminProfile.changeLicenseRequest.success.back"
                    ),
                    errorTitle: t(
                        "myAdminProfile.changeLicenseRequest.error.title"
                    ),
                    errorSubtitle: t(
                        "myAdminProfile.changeLicenseRequest.error.subtitle"
                    ),
                    errorRetry: t(
                        "myAdminProfile.changeLicenseRequest.error.back"
                    ),
                    errorPrimary: t(
                        "myAdminProfile.changeLicenseRequest.error.support"
                    ),
                }}
            />
        </>
    );
};
