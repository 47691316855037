import { useTranslation } from "react-i18next";
import { HelpCard } from "../../components/supportComponents/helpCard/helpCard";
import styles from "./supportPage.module.scss";
import { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import { useHeader } from "../../hooks/headerHook";
import { useNavigate } from "react-router-dom";
import { UserManualCard } from "../../components/supportComponents/userManualCard/userManualCard";
import { Spacer } from "../../components/appCore/spacer/spacer";
import { HowItWorksCard } from "../../components/supportComponents/howItWorksCard/howItWorksCard";
import { AboutCard } from "../../components/supportComponents/aboutCard/aboutCard";
import { SendCommentModal } from "../../components/supportComponents/sendCommentModal/sendCommentModal";
import { RemainingItemsQuery } from "../../generated/graphql";
import { useQuery } from "@apollo/client";
import fragments from "../../gql/fragments";

const SupportPage = () => {
    const { t } = useTranslation();
    const { setHeaderContent } = useHeader();
    let navigate = useNavigate();

    const [isSendCommentModalVisible, setIsSendCommentModalVisible] =
        useState<boolean>(false);
    const { data: remainingItemsData } = useQuery<RemainingItemsQuery>(
        fragments.GET_REMAINING_ITEMS
    );

    useEffect(() => {
        setHeaderContent({
            title: t("support.title"),
            breadCrumb: [],
            goBackLinkOverride: undefined,
            buttonElement: (
                <Button
                    type="primary"
                    size="large"
                    key="caseListBasicUser"
                    onClick={() => {
                        navigate("/createCase");
                    }}
                    disabled={
                        remainingItemsData?.remainingItems.remainingCases === 0
                    }
                >
                    {t("caseList.createCase")}
                    <span className={`kenko-icon-plus ${styles.buttonIcon}`} />
                </Button>
            ),
        });
    }, [setHeaderContent, t, remainingItemsData]);

    return (
        <>
            <Tabs size="large" className={styles.Tabs}>
                <Tabs.TabPane tab={t("support.tabs.help")} key="1">
                    <div className={styles.Container}>
                        <div className={styles.Col}>
                            <div className={styles.Row}>
                                <HelpCard
                                    setIsSendCommentModalVisible={
                                        setIsSendCommentModalVisible
                                    }
                                />
                            </div>
                            <Spacer size="s" />
                            <div className={styles.Row}>
                                <HowItWorksCard />
                            </div>
                        </div>
                        <div className={styles.Col}>
                            <div className={styles.Row}>
                                <UserManualCard />
                            </div>
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("support.tabs.info")} key="2">
                    <div>
                        <AboutCard />
                    </div>
                </Tabs.TabPane>
            </Tabs>
            {isSendCommentModalVisible && (
                <SendCommentModal
                    setIsSendCommentModalVisible={setIsSendCommentModalVisible}
                />
            )}
        </>
    );
};

export default SupportPage;
