import { Card } from "antd";
import styles from "./notificationBox.module.scss";
import { NotificationTile } from "./notificationTile/notificationTile";
import React, { RefObject } from "react";
import { INotification } from "../appHeader/appHeader";
import { useTranslation } from "react-i18next";
import { PushNotificationType } from "../../models/core/notificationModel";

interface IProps {
    reference: RefObject<HTMLDivElement>;
    notificationList: INotification[];
    setAsRead: (element: INotification) => void;
    deleteNotification: (element: INotification) => void;
    setAllRead: () => void;
}

export const NotificationBox = ({
    reference,
    notificationList,
    setAsRead,
    deleteNotification,
    setAllRead,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Card
            ref={reference}
            className={styles.Container}
            title={t("notifications.title")}
            extra={
                <a onClick={() => setAllRead()}>
                    {t("notifications.markAsRead")}
                </a>
            }
        >
            {notificationList.map((element, i) => {
                // TODO: Create configuration for this
                const { notificationType, doctorReference, relevantId } =
                    element;

                let notification = "";

                switch (notificationType) {
                    case PushNotificationType.FewCasesLeft:
                        notification = t("notifications.fewCasesLeft", {
                            count: element.numberIfRelevant,
                        });
                        break;
                    case PushNotificationType.FewUsersLeft:
                        notification = t("notifications.fewUsersLeft", {
                            count: element.numberIfRelevant,
                        });
                        break;
                    case PushNotificationType.CaseCompleted:
                        notification = t("notifications.caseComplete", {
                            caseUrl: `/case/${relevantId}`,
                            label: doctorReference,
                        });
                        break;
                    case PushNotificationType.ZipStateChanged:
                        if (element.textIfRelevant === "Processing") {
                            notification = t(
                                "notifications.zipStateChanged.processing",
                                {
                                    caseUrl: `/case/${relevantId}`,
                                    label: doctorReference,
                                }
                            );
                        } else if (element.textIfRelevant === "Finished") {
                            notification = t(
                                "notifications.zipStateChanged.finished",
                                {
                                    caseUrl: `/case/${relevantId}`,
                                    label: doctorReference,
                                }
                            );
                        }
                        break;
                }

                return (
                    <NotificationTile
                        notification={element}
                        text={notification}
                        setAsRead={() => setAsRead(element)}
                        deleteNotification={() => {
                            deleteNotification(element);
                        }}
                        key={i}
                    />
                );
            })}
        </Card>
    );
};
